import React, {useEffect, useState} from "react";
import {Alert, Form, Input, message, Modal, Upload} from 'antd';
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import axios, {AxiosError} from "axios";

const getBase64 = (img:any, callback:any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file:File) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
};


const ProfileEditModal = (props:any) =>
{
  const [Profile, setProfile] = useState<any>(undefined);
  const [AvatarUploadURL, setAvatarUploadURL] = useState<string>('');
  const [DetailsDisabled, setDetailsDisabled] = useState(true);
  const [ImgUploaded,setImgUploaded] = useState(false);
  const [ErrorMessage,setErrorMessage] = useState<string|undefined>(undefined);
  const [TenantBehaviour, setTenantBehaviour] = useState(null);
  const [Loaded,setLoaded] = useState(false);

  // Based on if we are editing or adding
  const [Editing, setEditing] = useState(false);
  const [Adding, setAdding] = useState(false);

  const [form] = Form.useForm();
  const [formBehaviour] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');


  useEffect(() => {

    console.dir(props.profile);

    props.api.getMeAvatarUploadURL().then((blob:any) => {
      setAvatarUploadURL(blob.Location);
    });

    setProfile(props.profile);

  },[props.profile]);

  const handleFinish = (values:any) => {
    let payload = {};

    if(ImgUploaded)
    {
      props.api.updateProfile(payload).then(() => {
        props.onUpdated();
      })
    } else {
      message.error("Upload a user avatar before finishing profile editing");
    }


  };

  const upload = (options:any) => {
    axios.put(options.action, options.file, {
      headers: {
        'Content-Type': 'application/octet-stream',
        "x-ms-blob-type": "BlockBlob"
      }
    }).then((res) => {
      options.onSuccess(res.data, options.file)
      setImgUploaded(true);
    }).catch((err:AxiosError) => {
      setErrorMessage("Profile upload failed with error " + err.message);
    });
  }

  const handleChange = (info:any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url:string) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
            style={{
              marginTop: 8,
            }}
        >
          Upload
        </div>
      </div>
  );

  return (
      <Modal
          title="Profile Edit"
          width={500}
          onCancel={props.onCancel}
          open={true}
          cancelButtonProps={{
            disabled: true
          }}
          onOk={form.submit}>

        <Form
            layout="vertical"
            initialValues={Profile}
            form={form}
            onFinish={handleFinish}>

          {ErrorMessage && <Alert message={ErrorMessage} type="error" />}

          <Form.Item label="Profile picture" required tooltip="Upload a profile picture smaller than 2MB">
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                method="PUT"
                action={AvatarUploadURL}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={upload}>

              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item name="firstname" label="Firstname" required tooltip="Firstname" hidden={true}>
            <Input disabled={DetailsDisabled} />
          </Form.Item>

          <Form.Item name="surname" label="Surname" required tooltip="Surname" hidden={true}>
            <Input disabled={DetailsDisabled} />
          </Form.Item>

        </Form>

      </Modal>

  )
}

export default ProfileEditModal;