import React, {useContext, useRef} from "react";
import {Badge, Button, Dropdown, Image, Modal, Space, Table, Tag, Typography} from 'antd';
import Text from "antd/es/typography/Text";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import ProxyPhishletEditModal from "./ProxyPhishletEditModal/ProxyPhishletEditModal";
import ModalRef from "../../../classes/ModalRef/ModalRef";

const ProxyPhishletTable =
    ({ProxyPhishlets, Loading, onChange}: { ProxyPhishlets: any, Loading: boolean, onChange: () => void}) => {

        // Construct token
        const {token} = useContext(AppContext);
        const APIT = new APITProvider(token, true);

        // Modal refs
        const refPhishletEditDrawer = useRef<ModalRef>(null);

        const Delete = async (id:string) => await APIT.deleteProxyPhishlet(id).then(() => onChange());

        return (
            <>
                <ProxyPhishletEditModal ref={refPhishletEditDrawer} />
                <Table
                    dataSource={ProxyPhishlets}
                    rowKey="id"
                    pagination={false}
                    loading={Loading}
                    columns={[
                       // {
                            //:'icon',
                           // width:50,
                            //render: (record) => {
                                // OS Icon selection
                                //if(record.OS === "Linux") return <FontAwesomeIcon icon={faDocker} />
                               /// if(record.OS === "Windows") return <WindowsOutlined/>
                                //if(record.OS === "Mac") return <AppleOutlined/>

                               // return <DesktopOutlined />

                            //}
                       //},
                        {
                            title: "Name",
                            key: 'Name',
                            render: (record) => {
                                let imgUrl = "https://www.google.com/s2/favicons?domain=" + record.Destination;
                                return <Space>
                                    <Image src={imgUrl}/>
                                    <Typography.Text>{record.Name}</Typography.Text>
                                </Space>
                            },
                        },
                        {
                            title: "Description",
                            key: 'Description',
                            dataIndex: 'Description',
                            render: (text) => text,
                        },
                        {
                            title: "Destination",
                            key: 'Destination',
                            dataIndex: 'Destination',
                            render: (text) => text,
                        },
                        {
                            key:'actions',
                            render:(record) => {

                                let items = [
                                    {
                                        label: 'Edit',
                                        icon: <EditOutlined/>,
                                        key: 'Edit',
                                    },
                                    {
                                        label: 'Delete',
                                        icon: <DeleteOutlined/>,
                                        key: 'Delete',
                                    }
                                ];

                                const onClick = (key:any) => {


                                    if(key.key==="Edit" && refPhishletEditDrawer.current !== null)
                                    {
                                        refPhishletEditDrawer.current.show(record.id);
                                    }

                                    if(key.key==="Delete")
                                    {
                                        // Confirm delete
                                        Modal.confirm({
                                            title: 'Confirm delete',
                                            icon: <ExclamationCircleOutlined />,
                                            content: <>
                                                <Text>Deleting </Text>
                                                <Text strong>{record.id} </Text>
                                                <Text>is permanent.</Text>
                                            </>,
                                            okText: 'Delete',
                                            cancelText: 'Cancel',
                                            onOk: () => Delete(record.id)
                                        });
                                    }
                                }

                                return (

                                    <Dropdown menu={{ items, onClick }}>
                                        <Button onClick={(e) => e.preventDefault()}>
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                )
                            }
                        }
                    ]}
                />
            </>

        )
    }

export default ProxyPhishletTable;