enum LabUserType {
    /// <summary>
    /// Unmanaged user
    /// </summary>
    Unmanaged=0,

    /// <summary>
    /// Managed Lab User
    /// </summary>
    Managed=1,

    /// <summary>
    /// Managed Identity by CAttack (used for simulations, etc)
    /// </summary>
    ManagedIdentity=2,

    /// <summary>
    /// Managed Identity by CAttack that is releasable (e.g can be released by CAttack to the user)
    /// </summary>
    ManagedIdentityReleasable=3
}

export default LabUserType;