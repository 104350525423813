import React, {useContext} from 'react'
import {AppContext} from "../../AppProvider";
import Text from "antd/es/typography/Text";
import {Descriptions, Popover, Progress, Space, Spin} from "antd";
import ReactCountryFlag from "react-country-flag";
import APIProvider from "../../service/api2";
import DateTimeView from "../misc/DateTimeView";
import {CheckCircleTwoTone, CloseCircleOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";

const IPDisplay = props => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // React Query
    const { data: ipinfo, isLoading: isLoading } = useQuery(
        ["ipinfo", props.IP],
        async() => await API.getIPInfo(props.IP),
        {
            staleTime: 120000
        }
    );

    if(isLoading) {
        return <Space>
            <Spin />
            <Text>{props.IP}</Text>
        </Space>
    } else {
        return <Popover
            content={
                <Descriptions bordered column={1} size="small">
                    <Descriptions.Item label="Times Seen">
                        {ipinfo.HitCounter}
                    </Descriptions.Item>
                    <Descriptions.Item label="First Seen">
                        <DateTimeView DateTime={ipinfo.FirstSeen}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Seen">
                        <DateTimeView DateTime={ipinfo.LastSeen}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="ISP">{ipinfo.isp}</Descriptions.Item>
                    <Descriptions.Item label="AS">{ipinfo.asCode}</Descriptions.Item>
                    <Descriptions.Item label="AS Name">{ipinfo.asName}</Descriptions.Item>
                    <Descriptions.Item label="City">{ipinfo.city}</Descriptions.Item>
                    <Descriptions.Item label="District">{ipinfo.district}</Descriptions.Item>
                    <Descriptions.Item label="Region">{ipinfo.region}</Descriptions.Item>
                    <Descriptions.Item label="Country">
                        <Space>
                            <ReactCountryFlag countryCode={ipinfo.countryCode} svg style={{
                                width: '2em',
                                height: '2em',
                            }}/>
                            <Text>{ipinfo.country}</Text>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Hosting">
                        {
                            !ipinfo.hosting && <CloseCircleOutlined />
                        }
                        {
                            ipinfo.hosting && <CheckCircleTwoTone />
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Proxy">
                        {
                            !ipinfo.proxy && <CloseCircleOutlined />
                        }
                        {
                            ipinfo.proxy && <CheckCircleTwoTone />
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Mobile">
                        {
                            !ipinfo.mobile && <CloseCircleOutlined />
                        }
                        {
                            ipinfo.mobile && <CheckCircleTwoTone />
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Sonar Conf">
                        <Progress percent={ipinfo.SonarConfidence} steps={5} />
                    </Descriptions.Item>
                </Descriptions>
            }
        >
            <Space>
                <ReactCountryFlag countryCode={ipinfo.countryCode} svg style={{
                    width: '2em',
                    height: '2em',
                }}/>
                <Text>{props.IP}</Text>
            </Space>
        </Popover>
    }

}
export default IPDisplay