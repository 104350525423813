import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {Alert, Button, Tabs} from "antd";
import {GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ProxyPhishletTable from "./ProxyPhishletTable";
import {AppContext} from "../../../AppProvider";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import PageHeader from "../../misc/PageHeader";
import APITProvider from "../../../service/api";
import ProxyPhishletAddDrawer from "./ProxyPhishletAdd";

const ProxyPhishletsPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refDrawerPhishletAdd = useRef<DrawerRef>(null);

    const {
        data: ProxyPhishlets,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["proxyphishlets"],
        async () => await APIT.getProxyPhishlets()
    );

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Proxy Phishlets"
                Buttons={
                    <Button icon={<PlusCircleOutlined />} onClick={() => refDrawerPhishletAdd.current !== null ? refDrawerPhishletAdd.current.showDrawer() : null}>Add</Button>
                }
            />

            <Alert
                message="What are phishlets?"
                description="Phishlets are rules for performing proxying through the cattack infrastructure."
                type="info"
                showIcon
                closable={true}
                style={{marginBottom:10}}
            />

            <ProxyPhishletAddDrawer ref={refDrawerPhishletAdd} onChange={refetch} />

            <Tabs defaultActiveKey="local">

                <Tabs.TabPane
                    key="local"
                    tab={<span><HomeOutlined/>Container Payloads</span>
                    }>

                    <ProxyPhishletTable
                        ProxyPhishlets={ProxyPhishlets !== undefined ? ProxyPhishlets.filter(x => x.Container == APIT.GetSetContainer()) : []}
                        onChange={() => refetch()}
                        Loading={isLoading}                //onDelete={playbookDelete}
                        //onPlaybookEdit={handleEdit}
                        //onPlaybookRun={(id) => PlaybookRunModal.current.run(id)}
                    />

                </Tabs.TabPane>

                <Tabs.TabPane
                    key="global"
                    tab={<span><GlobalOutlined />Shared Payloads</span>
                    }>

                    <ProxyPhishletTable
                        ProxyPhishlets={ProxyPhishlets}
                        onChange={() => refetch()}
                        Loading={isLoading}                //onDelete={playbookDelete}
                        //onPlaybookEdit={handleEdit}
                        //onPlaybookRun={(id) => PlaybookRunModal.current.run(id)}
                    />

                </Tabs.TabPane>

            </Tabs>
        </>

    )
}

export default ProxyPhishletsPage;