// @flow

import React, {Component} from "react";

import {Button, Dropdown, Menu, message, Modal, Table} from "antd";
import ContainerAdd from "./ContainerAdd";
import ApiError from "../ApiError.react";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import DateTimeView from "../misc/DateTimeView";
import AdminListDrawer from "../admins/AdminListDrawer";
import PageHeader from "../misc/PageHeader";

class ContainersPage extends Component {
  constructor(props) {
    super(props);
    this.ContainerAddModal = React.createRef();
}

  state = {
    Containers: [],
    Loading: false,
    EditingContainer: null,
    EditingMembers: false,
    EditingMembersList: [],
    EditingMembersID: null,
    EditingMembersTitle: null
  }

  // Global AppContext contains token and operator info
  static contextType = AppContext;

  componentDidMount() {

    this.API = new APIProvider(this.context.token);

    this.refreshContainers();
  }

  refreshContainers() {
    this.setState({Loading: true});
    this.API.getContainers().then((Containers) => {
      this.setState({Containers: Containers});
      this.setState({Loading: false});
    })
    .catch((error) => {
      this.setState({error: error})
    })
  }

  addContainer(Container) {
    
    const hide = message.loading("Adding Container..", 0);

    this.API.postContainer(Container).then((Container) => {
      message.destroy(hide.key);
      this.refreshContainers();
    })
    .catch((error) => {
      message.destroy(hide.key);
      this.setState({error: error})
    })
  }


  deleteContainer(id) {
    const hide = message.loading("Deleting Container..", 0);

    this.API.deleteContainer(id).then(() => {
      message.destroy(hide.key);
      let filteredArray = this.state.Containers.filter(item => item.id !== id)
      this.setState({Containers: filteredArray});
    })
    .catch((error) => {
      message.destroy(hide.key);
      this.setState({error: error})
    })
  }

  openEditMembers(id,title)
  {
    this.API.getContainerAdmins(id).then((admins) => {
      this.setState({EditingContainer: id});
      this.setState({EditingContainerAdmins: admins});
      this.setState({EditingMembers: true});
      this.setState({EditingMembersTitle: title + " admins"});
    })
  }

  removeAdminContainer(container,admin)
  {
    this.API.removeContainerAdmin(container,admin).then(() => {
      this.API.getContainerAdmins(container).then((admins) => {
        this.setState({EditingContainerAdmins: admins});
      });
    })
  }

  addAdminContainer(container,admin)
  {
    this.API.addContainerAdmin(container,admin).then(() => {
      this.API.getContainerAdmins(container).then((admins) => {
        this.setState({EditingContainerAdmins: admins});
      });
    })
  }

  expireContainer(name,container)
  {
    Modal.confirm({
      title: 'Confirm expiration',
      icon: <ExclamationCircleOutlined />,
      content: <>
        <Text>Expiring </Text>
        <Text strong>{name} </Text>
        <Text>is permanent. It will delete all objects contained inside of it.</Text>
      </>,
      okText: 'Expire',
      cancelText: 'Cancel',
      onOk: () =>  this.API.expireContainer(container).then(() => {
        this.refreshContainers()
      })
    });


  }

  render() {

  return (
    <>

      <ContainerAdd ref={this.ContainerAddModal} onAdd={(Container) => this.addContainer(Container)}/>


      <AdminListDrawer
          Title={this.state.EditingMembersTitle}
          Show={this.state.EditingMembers}
          Admins={this.state.EditingContainerAdmins}
          onAddAdmin={(id) => this.addAdminContainer(this.state.EditingContainer,id)}
          onRemoveAdmin={(id) => this.removeAdminContainer(this.state.EditingContainer,id)}
          onClose={() => this.setState({EditingMembers: false})}
          API={this.API}/>


    <PageHeader
        Title="Containers"
        Buttons={[
          <Button icon={<PlusCircleOutlined />} onClick={() => this.ContainerAddModal.current.Open()} outline>Add</Button>
        ]}/>

      <ApiError error={this.state.error}/>

      <Table
          pagination={{ pageSize: 15 }}
          dataSource={this.state.Containers}
          rowKey="id"
          loading={this.state.Loading}
          columns={[
            {
              key: 'name',
              title: 'Name',
              dataIndex:'Name',
              render:(text) => <Text>{text}</Text>
            },
            {
              key: 'description',
              title: 'Description',
              dataIndex:'Description',
              render:(text) => <Text>{text}</Text>
            },
            {
              key: 'expires',
              title: 'Expires',
              render:(record) =>
              {
                  let expirationText = "Never";
                  if(record.Expires)
                {
                  return <DateTimeView DateTime={record.ExpirationDate}/>
                } else {
                    return <Text type="secondary">{expirationText}</Text>
                  }
              }
            },
            {
              key: 'actions',
              render:(record) => {

                let showExpire = true;

                if(record.id==="Global")
                {
                  showExpire = false;
                }

                return (
                    <Dropdown  overlay={

                      <Menu>

                        <Menu.Item key="edit" onClick={() => this.openEditMembers(record.id,record.id)}>
                          <EditOutlined /> Manage Admins
                        </Menu.Item>

                        {showExpire &&
                            <Menu.Item key="expire" onClick={() => this.expireContainer(record.Name, record.id)}>
                              <DeleteOutlined/> Expire Container
                            </Menu.Item>
                        }

                      </Menu>

                    }>
                      <Button>
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                )
              }
            },
            ]}
        />

    </>
  );
  }
}

export default ContainersPage;
