import React, {useContext, useEffect, useState} from 'react'
import {Image, Spin} from "antd";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";

const WebSessionActionScreenshot = (props) => {

    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [img, setImg] = useState(undefined);

    const fetchImage = async () => {
        API.getTaskFile(props.FileID).then((r) => {
            const imageObjectURL = URL.createObjectURL(r);
            console.dir(imageObjectURL);
            setImg(imageObjectURL);
        })
    };

    useEffect(() => {
        fetchImage();
    }, []);

    if(img===undefined)
        return <Spin />

    if(props.Height!==undefined)
    {
        return <Image src={img} height={props.Height} />
    }

    return <Image src={img} alt="icons" />

}
export default WebSessionActionScreenshot