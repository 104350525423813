// @flow

import React, {Component} from "react";

import BehaviourUserModal from "./BehaviourUserModal.react";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import Text from "antd/es/typography/Text";
import {Button, Dropdown, Menu, Popconfirm, Table} from "antd";

import {DeleteOutlined, DownOutlined, EditOutlined, PlusCircleOutlined} from "@ant-design/icons";

import ApiError from "../ApiError.react";
import PageHeader from "../misc/PageHeader";

class BehaviourPage extends Component {
  state = {
    behaviours: [],
    selectedEmail: [],
    Loading: true,
    ModalOpen: false,
    EditingBehaviour: null
  }

  // Global AppContext contains token and operator info
  static contextType = AppContext;

  componentDidMount() {

    this.API = new APIProvider(this.context.token);

    this.setState({Loading: true});

    this.API.getUserBehaviours().then((userbehaviours) => {
      this.setState({behaviours: userbehaviours});
      this.setState({Loading: false});
    })
    .catch((error) => {
      this.setState({error: error.message});

      this.setState({Loading: false});
    })
      
  }

  deleteBehaviour(id)
  {

    this.API.deleteUserBehaviours(id).then((res) => {
      let filteredArray = this.state.behaviours.filter(item => item.id !== id)
      this.setState({behaviours: filteredArray});
    })
    .catch((error) => {
      console.dir(error);
      this.setState({error: error.message})
    })

  }

  behaviourAdd() {

    this.createModalClose();

      const payload = this.buildPayload();

      this.API.createUserBehaviours(payload).then((res) => {
      this.setState(prevState => ({
        behaviours: [...prevState.behaviours, res]
      }))
    })
    .catch((error) => {
      console.dir(error);
      this.setState({error: error.message})
    })
  }

  openBehaviour(id) {

    if(id !== null && id !== undefined) {
      this.API.getUserBehaviour(id).then((res) => {
        this.setState({EditingBehaviour: res});
        this.setState({ModalOpen: true});
      })
          .catch((error) => {
            this.setState({error: error.message})
          })
    } else {
      this.setState({EditingBehaviour: null});
      this.setState({ModalOpen: true});
    }

  }

  onModalCancel()
  {
    this.setState({ModalOpen: false});
  }

  behaviourEdit() {

    this.createModalClose();

      const payload = this.buildPayload();

      this.API.createUserBehaviours(payload).then((res) => {
      this.setState(prevState => ({
        behaviours: [...prevState.behaviours, res]
      }))
    })
    .catch((error) => {
      console.dir(error);
      this.setState({error: error.message})
    })
  
  }

  handleEditorChange(e) {
    this.setState({ createModalEditorBody: e.target.getContent() });
  }

  editBehaviourModalOpen(b)
  {
    this.setState({ ModalOpen: true })
  }

  usageChange(event)
  {
    this.setState({ createModalData_payload: event.target.value });

    if(event.target.value===0)
    {
      this.setState({ createModalData_PayloadNormal: true});
    }
    else
    {
      this.setState({ createModalData_PayloadNormal: false});
    }

    if(event.target.value===2)
    {
      this.setState({ createModalData_PayloadPhish: true});
    }
    else
    {
      this.setState({ createModalData_PayloadPhish: false});
    }

    if(event.target.value===4)
    {
      this.setState({ createModalData_PayloadMalware: true});
    }
    else
    {
      this.setState({ createModalData_PayloadMalware: false});
    }
  };

  createModalOpen = () => 
  {

    this.setState({ createModalData_name: null})
    this.setState({ createModalEditor: null})
    this.setState({ createModalAdd: true })
    this.setState({ createModalEdit: false })
    this.setState({ createModalOpen: true })
  };

  render() {
  return (
    <>

      <PageHeader
          Title="Behaviours"
          Buttons={[
            <Button icon={<PlusCircleOutlined />} onClick={() => this.openBehaviour()}>Add</Button>
          ]}/>

      <ApiError error={this.state.error}/>

      {this.state.ModalOpen &&
          <BehaviourUserModal Behaviour={this.state.EditingBehaviour} onCancel={() => this.onModalCancel()}/>
      }

        <Table
            pagination={{ pageSize: 15 }}
            dataSource={this.state.behaviours}
            rowKey="id"
            loading={this.state.Loading}
            columns={[
              {
                key: 'name',
                title: 'Name',
                dataIndex:'id',
                render:(text) => <Text>{text}</Text>
              },
              {
                key: 'actions',
                render:(record) => {

                  return (
                      <Dropdown  overlay={

                        <Menu>

                          <Menu.Item key="delete">
                            <Popconfirm
                                title="Are you sure to delete this tenant? Removing this tenant may invalidate playbooks and tasks using this tenant."
                                okText="Yes"
                                cancelText="No">
                              <DeleteOutlined /> Delete
                            </Popconfirm>
                          </Menu.Item>

                          <Menu.Item key="edit" onClick={() => this.openBehaviour(record.id)}>
                            <EditOutlined /> Edit
                          </Menu.Item>

                        </Menu>

                      }>
                        <Button>
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                  )
                }
              },
            ]}
        />

    </>
  );
  }
}

export default BehaviourPage;
