import React, {FC, useContext, useState} from 'react'
import {List, Space, Button, Input} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";

interface OnChangeHandler {
    (e:any): void;
}

interface MultipleStringsInputProps {
    value?: string[];
    onChange?: OnChangeHandler;
}

const MultipleStringsInput: FC<MultipleStringsInputProps> = ({value, onChange}: MultipleStringsInputProps) => {

    const [addValue,SetAddValue] = useState<string>();
    const [addButtonEnabled,SetAddButtonEnabled] = useState<boolean>(false);

    const handleRemove = (item:string) => {
        if (onChange !== undefined && value !== undefined)
        {
            onChange(value.filter(x => x !== item))
        }
    }

    const addVal = () => {
        if (onChange !== undefined && addValue !== undefined) {
            let newVal = value;

            if (newVal === undefined || newVal === null)
            {
                newVal = [addValue];
            } else {
                newVal.push(addValue);
            }

            onChange(newVal)
            handleAddValChange("");
        }
    }

    const handleAddValChange = (val:string) => {
        if(val.length > 0)
        {
            SetAddButtonEnabled(true)
        } else {
            SetAddButtonEnabled(false);
        }

        SetAddValue(val);
    }

    return <List bordered footer={<Space.Compact style={{ width: '100%' }}>
        <Input value={addValue} onChange={(v) => handleAddValChange(v.target.value)} onPressEnter={() => addVal()} />
        <Button type="primary" onClick={() => addVal()} disabled={!addButtonEnabled} icon={<PlusOutlined />}/>
        </Space.Compact>}>
        {
            value && value.map((item:string) => {
                return <List.Item
                    actions={[<DeleteOutlined onClick={() => handleRemove(item)}/>]}>{item}</List.Item>
            })
        }
    </List>
}

export default MultipleStringsInput;