import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Button, Col, DatePicker, Form, Input, message,
    Modal, Row, Select, Space, Spin, Switch, Tag, Timeline,
} from "antd";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import {InfoCircleOutlined, UserOutlined} from "@ant-design/icons";
import AgentSelect from "../agents/AgentSelect";
import AdminSelect from "../admins/AdminsSelect/AdminsSelect";
import VariableValueEntries from "../Variables/VariableValueEntryForm/VariableValueEntries";
import AdminsSelect from "../admins/AdminsSelect/AdminsSelect";

const AttackRunModalNew = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        run: run,
        edit: edit
    }));

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    const [ButtonText, SetButtonText] = useState("Next");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [PlaybookID, SetPlaybookID] = useState();
    const [Schedule, SetSchedule] = useState();
    const [IsEdit, SetIsEdit] = useState(false);

    const [Step,SetStep] = useState(1);

    // Form refs
    const [formStep1] = useForm();
    const [formStep2] = useForm();
    const [formStep3] = useForm();

    // Attributes
    const {
        data: Playbook,
        isLoading: isLoading
    } = useQuery({
            queryKey: ["playbook", PlaybookID],
            queryFn: async () => await API.getPlaybook(PlaybookID),
            enabled: (PlaybookID == undefined ? false : true)
        }
    );

    const {
        data: Identities,
        isLoading: isLoadingIdentities
    } = useQuery({
            queryKey: ["identities"],
            queryFn: async () => await API.getIdentities(),
            enabled: (PlaybookID == undefined ? false : true)
        }
    );

    const {
        data: Agents,
        isLoading: isLoadingAgents
    } = useQuery({
            queryKey: ["agents"],
            queryFn: async () => await API.getAgents(),
            enabled: (PlaybookID == undefined ? false : true)
        }
    );

    const {
        data: EmailTemplates,
        isLoading: isLoadingEmailTemplates
    } = useQuery({
            queryKey: ["emailtemplates"],
            queryFn: async () => await API.getTemplateEmails(),
            enabled: (PlaybookID == undefined ? false : true)
        }
    );

    const handleClose = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        handleClose();
    };

    const edit = (schedule) => {
        formStep1.resetFields();
        formStep2.resetFields();
        formStep3.resetFields();

        SetIsEdit(true);

        API.getPlaybookSchedule(schedule).then((sch) => {
            SetPlaybookID(sch.Playbook);

            // Load in the properties
            formStep1.setFieldValue("name", sch.Name);

            SetStep(1);
            SetSchedule(sch);

            setIsModalOpen(true);
        });
    }

    const run = (playbook) => {
        formStep1.resetFields();
        formStep2.resetFields();
        formStep3.resetFields();

        SetPlaybookID(playbook);
        SetStep(1);
        SetIsEdit(false);

        setIsModalOpen(true);
    };

    const handleNext = () => {
        if (Step === 1) {
            formStep1.submit();
        }

        if (Step === 2) {
            formStep2.submit();
        }

        if (Step === 3) {
            formStep3.submit();
        }

        if (Step === 4)
        {
            // Run now
            HandleComplete();
        }

    }

    const HandleComplete = () => {
        // Build payload entities

        let Payload = GetAttackRunPayload();

        const modal = Modal.success({
            title: 'Running',
            content: `Your attack is starting, please hold.`,
        });

        // Being ran not edited
        if(!IsEdit)
        {
            API.runPlaybook(PlaybookID, Payload).then((attack) => {
                modal.destroy();

                message.info("Started " + attack.id);

                if(props.onRun !== undefined)
                {
                    props.onRun(attack.id)
                }

                if(props.closeOnComplete===true)
                {
                    handleClose();
                }
            }).catch((error) => {
                modal.destroy();
            })
        }

        // Being edited (a schedule)
        if(IsEdit)
        {
            API.editPlaybookSchedule(Schedule.id, Payload).then((s) => {
                modal.destroy();

                if(props.closeOnComplete===true)
                {
                    handleClose();
                }
            })
        }


    }

    const GetAttackRunPayload = () => {
        let Payload = formStep1.getFieldsValue(true);

        // Get payload entities
        let PayloadEntities = formStep2.getFieldsValue(true);
        Payload.Variables = [];
        Object.keys(PayloadEntities).forEach((key) => {
            Payload.Variables.push({
                Name: key,
                Data: PayloadEntities[key]
            })
        })

        // Page 3 entities - agent schedule etc
        let Page3Properties = formStep3.getFieldsValue(true);
        Object.keys(Page3Properties).forEach((key) => {

            if(key==="Schedule")
            {
                // Change around
                Payload.starttime = Page3Properties[key]["$d"].toUTCString();
            } else {
                Payload[key] = Page3Properties[key];
            }

        })

        return Payload;
    }

    const handleFinish = (values) => {
        let newStep = Step + 1;

        if(newStep === 2)
        {
            // Skip if no attack vars
            if(Playbook.VariablesV2 == undefined || Playbook.VariablesV2 == null || Playbook.VariablesV2.length == 0)
            {
                newStep = Step + 1;
            }
        }

        if(newStep === 4)
        {
            SetButtonText("Run");
        }

        SetStep(newStep);
    }

    return (
        <Modal
            title="Run playbook"
            onCancel={handleCancel}
            open={isModalOpen}
            width={1000}
            footer={
                [
                    <Button key="next" onClick={handleNext}>{ButtonText}</Button>
                ]
            }>
            {isLoading && <Spin />}
            {!isLoading &&
                <Row style={{paddingTop: 30}}>

                    <Col span={6}>
                        <Timeline>
                            <Timeline.Item color={(Step == 1 ? "blue" : "gray")} onClick={() => SetStep(1)}>Name</Timeline.Item>
                            <Timeline.Item color={(Step == 2 ? "blue" : "gray")} onClick={() => SetStep(2)}>Specify Variables</Timeline.Item>
                            <Timeline.Item color={(Step == 3 ? "blue" : "gray")} onClick={() => SetStep(3)}>Schedule</Timeline.Item>
                            <Timeline.Item color={(Step == 4 ? "blue" : "gray")}>Pre-flight</Timeline.Item>
                        </Timeline>
                    </Col>

                    <Col span={18}>

                        {Step===1 &&
                            <Form
                                form={formStep1}
                                onFinish={handleFinish}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 30 }}>

                                <Form.Item
                                    label="Name"
                                    name="name"
                                    tooltip="Descriptive name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a name',
                                        },
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Form>
                        }

                        {Step===2 &&
                            <Form
                                form={formStep2}
                                onFinish={handleFinish}
                                labelWrap={true}
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 30 }}>

                                { Playbook.VariablesV2 && <VariableValueEntries Variables={Playbook.VariablesV2} />


                                }
                            </Form>
                        }

                        {Step===3 &&
                            <Form form={formStep3}
                                  onFinish={handleFinish}
                                  labelCol={{ span: 5 }}
                                  wrapperCol={{ span: 30 }}>
                                <>
                                    <Form.Item
                                        label="Agent"
                                        name="Agent"
                                        tooltip={{
                                            title: 'When specifying an agent here, all tasks not specifically designated to an agent will be ran on this agent.',
                                            icon: <InfoCircleOutlined />,
                                        }}>
                                        <AgentSelect Agents={Agents} label="Run on Agent (Select none for any agent)" loading={isLoadingAgents}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Schedule"
                                        name="Schedule"
                                        tooltip={{
                                            title: 'When specifying an agent here, all tasks not specifically designated to an agent will be ran on this agent.',
                                            icon: <InfoCircleOutlined />,
                                        }}>
                                        <DatePicker showTime />
                                    </Form.Item>

                                    <Form.Item
                                        name="OperatorWatchers"
                                        label="Admin Watchers"
                                        tooltip={{
                                            title: 'Admin watchers will receive notifications for this attack',
                                            icon: <InfoCircleOutlined />,
                                        }}>
                                        <AdminsSelect />
                                    </Form.Item>

                                    <Form.Item
                                        label="Repeat every"
                                        name="RepeatEveryMinutes"
                                        tooltip={{
                                            title: 'When specifying an agent here, all tasks not specifically designated to an agent will be ran on this agent.',
                                            icon: <InfoCircleOutlined />,
                                        }}>
                                        <Select defaultValue="0" style={{ width: 120 }}>
                                            <Select.Option value="0">Dont Repeat</Select.Option>
                                            <Select.Option value="5">5 Minutes</Select.Option>
                                            <Select.Option value="60">Hour</Select.Option>
                                            <Select.Option value="1440">Day</Select.Option>
                                            <Select.Option value="10080">Week</Select.Option>
                                            <Select.Option value="40320">Month</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </>
                            </Form>
                        }

                    </Col>

                </Row>
            }


        </Modal>
    )

});

export default AttackRunModalNew;