import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Form, Modal} from "antd";
import {useForm} from "antd/es/form/Form";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";
import ContainerSelect from "./ContainerSelect.react";

const ContainerSwitchModal = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        Open: show
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // State
    const [Open, SetOpen] = useState(false);

    // Refs
    const [form] = useForm();

    // Funcs
    const show = () => {
        SetOpen(true);
    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    const handleChange = async () => {
        let Container = form.getFieldValue("Container");

        await API.SetContainer(Container);

        handleClose();

        window.location.reload();
    }

    return (
        <Modal
            title="Switch Containers"
            onCancel={handleClose}
            onOk={handleChange}
            open={Open}
        >

            <Form layout="vertical" form={form}>
                <Form.Item name="Container" label="Container" required>
                    <ContainerSelect />
                </Form.Item>
            </Form>
        </Modal>
    )

});

export default ContainerSwitchModal;