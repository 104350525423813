import React, {Component} from "react";


import {Form, Modal, Select} from 'antd';
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";

const { Option } = Select;

class ContainerSwitch extends Component {
    state = {
        Container:[]
    }

    // Global AppContext contains token and operator info
    static contextType = AppContext;

    componentDidMount() {

        this.API = new APIProvider(this.context.token);


    }

    ResetState()
    {

        this.setState({SelectedContainer: null});

    }

    Close()
    {
        this.setState({ModalOpen: false});
    }

    Open(Container)
    {

        this.API.getMyContainers().then((containers) => {
            this.setState({containers: containers});
        })
        .catch((error) => {
            console.dir(error);
            this.setState({error: error.message})
        })

        this.ResetState(Container);
        this.setState({ModalOpen: true});
    }

    Change()
    {
        this.API.SetContainer(this.state.SelectedContainer);
        this.Close();

    }

    render() {
        return (

            <Modal
                title="Switch Containers"
                onCancel={() => this.Close()}
                onOk={() => this.Change()}
                open={this.state.ModalOpen}
            >

                <Form
                    layout="vertical"
                >
                    <Form.Item label="Container" required>

                    </Form.Item>
                </Form>
            </Modal>

        );
    }
}

export default ContainerSwitch;
