import React, {useContext, useEffect, useState} from "react";


import {Col, Form, Row, Select, Tag} from 'antd';
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import Page from "../../../Models/Page/Page";

const { Option } = Select;

interface PageSelectProps {
    value?: any,
    onChange?: any
}

const PageSelect = ({value, onChange}:PageSelectProps) =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const {
        data: Pages,
        isLoading: isLoading
    } = useQuery(
        ["pages"],
        async () => await API.getPages()
    );

    // return on change properties

    return (

        <Select
            style={{ width: '100%' }}
            placeholder="Select a page"
            onChange={onChange}
            defaultValue={value}
            value={value}
            optionLabelProp="label">
            {
                Pages && Pages.map((p:Page) => {

                    return (
                        <Option value={p.id} label={

                            <Row gutter={5} align="middle">
                                <Col>
                                    {
                                        p.Flags && p.Flags.map((f) => {

                                            let tagColor = "default";

                                            if(f==="Phish") tagColor = "error";

                                            return (
                                                <Tag color={tagColor}>{f}</Tag>
                                            );
                                        })
                                    }
                                </Col>
                                <Col>
                                    {p.Name}
                                </Col>
                            </Row>

                        }>
                            <Row gutter={5} align="middle">
                                <Col>
                                    {
                                        p.Flags && p.Flags.map((f, i) => {

                                            let tagColor = "default";

                                            if(f==="Phish") tagColor = "error";

                                            return (
                                                <Tag color={tagColor}>{f}</Tag>
                                            );
                                        })
                                    }
                                </Col>
                                <Col>
                                    {p.Name}
                                </Col>
                            </Row>
                        </Option>
                    )
                })
            }

        </Select>

    )
}

export default PageSelect;