import React from 'react';
import date from "date-and-time";
import Text from "antd/es/typography/Text";
import {BaseType} from "antd/lib/typography/Base";

interface DateTimeViewProps {
    DateTime: string|Date;
    type?: BaseType|undefined;
}


const DateTimeView  = ({DateTime, type=undefined}:DateTimeViewProps) => {

    const dt = new Date(DateTime);

    const TimeString = date.format(dt,"h[:]mm A");
    const DateString = date.format(dt,"ddd D MMMM");

      return (
          <Text type={type}>{DateString}, {TimeString}</Text>
      );

}

export default DateTimeView;