import {
    Button,
    Dropdown,
    Menu,
    message,
    Modal,
    Space,
    Spin,
    Table,
    Tag,
    Typography
} from "antd";
import {useNavigate} from "react-router-dom";
import React, {useContext, useRef} from "react";
import {
    CheckCircleOutlined,
    DeleteOutlined, DownOutlined, InfoCircleOutlined,
} from "@ant-design/icons";
import PageHeader from "../../misc/PageHeader";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import Text from "antd/es/typography/Text";
import LabUserState from "../../../Models/Labs/LabUserState";
import LabUserStateTag from "../../lab/user/components/LabUserStateTag";

const HomeMyLabUsers = () => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    const navigate = useNavigate();

    const {
        data: MyLabUsers,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["MyLabUsers"],
        async () => await APIT.myLabUsersGet()
    );

    const {
        data: LabEnvironments,
        isLoading: isLoadingEnvironments,
        refetch: refetchEnvironments
    } = useQuery(
        ["labenvironments"],
        async () => await APIT.labsGet()
    );

    const handleAttest = (u:any) => {
        navigate("/attest?id=" + u.id + "&k=" + u.AttestationKey);
    }

    const handleRemove = (id:string) => {
        Modal.confirm({
            title: 'Confirm removal',
            icon: <InfoCircleOutlined />,
            content: <>
                <Text>Deleting a Lab User is permanent. Accounts are kept for record purposes but the underlying user will be removed.</Text>
            </>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: async () => {
                const hide = message.loading('Marking user for removal ' + id);

                APIT.labUserDelete(id).then(() => {
                    hide();
                    refetch();
                }).catch(() => {
                    hide();
                })
            }
        });
    }

    return (
        <>
            <PageHeader
                Title="My Lab Users"
            />

            <Table
                dataSource={MyLabUsers}
                rowKey="id"
                pagination={false}
                loading={isLoading}
                columns={[
                    {
                        title: "UPN",
                        key: 'UPN',
                        width:400,
                        render: (record) => {
                            return <Space>
                                <FontAwesomeIcon icon={faUser} />
                                <Typography.Text>{record.UserPrincipalName}</Typography.Text>
                            </Space>
                        },
                    },
                    {
                        title: "Lab",
                        key: 'LabID',
                        width:400,
                        render: (record) => {

                            if(LabEnvironments === undefined)
                            {
                                return <Spin />
                            } else {
                                const lab = LabEnvironments.find((element) => element.id === record.LabID);

                                return <Typography.Text>{lab?.Name}</Typography.Text>
                            }
                        }
                    },
                    {
                        title: "State",
                        key: 'State',
                        render: (record) => <LabUserStateTag LabUser={record}/>
                    },
                    {
                        key:'actions',
                        render:(record) => {
                            return (
                                <>

                                    <Dropdown overlay={

                                        <Menu>

                                            <Menu.Item key="attest" onClick={() => handleAttest(record)} icon={<CheckCircleOutlined/>}>
                                                Attest
                                            </Menu.Item>

                                            <Menu.Item key="remove" onClick={() => handleRemove(record.id)} icon={<DeleteOutlined/>}>
                                                Remove
                                            </Menu.Item>
                                        </Menu>

                                    }>
                                        <Button>
                                            <DownOutlined/>
                                        </Button>
                                    </Dropdown>

                                </>
                            )
                        }
                    }
                ]}
            />
        </>

    )
}

export default HomeMyLabUsers;