import React, {useContext} from "react";
import {Col, Row, Select, Space, Tag} from "antd";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import {HomeOutlined} from "@ant-design/icons";
import AdminDisplay from "../AdminDisplay";

const AdminSelect = ({value=undefined,onChange=undefined, onBaseDomainChange = undefined}) => {

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const {
        data: Operators,
        isLoading: isLoading,
    }  = useQuery({
            queryKey: ["operators"],
            queryFn: async() => await API.getOperators()
        }
    );

    const handleChange = (val) => {
        if(onChange)
        {
            onChange(val)
        }

        if(onBaseDomainChange)
        {
            onBaseDomainChange(val.BaseDomain)
        }
    }

    return (
        <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
            }
            style={{ width: '100%' }}
            placeholder="Select an admin"
            value={value}
            onChange={onChange}>
            {
                Operators && Operators.map((t) => {

                    return (
                        <Select.Option value={t.id}>
                            <AdminDisplay Admin={t.id} showName={true} showAvatar={true} />
                        </Select.Option>
                    )
                })
            }
        </Select>
    )

};

export default AdminSelect;