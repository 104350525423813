import React, {FC, useContext, useRef, useState} from 'react'
import {Button, Card, Dropdown, Input, MenuProps, Space, Tabs} from "antd";
import {DownOutlined, LoginOutlined, UserOutlined} from "@ant-design/icons";

interface OnClickHandler {
    (e:any): void;
}

interface PlaybookVariableDropdownMenuOpts {
    onClick?: OnClickHandler;
    DependencyOutputs: VariableList[] | undefined;
    PlaybookVariables: VariableList[] | undefined;
    children: string | JSX.Element | JSX.Element[],
    showContextVariables?: boolean;
}

interface VariableList {
    id: string,
    entryText: string,
    Name: string,
    Type: string,
    VarType: string,
    Description: string,
    OutputTask: string
}

const PlaybookVariableDropdownMenu: FC<PlaybookVariableDropdownMenuOpts> = ({children, onClick, PlaybookVariables, DependencyOutputs, showContextVariables}: PlaybookVariableDropdownMenuOpts) => {

    // State
    const [ActiveTab,SetActiveTab] = useState<string|undefined>("WYSIWYG");

    let items: MenuProps['items'] = [
        {
            key: "In-built",
            label: "In-built Variables",
            disabled: true
        },
        {
            key: "Commands",
            label: "Commands",
            icon: <LoginOutlined />,
            children: [
                {
                    key: "${cat.RandFirstname}",
                    label: "Random Firstname"
                },
                {
                    key: "${cat.RandCity}",
                    label: "Random City"
                },
                {
                    key: "${cat.RandString[x,*]}",
                    label: "Random String of x chars, all characters"
                },
                {
                    key: "${cat.RandString[x,l]}",
                    label: "Random String of x chars, lower alphabet"
                },
                {
                    key: "${cat.RandString[x,u]}",
                    label: "Random String of x chars, upper alphabet"
                },
                {
                    key: "${cat.RandString[x,n]}",
                    label: "Random String of x chars, numbers"
                },
                {
                    key: "${cat.Rand[option1|option2|option3]}",
                    label: "Random string from list"
                },
                {
                    key: "${cat.DateTime[0,MM-dd-yy]}",
                    label: "Random date time"
                },
                {
                    key: "${cat.Obfuscate[\"text to change\",\"High\"]}",
                    label: "Obfuscate text"
                }
            ]
        }
    ];

    if(showContextVariables === true && items !== undefined)
    {
        items.push({
            key: "Context",
            label: "Task Context",
            icon: <UserOutlined />,
            children: [
                {
                    key: "${Context.Username}",
                    label: "Context Username"
                },
                {
                    key: "${Context.Password}",
                    label: "Context Password"
                }
            ]
        })
    }

    if(PlaybookVariables && items !== undefined)
    {
        if(items)
        {
            items.push({
                key: "Playbook Variables",
                label: "Playbook Variables",
                disabled: true
            });
        }

        PlaybookVariables.map((x) => {
            if(items)
            {
                items.push({
                    key: x.id,
                    label: x.id,
                    icon: <UserOutlined />,
                    children: [
                        {
                            key: "${Entity." + x.id + ".GivenName}",
                            label: "Given/Firstname"
                        },
                        {
                            key: "${Entity." + x.id + ".Surname}",
                            label: "Surname"
                        },
                        {
                            key: "${Entity." + x.id + ".JobTitle}",
                            label: "Job Title"
                        },
                        {
                            key: "${Entity." + x.id + ".Phone}",
                            label: "Phone Number"
                        },
                        {
                            key: "${Entity." + x.id + ".CompanyName}",
                            label: "Company Name"
                        },
                        {
                            key: "${Entity." + x.id + ".Department}",
                            label: "Department"
                        },
                        {
                            key: "${Entity." + x.id + ".Country}",
                            label: "Country"
                        },
                        {
                            key: "${Entity." + x.id + ".CountryCode}",
                            label: "Country Code"
                        },
                        {
                            key: "${Entity." + x.id + ".City}",
                            label: "City"
                        },
                        {
                            key: "${Entity." + x.id + ".Email}",
                            label: "Email"
                        },
                        {
                            key: "${Entity." + x.id + ".PostalCode}",
                            label: "Post Code"
                        },
                        {
                            key: "${Entity." + x.id + ".State}",
                            label: "State"
                        },
                        {
                            key: "${Entity." + x.id + ".Address}",
                            label: "Address"
                        },
                    ]
                })
            }
        });
    }

    if(DependencyOutputs)
    {

        if(items)
        {
            items.push({
                key: "Dependant Task Outputs",
                label: "Dependant Task Outputs",
                disabled: true
            });
        }

        console.dir(DependencyOutputs);

        DependencyOutputs.map((x) => {


            if(x.Type==="String" || x.Type==="CookieJar")
            {
                if(items !== undefined)
                {
                    // Task output
                    if(x.OutputTask !== undefined)
                    {

                        // Build child obj
                        let childObj = {
                            key: x.id,
                            label: x.id
                        }

                        // get index of task
                        // @ts-ignore
                        let index = items.findIndex(y => y !== undefined && y.key == x.OutputTask);

                        if(index == -1)
                        {
                            items.push({
                                key: x.OutputTask,
                                label: "Task " + x.OutputTask,
                                children: [childObj]
                            })
                        } else {
                            // @ts-ignore
                            items[index].children.push(childObj);
                        }
                    }
                }
            }
        });
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if(onClick !== undefined)
        {
            onClick(e.key);
        }
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return <Dropdown menu={menuProps}>
        {children}
    </Dropdown>
}

export default PlaybookVariableDropdownMenu;