import React, {forwardRef, useContext, useImperativeHandle, useState} from 'react'
import {Button, Drawer, Form, Input, Select, Space} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import Text from "antd/es/typography/Text";
import TextArea from "antd/es/input/TextArea";

const FileTemplateAddDrawer = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // State
    const [open, setOpen] = useState(false);
    const [IsSubmitting, setIsSubmitting] = useState(false);

    // Refs
    const [form] = Form.useForm();

    // Variables
    const { Option, OptGroup } = Select;

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleTypeChange = (opt) => {
        console.dir(opt);
    }

    const finishAdd = async (values) => {
        // post values
        setIsSubmitting(true);
        let x = await API.postFileTemplate(values);
        setIsSubmitting(false);

        console.dir(x);

        // If new object created inform parent and close
        if(x !== undefined)
        {
            props.onAdd();
            onClose();
        }
    }

    return (
        <Drawer
            {...props}
            title="Add File Template"
            closable={true}
            onClose={onClose}
            open={open}>

            <Form form={form} layout="vertical" onFinish={finishAdd}>
                <Form.Item name="Type" label="File Template Source Type" rules={[{ required: true }]}>
                    <Select style={{ width: 300}} onChange={handleTypeChange}>

                        <OptGroup label="Office Documents">
                            <Option value="excel" disabled={true}>Excel</Option>
                            <Option value="word" disabled={true}>Word</Option>
                        </OptGroup>

                        <OptGroup label="Compiled Files">
                            <Option value="FileTemplateNim">
                                <Space direction="vertical">
                                    <Text>NIM</Text>
                                    <Text type="secondary">Can compile in to executable formats</Text>
                                </Space>
                            </Option>
                        </OptGroup>

                        <OptGroup label="Other">
                            <Option value="FileTemplateScript">
                                <Space direction="vertical">
                                    <Text>Script</Text>
                                    <Text type="secondary">PowerShell, Bash, etc.</Text>
                                </Space>
                            </Option>

                            <Option value="FileTemplateQRCode">
                                <Space direction="vertical">
                                    <Text>QR Code Image</Text>
                                    <Text type="secondary">Text to QR code</Text>
                                </Space>
                            </Option>
                        </OptGroup>

                    </Select>
                </Form.Item>

                <Form.Item name="Name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="Description" label="Description" rules={[{ required: true }]}>
                    <TextArea />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<PlusCircleOutlined/>} loading={IsSubmitting}>
                        Add
                    </Button>
                </Form.Item>
            </Form>



        </Drawer>
    );

});

export default FileTemplateAddDrawer