import {Card, Col, Row, Statistic} from "antd";
import React, {useContext} from "react";
import {ArrowDownOutlined, ArrowUpOutlined, RocketFilled, RocketOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import {RocketLaunchOutlined} from "@mui/icons-material";

const HomeStat = () => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    const {
        data: containerstat,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["containerstat"],
        async () => await APIT.thisContainerStatGet()
    );

    return (
    <Row gutter={16}>
        <Col span={8}>
            <Card bordered={false}>
                <Statistic
                    loading={isLoading}
                    title="Runnning Attacks"
                    value={containerstat?.AttacksRunning}
                    valueStyle={{
                        color: '#3f8600',
                    }}
                    prefix={<RocketOutlined/>}
                />
            </Card>
        </Col>
        <Col span={8}>
            <Card bordered={false}>
                <Statistic
                    title="Completed Attacks"
                    value={containerstat?.AttacksComplete}
                    prefix={<RocketFilled/>}
                />
            </Card>
        </Col>
    </Row>
    )
}

export default HomeStat;