import React from "react";


import {Badge, Collapse, Descriptions, List, Table} from 'antd';
import DateTimeView from "../../misc/DateTimeView";
import Text from "antd/es/typography/Text";
import DefenderIncidentMap from "../../../Models/DefenderMapping/DefenderIncidentMap";
import {PresetStatusColorType} from "antd/es/_util/colors";
import DefenderAlertSeverity from "../../../Models/Defender/DefenderAlertSeverity";
import DefenderAlert from "../../../Models/Defender/DefenderAlert";
import DefenderServiceSource from "../../../Models/Defender/DefenderAlertSource";

interface AttackIncidentTableProps {
    Incident: DefenderIncidentMap;
}

const AttackIncidentTable = (props:AttackIncidentTableProps) =>
{

    const expandedRowRender = (record:any) => {

        let x = 0;

        return <Collapse accordion>

            {record.Evidence && record.Evidence.map((evidence:any) => {

                let mainPart = <Text>No Detail</Text>;
                let headerName = evidence["$type"];

                if(evidence["$type"] === "AnalyzedMessageEvidence")
                {
                    headerName = "Analyzed Message";
                    mainPart = <Descriptions bordered>
                        <Descriptions.Item label="Message ID">{evidence.NetworkMessageId}</Descriptions.Item>
                        <Descriptions.Item label="Internet Message ID">{evidence.InternetMessageId}</Descriptions.Item>
                        <Descriptions.Item label="Subject">{evidence.Subject}</Descriptions.Item>
                        <Descriptions.Item label="URLs">
                            <List>
                                {evidence.URLs && evidence.URLs.map((url:string) => {
                                    return <List.Item>{url}</List.Item>
                                })}
                            </List>
                        </Descriptions.Item>
                    </Descriptions>
                }

                if(evidence["$type"] === "MailClusterEvidence")
                {
                    headerName = "Mail Cluster";
                    mainPart = <Descriptions bordered>
                        <Descriptions.Item label="Query">{evidence.query}</Descriptions.Item>
                        <Descriptions.Item label="URLs">
                            <List>
                                {evidence.networkMessageIds && evidence.networkMessageIds.map((messageId:string) => {
                                    return <List.Item>{messageId}</List.Item>
                                })}
                            </List>
                        </Descriptions.Item>
                    </Descriptions>
                }

                if(evidence["$type"] === "UserEvidence")
                {
                    headerName = "User";
                    mainPart = <Descriptions bordered>
                        <Descriptions.Item label="UPN">{evidence.userAccount.userPrincipalName}</Descriptions.Item>
                        <Descriptions.Item label="SID">{evidence.userAccount.userSid}</Descriptions.Item>
                    </Descriptions>
                }

                if(evidence["$type"] === "UrlEvidence")
                {
                    headerName = "URL";
                    mainPart = <Text>{evidence.url}</Text>;
                }


                x++;
                return <Collapse.Panel header={headerName} key={x}>
                    {mainPart}
                </Collapse.Panel>
            })}
        </Collapse>

        return <Table dataSource={record.evidence} pagination={false}
                      columns={[
                          {
                              key:'detail'
                          }
                      ]}
        />;
    };

    return (

        <Table
            dataSource={props.Incident.alerts}
            rowKey="id"
            pagination={false}
            expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ['0'],
            }}
            columns={[
                {
                    title: 'First Activity',
                    dataIndex: 'FirstActivityDateTime',
                    key: 'firstActivity',
                    render: text => <DateTimeView DateTime={text}/>,
                },
                {
                    title: 'Severity',
                    key: 'severity',
                    render: (_: any, record: DefenderAlert) => {
                        let badgeColour:PresetStatusColorType = "default";
                        let severityName:string = DefenderAlertSeverity[record.Severity];

                        if (record.Severity === DefenderAlertSeverity.High) {
                            badgeColour = "error"
                        }

                        return <Badge status={badgeColour} text={severityName}/>

                    }
                },
                {
                    title: 'Title',
                    key: 'title',
                    dataIndex: 'Title',
                    render: text => <Text>{text}</Text>
                },
                {
                    title: 'Category',
                    dataIndex: 'Category',
                    key: 'Category',
                    render: text => <Text>{text}</Text>
                },
                {
                    title: 'Source',
                    key: 'source',
                    dataIndex: 'ServiceSource',
                    render: (_: any, record: DefenderAlert) => {

                        // Map the defender returned service source to something readable
                        let serviceSourceName:string = record.ServiceSource.toString();

                        if(record.ServiceSource===DefenderServiceSource.Microsoft365Defender)
                        {
                            serviceSourceName = "Microsoft 365 Defender"
                        }

                        if(record.ServiceSource===DefenderServiceSource.MicrosoftDefenderForCloudApps)
                        {
                            serviceSourceName = "Microsoft Defender for Cloud Apps"
                        }

                        if(record.ServiceSource===DefenderServiceSource.MicrosoftDefenderForEndpoint)
                        {
                            serviceSourceName = "Microsoft Defender for Endpoint"
                        }

                        if(record.ServiceSource===DefenderServiceSource.MicrosoftDefenderForOffice365)
                        {
                            serviceSourceName = "Microsoft Defender for Office 365"
                        }

                        if(record.ServiceSource===DefenderServiceSource.MicrosoftDefenderForIdentity)
                        {
                            serviceSourceName = "Microsoft Defender for Identity"
                        }

                        return <Text>{serviceSourceName}</Text>
                    }
                }
            ]}
        />

    )
}

export default AttackIncidentTable;