import * as React from "react";
import {MsalProvider} from "@azure/msal-react";
import LoginPage from "./pages/LoginPage";
import {useLocation} from "react-router-dom";
import AttestPage from "./pages/AttestPage";

const NonAuthenticatedWrapper = (props) => {
    const location = useLocation();
    console.log(location.pathname);

        if(location.pathname === "/attest")
        {
            return <AttestPage />
        }

        return (
            <LoginPage/>
        )
}

export default NonAuthenticatedWrapper;