import React from "react";
import {faEnvelope, faMessage, faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpFromBracket, faCopy, faShareFromSquare, faTerminal, faArrowPointer} from "@fortawesome/free-solid-svg-icons";
import {ClockCircleOutlined, CloudDownloadOutlined} from "@ant-design/icons";
import {
    AccountTreeOutlined,
    DeveloperBoardOutlined,
    GppMaybeOutlined,
    InputOutlined,
    MarkEmailReadOutlined,
    WebOutlined
} from "@mui/icons-material";

const PlaybookItemTypes = [
    {
        TaskType: "PlaybookTaskEmail",
        Display: "Send Email",
        Description: "Used for sending emails between two identities",
        requiresUserContext:true,
        requiresAgentContext:false,
        Icon: <FontAwesomeIcon icon={faEnvelope} />,
        Outputs:[
            {
                "VarType": "Email", // temp
                "id": "%EMAILID%"
            }
        ]
    },
    {
        TaskType: "PlaybookTaskCreateWebsite",
            Display: "Create Website",
        Icon: <WebOutlined/>,
        requiresUserContext:false,
        requiresAgentContext:false,
        Outputs:[
            {
                "VarType": "URL", // temp
                "id": "%URL%"
            }
        ]
    },
    {
        TaskType: "PlaybookTaskWaitForIntervention",
        Display: "Wait for Intervention",
        Icon: <ClockCircleOutlined/>,
        requiresUserContext:false,
        requiresAgentContext:false,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskWeaponizeURL",
            Display: "Weaponize URL",
        Icon: <GppMaybeOutlined/>,
        requiresUserContext:false,
        requiresAgentContext:false,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskEmailRead",
            Display: "Read Email",
        Icon:<MarkEmailReadOutlined/>,
        requiresUserContext:true,
        requiresAgentContext:true,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskRPSCommand",
            Display: "Remote PowerShell",
        Icon: <InputOutlined/>,
        requiresUserContext:true,
        requiresAgentContext:true,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskDownloadFile",
            Display: "SharePoint Download",
        requiresUserContext:true,
        requiresAgentContext:true,
        Icon: <CloudDownloadOutlined/>,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskShareFile",
            Display: "SharePoint Sharing",
        requiresUserContext:true,
        requiresAgentContext:true,
        Icon: <FontAwesomeIcon icon={faShareFromSquare} />,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskUploadFile",
        Display: "SharePoint Upload",
        requiresUserContext:true,
        requiresAgentContext:true,
        Icon: <FontAwesomeIcon icon={faArrowUpFromBracket} />,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskStartProcess",
            Display: "Start Process",
        Icon: <DeveloperBoardOutlined/>,
        requiresAgentContext:true,
        requiresUserContext:false,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskDoNothing",
        Display: "Do Nothing (Test)",
        requiresAgentContext:true,
        requiresUserContext:false,
        Icon: <FontAwesomeIcon icon={faPaperPlane} />,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskCreateFile",
        Display: "Create File",
        requiresAgentContext:true,
        requiresUserContext:false,
        Icon: <FontAwesomeIcon icon={faCopy} />,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskWin32Registry",
        Display: "Win32 Registry",
        requiresAgentContext:true,
        requiresUserContext:false,
        Icon: <AccountTreeOutlined/>,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskTeamsMessage",
        Display: "Teams Message",
        requiresAgentContext:false,
        requiresUserContext:true,
        Icon: <FontAwesomeIcon icon={faMessage} />,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskExchangeCommand",
        Display: "Exchange Command",
        requiresAgentContext:false,
        requiresUserContext:true,
        Icon: <FontAwesomeIcon icon={faTerminal} />,
        Outputs:[]
    },
    {
        TaskType: "PlaybookTaskWebControl",
        Display: "Web Session Control",
        requiresAgentContext:false,
        requiresUserContext:false,
        Icon: <FontAwesomeIcon icon={faArrowPointer} />,
        Outputs:[]
    },
]

export default PlaybookItemTypes;