import React, {useContext, useEffect} from "react";


import {Button, Dropdown, Menu, Modal, Space, Table, Tag} from 'antd';
import PlaybookMap from "./PlaybookMap.react";
import AdminDisplay from "../admins/AdminDisplay";
import Text from "antd/es/typography/Text";
import {
    CaretRightOutlined, ClockCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {useNavigate} from "react-router-dom";

const PlaybookTable = (props) =>
{

    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    let Navigate = useNavigate();

    useEffect(() => {

    },[]);

    const handleDupe = (id) => {
        API.dupePlaybook(id).then((duped) => {
            Navigate(duped.id);
        })
    }

    const confirmDelete = (r) => {
        Modal.confirm({
            title: 'Confirm delete',
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Text>Deleting </Text>
                <Text strong>{r.Name} </Text>
                <Text>is permanent.</Text>
                </>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => props.onDelete(r)
        });
    }

    const confirmDupe = (r) => {
        Modal.confirm({
            title: 'Confirm duplication',
            icon: <ExclamationCircleOutlined />,
            content: <Text>Duplicating playbooks is a great way of making your own modifications. If your modifications are not unique to your use-case, consider suggesting the changes to the author instead.</Text>,
            okText: 'Dupe',
            cancelText: 'Cancel',
            onOk: () => handleDupe(r)
        });
    }

    const getFilterTags = () => {
        let array = [];

        if(props.Playbooks !== undefined)
        {
            props.Playbooks.forEach((p) => {
                if(p.Tags !== null && p.Tags !== undefined)
                {
                    p.Tags.forEach((t) => {
                        if(!array.find(c => c.value == t))
                        {
                            array.push({
                                text: t,
                                value: t
                            })
                        }
                    })
                }
            });
        }

        return array;
    }

    return (
        <Table
            dataSource={props.Playbooks}
            rowKey="id"
            pagination={false}
            loading={props.Loading}
            expandable={{
                expandedRowRender: record =>
                    <div style={{ height: 600 }}>
                        <PlaybookMap key={record.id} Playbook={record}/>
                    </div>
            }}
            columns={[
                {
                    key: 'avatar',
                    render: record => <AdminDisplay Admin={record.Owner} showAvatar={true}/>,
                },
                {
                    title: "Name",
                    key: 'id',
                    sorter: (a, b) => a.Name.localeCompare(b.Name),
                    defaultSortOrder: "ascend",
                    render: record => {
                        return <Space direction="vertical">
                            <Text>{record.Name}</Text>
                            <Text type="secondary">{record.description}</Text>
                        </Space>
                    }
                },
                {
                    title: "Tags",
                    key: 'tags',
                    filters: getFilterTags(),
                    onFilter: (value, record) => record.Tags !== null && record.Tags.includes(value),
                    render: record => {
                        return <>
                            { record.Tags && record.Tags.map((t) => {
                                    var icon = <></>;
                                    if(t==="Scheduled")
                                    {
                                        icon = <ClockCircleOutlined />
                                    }
                                    return (<Tag icon={icon}>{t}</Tag>);
                                }
                            )}
                        </>
                    }
                },
                {
                    key:'actions',
                    render:(record) => {
                        return (
                            <>

                                <Dropdown overlay={

                                    <Menu>

                                        {props.showRunButton &&
                                            <Menu.Item key="run" onClick={() => props.onPlaybookRun(record.id)}>
                                                <CaretRightOutlined/> Run
                                            </Menu.Item>
                                        }

                                        <Menu.Divider title="Authoring"/>

                                        {props.showDupeButton &&
                                            <Menu.Item key="dupe" onClick={() => confirmDupe(record.id)} icon={<CopyOutlined/>}>
                                                Dupelicate
                                            </Menu.Item>
                                        }

                                        {props.showEditButton &&
                                            <Menu.Item key="edit" onClick={() => props.onPlaybookEdit(record)} icon={<EditOutlined/>}>
                                                Edit
                                            </Menu.Item>
                                        }

                                        {props.showEditButton &&
                                            <Menu.Item danger key="delete" onClick={() => confirmDelete(record)} icon={<DeleteOutlined/>}>
                                                Delete
                                            </Menu.Item>
                                        }

                                    </Menu>

                                }>
                                    <Button>
                                        <DownOutlined/>
                                    </Button>
                                </Dropdown>

                            </>
                        )
                    }
                }
            ]}
        />
    )
}

export default PlaybookTable;