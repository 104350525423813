enum DefenderServiceSource {
    Unknown,
    MicrosoftDefenderForEndpoint,
    MicrosoftDefenderForIdentity,
    MicrosoftDefenderForCloudApps,
    MicrosoftDefenderForOffice365,
    Microsoft365Defender,
    AzureAdIdentityProtection,
    MicrosoftAppGovernance,
    DataLossPrevention,
    UnknownFutureValue,
}

export default DefenderServiceSource;