import React, {useContext, useState} from 'react';
import date from "date-and-time";
import Text from "antd/es/typography/Text";
import DateTimeView from "../../misc/DateTimeView";
import {Table, Tag} from "antd";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../../service/api";
import TaskLogType from "../../../Models/Task/TaskLogType";

interface TaskLogTableProps {
    TaskID: string|undefined;
    Autorefresh: boolean;
}


const TaskLogTable  = ({TaskID=undefined,Autorefresh=false}:TaskLogTableProps) => {

    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    const {
        data: TaskLog,
        isLoading: isLoading
    } = useQuery({
        queryKey: ["TaskLog", TaskID],
        refetchInterval: Autorefresh === true ? 3000 : false,
        queryFn: TaskID != undefined ? async () => await APIT.getTaskLog(TaskID) : undefined
        }
    );

    return (
        <Table
            dataSource={TaskLog}
            loading={isLoading}
            rowKey="date"
            columns={[
                {
                    title: 'Time',
                    dataIndex: 'date',
                    key: 'Time',
                    render: text => <DateTimeView DateTime={text}/>,
                },
                {
                    title: 'Message',
                    dataIndex: 'message',
                    key: 'message',
                    render: text => <Text>{text}</Text>,
                },
                {
                    title: '',
                    dataIndex: 'type',
                    key: 'type',
                    render: type =>
                    {
                        if(type === TaskLogType.Ok) return <Tag color="blue">OK</Tag>
                        if(type === TaskLogType.Error) return <Tag color="red">Error</Tag>
                    },
                },
            ]}
            />
    );

}

export default TaskLogTable;