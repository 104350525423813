// TaskList.js

import React, {useContext} from 'react';
import {AppContext} from "../../AppProvider";
import {Button, Dropdown, Menu, message, Popconfirm, Space, Table} from "antd";
import APIProvider from "../../service/api2";
import TaskStatusIcon from "./TaskStatusIcon";
import {
    CaretRightFilled,
    CopyOutlined,
    DesktopOutlined,
    DownOutlined,
    ReloadOutlined,
    TagOutlined,
    WindowsOutlined
} from "@ant-design/icons";

import TaskModal from "./TaskModal";
import DateTimeView from "../misc/DateTimeView";
import Text from "antd/es/typography/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDocker} from "@fortawesome/free-brands-svg-icons";
import AgentDisplay from "../agents/AgentDisplay";

const TaskList = ({
                    showPlaybookExecution = true,
                    Tasks=[]
                  }) =>  {

    // Construct token
    const { token } = useContext(AppContext);
    const API = new APIProvider(token);

    const refTaskModal = React.createRef();

    function handleMenuClick(e,item) {

        if(e.key==="run")
        {
            this.runTask(item)
        }

        if(e.key==="detail")
        {
            refTaskModal.current.open(item.id);
        }

        if(e.key==="pbedetail")
        {
            this.openExecutionDetail(item.Playbook.Execution);
        }

        if(e.key==="copytaskid")
        {
            navigator.clipboard.writeText(item.id);
            message.success("Copied Task ID to clipboard");
        }

        if(e.key==="copytaskid")
        {
            navigator.clipboard.writeText(item.id);
            message.success("Copied Task ID to clipboard");
        }

    }

    function resumeTask(id)
    {
        API.taskResume(id).then(() => {
            console.log("Task restarted");
        })
    }

    function restartTask(id)
    {
        API.taskRestart(id).then(() => {
            console.log("Task restarted");
        })
    }

    function triggerTask(id)
    {
        API.taskTrigger(id).then(() => {
            console.log("Task trigger");
        })
    }

    return (
        <>
            <TaskModal ref={refTaskModal}/>
            <Table
                dataSource={Tasks}
                rowKey="id"
                pagination={15}
                columns={[
                    {
                        key:'icon',
                        width:50,
                        render: (record) => {
                            // OS Icon selection
                            if(record.os === "Linux") return <FontAwesomeIcon icon={faDocker} />
                            if(record.os === "Windows") return <WindowsOutlined/>

                            return <DesktopOutlined />

                        }
                    },
                    {
                        title: 'Scheduled',
                        dataIndex: 'scheduled',
                        key: 'Scheduled',
                        render: text => <DateTimeView DateTime={text}/>,
                    },
                    {
                        title: 'Description',
                        key: 'desc',
                        render: record =>
                            <Space direction="vertical">
                                <Text>{record.Description}</Text>
                                <Text type="secondary">{record.ev.ContextUser}</Text>
                            </Space>,
                    },
                    {
                        title: 'Status',
                        key: 'status',
                        render: (record) => <TaskStatusIcon task={record}/>
                    },
                    {
                        title: 'Retries',
                        key: 'retryCount',
                        render: record => {
                            if(record.retryCount === undefined) return 0;
                            return record.retryCount;
                        },
                    },
                    {
                        title: 'Agent',
                        dataIndex: 'ownername',
                        key: 'agent',
                        render: text => <AgentDisplay Agent={text}/>,
                    },
                    {
                        title: 'Completed',
                        key: 'completed',
                        render: record => {
                            if(record.complete) return <DateTimeView DateTime={record.completed}/>
                            return null;
                        },
                    },
                    {
                        title: 'Phase',
                        key: 'phase',
                        render: (record) => {

                            let Phase = "Main";

                            if(record.Playbook !== undefined && record.Playbook !== null)
                            {
                                if(record.Playbook.Phase === 1)
                                {
                                    Phase = "Preperation";
                                }

                                if(record.Playbook.Phase === 2)
                                {
                                    Phase = "Main";
                                }

                                if(record.Playbook.Phase === 4)
                                {
                                    Phase = "Clean-up";
                                }

                                return (
                                    <Text type="secondary">{Phase}</Text>
                                )
                            }
                        },
                    },
                    {
                        key:'actions',
                        render:(record) => {
                            return (
                                <Dropdown  overlay={

                                    <Menu onClick={(e) => handleMenuClick(e,record)}>

                                        <Menu.Item key="detail" icon={<TagOutlined />}>
                                             View Details
                                        </Menu.Item>

                                        {
                                            <Menu.Item key="pbedetail" icon={<TagOutlined />}>
                                                View Playbook Execution
                                            </Menu.Item>
                                        }

                                        <Menu.Item key="copytaskid" icon={<CopyOutlined />}>
                                             Copy Task ID to Clipboard
                                        </Menu.Item>

                                        <Menu.Divider/>

                                        {record.Status & 256 &&
                                            <Menu.Item key="resume" icon={<CaretRightFilled />} onClick={() => resumeTask(record.id)}>
                                                Resume
                                            </Menu.Item>
                                        }

                                        {record.complete &&
                                            <Menu.Item key="restart" icon={<ReloadOutlined/>}>
                                                <Popconfirm
                                                    title="WARNING: Restarting a playbook task may cause erroneous results. The playbook task will be restarted, and all dependency tasks too."
                                                    onConfirm={() => restartTask(record.id)}
                                                    okText="Yes"
                                                    cancelText="No">
                                                    Restart
                                                </Popconfirm>
                                            </Menu.Item>
                                        }

                                        {!record.complete && !record.waitingOnDependencies &&
                                            <Menu.Item key="trigger" icon={<CaretRightFilled/>}>
                                                <Popconfirm
                                                    title="WARNING: Triggering a task ignores the scheduled time for the task. This may cause erroneous results in a playbook."
                                                    onConfirm={() => triggerTask(record.id)}
                                                    okText="Yes"
                                                    cancelText="No">
                                                    Trigger Now
                                                </Popconfirm>
                                            </Menu.Item>
                                        }
                                    </Menu>

                                }>
                                    <Button>
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )
                        }

                    }
                ]}
            />
        </>
    );

}

export default TaskList;