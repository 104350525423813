import React, {useContext} from 'react'
import {AppContext} from "../../AppProvider";
import Text from "antd/es/typography/Text";
import {Descriptions, Popover, Space, Spin} from "antd";
import APIProvider from "../../service/api2";
import DateTimeView from "../misc/DateTimeView";
import {useQuery} from "@tanstack/react-query";

const ContainerDisplay = ({Container}) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // React Query
    const { data: ContainerResult, isLoading: isLoading } = useQuery(
        ["container", Container],
        async() => await API.getContainer(Container),
        {
            staleTime: 300000,
            enabled: Container !== null ? true : false,
            refetchInterval: false
        }
    );

    if(Container === null)
    {
        return <></>
    }

    if(isLoading) {
        return <Spin />
    }

    if(ContainerResult !== undefined)
    {
        return (
            <Popover
                content={
                    <Descriptions bordered column={1} size="small">
                        <Descriptions.Item label="ID">
                            {ContainerResult.id}
                        </Descriptions.Item>

                        <Descriptions.Item label="Name">
                            {ContainerResult.Name}
                        </Descriptions.Item>

                        <Descriptions.Item label="Description">
                            {ContainerResult.Description}
                        </Descriptions.Item>

                        <Descriptions.Item label="Created">
                            <DateTimeView DateTime={ContainerResult.Created}/>
                        </Descriptions.Item>
                    </Descriptions>
                }>

                <Space>
                    <Text>{ContainerResult.Name}</Text>
                </Space>
            </Popover>
        );
    }

    return <></>

}
export default ContainerDisplay