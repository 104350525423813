import { Table } from "antd";
import React, {useContext} from "react";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import Text from "antd/es/typography/Text";
import TaskTag from "../../../tasks/TaskTag/TaskTag";
import WebSessionActionScreenshot from "../../../tasks/WebSessionAction/WebSessionActionScreenshot.react";

interface AttackFilesTableProps {
    AttackID: string
}

const AttackFilesTable = ({AttackID}:AttackFilesTableProps) => {

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // React queries
    const {
        data: AttackFiles,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["attackfiles", AttackID],
            queryFn: async() => await APIT.getAttackFiles(AttackID)
        }
    );

    return <Table
        dataSource={AttackFiles}
        rowKey="id"
        pagination={false}
        //expandable={{ expandedRowRender }}
        loading={Loading}
        columns={[
            {
                title: "Filename",
                key: 'Filename',
                dataIndex: "Filename",
                render: text => <Text>{text}</Text>
            },
            {
                title: "Created in Task",
                key: 'TaskID',
                dataIndex: "TaskID",
                render: text => <TaskTag TaskID={text}/>
            },
            {
                title: "SHA 256",
                key: 'hashSHA256',
                dataIndex: "hashSHA256",
                render: text => <Text>{text}</Text>
            },
            {
                title: "Type",
                key: 'mime',
                dataIndex: "mime",
                render: text => <Text>{text}</Text>
            },
            {
                key:'additional',
                render: (record) => {

                    if(record.mime==="image/x-png")
                    {
                        return <WebSessionActionScreenshot FileID={record.id} Height={50}/>
                    }

                    return <></>
                }
            }
        ]}
    />
}

export default AttackFilesTable;