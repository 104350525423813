import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Drawer, Form, Input, Modal} from "antd";
import DrawerRef from "../../../../classes/DrawerRef/DrawerRef";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import {useForm} from "antd/es/form/Form";
import CGContext from "../../../../Models/CGContent/CGContext";
import DrawerAddProps from "../../../../classes/DrawerAdd/DrawerAddProps";
import TenantSelect from "../../../tenants/TenantSelect/TenantSelect.react";
import LabEnvironment from "../../../../Models/Labs/LabEnvironment";

const LabEnvironmentAddDrawer = forwardRef<DrawerRef,DrawerAddProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);

    // Refs
    const [form] = useForm();

    // Funcs
    const showDrawer = () => {
        SetOpen(true);
    }

    const handleSave = async (vals:LabEnvironment) => {
        // Create dto edit object

        const modal = Modal.success({
            title: 'Saving',
            content: `CG Context is being created, please hold.`,
        });

        await APIT.labPost(vals);

        modal.destroy();
        handleClose();

        if(onChange !== undefined)
        {
            onChange();
        }

    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    return (
        <Drawer
            title="Add Lab Environment"
            onClose={handleClose}
            open={Open}>

            <Form
                form={form}
                onFinish={handleSave}>

                <Form.Item
                    label="Name"
                    name="Name"
                    rules={[{ required: true, message: 'Please enter a descriptive name' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Tenant"
                    name="TenantID"
                    rules={[{ required: true, message: 'Please select a parent tenant' }]}>
                    <TenantSelect />
                </Form.Item>

                <Form.Item
                    label="Tenant"
                    name="TenantID"
                    rules={[{ required: true, message: 'Please select a parent tenant' }]}>
                    <TenantSelect />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    Add
                </Button>


            </Form>

        </Drawer>

    )

});

export default LabEnvironmentAddDrawer;