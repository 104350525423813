import React, {useContext, useRef} from "react";
import {Badge, Button, Dropdown, Modal, Table, Tag, Typography} from 'antd';
import Text from "antd/es/typography/Text";
import {
    AlertOutlined,
    AppleOutlined,
    CheckOutlined, CheckSquareOutlined, ClockCircleOutlined,
    DeleteOutlined,
    DesktopOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    StopOutlined,
    TagOutlined,
    WindowsOutlined,
} from "@ant-design/icons";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import AgentEditDrawer from "./AgentEdit/AgentEditDrawer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDocker} from "@fortawesome/free-brands-svg-icons";
import IPDisplay from "../ipinfo/IPDisplay";
import moment from "moment/moment";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import {PresetStatusColorType} from "antd/es/_util/colors";
import HealthTag from "../health/HealthTag/HealthTag";

interface AgentTableProps {
    Agents:any,
    Loading: boolean,
    onChange?: () => void
}

const AgentTable =
    ({Agents, Loading, onChange}: { Agents: any, Loading: boolean, onChange: () => void}) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // Modal refs
    const refAgentEditDrawer = useRef<DrawerRef>(null);

    const Delete = async (id:string) => await API.deleteAgent(id).then(() => onChange());
    const Drainstop = async (id:string) => await API.agentDrainstop(id).then(() => onChange());
    const Wake = async (id:string)=> await API.agentWake(id).then(() => onChange());

    const HealthCheck = async (id:string) => await API.agentHealthCheck(id).then(() => onChange());

    //const Disable = async (id) => await API.setAdminEnableDisable(id,"disable").then(() => onChange());
    //const Enable = async (id) => await API.setAdminEnableDisable(id,"enable").then(() => onChange());

    return (
        <>
            <AgentEditDrawer ref={refAgentEditDrawer} onChange={onChange}/>
            <Table
                dataSource={Agents}
                rowKey="id"
                pagination={false}
                loading={Loading}
                columns={[
                    {
                        key:'icon',
                        width:50,
                        render: (record) => {
                            // OS Icon selection
                            if(record.OS === "Linux") return <FontAwesomeIcon icon={faDocker} />
                            if(record.OS === "Windows") return <WindowsOutlined/>
                            if(record.OS === "Mac") return <AppleOutlined/>

                            return <DesktopOutlined />

                        }
                    },
                    {
                        title: "Name",
                        key: 'Name',
                        dataIndex: 'Name',
                        render: (text) => text,
                    },
                    {
                        title: 'Status',
                        key: 'status',
                        render: (record) => {

                            let status = "Offline";
                            let statusco = "red";
                            let statusico = <InfoCircleOutlined/>;

                            if(record.Online)
                            {
                                status = "Online";
                                statusco = "green";
                                statusico = <CheckOutlined />;
                            } else {
                                status = "Offline";
                                statusco = "red";
                                statusico = <InfoCircleOutlined/>;
                            }

                            if(record.Online && !record.Adopted)
                            {
                                status = "Not Adopted";
                                statusco = "orange";
                                statusico = <InfoCircleOutlined/>;
                            }

                            if(record.Online && record.DrainStop)
                            {
                                status = " Drain stopping";
                                statusco = "orange";
                                statusico = <StopOutlined />;
                            }

                            return (
                                <>
                                    <Tag color={statusco} key="Online" icon={statusico}>
                                        {status}
                                    </Tag>
                                </>
                            )
                        },
                    },
                    {
                        title: 'Version',
                        dataIndex: 'Version',
                        key: 'version',
                        render: text => <Typography.Text type={"secondary"}>{text}</Typography.Text>,
                    },
                    {
                        title: 'Characteristics',
                        dataIndex: 'Characteristics',
                        key: 'Characteristics',
                        width: 200,
                        render: text => <>
                            { text && text.map((c:string) => {
                                return(<Tag>{c}</Tag>)
                            })
                            }

                        </>
                    },
                    {
                        title: 'IP',
                        dataIndex: 'IP',
                        key: 'IP',
                        render: text => <IPDisplay IP={text} />,
                    },
                    {
                        title: 'Health',
                        key: 'Health',
                        render:(record) => <HealthTag ObjectType={"agent"} ObjectID={record.id}/>
                    },
                    {
                        title: 'Uptime',
                        dataIndex: 'ConnectionStart',
                        key: 'up',
                        render: (text,record:any) => {

                            const timeStart = moment(text);
                            const timeEnd = moment(new Date());
                            const diff = timeEnd.diff(timeStart);
                            const diffDuration = moment.duration(diff);
                            let UptimeString = "";

                            if(record.Online)
                            {
                                UptimeString = diffDuration.days() + " d " + diffDuration.hours() + " h " + diffDuration.minutes() + " m " + diffDuration.seconds() + " s";
                            }

                            return (
                                <Text type="secondary">{UptimeString}</Text>
                            )
                        },
                    },
                    {
                        key:'actions',
                        render:(record) => {

                            let items = [
                                {
                                    label: 'Edit',
                                    icon: <EditOutlined/>,
                                    key: 'Edit',
                                },
                                {
                                    label: 'Delete',
                                    icon: <DeleteOutlined/>,
                                    key: 'Delete',
                                },
                                {
                                    label: 'Wake',
                                    icon: <ClockCircleOutlined />,
                                    key: 'wake',
                                },
                                {
                                    label: 'Run Health Check',
                                    icon: <CheckSquareOutlined />,
                                    key: 'health',
                                },
                                {
                                    label: 'Drainstop',
                                    icon: <TagOutlined />,
                                    key: 'drainstop',
                                }
                            ];

                            const onClick = (key:any) => {


                                if(key.key==="Edit" && refAgentEditDrawer.current !== null)
                                {
                                    refAgentEditDrawer.current.showDrawer(record.id);
                                }

                                if(key.key==="drainstop")
                                {
                                    Drainstop(record.id);
                                }

                                if(key.key==="wake")
                                {
                                    Wake(record.id);
                                }

                                if(key.key=="health")
                                {
                                    HealthCheck(record.id);
                                }

                                if(key.key==="Delete")
                                {
                                    // Confirm delete
                                    Modal.confirm({
                                        title: 'Confirm delete',
                                        icon: <ExclamationCircleOutlined />,
                                        content: <>
                                            <Text>Deleting </Text>
                                            <Text strong>{record.id} </Text>
                                            <Text>is permanent.</Text>
                                        </>,
                                        okText: 'Delete',
                                        cancelText: 'Cancel',
                                        onOk: () => Delete(record.id)
                                    });
                                }
                            }

                            return (

                                <Dropdown menu={{ items, onClick }}>
                                    <Button onClick={(e) => e.preventDefault()}>
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )
                        }
                    }
                ]}
            />
        </>

    )
}

export default AgentTable;