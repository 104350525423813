import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APITProvider from "../../service/api";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import PageHeader from "../misc/PageHeader";
import {Alert, Space} from "antd";
import HomeStat from "./HomeStat/HomeStat";
import HomeMyLabUsers from "./HomeMyLabUsers/HomeMyLabUsers";
import HomeMyLabs from "./HomeMyLabs/HomeMyLabs";
const HomePage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refDrawerLabAdd = useRef<DrawerRef>(null);

    const {
        data: Labs,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["labs"],
        async () => await APIT.labsGet()
    );

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Home"
            />

            <Space direction="vertical">


                <HomeStat />

                <HomeMyLabs />

                <HomeMyLabUsers />

                <Space>

                </Space>
            </Space>
        </>

    )
}

export default HomePage;