import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Drawer, Form, Input, Modal, Radio, Select, Space} from "antd";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import DrawerAddProps from "../../../classes/DrawerAdd/DrawerAddProps";
import APITProvider from "../../../service/api";
import DomainSelect2 from "../../domains/DomainSelect2";

const TenantAddDrawer = forwardRef<DrawerRef,DrawerAddProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [TenantOption, SetTenantOption] = useState(1);

    // Refs
    const [form] = useForm();

    // Funcs
    const showDrawer = () => {
        SetOpen(true);
    }

    const handleSave = async (vals:any) => {
        // Create dto edit object

        const modal = Modal.success({
            title: 'Saving',
            content: `Tenant is being created, please hold.`,
        });

        vals.TenantType = TenantOption;

        if(TenantOption===2)
        {
            vals.TenantDomain = null;
            vals.TenantID = null;
        }

        API.postTenant(vals).then(() => {

            if(onChange !== undefined)
            {
                onChange();
            }

            modal.destroy();
            handleClose();
        }).catch(() => {
            modal.destroy();
        });

    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    const changeTenantOption = (val:any) => {
        SetTenantOption(val.target.value);
    }

    return (
        <Drawer
            title="Add new tenant"
            onClose={handleClose}
            open={Open}>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}>

                <Form.Item
                    label="Tenant Type"
                    name="TenantType"
                    rules={[{ required: true, message: 'Please enter descriptive name' }]}>
                    <Radio.Group onChange={changeTenantOption} value={TenantOption}>
                        <Space direction="vertical">
                            <Radio value={1} defaultChecked={true}>Office 365</Radio>
                            <Radio value={2}>SMTP</Radio>
                            <Radio value={3}>Office 365 (Unmanaged)</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Descriptive Name"
                    name="Name"
                    rules={[{ required: true, message: 'Please enter descriptive name' }]}>
                    <Input />
                </Form.Item>

                {TenantOption===1 || TenantOption==3 &&
                    <>
                        <Form.Item
                            label="Office 365 Tenant ID"
                            name="TenantID"
                            rules={[{ required: true, message: 'Please enter office 365 tenant id' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Office 365 Tenant Domain"
                            name="TenantDomain"
                            rules={[{ required: true, message: 'Please enter office 365 tenant domain' }]}>
                            <Input />
                        </Form.Item>
                    </>
                }

                {TenantOption===2 &&
                    <>
                        <Form.Item
                            label="Email Domain"
                            name="BaseDomain"
                            rules={[{ required: true, message: 'Please select a domain' }]}>
                            <DomainSelect2/>
                        </Form.Item>
                    </>
                }


                <Button type="primary" htmlType="submit">
                    Add
                </Button>

            </Form>

        </Drawer>

    )

});

export default TenantAddDrawer;