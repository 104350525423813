import React, {useContext} from "react";
import {Badge, Collapse, Space, Spin, Table, Typography} from 'antd';
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import {useQuery} from "@tanstack/react-query";
import DateTimeView from "../../misc/DateTimeView";
import IPDisplay from "../../ipinfo/IPDisplay";
import UserAgentView from "../../misc/UserAgentView";
import WebsitePayloadTag from "../WebsitePayloadTag";

const WebsessionDetail = ({WebsiteID, SessionID}: { WebsiteID: string, SessionID: string }) => {

        // Construct token
        const {token} = useContext(AppContext);
        const APIT = new APITProvider(token, true);


        const {
            data: SessionsCookies,
            isLoading: isLoading,
            refetch: refetch
        } = useQuery({
            queryKey: ["websitesessioncookies", SessionID],
            queryFn: async () => await APIT.WebsiteSessionCookiesGet(WebsiteID, SessionID)
        });

    const {
        data: SessionVisits,
        isLoading: isLoadingVisits,
        refetch: refetchVisits
    } = useQuery({
        queryKey: ["websitesessionvisits", SessionID],
        queryFn: async () => await APIT.WebsiteSessionRequestsGet(WebsiteID, SessionID)
    });


    return <Collapse ghost={true}>
        <Collapse.Panel header={
            <Space>
                <Typography.Text>Cookies</Typography.Text>
                {SessionsCookies !== undefined && <Badge count={SessionsCookies.length}/>}
                {isLoading && <Spin />}
            </Space>
        } key="Cookies">
            <Table
                dataSource={SessionsCookies}
                rowKey="id"
                pagination={false}
                loading={isLoading}
                columns={[
                    {
                        title: "Host",
                        key: 'Host',
                        dataIndex: 'Host',
                        render: (text) => text,
                    },
                    {
                        title: "Name",
                        key: 'Name',
                        dataIndex: 'Name',
                        render: (text) => text,
                    },
                    {
                        title: "Value",
                        key: 'Value',
                        dataIndex: 'Value',
                        render: (text) => text,
                    }
                ]}
            />
        </Collapse.Panel>

        <Collapse.Panel header={
            <Space>
                <Typography.Text>Requests</Typography.Text>
                {SessionVisits !== undefined && <Badge count={SessionVisits.length}/>}
                {isLoadingVisits && <Spin />}
            </Space>
        } key="Requests">
            <Table
                dataSource={SessionVisits}
                rowKey="time"
                pagination={false}
                loading={isLoading}
                columns={[
                    {
                        title: "Time",
                        key: 'time',
                        dataIndex: 'time',
                        width:200,
                        render: (text) => <DateTimeView DateTime={text} />,
                    },
                    {
                        title: "Method",
                        key: 'Method',
                        dataIndex: 'Method',
                        width: 50,
                        render: (text) => text,
                    },
                    {
                        title: "Path",
                        key: 'RequestPath',
                        dataIndex: 'requestpath',
                        render: (text) => text,
                    },
                    {
                        title: "Query",
                        key: 'Query',
                        dataIndex: 'Query',
                        render: (text) => text,
                    },
                    {
                        title: "OS",
                        key: 'OS',
                        dataIndex: 'UserAgent',
                        render: (text) => <UserAgentView UserAgent={text} ShowOS />,
                    },
                    {
                        title: "Browser",
                        key: 'Browser',
                        dataIndex: 'UserAgent',
                        render: (text) => <UserAgentView UserAgent={text} ShowBrowser />,
                    },
                    {
                        title: "IP",
                        key: 'IP',
                        dataIndex: 'ip',
                        render: (text) => <IPDisplay IP={text}/>,
                    },
                    {
                        title: "Payload",
                        key: 'Payload',
                        dataIndex: 'Payload',
                        render: (text) => <WebsitePayloadTag Payload={text}/>,
                    },
                ]}
            />
        </Collapse.Panel>

    </Collapse>

    }

export default WebsessionDetail;