import React, {useContext, useRef} from 'react'
import {useQuery} from "@tanstack/react-query";
import PlaybookTable from "../Playbook/PlaybookTable";
import PageHeader from "../misc/PageHeader";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";
import {useNavigate} from "react-router-dom";
import {Button, message, Tabs} from "antd";
import {GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import PlaybookAddDrawer from "./PlaybookAddDrawer";
import AttackRunModalNew from "../attack/AttackRunModalNew";

const MyPlaybooksPage = (props) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token,true);

    const PlaybookAddDrawerRef = useRef();
    const PlaybookRunModal = useRef();

    let Navigate = useNavigate();

    // React Query
    const { data: MyPlaybooks,
            isLoading: isLoadingMy,
            refetch: refreshMy } = useQuery(
        ["myplaybooks"],
        async() => await API.getPlaybooks(true, false,true)
    );

    const { data: Playbooks,
        isLoading: isLoading,
        refetch: refresh } = useQuery(
        ["playbooks"],
        async() => await API.getPlaybooks(true, false,false)
    );

    const handleEdit = (playbook) => {
        Navigate(playbook.id);
    }

    const openPlaybookExec = (playbook) => {
        PlaybookRunModal.current.Open(playbook.id);
    }

    const playbookDelete = (playbook) => {
        API.deletePlaybook(playbook.id).then(() => {
            refreshMy();
        });
    }

    const executePlaybook = (id,payload) => {
        API.runPlaybook(id,payload).then((pe) => {
            message.info("Playbook Started");
        })
    }

    return (
        <>
            <PageHeader
                Title="Playbooks"
                Buttons={
                    <Button icon={<PlusCircleOutlined />} onClick={() => PlaybookAddDrawerRef.current.showDrawer()} outline>Create</Button>
                }
            />

            <PlaybookAddDrawer ref={PlaybookAddDrawerRef} onAdd={(id) => Navigate(id)}/>
            <AttackRunModalNew ref={PlaybookRunModal} closeOnComplete={true}/>


            <Tabs defaultActiveKey="local">

                <Tabs.TabPane
                    key="local"
                    tab={<span><HomeOutlined/>My Playbooks</span>
                    }>

                    <PlaybookTable
                        Playbooks={MyPlaybooks}
                        onDelete={playbookDelete}
                        onPlaybookEdit={handleEdit}
                        showDupeButton={true}
                        onPlaybookRun={(id) => PlaybookRunModal.current.run(id)}
                        showEditButton={true}
                        showRunButton={true} />


                </Tabs.TabPane>

                <Tabs.TabPane
                    key="global"
                    tab={<span><GlobalOutlined />Shared Playbooks</span>
                    }>

                    <PlaybookTable
                        Playbooks={Playbooks}
                        onDelete={playbookDelete}
                        onPlaybookEdit={handleEdit}
                        showDupeButton={true}
                        onPlaybookRun={(id) => PlaybookRunModal.current.run(id)}
                        showRunButton={true} />

                </Tabs.TabPane>

            </Tabs>

        </>
    );

}
export default MyPlaybooksPage