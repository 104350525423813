import React, {useEffect, useState} from "react";
import {Button, Col, Drawer, Form, Row, Select, Space, Table} from 'antd';
import Text from "antd/es/typography/Text";
import AdminDisplay from "./AdminDisplay";
import {CloseOutlined} from "@ant-design/icons";

const AdminListDrawer = (props) =>
{
    // Based on if we are editing or adding
    const [Editing, setEditing] = useState(false);
    const [Adding, setAdding] = useState(false);

    const [SelectedAdmin,setSelectedAdmin] = useState();
    const [AdminsAvailable,setAdminsAvailable] = useState();

    useEffect(() => {

        if(props.API !== undefined)
        {

            props.API.getOperators().then((Operators) => {
                setAdminsAvailable(Operators)
            })
        }


    },[props.Show, props.API]);

    const handleFinish = values => {

        console.dir(values);

        if(Adding)
        {
            props.onAdd(values);
        }

        if(Editing)
        {
            props.onEdit(values);
        }

    };

    const handleAdd = () => {
        props.onAddAdmin(SelectedAdmin);
        setSelectedAdmin(null);
    }

    //onClose={onClose} open={open}

    return (
        <Drawer title={props.Title} placement="right" open={props.Show} onClose={props.onClose} width={400}>
        <Space direction="vertical" style={{display: 'flex'}}>
            <Row gutter={5}>
                <Col span={19}>
                <Select
                    showSearch
                    optionLabelProp="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                    }
                    style={{ width: '100%' }}
                    placeholder="Select a user"
                    value={SelectedAdmin}
                    onChange={(x) => setSelectedAdmin(x)}>

                    {
                        AdminsAvailable && AdminsAvailable.map((u, i) => {

                            let Show = true;

                            // already in list?
                            if(Array.isArray(props.Admins))
                            {
                                if(props.Admins.findIndex(x => x === u.id) > -1)
                                {
                                    Show = false;
                                }
                            }

                            // only show if i can
                            if(Show === true)
                            {
                                return (
                                    <Select.Option value={u.id} label={u.id}>
                                        <Row gutter={10} align="middle">
                                            <Col>
                                                <AdminDisplay Admin={u.id} showAvatar={true}/>
                                            </Col>
                                            <Col>
                                                {u.Firstname} {u.Surname}
                                            </Col>
                                        </Row>
                                    </Select.Option>
                                )
                            }
                        })
                    }

                </Select>
                </Col>
                <Col>
                    <Button onClick={handleAdd}>Add</Button>
                </Col>

            </Row>

            <Table
                pagination={{ pageSize: 15 }}
                dataSource={props.Admins}
                rowKey="id"
                showHeader={false}
                columns={[
                    {
                        key: 'pic',
                        render:(text) => {
                            console.log(text);
                            return <AdminDisplay Admin={text} showAvatar={true}/>
                        }
                    },
                    {
                        key: 'id',
                        render:(id) => {

                            let DisplayName = "Unknown";

                            let searchObject= AdminsAvailable.find((admin) => admin.id==id);

                            if(searchObject !== undefined && searchObject !== null)
                            {
                                DisplayName = searchObject.Firstname + " " + searchObject.Surname;
                            }

                            return <Space direction="vertical">
                                <Text>{DisplayName}</Text>
                                <Text type="secondary">{id}</Text>
                            </Space>;
                        }
                    },
                    {
                        key:'remove',
                        render:(id) => {
                            return <CloseOutlined onClick={() => props.onRemoveAdmin(id)}/>
                        }
                    }
                ]}
            />
        </Space>
        </Drawer>
    )
}

export default AdminListDrawer;