import React, {useContext, useRef, useState} from 'react'
import {Alert, Button, Tabs} from "antd";
import {ApartmentOutlined, GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import SiteTable from "./SiteTable";
import SiteAddDrawer from "./SiteAddDrawer";
import PageHeader from "../../misc/PageHeader";
import TaskModal from "../../tasks/TaskModal";
import AttackMap from "../../attack/AttackMap";
import Text from "antd/es/typography/Text";

const SitesPage = (props) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const RefSiteAddDrawer = useRef();

    // React Query
    const { data: Pages, isLoading: isLoading, refetch: refresh } = useQuery(
        ["pages"],
        async() => await API.getPages()
    );

    return (
        <>
            <PageHeader
                Title="Sites"
                Buttons={[
                    <Button icon={<PlusCircleOutlined />} onClick={() => RefSiteAddDrawer.current.showDrawer()} outline>Add</Button>
                ]}
            />

            <SiteAddDrawer ref={RefSiteAddDrawer} onAdd={refresh}/>
            <Tabs defaultActiveKey="local">

                <Tabs.TabPane
                    key="local"
                    tab={<span><HomeOutlined/>Container Payloads</span>
                    }>

                    <Alert
                        message="My container payloads"
                        description="Payloads on this page are only accessible to users in my container."
                        type="info"
                        showIcon
                        closable={true}
                        style={{marginBottom:10}}
                    />

                    <SiteTable Sites={Pages !== undefined ? Pages.filter(x => x.Container == API.GetSetContainer()) : []}
                               Loading={isLoading} onChange={refresh} />

                </Tabs.TabPane>

                <Tabs.TabPane
                    key="global"
                    tab={<span><GlobalOutlined />Shared Payloads</span>
                    }>

                    <Alert
                        message="Shared Payloads"
                        description="Payloads on this page are shared across all containers."
                        type="info"
                        showIcon
                        closable={true}
                        style={{marginBottom:10}}
                    />

                    <SiteTable Sites={Pages} Loading={isLoading} onChange={refresh} />

                </Tabs.TabPane>

            </Tabs>


        </>
    );

}
export default SitesPage