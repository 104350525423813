import React, {useContext} from 'react'
import {Alert, Avatar, Button, Card, Form, Input, message, Modal, Select, Space, Table, Tabs, Tag, Tooltip} from "antd";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";
import Text from "antd/es/typography/Text";
const PlaybookTaskTable = (props) => {


    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    return (
        <Table
            dataSource={props.Tasks}
            rowKey="id"
            pagination={false}
            loading={props.Loading}
            columns={[
                {
                    title: "Task Type",
                    key: 'TaskType',
                    dataIndex: '$type',
                    render: (text) => <Text>{text}</Text>
                },
                {
                    title: "Name",
                    key: 'Name',
                    dataIndex: 'Name',
                    render: (text) => <Text>{text}</Text>
                },
                {
                    title: "Description",
                    key: 'Description',
                    dataIndex: 'description',
                    render: (text) => <Text>{text}</Text>
                },
                {
                    key: 'Actions',
                    render: (record) => <>
                        <Button onClick={() => props.onEditItem(record)}>Edit</Button>
                    </>
                }
            ]}
    />
    )

}
export default PlaybookTaskTable