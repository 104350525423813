import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, Drawer, Row, Select, Space, Table} from "antd";
import AdminDisplay from "../AdminDisplay";
import Text from "antd/es/typography/Text";
import {CloseOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../../service/api";
import AdminsSelectDrawerRef from "./AdminsSelectDrawerRef";

interface AdminSelectDrawerProps {
    Title: string,
    onChange: any,
    Admins: any[]
}

const AdminsSelectDrawer = forwardRef<AdminsSelectDrawerRef,AdminSelectDrawerProps>((props:AdminSelectDrawerProps, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [SelectedAdmin, SetSelectedAdmin] = useState<string|undefined>(undefined);
    const [ReturnFunc, SetReturnFunc] = useState<any>();
    const [AddDisabled, SetAddDisabled] = useState<boolean>(true);

    // Funcs
    const showDrawer = () => {
        handleAdminSelectionChange(undefined);
        SetOpen(true);
    }

    // React queries
    const {
        data: AdminsAvailable,
        isLoading: LoadingOperators,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["admins"],
            queryFn: async() => await APIT.getAdmins(),
            enabled: Open
        }
    );

    const handleAdd = () => {
        // Get the admin
        if(Array.isArray(props.Admins))
        {
            props.onChange([...props.Admins,SelectedAdmin]);
        } else {
            let newArray = [SelectedAdmin];
            props.onChange(newArray);
        }

        // Reset selection
        handleAdminSelectionChange(undefined);
    }

    const handleRemove = (id:string) => {
        props.onChange(props.Admins.filter(item => item !== id));
    }

    const handleAdminSelectionChange = (id:string|undefined) => {
        SetAddDisabled(!(id!=="undefined" && id !== ""))
        SetSelectedAdmin(id);
    }

    const handleClose = () => SetOpen(false);

    return (
        <Drawer title={props.Title} placement="right" open={Open} width={400} onClose={handleClose}>
            <Space direction="vertical" style={{display: 'flex'}}>
                <Row gutter={5}>
                    <Col span={19}>
                        <Select
                            showSearch
                            optionLabelProp="label"
                            optionFilterProp="children"
                            filterOption={(input, option:any) =>
                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select a user"
                            value={SelectedAdmin}
                            onChange={(x) => handleAdminSelectionChange(x)}>

                            {
                                AdminsAvailable && AdminsAvailable.map((u, i) => {

                                    let Show = true;

                                    // already in list?
                                    if(Array.isArray(props.Admins))
                                    {
                                        if(props.Admins.findIndex(x => x === u.id) > -1)
                                        {
                                            Show = false;
                                        }
                                    }

                                    // only show if i can
                                    if(Show === true)
                                    {
                                        return (
                                            <Select.Option value={u.id} label={u.id}>
                                                <Row gutter={10} align="middle">
                                                    <Col>
                                                        <AdminDisplay Admin={u.id} showAvatar={true}
                                                                      showName={true}/>
                                                    </Col>
                                                    <Col>
                                                        {u.Firstname} {u.Surname}
                                                    </Col>
                                                </Row>
                                            </Select.Option>
                                        )
                                    }
                                })
                            }

                        </Select>
                    </Col>
                    <Col>
                        <Button onClick={handleAdd} disabled={AddDisabled}>Add</Button>
                    </Col>

                </Row>

                <Table
                    pagination={{ pageSize: 15 }}
                    dataSource={props.Admins}
                    rowKey="id"
                    showHeader={false}
                    columns={[
                        {
                            key: 'pic',
                            render:(text) => {
                                return <AdminDisplay Admin={text} showAvatar={true} showName={false}/>
                            }
                        },
                        {
                            key: 'id',
                            render:(id) => {

                                let DisplayName = "Unknown";

                                if(AdminsAvailable!==undefined)
                                {
                                    let searchObject= AdminsAvailable.find((admin) => admin.id==id);

                                    if(searchObject !== undefined && searchObject !== null)
                                    {
                                        DisplayName = searchObject.Firstname + " " + searchObject.Surname;
                                    }

                                }

                                return <Space direction="vertical">
                                    <Text>{DisplayName}</Text>
                                    <Text type="secondary">{id}</Text>
                                </Space>;
                            }
                        },
                        {
                            key:'remove',
                            render:(id) => {
                                return <CloseOutlined onClick={() => handleRemove(id)}/>
                            }
                        }
                    ]}
                />
            </Space>
        </Drawer>
    )

});

export default AdminsSelectDrawer;