// @flow

import React, {Component} from "react";

import {Descriptions, Image, Modal, Table, Tabs, Tag} from 'antd';

import {BulbOutlined, LogoutOutlined, MinusCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import DateTimeView from "../misc/DateTimeView";
import Text from "antd/es/typography/Text";
import TaskStatusIcon from "./TaskStatusIcon";
import AttackEmailsTable from "../attack/AttackEmailsTable";
import {faArrowPointer} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WebSessionActionScreenshot from "./WebSessionAction/WebSessionActionScreenshot.react";
import TaskLogType from "../../Models/Task/TaskLogType";
import TaskLogTable from "./TaskLog/TaskLogTable";

class TaskModal extends Component {

    state = {
        Task: [],
        Emails: [],
        LoadingEmails: false,
        ModalOpen: false
    }

    // Global AppContext contains token and operator info
    static contextType = AppContext;

    componentDidMount() {

        this.API = new APIProvider(this.context.token);
    }

    open(id)
    {
      this.API.getTask(id).then((Task) => {
        this.setState({Task: Task});
        this.setState({ModalOpen: true});
      })
      .catch((error) => {
        console.dir(error);
        this.setState({error: error.message})
      })

        this.setState({LoadingEmails:true});
        this.API.getTaskEmails(id).then((r) => {
            this.setState({Emails: r});
            this.setState({LoadingEmails: false});
        })
        .catch((error) => {
            console.dir(error);
            this.setState({error: error.message})
        })
    }

    close()
    {
        this.setState({ModalOpen: false});
    }

    render()
    {

      const { TabPane } = Tabs;

        return (
          <Modal
          onOk={() => this.close()}
          onCancel={() => this.close()}
          width={2000}
          visible={this.state.ModalOpen}
          cancelButtonProps={{hidden: true}}
          okButtonProps={{hidden: true}}
        >
    
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <BulbOutlined />
                Detail
              </span>
            }
            key="1">

            <Descriptions bordered column={2}>

              <Descriptions.Item label="ID">{this.state.Task.id}</Descriptions.Item>
              <Descriptions.Item label="Status"><TaskStatusIcon task={this.state.Task}/></Descriptions.Item>
              <Descriptions.Item label="Admin Owner">{this.state.Task.Operator}</Descriptions.Item>
              <Descriptions.Item label="Agent Owner">{this.state.Task.ownername}</Descriptions.Item>
              <Descriptions.Item label="Description" span="2">{this.state.Task.Description}</Descriptions.Item>
              <Descriptions.Item label="Agent Requirements" span="2">
                {
                  this.state.Task.requiresAgentTo && this.state.Task.requiresAgentTo.map((required, i) => {
                    return (
                      <Tag>{required}</Tag>
                    )
                  })
                }
                  {
                      this.state.Task.AgentRequiresChar && this.state.Task.AgentRequiresChar.map((required, i) => {
                          return (
                              <Tag>{required}</Tag>
                          )
                      })
                  }
                  {
                      this.state.Task.AgentRequiresNotChar && this.state.Task.AgentRequiresNotChar.map((required, i) => {
                          return (
                              <Tag icon={<MinusCircleOutlined />}>{required}</Tag>
                          )
                      })
                  }
              </Descriptions.Item>

            </Descriptions>

          </TabPane>

            <TabPane
                tab={
                    <span>
                  <LogoutOutlined />
                  Input
                </span>
                }
                key="2"
            >

                {this.state.Task && this.state.Task.ev && this.state.Task.ev.InputVariables &&
                    <Table
                        pagination={{pageSize: 100}}
                        dataSource={Object.keys(this.state.Task.ev.InputVariables)}
                        rowKey="id"
                        columns={[
                            {
                                key: 'Key',
                                title: 'Key',
                                render: (record) => {

                                    return record;
                                }
                            },
                            {
                                key: 'Value',
                                title: 'Value',
                                render: (record) => {
                                    return this.state.Task.ev.InputVariables[record];
                                }
                            }
                        ]}
                    />
                }

            </TabPane>
    
    
          <TabPane
              tab={
                <span>
                  <LogoutOutlined />
                  Output
                </span>
              }
              key="3"  
            >

              {this.state.Task && this.state.Task.ev && this.state.Task.ev.output &&
                  <Table
                      pagination={{pageSize: 100}}
                      dataSource={Object.keys(this.state.Task.ev.output)}
                      rowKey="id"
                      columns={[
                          {
                              key: 'Key',
                              title: 'Key',
                              render: (record) => {

                                  return record;
                              }
                          },
                          {
                              key: 'Value',
                              title: 'Value',
                              render: (record) => {
                                  return this.state.Task.ev.output[record];
                              }
                          }
                      ]}
                  />
              }

          </TabPane>

            { this.state.Task && this.state.Task.id &&
          <TabPane
              tab={
                <span>
                  <UnorderedListOutlined />
                  Log
                </span>
              }
              key="4"  
            >

                  <TaskLogTable TaskID = {this.state.Task.id} Autorefresh={!this.state.Task.complete} />

          </TabPane>
            }

          {
              this.state.Task.ev && this.state.Task.ev.$type==="simEventEmail" &&
              <TabPane
              tab={
                <span>
                  <BulbOutlined />
                  Email Send
                </span>
              }
              key="5">

                  <AttackEmailsTable Emails={this.state.Emails} Loading={this.state.LoadingEmails}/>
              </TabPane>
          }

          {
              this.state.Task.ev && this.state.Task.ev.$type==="simEventEmailCheck" &&
              <TabPane
              tab={
                <span>
                  <BulbOutlined />
                  Email Check
                </span>
              }
              key="5">
                  <>
                      { this.state.Task.ev && this.state.Task.ev.urlResults &&
                          <Table
                              dataSource={this.state.Task.ev.urlResults}
                              rowKey="id"
                              pagination={false}
                              columns={[
                                  {
                                      title: 'Real URL',
                                      dataIndex: 'realURL',
                                      key: 'realurl',
                                      render: text => <Text>{text}</Text>,
                                  },
                                  {
                                      title: 'Actions',
                                      key: 'Actions',
                                      render: record =>
                                      {
                                          // Defaults
                                          let ActionColour = "grey";
                                          let Action = "Ignored";

                                          if(record.clicked)
                                          {
                                              ActionColour = "blue";
                                              Action = "Clicked";
                                          }

                                          if(record.clicked && record.downloaded)
                                          {
                                              ActionColour = "blue";
                                              Action = "Clicked | Downloaded";
                                          }

                                          return <Tag color={ActionColour}>{Action}</Tag>

                                      },
                                  },
                                  {
                                      title: 'Protection',
                                      key: 'Protection',
                                      render: record =>
                                      {
                                          let Protected = "None";
                                          if(record.safeLinks)
                                          {
                                              Protected = "Safe Links";
                                          }

                                          return <Text>{Protected}</Text>

                                      },
                                  },
                                  {
                                      title: 'Title',
                                      key: 'Title',
                                      dataIndex: 'title',
                                      render: text => <Text>{text}</Text>,
                                  },
                              ]}
                          />
                      }
                  </>
              </TabPane>
            }

            {
                this.state.Task.ev && this.state.Task.ev.$type==="simEventWebControl" && this.state.Task.ev.Actions.map((act) => {
                    let tabKey = "act_" + act.Order;
                    let tabName = "Action " + act.Order;

                    // Find output screenshot
                    let screenshotOutput = this.state.Task.OutputV2.find(x => x.Name === "Screenshot_" + act.Order);
                    console.dir(screenshotOutput);

                    let screenshotOutputFile = "";
                    if (screenshotOutput !== null && screenshotOutput !== undefined)
                    {
                        screenshotOutputFile = screenshotOutput.Data.FileID;
                    }

                    return <TabPane
                        tab={
                            <span>
                                  <FontAwesomeIcon icon={faArrowPointer} />
                                {tabName}
                                </span>
                        }
                        key={tabKey}>
                        {screenshotOutputFile && <WebSessionActionScreenshot FileID={screenshotOutputFile} /> }
                    </TabPane>
                })

            }

            {
                this.state.Task.ev && this.state.Task.ev.$type==="simEventEmailCheck" && this.state.Task.ev.UseSelenium && this.state.Task.OutputV2.map((output) => {
                    if(output.Name === "Screenshot_Click" && output.Data)
                    {
                        return <TabPane
                            tab={
                                <span>
                            <FontAwesomeIcon icon={faArrowPointer} />
                            URL Click
                        </span>}
                            key="act_click">
                            <WebSessionActionScreenshot FileID={output.Data.FileID} />
                        </TabPane>
                    }

                })


            }

        </Tabs>
    
        </Modal>
        );

    }
}

export default TaskModal;
