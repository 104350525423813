import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {Alert, Button, Space, Tabs} from "antd";
import {GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ProxyPhishletTable from "./ProxyPhishletTable";
import {AppContext} from "../../AppProvider";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import PageHeader from "../misc/PageHeader";
import APITProvider from "../../service/api";
import ProxyPhishletAddDrawer from "./ProxyPhishletAdd";
import CGContextsSegment from "./CGContexts/CGContextsSegment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWandMagicSparkles} from "@fortawesome/free-solid-svg-icons";

const AIPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refDrawerPhishletAdd = useRef<DrawerRef>(null);

    const {
        data: ProxyPhishlets,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["proxyphishlets"],
        async () => await APIT.getProxyPhishlets()
    );

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Artificial Intelligence"
            />


            <ProxyPhishletAddDrawer ref={refDrawerPhishletAdd} onChange={refetch} />

            <Tabs defaultActiveKey="local">

                <Tabs.TabPane
                    key="local"
                    tab={<Space><FontAwesomeIcon icon={faWandMagicSparkles} />Content Generation</Space>
                    }>

                    <CGContextsSegment />

                </Tabs.TabPane>

            </Tabs>
        </>

    )
}

export default AIPage;