import React, {useContext, useEffect, useRef, useState} from 'react'
import {Image, Space, Tag} from "antd";
import SitePayload from "../../Models/Websites/SitePayload";
import {
    FileOutlined,
    InfoCircleOutlined,
    LinkOutlined,
    RightCircleOutlined,
    SearchOutlined, StopOutlined,
    SwapOutlined
} from "@ant-design/icons";
import SitePayloadFile from "../../Models/Websites/SitePayloadFile";
import SitePayloadPage from "../../Models/Websites/SitePayloadPage";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APITProvider from "../../service/api";
import APIProvider from "../../service/api2";
import SitePayloadPhishlet from "../../Models/Websites/SitePyloadPhishlet";
import SitePayloadResponseCode from "../../Models/Websites/SitePayloadResponseCode";
import FileRefHosted from "../../Models/File/FileRefHosted";

interface WebsitePayloadTagProps {
    Payload: SitePayload;
    Weaponized?: boolean;
}

const WebsitePayloadTag = ({Payload,Weaponized}:WebsitePayloadTagProps) => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);
    const API = new APIProvider(token, true);

    const [PayloadPageID, setPayloadPageID] = useState<string | undefined>(undefined);
    const [PayloadFileID, setPayloadFileID] = useState<string | undefined>(undefined);
    const [PayloadPhishletID, setPayloadPhishletID] = useState<string | undefined>(undefined);

    const {
        data: PayloadPage,
        isLoading: isLoadingPayloadPage
    } = useQuery(
        {
            queryKey: ["Page", PayloadPageID],
            enabled: PayloadPageID !== undefined,
            queryFn: async () => await API.getPage(PayloadPageID)
        }
    );

    const {
        data: PayloadFile,
        isLoading: isLoadingPayloadFile
    } = useQuery(
        {
            queryKey: ["File", PayloadFileID],
            enabled: PayloadFileID !== undefined,
            queryFn: async () => await API.getFile(PayloadFileID)
        }
    );

    const {
        data: PayloadPhishlet,
        isLoading: isLoadingPayloadPhishlet
    } = useQuery(
        {
            queryKey: ["Phishlet", PayloadPhishletID],
            enabled: PayloadPhishletID !== undefined,
            queryFn: PayloadPhishletID !== undefined ? async () => await APIT.getProxyPhishlet(PayloadPhishletID) : undefined
        }
    );

    // Determine additional data to load
    useEffect(() => {
        if(Payload !== undefined && Payload !== null)
        {
            if (Payload.$type === "SitePayloadPage") {
                setPayloadPageID((Payload as SitePayloadPage).PageID);
            }
            if (Payload.$type === "SitePayloadPhishlet") {
                setPayloadPhishletID((Payload as SitePayloadPhishlet).PhishletID);
            }
            if (Payload.$type === "SitePayloadFile") {
                if((Payload as SitePayloadFile).File !== undefined)
                {
                    if((Payload as SitePayloadFile).File.$type === "FileRefHosted")
                    {
                        setPayloadFileID(((Payload as SitePayloadFile).File as FileRefHosted).FileID);
                    }
                }

            }
        }
    }, [Payload])

    // React Query

    let color = "";
    let PayloadText = "Unknown";
    let PayloadIcon = null;
    let imgUrl = undefined;

    if (Weaponized === true) {
        color = "red";
    }

    if (Weaponized === false) {
        color = "green"
    }

    if(Payload === null)
    {
        PayloadText = "None";
        PayloadIcon = <StopOutlined />
    }

    if (Payload !== undefined && Payload !== null)
    {
        if (Payload.$type === "SitePayloadFile") {

            PayloadText = "File | Unknown";

            if(PayloadFile !== undefined && (Payload as SitePayloadFile).File !== undefined) {
                PayloadText = "File | " + PayloadFile.name + " as " + (Payload as SitePayloadFile).File.Filename;
            }

            PayloadIcon = <FileOutlined/>
        }

        if (Payload.$type === "SitePayloadRedirect") {
            PayloadText = "Redirect"
            PayloadIcon = <RightCircleOutlined/>
        }
        if (Payload.$type === "SitePayloadPage") {
            if(PayloadPage == undefined)
            {
                PayloadText = "Page | " + (Payload as SitePayloadPage).PageID;
            } else {
                PayloadText = "Page | " + PayloadPage.Name;
            }

            PayloadIcon = <LinkOutlined/>
        }
        if (Payload.$type === "SitePayloadFileBlob") {
            PayloadText = "Blob";
            PayloadIcon = <FileOutlined/>
        }
        if (Payload.$type === "SitePayloadRecon") {
            PayloadText = "Recon";
            PayloadIcon = <SearchOutlined/>
        }

        if (Payload.$type === "SitePayloadResponseCode") {
            PayloadText = "Response Code | " + (Payload as SitePayloadResponseCode).Code;
            PayloadIcon = <InfoCircleOutlined/>
        }

        if (Payload.$type === "SitePayloadPhishlet") {

            if(PayloadPhishlet !== undefined)
            {
                /*if (Phishlet.length === 1) {
                    PhishletName = Phishlet[0].Name;

                }*/

                PayloadText = "Phishlet | " + PayloadPhishlet.Name;
                imgUrl = "https://www.google.com/s2/favicons?domain=" + PayloadPhishlet.Destination;

                /*if (imgUrl !== undefined) {
                    PayloadText = <Space>{"Phishlet | " + PhishletName}<Image src={imgUrl}/></Space>
                } else {
                    PayloadText = "Phishlet | " + PhishletName

                }*/
            }
            PayloadIcon = <SwapOutlined/>

        }
    }

    return <Tag icon={PayloadIcon} color={color}>
        <Space>
            {PayloadText}
            {imgUrl !== undefined && <Image src={imgUrl}/>}
        </Space>
    </Tag>;

}
export default WebsitePayloadTag;