import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Drawer, Form, Input, Modal, Space} from "antd";
import {AppContext} from "../../../AppProvider";
import {useForm} from "antd/es/form/Form";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import DrawerAddProps from "../../../classes/DrawerAdd/DrawerAddProps";
import APITProvider from "../../../service/api";
import proxyPhishlet from "../../../Models/ProxyPhishlet/ProxyPhishlet";

const ProxyPhishletAddDrawer = forwardRef<DrawerRef,DrawerAddProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);

    // Refs
    const [form] = useForm();

    // Funcs
    const showDrawer = () => {
        SetOpen(true);
    }

    const handleSave = async (vals:proxyPhishlet) => {
        // Create dto edit object

        console.dir(vals);

        const modal = Modal.success({
            title: 'Saving',
            content: `Phishlet is being created, please hold.`,
        });

        await APIT.postPhishlet(vals);

        modal.destroy();
        handleClose();

        if(onChange !== undefined)
        {
            onChange();
        }

    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    return (
        <Drawer
            title="Add new agent"
            onClose={handleClose}
            open={Open}>

            <Form
                form={form}
                onFinish={handleSave}>

                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter a name' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: 'Please enter a description' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Destination"
                    name="destination"
                    rules={[{ required: true, message: 'Please enter a destination' }]}>
                    <Input />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    Add
                </Button>


            </Form>

        </Drawer>

    )

});

export default ProxyPhishletAddDrawer;