import React, {useContext, useEffect, useRef, useState} from "react";


import {Button, Col, Form, Row, Select, Tag} from 'antd';
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import PageHeader from "../misc/PageHeader";
import {PlusCircleOutlined} from "@ant-design/icons";
import AdminTable from "./AdminTable.react";

const AdminsPage2 = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const {
        data: Admins,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["admins"],
        async () => await API.getOperators()
    );

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Admins"
            />

            <AdminTable
                Admins={Admins}
                onChange={() => refetch()}
                //onDelete={playbookDelete}
                //onPlaybookEdit={handleEdit}
                //onPlaybookRun={(id) => PlaybookRunModal.current.run(id)}
            />
        </>

    )
}

export default AdminsPage2;