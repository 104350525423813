import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Col, Divider,
    Form,
    Input,
    Modal,
    Row,
    Select, Space,
    Tag
} from "antd";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import {FileOutlined, PlusOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";

const FileReferenceSelector = ({value, onChange, VariablesDependencies, VariablesPlaybook}) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [UsingVariable,SetUsingVariable] = useState(false);

    const {
        data: Files,
        isLoading: isLoading
    } = useQuery(
        ["files"],
        async () => await API.getFiles("id,Name,Description,Flags")
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [innerForm] = useForm();

    const onFinish = (values) => {

        // Create a file reference object
        let fileReference = null;

        if(UsingVariable)
        {
            fileReference = values.FileVariable;
        } else {
            fileReference = {
                "$type": "FileRefHosted",
                "FileID": values.File,
                "Filename": values.Filename
            }
        }

        onChange(fileReference);
        setIsModalOpen(false);
    }

    // For building variable options
    let optionsPlaybook =  [];
    let optionsDependencies = [];
    if(VariablesPlaybook !== undefined)
    {
        VariablesPlaybook.forEach((v) => {

            if(v.Type==="FileReference")
            {
                optionsPlaybook.push({
                    value: v.entryText,
                    label: <Space>
                        <Tag>{v.id}</Tag>
                        <Text>{v.Description}</Text>
                    </Space>
                })
            }
        })
    }

    if(VariablesDependencies !== undefined)
    {
        VariablesDependencies.forEach((v) => {
            if(v.Type==="FileReference")
            {
                optionsDependencies.push({
                    value: v.id,
                    label: <Space>
                        <Tag>{v.id}</Tag>
                        <Text>{v.Description}</Text>
                    </Space>
                })
            }
        })
    }

    if (onChange !== undefined) {

        let showVariableEntry = (VariablesDependencies !== undefined || VariablesDependencies !== undefined) ? true : false;

        let labelName = "";
        if(value !== undefined)
        {
            if(typeof value === "object")
            {
                labelName = value.FileID
            } else {
                labelName = value;
            }
        }

        return (
            <>
                <Modal title="Select File" open={isModalOpen} onOk={innerForm.submit} onCancel={handleCancel}>
                    <Form
                        form={innerForm}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        onFinish={onFinish}>

                        {showVariableEntry && <Form.Item name="FileVariable" label="From Variables">
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Use Variable"
                                onChange={(v) => SetUsingVariable(v===undefined ? false : true)}
                                tagRender={(props) => {
                                    const { value, closable, onClose } = props;

                                    let label = props.label;

                                    if(label === undefined)
                                    {
                                        label = "Static Defined"
                                    }

                                    return (
                                        <Tag
                                            closable={closable}
                                            onClose={onClose}>
                                            {label}
                                        </Tag>
                                    );
                                }}
                                options={[
                                    {
                                        label: "Playbook Variables",
                                        options: optionsPlaybook
                                    },
                                    {
                                        label: "Dependency Outputs",
                                        options: optionsDependencies
                                    }
                                ]}
                            />
                        </Form.Item>}

                        {!UsingVariable &&
                            <>
                                <Form.Item name="File" label="Hosted File"
                                           rules={[{required: true, message: 'Please select a file'}]}>
                                    <Select
                                        style={{width: '100%'}}
                                        placeholder="Select a base file"
                                        optionLabelProp="label">
                                        {Files && Files.map((f, i) => {

                                            const tagName = "Normal";
                                            const tagColor = "default";
                                            const modificationOptions = "";

                                            const Flags = [];
                                            const FlagsRaw = f.Flags.split(',').map(function (item) {
                                                return item.trim();
                                            });
                                            ;

                                            if (FlagsRaw.includes("Malware")) {
                                                Flags.push({
                                                    Name: "Malware",
                                                    Color: "error"
                                                });
                                            }

                                            if (FlagsRaw.includes("PUA")) {
                                                Flags.push({
                                                    Name: "PUA",
                                                    Color: "error"
                                                });
                                            }

                                            if (FlagsRaw.includes("Phish")) {
                                                Flags.push({
                                                    Name: "Phishing",
                                                    Color: "error"
                                                });
                                            }


                                            if (FlagsRaw.includes("Normal")) {
                                                Flags.push({
                                                    Name: "Normal",
                                                    Color: "default"
                                                });
                                            }

                                            if (f.ModificationOptions === "PrependHashedText") {
                                                Flags.push({
                                                    Name: "Modifiable with Random Text",
                                                    Color: "gold"
                                                });
                                            }

                                            if (f.ModificationOptions === "PrependSlashedText") {
                                                Flags.push({
                                                    Name: "Modifiable with Random Text",
                                                    Color: "gold"
                                                });
                                            }

                                            if (f.ModificationOptions === "PrependString") {
                                                Flags.push({
                                                    Name: "Modifiable with Text",
                                                    Color: "gold"
                                                });
                                            }

                                            if (f.ModificationOptions === "PatchString") {
                                                Flags.push({
                                                    Name: "Modifiable Patching Byte Offset (Text)",
                                                    Color: "gold"
                                                });
                                            }


                                            if (f.ModificationOptions === "PatchBytes") {
                                                Flags.push({
                                                    Name: "Modifiable Patching Byte Offset (Binary)",
                                                    Color: "gold"
                                                });
                                            }

                                            if (f.ModificationOptions === "PatchRandomString") {
                                                Flags.push({
                                                    Name: "Modifiable Patching Byte Offset (Random)",
                                                    Color: "gold"
                                                });
                                            }

                                            return (
                                                <Select.Option value={f.id} label={
                                                    <Row gutter={5} align="middle">
                                                        <Col>
                                                            <strong>{f.name}</strong>
                                                        </Col>
                                                        <Col>
                                                            {
                                                                Flags.map((flag, i) => {
                                                                    return (
                                                                        <Tag color={flag.Color}
                                                                             key={flag.Name}>{flag.Name}</Tag>
                                                                    )
                                                                })
                                                            }
                                                        </Col>
                                                    </Row>
                                                }>
                                                    <Row>
                                                        <strong>{f.name}</strong>
                                                    </Row>
                                                    <Row>
                                                        {f.Description}
                                                    </Row>
                                                    <Row>
                                                        {modificationOptions}
                                                    </Row>
                                                    <Row>
                                                        {
                                                            Flags.map((flag, i) => {
                                                                return (
                                                                    <Tag color={flag.Color}>{flag.Name}</Tag>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </Select.Option>
                                            )
                                        })
                                        }

                                    </Select>
                                </Form.Item>

                                <Form.Item name="Filename" label="Filename override">
                                <Input/>
                                </Form.Item>
                            </>
                        }
                    </Form>
                </Modal>

                <>

                    {!value &&
                        <Tag onClick={() => setIsModalOpen(true)} className="site-tag-plus"
                             style={{padding: 10, borderStyle: "dashed", background: "#fff"}}>
                            <PlusOutlined/> Select File
                        </Tag>
                    }

                    {value &&
                        <Tag icon={<FileOutlined/>} style={{padding: 10}} closable={true} onClose={() => onChange(undefined)}>{labelName}</Tag>
                    }

                    <Divider type="vertical"/>

                </>

            </>
        )
    }

};

export default FileReferenceSelector;