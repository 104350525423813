/*

App Provider provides global context to all things underneath

It is used to store
- The API Key
- The current logged on user

 */

import React, {createContext, useEffect, useState} from "react";
import Cookies from "js-cookie";

import EventEmitter from "eventemitter3";

export const AppContext = createContext();

const AppProvider = ({ children }) => {

    const [DarkMode, SetDarkMode] = useState(true);

    const [token, setToken] = useState(Cookies.get('token'));

    // me (the logged on operator
    const [me, setMe] = useState();

    const [myOperator,setMyOperator] = useState();

    // SignalR connection  ;
    const [SetupSigR] = useState();

    const EE = new EventEmitter();

    useEffect(() => {
        localStorage.setItem("darkmode", DarkMode)
    },[DarkMode])

    return (
        <AppContext.Provider value={{token, setToken, me, setMe, myOperator, setMyOperator, DarkMode, SetDarkMode}}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;