import React, { useState} from "react";
import {Switch} from "antd";

interface SwitchWrapperProps {
    value?:boolean,
    onChange?: () => void
}

const SwitchWrapper = ({value, onChange}:SwitchWrapperProps) => {

    return (
        <Switch defaultChecked={value} onChange={onChange}/>
    )

};

export default SwitchWrapper;