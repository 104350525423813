import React, {useContext, useState} from 'react'
import {Form, Input, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import SwitchWrapper from "../../../../MyPlaybooks/SwitchWrapper";
import MultipleStringsInput from "../../../../FormItems/MultipleStringsInput/MultipleStringsInput";


const ProxyPhishletModificationEditItem = (props:any) => {

    // refs

    const [form] = useForm();

    const handleChange = () => {
        if(props.onChange !== undefined)
        {
           props.onChange(form.getFieldsValue())
        }
    }

    return (
        <Form
            initialValues={props.value}
            form={form}
            labelAlign="left"
            labelWrap={true}
            onFieldsChange={handleChange}
            layout="vertical">

            <Form.Item name="id" label="id" hidden={true}>
                <Input />
            </Form.Item>

            <Form.Item name="Priority" label="Priority" hidden={true}>
                <Input />
            </Form.Item>

            <Form.Item name="Match" label="Match">
                <Input />
            </Form.Item>

            <Form.Item name="ManipulateCookies" label="Manipulate Cookies" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item name="DesecureCookies" label="Desecure Cookies" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item name="DesecureCSP" label="Remove Content Security Policy" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item name="DesecureSTS" label="Remove Strict Transport Security" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item name="CaptureURLs" label="Auto Proxy URLs">
                <MultipleStringsInput />
            </Form.Item>

            <Form.Item name="CookieCaptures" label="Captured Cookies">
                <MultipleStringsInput />
            </Form.Item>
        </Form>
    );
}
export default ProxyPhishletModificationEditItem