import React, {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import {Button, Card, Form, Input, message, Switch, Tabs} from "antd";
import {EditFilled, SaveOutlined} from "@ant-design/icons";
import EmailTemplateTable from "./EmailTemplateTable";
import Editor from "@monaco-editor/react";
import {useForm} from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import PageHeader from "../../misc/PageHeader";
import EmailEditorWYSIWYG from "./EmailEditorWSIWYG";

const EmailTemplatePage = (props) => {

    let params = useParams();
    let Navigate = useNavigate();

    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [editor, SetEditor] = useState();
    const [monaco, SetMonaco] = useState();

    // Language for editor
    const [language, SetLanguage] = useState();

    const [Body, SetBody] = useState();
    const [Simulation, SetSimulation] = useState(false);
    const [EditorBody, SetEditorBody] = useState();
    const [TemplateID, SetTemplateID] = useState(undefined);

    // refs
    const [form] = useForm();

    // React Query
    const {
        data: Template,
        isLoading: isLoading
    } = useQuery(
        ["emailtemplate", TemplateID],
        async() => await API.getTemplateEmail(TemplateID)
    );

    const {
        data: TemplateReplies,
        isLoading: isLoadingReplies
    } = useQuery(
        ["emailtemplatereplies", TemplateID],
        async() => await API.getTemplateEmailReplies(TemplateID)
    );

    // Use props over params.
    useEffect(() => {
        if(props.id !== undefined)
        {
            SetTemplateID(props.id);
        } else {
            SetTemplateID(params.id);
        }
    }, [params.id,props.id])

    useEffect(() => {
        if(!isLoading && Template !== undefined)
        {
            SetBody(Template.body);
            SetSimulation(Template.Simulation);
        }
    }, [Template, isLoading])

    const handleEditorDidMount = (editor, monaco) => {

        // Define nim language
        monaco.languages.register({ id: "nim "});

        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        console.dir("handleEditorDidMount");
        SetEditor(editor);
        SetMonaco(monaco);
    }

    const Save = async() => {
        let payload = Template;

        Template.body = Body;
        Template.subject = form.getFieldValue("subject");
        Template.Description = form.getFieldValue("Description");
        Template.Name = form.getFieldValue("Name");
        Template.Simulation = Simulation;

        await API.createTemplateEmail(payload);

        message.info("Saved");
    }

    const HandleWYSIWYGChange = (e) => {
        SetBody(e);
    }

    const HandleEditorChange = (e) => {
        SetEditorBody(e);
    }

    const HandleTabChange = (t) => {
        if(t === "WYSIWYG")
        {
            SetBody(EditorBody)
        }
    }

    if(!isLoading)
    {

        return (
            <>
                <PageHeader
                    Title={Template.Name}
                    Buttons={[
                        <Button icon={<SaveOutlined />} onClick={Save} outline type="primary">Save</Button>
                    ]}
                />

                <Card>
                <Form
                    form={form}
                    initialValues={Template}>

                    <Form.Item name="Name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="Description" label="Description" rules={[{ required: true }]}>
                        <TextArea />
                    </Form.Item>

                    <Form.Item name="subject" label="Subject" >
                        <Input />
                    </Form.Item>

                    {props.hideUseSimulation !== true &&
                        <Form.Item name="Simulation" label="Use in Simulation" rules={[{ required: true }]}>
                            <Switch defaultChecked={Template.Simulation} onChange={(e) => SetSimulation(e)}/>
                        </Form.Item>
                    }

                </Form>

                    <Tabs defaultActiveKey="WYSIWYG" onChange={HandleTabChange}>
                        <Tabs.TabPane tab="WYSIWYG" key="WYSIWYG" icon={<EditFilled/>}>

                            <EmailEditorWYSIWYG
                                Content={Body}
                                onChange={HandleWYSIWYGChange}/>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Edit" key="1" icon={<EditFilled/>}>

                            <Editor
                                height="60vh"
                                language="html"
                                value={Body}
                                onChange={HandleEditorChange}
                                onMount={handleEditorDidMount}/>

                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Replies" key="2">
                            <EmailTemplateTable Templates={TemplateReplies} Loading={isLoadingReplies} onEditClick={(email) => Navigate("../emailtemplate/" + email)}/>
                        </Tabs.TabPane>
                    </Tabs>

                </Card>
            </>
        );
    }

    return <></>
}
export default EmailTemplatePage