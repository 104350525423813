import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {Alert, Button, Form, Input, message, Modal, Spin, Tabs, Tour, TourProps} from "antd";
import {
    GlobalOutlined,
    HighlightOutlined,
    HomeOutlined,
    PlusCircleOutlined,
    RocketOutlined, SaveOutlined,
    ShareAltOutlined
} from "@ant-design/icons";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import DrawerRef from "../../../../classes/DrawerRef/DrawerRef";
import DrawerEditProps from "../../../../classes/DrawerEdit/DrawerEditProps";
import PageHeader from "../../../misc/PageHeader";
import ModalRef from "../../../../classes/ModalRef/ModalRef";
import ProxyPhishletTable from "../ProxyPhishletTable";
import {useForm} from "antd/es/form/Form";
import ProxyPhishletModificationEdit from "./ProxyPhishletModificationEdit/ProxyPhishletModificationEdit";

const ProxyPhishletEditModal = forwardRef<ModalRef, DrawerEditProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        show: showModal
    }));

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [ProxyPhishletID, SetProxyPhishletID] = useState<string | undefined>(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalEffectComplete, setModalEffectComplete] = useState(false);
    const [OkEnabled, setOkEnabled] = useState(false);

    const {
        data: ProxyPhishlet,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["proxyphishlet", ProxyPhishletID],
            queryFn: ProxyPhishletID !== undefined ? async() => await APIT.getProxyPhishlet(ProxyPhishletID) : () => {},
        }
    );

    // refs

    const [form] = useForm();

    // funcs

    const showModal = (id:string) => {
        SetProxyPhishletID(id);
        setIsModalOpen(true);
    }

    const handleOk = () => handleSave();

    const handleSave = () => {
        if(ProxyPhishletID !== undefined)
        {
            const modal = Modal.success({
                title: 'Saving',
                content: `Your phishlet is being saved, please hold.`,
            });

            // Get phishlet from vals
            let payload = form.getFieldsValue();

            // Handle saving
            APIT.phishletPut(ProxyPhishletID, payload).then(() => {
                modal.destroy();
                close();
            }).catch((e:any) => {
                message.error("An error occurred and the phishlet could not be saved");
                modal.destroy();
            })

        }
    }

    const handleCancel = () => close();

    const close = () => {
        setIsModalOpen(false);
        setModalEffectComplete(false);
    }

    return <Modal
        title={ProxyPhishlet == undefined ? "Loading" : "Edit Phishlet"}
        open={isModalOpen}
        okButtonProps={{disabled: !OkEnabled, icon: <SaveOutlined />}}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
        destroyOnClose={true}
        afterOpenChange={() => {
            form.resetFields();
            setModalEffectComplete(true);
        }
        }
    >
        {!setModalEffectComplete && <Spin /> }
        {modalEffectComplete && ProxyPhishlet !== undefined &&
            <Form
                initialValues={ProxyPhishlet}
                form={form}
                labelAlign="left"
                labelWrap={true}
                onFieldsChange={() => setOkEnabled(true)}
                layout="vertical">

                <Tabs defaultActiveKey="info">

                    <Tabs.TabPane
                        key="local"
                        tab={<span><ShareAltOutlined />Phishlet</span>
                        }>

                        <Form.Item name="Name" label="Name">
                            <Input />
                        </Form.Item>

                        <Form.Item name="Description" label="Description">
                            <Input />
                        </Form.Item>

                        <Form.Item name="Destination" label="Destination">
                            <Input />
                        </Form.Item>

                    </Tabs.TabPane>

                    <Tabs.TabPane
                        key="modifications"

                        tab={<span><HighlightOutlined />Modifications</span>
                        }>

                        <Form.Item name="Modifications" label="Modifications">
                            <ProxyPhishletModificationEdit value={undefined} onChange={undefined} />
                        </Form.Item>

                    </Tabs.TabPane>
                </Tabs>

            </Form>
        }
    </Modal>
});

export default ProxyPhishletEditModal;