import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
     Form, Input, Select, Switch
} from "antd";
import {useForm} from "antd/es/form/Form";
import SwitchWrapper from "../MyPlaybooks/SwitchWrapper";

const PlaybookRunConditions = ({RunConditions, onChange}) => {

    const [form] = useForm();

    const handleChange = () => {
        console.log("handleChange");
        onChange(form.getFieldsValue());
    }

    return (
        <>
            <Form
                form={form}
                labelWrap={true}
                initialValues={RunConditions}
                onChange={() => handleChange()}
                labelAlign="left" labelCol={{span: 6}}>

                <Form.Item name="NoSameEntities" label="No same entities"  tooltip="This playbook cannot be used against the same entities. There cannot be another attack started using this playbook with the same entities.">
                    <SwitchWrapper />
                </Form.Item>
            </Form>
        </>
    )
};

export default PlaybookRunConditions;