import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../AppProvider";
import {useQuery} from "@tanstack/react-query";
import PageHeader from "../../misc/PageHeader";
import TenantsTable from "../TenantsTable/TenantsTable";
import APITProvider from "../../../service/api";
import TenantAddDrawer from "../TenantAdd/TenantAddDrawer";
import {Button, message} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";

const TenantsPage2 = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refTenantAddDrawer = useRef<DrawerRef>(null);

    // React query
    const {
        data: Tenants,
        isLoading: isLoading,
        isRefetching: isRefetching,
        refetch: refetch,
    } = useQuery({
        queryKey: ["tenants"],
        queryFn: async () => await APIT.getTenants(),
    });

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Tenants"
                Buttons={
                    <Button icon={<PlusCircleOutlined />} onClick={() => refTenantAddDrawer.current !== null ? refTenantAddDrawer.current.showDrawer() : null}>Add</Button>
                }
            />

            <TenantAddDrawer ref={refTenantAddDrawer} onChange={() => refetch()} />

            <TenantsTable Loading={isLoading} Tenants={Tenants} onChange={() => refetch()}/>

        </>

    )
}

export default TenantsPage2;