import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Button, Drawer, Form, Input, Modal, Select, Space, Spin, Switch, Tabs} from "antd";
import {BulbOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../../service/api";

const IdentityEdit = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [IdentityID, SetUserID] = useState(null);

    // Refs
    const [form] = useForm();

    // React queries
    const {
        data: Identity,
        isLoading: isLoading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["identity", IdentityID],
            queryFn: async() => await API.getIdentity(IdentityID),
            enabled: IdentityID !== null ? true : false
        }
    );

    const {
        data: Behaviours,
        isLoading: isLoadingBehaviours,
    }  = useQuery({
            queryKey: ["behaviours"],
            queryFn: async() => await API.getUserBehaviours()
        }
    );

    // Funcs
    const showDrawer = (id) => {
        SetUserID(id);

        refetch().then(() => {
            form.resetFields();
            SetOpen(true);
        });
    }

    const handleSave = async () => {
        // Create dto edit object

        let dtoEditObject = form.getFieldsValue(true);

        const modal = Modal.success({
            title: 'Saving',
            content: `Identity is being saved, please hold.`,
        });

        await API.putIdentity(IdentityID, dtoEditObject);

        modal.destroy();
        handleClose();

        props.onChange();
    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
        SetUserID(null);
    }

    return (
        <Drawer
            {...props}
            title="Edit Identity"
            closable={IdentityID}
            width={800}
            closeable={true}
            onClose={handleClose}
            extra={
                <Space>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={handleSave}>Save</Button>
                </Space>
            }
            open={Open}>

            {!isLoading &&
                <Form
                    initialValues={Identity}
                    form={form}
                    labelAlign="left"
                    labelWrap={true}
                    labelCol={{span: 4}}>

                    <Tabs defaultActiveKey="detail">
                        <Tabs.TabPane
                            tab={
                                <span><BulbOutlined/>Details</span>
                            }
                            key="taskitemprop">

                            <Form.Item name="Email" label="Email">
                                <Input disabled={true}/>
                            </Form.Item>

                            <Form.Item name="Tags" label="Tags">
                                <Select mode="tags"/>
                            </Form.Item>

                            <Form.Item name="BehaviourID" label="Simulation Behaviour">
                                <Select>
                                    {Behaviours && Behaviours.map((b) => <Select.Option
                                        value={b.id}>{b.id}</Select.Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item name="Unsecure" label="Unsecure User" tooltip="Unsecure users allow passwords to be given as variables in playbooks">
                                <Switch defaultChecked={Identity ? Identity.Unsecure : false} onChange={async (setting) => await APIT.putIdentityUnsecure(Identity.id, setting)}/>
                            </Form.Item>

                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            }
        </Drawer>

    )

});

export default IdentityEdit;