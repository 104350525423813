import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";


import {Form, Input, Modal} from 'antd';
import {useForm} from "antd/es/form/Form";

const PlaybookEditComment = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        show: showModal
    }));

    const [open, setOpen] = useState(false);

    const { defer, deferRef } = useDeferredPromise();

    function useDeferredPromise() {
        const deferRef = useRef(null);

        const defer = () => {
            const deferred = {};

            const promise = new Promise((resolve, reject) => {
                deferred.resolve = resolve;
                deferred.reject = reject;
            });

            deferred.promise = promise;
            deferRef.current = deferred;
            return deferRef.current;
        };

        return { defer, deferRef: deferRef.current };
    }

    const handleOk = () => {
        const comment = form.getFieldValue("comments");

        if(comment === null || comment === undefined)
        {
            Modal.error({
                title: 'Comment Required',
                content: `Enter a comment on this change`,
            });
        }
        else
        {
            deferRef.resolve(comment);
            setOpen(false);
        }
    }

    const handleCancel = () => {
        deferRef.reject();
        setOpen(false);
    }

    // Drawer open close
    const showModal = () => {
        setOpen(true);
        return defer().promise;
    };

    const onClose = () => {
        setOpen(false);
    };

    const [form] = useForm();

    return (
        <Modal
            onOk={handleOk}
            closable={false}
            open={open}
            onCancel={handleCancel}>
            <Form
                layout="vertical"
                form={form}>

                <Form.Item
                    label="Change comments"
                    name="comments">
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )

});

export default PlaybookEditComment;