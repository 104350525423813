import React from "react";
import {Space, Table, Tag} from 'antd';
import Text from "antd/es/typography/Text";
import DateTimeView from "../misc/DateTimeView";

/*



                                const InternetMessageID = email.InternetMessageIDs[0];
                                const NetworkMessageID = email.Recipients[0].NetworkMessageID;


 */

const AttackEmailsTable = (props) =>
{

    const expandedRowRender = (record) => {
        return  <Table
            dataSource={record.Recipients}
            rowKey="id"
            pagination={false}
            columns={[
                {
                    title: 'Recipient',
                    dataIndex: 'Email',
                    key: 'email',
                    render: text => <Text>{text}</Text>
                },
                {
                    title: 'Received',
                    key: 'receivedDT',
                    render: record => {

                        // receivedDT

                        if(record.Received) {
                            return <DateTimeView DateTime={record.ReceivedDT}/>
                        } else {
                            return <Text type="secondary">Not Received</Text>
                        }


                    }
                },
                {
                    title: 'Location',
                    key: 'Location',
                    dataIndex: 'Location',
                    render: text => {

                        if (text === 1) {
                            return <Text type="secondary">Inbox</Text>;
                        }

                        if (text === 2) {
                            return <Text type="secondary">Junk</Text>;
                        }

                        return null;
                    }

                },
                {
                    title: '',
                    key: 'Actions',
                    render: record => {

                        let Actions = [];

                        if (record.ActionsPerformed & 1) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Read"
                            });
                        }

                        if (record.ActionsPerformed & 2) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Reported Phish"
                            });
                        }

                        if (record.ActionsPerformed & 4) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Reported Junk"
                            });
                        }

                        if (record.ActionsPerformed & 8) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Reported Not Junk"
                            });
                        }

                        if (record.ActionsPerformed & 16) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Replied"
                            });
                        }

                        if (record.ActionsPerformed & 32) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Forwarded"
                            });
                        }

                        if (record.ActionsPerformed & 64) {
                            Actions.push({
                                Color: "#bb1818",
                                Name: "Clicked URL"
                            });
                        }

                        if (record.ActionsPerformed & 128) {
                            Actions.push({
                                Color: "#bb1818",
                                Name: "Downloaded"
                            });
                        }

                        if (record.ActionsPerformed & 256) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Moved"
                            });
                        }

                        if (record.ActionsPerformed & 512) {
                            Actions.push({
                                Color: "#55acee",
                                Name: "Deleted"
                            });
                        }
                        return <>
                            { Actions && Actions.map((tagItem) => {
                                return <Tag color={tagItem.Color}>{tagItem.Name}</Tag>
                            })}
                            </>

                    }
                }
            ]}
        />
    }

    return (
        <Table
            dataSource={props.Emails}
            rowKey="id"
            pagination={false}
            expandable={{ expandedRowRender }}
            loading={props.Loading}
            columns={[
                {
                    title: "Subject",
                    key: 'Subject',
                    dataIndex: "Subject",
                    render: text => <Text>{text}</Text>
                },
                {
                    title: "Sender",
                    key: 'Sender',
                    dataIndex: "Sender",
                    render: text => <Text>{text}</Text>
                },
                {
                    title: "Network Message ID",
                    key: 'netMessageID',
                    render: record => {
                        const NetworkMessageID = record.Recipients[0].NetworkMessageID;

                        return <Text>{NetworkMessageID}</Text>
                    }
                }
            ]}
        />
    )
}

export default AttackEmailsTable;