import React, {useContext, useEffect, useState} from 'react'
import './login.css';
import {loginRequest} from "../fedAuthConfig";
import APIProvider from "../service/api2";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {AppContext} from "../AppProvider";
import {Alert, Button, Divider, Image, Space, Tag} from 'antd';
import {CloudOutlined, ExclamationCircleOutlined, LoginOutlined} from "@ant-design/icons";

import imgLogo from "../img/logo.webp"

// Login front page
const LoginPage = (props) => {

    const {token,setToken} = useContext(AppContext);
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [errors, setErrors] = useState();

    const [err, setError] = useState();

    useEffect(() => {
        console.log("Use effect");
        console.dir(instance);

        // Handle MSAL redirect
        instance.handleRedirectPromise()
            .then((s) => {
                console.log(s);
                if (s !== null && s.account != null)
                {
                    console.log("Success");

                    const API = new APIProvider();

                    API.loginfed(s.account.username,s.idToken).then((r) => {
                        setToken(r.token);
                    })
                    .catch((error) => {
                        handleErrorResponse(error);
                    });

                }

            })
            .catch((a) => {

            });

    },[]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleUsernameChange = (value) => {

        setUsername(value);
    }

    const handleErrorResponse = (err) => {
        console.dir(err);

        if(err !== undefined)
        {
            if(err.response !== undefined)
            {
                if(err.response.status === 401) {
                    setError(err.response.data);
                } else if (err.response.status === 500) {
                    // handle known errors
                    if(err.response.data.startsWith("System.ArgumentException: DefaultContainer must be specified"))
                    {
                        setError("User does not have a default container");
                    } else {
                        setError("API returned unknown HTTP 500");
                    }
                } else {
                    console.dir(err);
                }
            } else {
                setError(err.message);
            }
        }
    }

    const upLogin = () => {
        const API = new APIProvider();

        API.login(username,password).then((r) => {
            setToken(r.token);
        })
        .catch((error) => {
            handleErrorResponse(error);
        });
    }

    const handleFedLogin = () => {
        console.log("Fed logon");
        instance.loginRedirect(loginRequest).catch(e => {

        }).then(() => {
            if(isAuthenticated)
            {
                const request = {
                    scopes: ["User.Read"],
                    account: accounts[0]
                };

                instance.acquireTokenSilent(request).then((r) => {

                    console.log("Token return");

                }).catch((e) => {console.dir(e)})

            }
        });
    }

    return (
        <>
            <div className="login-page">
                <div className="form">
                    <Space direction="vertical" size="large">
                        <Image src={imgLogo} preview={false} width={179} height={50}/>
                        {process.env.REACT_APP_env==="Dev" && <Tag color="error" icon={<ExclamationCircleOutlined />}>Development Environment</Tag>}
                        {err &&
                            <Alert
                                description={err}
                                type="error"
                                showIcon
                            />
                        }

                        <form className="login-form">
                            <input type="text" placeholder="username" onChange={(e) => handleUsernameChange(e.target.value)} value={username}/>
                            <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} value={password}/>
                            <Space direction="vertical">
                                <Button type="primary" size="large" onClick={()=>upLogin()} icon={<LoginOutlined />}>Login</Button>
                                <Divider/>
                                <Button width="100%" type="primary" size="large" onClick={()=>handleFedLogin()} icon={<CloudOutlined />}>Federated Logon</Button>
                            </Space>
                        </form>
                    </Space>
                </div>
            </div>
        </>


    )
}

export default LoginPage