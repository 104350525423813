import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Drawer, Form, Input, Modal} from "antd";
import {useForm} from "antd/es/form/Form";
import DrawerAddProps from "../../../classes/DrawerAdd/DrawerAddProps";
import {AppContext} from "../../../AppProvider";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import APITProvider from "../../../service/api";
import ContainerSelect from "../../containers/ContainerSelect.react";


const AdminAddDrawer = forwardRef<DrawerRef,DrawerAddProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);

    // Refs
    const [form] = useForm();

    // Funcs
    const showDrawer = () => {
        SetOpen(true);
    }

    const handleSave = async (vals:any) => {
        // Create dto edit object

        const modal = Modal.success({
            title: 'Saving',
            content: `Admin is being created, please hold.`,
        });

        await APIT.adminPost(vals);

        modal.destroy();
        handleClose();

        if(onChange !== undefined)
        {
            onChange();
        }

    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    /*

        id:string,
    Firstname: string,
    Surname: string,
    Password: string,
    Federated: boolean,
    DefaultContainer: string,

     */
    return (
        <Drawer
            title="Add Admin User"
            onClose={handleClose}
            open={Open}>

            <Form
                form={form}
                onFinish={handleSave}>

                <Form.Item
                    label="Email"
                    name="id"
                    rules={[{ required: true, message: 'Please enter email address' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Firstname"
                    name="Firstname"
                    rules={[{ required: true, message: 'Please enter firstname' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Surname"
                    name="Surname"
                    rules={[{ required: true, message: 'Please enter surname' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Default Container"
                    name="DefaultContainer"
                    rules={[{ required: true, message: 'Please select default container' }]}>
                    <ContainerSelect all={true}/>
                </Form.Item>


                <Button type="primary" htmlType="submit">
                    Add
                </Button>


            </Form>

        </Drawer>

    )

});

export default AdminAddDrawer;