import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Col, Descriptions, Modal, Row, Space, Table, Tag, message
} from "antd";
import Text from "antd/es/typography/Text";
import {
    MailOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";

const EmailTemplateInput = ({value, onChange }) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [Loaded, SetIsLoaded] = useState(false);
    const [ModalOpen, SetModalOpen] = useState(false);
    const [SelectedEmail, SetSelectedEmail] = useState(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const {
        data: EmailTemplates,
        isLoading: isLoading
    } = useQuery(
        ["emailtemplates"],
        async () => await API.getTemplateEmails()
    );

    const getFilterTags = () => {
        let array = [];

        if(EmailTemplates !== undefined)
        {
            EmailTemplates.forEach((t) => {
                if(t.EmailType !== null && t.EmailType !== undefined)
                {
                    t.EmailType.forEach((tag) => {
                        if(!array.find(c => c.value == tag))
                        {
                            array.push({
                                text: tag,
                                value: tag
                            })
                        }
                    })
                }
            });
        }

        return array;
    }

    useEffect(() => {
        if(selectedRowKeys.length===0)
        {
            SetSelectedEmail(null);
        } else {
            API.getTemplateEmail(selectedRowKeys[0]).then((em) => {
                SetSelectedEmail(em);
                console.dir(em);
            })
        }
    },[selectedRowKeys])

    const handleShowModal = () => {
        setSelectedRowKeys([]);
        SetModalOpen(true);
    }

    const onSelectChange = (id) => {
        if(id.length===1)
        {
            setSelectedRowKeys([id[0]]);
        } else {
            setSelectedRowKeys([id[1]]);
        }

    }

    function createMarkup(html) {
        return {__html: html};
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleOk = () => {
        if(selectedRowKeys.length === 0)
        {
            message.error("Must select an email before pressing OK");
        } else {
            onChange(selectedRowKeys[0]);
            SetModalOpen(false);
        }
    }

    return (
        <>
            <Modal
                open={ModalOpen}
                onCancel={() => SetModalOpen(false)}
                onOk={() => handleOk()}
                title="Select template" width="100VW">
                <Row>
                    <Col span={8}>
                        <Table
                            dataSource={EmailTemplates}
                            rowKey="id"
                            rowSelection={rowSelection}
                            columns={[
                                {
                                    title: "Name",
                                    render: (record) => {
                                        return (
                                            <Space direction="vertical">
                                                <Text>{record.Name}</Text>
                                            </Space>
                                        )
                                    }
                                },
                                {
                                    title: "Type",
                                    filters: getFilterTags(),
                                    dataIndex: 'EmailType',
                                    onFilter: (value, record) => record.EmailType !== null && record.EmailType.includes(value),
                                    render: (text) => {
                                        return (
                                            <>
                                                {text && text.map((tag) => <Tag>{tag}</Tag>)}
                                            </>
                                        )
                                    }
                                }
                            ]}
                        />
                    </Col>
                    <Col span={16}>
                            {!SelectedEmail && <Text>Select an email for preview</Text>}

                            {SelectedEmail && <>
                            <Descriptions>

                            </Descriptions>
                            <Descriptions title={SelectedEmail.Name} layout="vertical" bordered column={1} style={{marginLeft:20, marginTop:20}}>
                                <Descriptions.Item label="Subject">{SelectedEmail.subject}</Descriptions.Item>
                                <Descriptions.Item label="Body" >
                                    <div dangerouslySetInnerHTML={createMarkup(SelectedEmail.body)}>

                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                            </>}
                    </Col>
                </Row>
            </Modal>

            {!value &&
                <Tag onClick={handleShowModal} className="site-tag-plus"
                     style={{padding: 10, borderStyle: "dashed", background: "#fff"}}>
                    <PlusOutlined/> Select Template
                </Tag>
            }

            {value &&
                <Tag icon={<MailOutlined/>} style={{padding: 10}} closable={true}
                     onClose={() => onChange(undefined)}>{value}</Tag>
            }

        </>
    )
};

export default EmailTemplateInput;