import React, {useContext, useEffect, useState} from 'react'
import {Alert, Button, Divider, Image, Space, Tag, theme} from 'antd';
import {CloudOutlined, ExclamationCircleOutlined, LoginOutlined} from "@ant-design/icons";
import UserMenuItems from "./UserMenuItems";
import {Header} from "antd/es/layout/layout";
import {AppContext} from "./AppProvider";

const HeaderBar = (props) => {

    const {me} = useContext(AppContext);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return <Header style={{ padding: 0, paddingLeft:5, background: colorBgContainer }} >
        {process.env.REACT_APP_env==="Dev" && <Tag color="error" icon={<ExclamationCircleOutlined />}>Development Environment</Tag>}
        <div className="UserMenuSection">
            <UserMenuItems me={me} Container={props.containerName} onLogout={() => props.onLogout()} onContainerSwitch={() => props.onContainerSwitch()} DarkModeSelected={props.DarkModeSelected} DarkModeChange={() => props.DarkModeChange}/>
        </div>
    </Header>
}

export default HeaderBar;