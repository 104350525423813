import React, {useContext, useEffect, useState} from "react";


import {Alert, Card, Checkbox, Divider, Form, Input, Row, Select, Space, Typography} from 'antd';

import PageSelect from "../PageSelect/PageSelect";
import WebsiteCodeSelect from "../WebsiteCodeSelect/WebsiteCodeSelect";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import ProxyPhishletSelect from "../../FormItems/ProxyPhishletSelect/ProxyPhishletSelect";
import {PagesOutlined} from "@mui/icons-material";
import {
    FileOutlined,
    InfoCircleOutlined,
    LinkOutlined,
    RightCircleOutlined,
    SearchOutlined,
    SwapOutlined
} from "@ant-design/icons";
import FileRefHostedSelect from "../../files/FileRefHostedSelect";

interface  WebsitePayloadFormItemProps {
    value?: any,
    onChange?: any,
    VariablesPlaybook?: any,
    VariablesDependencies?: any,
    UseTypeString?: any
}

const {Text} = Typography;

const WebsitePayloadFormItem = ({value, onChange=undefined, VariablesPlaybook, VariablesDependencies, UseTypeString}:WebsitePayloadFormItemProps) =>
{

    const [fileRequestModalVisible, showFileRequestModal] = useState(false);

    const [Payload, setPayload] = useState();
    const [Page, setPage] = useState();
    const [Redirect, setRedirect] = useState();
    const [FileRequest, setFileRequest] = useState();
    const [CodeMessage, setCodeMessage] = useState();
    const [Code, setCode] = useState();
    const [PhishletID, setPhishletID] = useState();
    const [ProxyDestination, setProxyDestination] = useState<string>();
    const [ProxyRootURLRedirect, setProxyRootURLRedirect] = useState<string>();
    const [ProxyCookiesManipulate, setProxyCookiesManipulate] = useState(false);
    const [ProxyCookiesDescure, setProxyCookiesDescure] = useState(false);
    const [ProxyCSPDesecure, setProxyCSPDescure] = useState(true);

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [form] = useForm();

    const {
        data: Pages,
        isLoading: isLoading
    } = useQuery(
        ["pages"],
        async () => await API.getPages()
    );

    useEffect(() => {
        if(value!==undefined && Payload === undefined)
        {
            console.log("Changing");
            let payloadType = value[UseTypeString];
            setPayload(payloadType);
        }
    }, [value])

    // make the payload for creating a site
    const BuildPayload = () => {

        const ReturnPayload:any = {};

        let formVals = form.getFieldsValue(true);

        UseTypeString = (UseTypeString === undefined || UseTypeString === null) ? "$type" : UseTypeString;

        ReturnPayload[UseTypeString] = Payload;

        if(ReturnPayload[UseTypeString]==="SitePayloadPage")
        {
            ReturnPayload["PageID"] = form.getFieldValue("PageID");;
        }
        else if(ReturnPayload[UseTypeString]==="SitePayloadRedirect")
        {
            ReturnPayload["URL"] = form.getFieldValue("URL");;
        }
        else if(ReturnPayload[UseTypeString]==="SitePayloadFile")
        {
            ReturnPayload["File"] = form.getFieldValue("File");
        }
        else if(ReturnPayload[UseTypeString]==="SitePayloadResponseCode")
        {
            ReturnPayload["Code"] = form.getFieldValue("Code");
            ReturnPayload["Message"] = form.getFieldValue("Message")
        }
        else if(ReturnPayload[UseTypeString]==="SitePayloadProxy")
        {
            ReturnPayload["Destination"] = ProxyDestination;
            ReturnPayload["RedirectRoot"] = ProxyRootURLRedirect;
            ReturnPayload["CookiesManipulate"] = ProxyCookiesManipulate;
            ReturnPayload["CookiesDescure"] = ProxyCookiesDescure;
        }
        else if(ReturnPayload[UseTypeString]==="SitePayloadPhishlet")
        {
            ReturnPayload["PhishletID"] = form.getFieldValue("PhishletID");;
        }

        console.dir(ReturnPayload);

        onChange(ReturnPayload);

    }

    const changePayloadType = (val:any) => setPayload(val);

    // Call back when file request modal completes
    const fileRequestAdd = (payload:any) => {

        setFileRequest(payload);

        showFileRequestModal(false);
    }

    return (
        <Form form={form} onValuesChange={BuildPayload} initialValues={value}>
            <Form.Item name={UseTypeString} label="Type">
                <Select
                    placeholder="Select a payload type"
                    style={{width: '100%'}}
                    onChange={event => changePayloadType(event)}>
                    <Select.Option value="SitePayloadPage">
                        <Space>
                            <LinkOutlined/>
                            <Text>Static Page</Text>
                        </Space>
                    </Select.Option>

                    <Select.Option value="SitePayloadRedirect">
                        <Space>
                            <RightCircleOutlined/>
                            <Text>Redirect</Text>
                        </Space>
                    </Select.Option>

                    <Select.Option value="SitePayloadFile">
                        <Space>
                            <FileOutlined/>
                            <Text>File</Text>
                        </Space>
                    </Select.Option>

                    <Select.Option value="SitePayloadResponseCode">
                        <Space>
                            <InfoCircleOutlined/>
                            <Text>Response Code</Text>
                        </Space>
                    </Select.Option>


                    <Select.Option value="SitePayloadPhishlet">
                        <Space>
                            <SwapOutlined/>
                            <Text>Phishlet</Text>
                        </Space>
                    </Select.Option>

                    <Select.Option value="SitePayloadRecon">
                        <Space>
                            <SearchOutlined />
                            <Text>Recon</Text>
                        </Space>
                    </Select.Option>

                </Select>
            </Form.Item>

            <Divider />

            {Payload==="SitePayloadFile" && <>
            <Alert
                message="File Hosting"
                description="You can use static content here. Playbooks allow you to create dynamic content using file templates, these files are unique."
                type="info"
                showIcon
            />

            <Form.Item name="File" label="File">
                <FileRefHostedSelect VariablesPlaybook={VariablesPlaybook} VariablesDependencies={VariablesDependencies}/>
            </Form.Item></>}

            {Payload==="SitePayloadPage" && <Form.Item name="PageID" label="Page">
                <PageSelect />
            </Form.Item> }

            {Payload==="SitePayloadRedirect" && <Form.Item name="URL" label="Redirect URL" required>
                <Input placeholder="http://www.bing.com"/>
            </Form.Item>}


            {Payload === "SitePayloadResponseCode" &&
                <>
                    <Form.Item name="Code" label="Status Code" required>
                        <WebsiteCodeSelect />
                    </Form.Item>

                    <Form.Item name="Message" label="Status Code Message" required>
                        <Input />
                    </Form.Item>
                </>
            }

            {Payload==="SitePayloadPhishlet" && <>
                <Form.Item name="PhishletID" label="Phishlet" required>
                    <ProxyPhishletSelect />
                </Form.Item>

            </>}

            {Payload==="SitePayloadProxy" && <>
                <Form.Item label="Proxy Destination" required>
                    <Input id="ProxyDestination" placeholder="http://www.bing.com" value={ProxyDestination} onChange={event => setProxyDestination(event.target.value)}/>
                </Form.Item>
                <Form.Item label="Proxy Redirect Root">
                    <Input id="ProxyRedirectRoot" placeholder="" value={ProxyRootURLRedirect} onChange={event => setProxyRootURLRedirect(event.target.value)}/>
                </Form.Item>
                <Divider orientation="left">Content Manipulation</Divider>
                <Space direction="vertical">
                    <Checkbox checked={ProxyCookiesDescure} onChange={() => setProxyCookiesDescure(!ProxyCookiesDescure)}>De-secure cookies</Checkbox>
                    <Checkbox checked={ProxyCSPDesecure} onChange={() => setProxyCSPDescure(!ProxyCSPDesecure)}>Remove Content-Security-Policy Header</Checkbox>
                </Space>
            </>}
        </Form>
    )
}

export default WebsitePayloadFormItem;