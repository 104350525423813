import React, {useContext, useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {Alert, Avatar, Button, Card, Form, Input, message, Modal, Select, Space, Table, Tabs, Tag, Tooltip} from "antd";
import {useForm} from "antd/es/form/Form";
import PageHeader from "../misc/PageHeader";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";
import {
    AuditOutlined,
    FileOutlined,
    BulbOutlined, CarryOutOutlined,
    CloudOutlined,
    EditOutlined,
    PlayCircleOutlined,
    PlusCircleOutlined, PlusOutlined,
    SaveOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {ReactFlowProvider} from "react-flow-renderer";
import PlaybookEditorMap from "../PlaybookEditing/editor/PlaybookEditorMap";
import PlaybookVariableTable from "../PlaybookEditing/PlaybookVariableTable";
import DateTimeView from "../misc/DateTimeView";
import PlaybookNewTaskDrawer from "./PlaybookNewTaskDrawer";
import PlaybookElementDrawer from "./PlaybookElementDrawer";
import PlaybookEditComment from "./PlaybookEditComment";
import AdminDisplay from "../admins/AdminDisplay";
import PlaybookNewVariableDrawer from "../Playbook/PlaybookNewVariableDrawer";
import AttackRunModalNew from "../attack/AttackRunModalNew";
import PlaybookTaskTable from "./PlaybookTaskTable";
import PlaybookIncidentOptions from "../PlaybookEditing/PlaybookIncidentOptions.react";
import AdminsSelectDrawer from "../admins/AdminsSelect/AdminsSelectDrawer";
import PlaybookRunConditions from "../PlaybookEditing/PlaybookRunConditions.react";
import PlaybookPayloadsTable from "../PlaybookEditing/PlaybookPayloads/PlaybookPayloadsTable";
import {faVial} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlaybookTestsPage from "../PlaybookEditing/PlaybookTests/PlaybookTestsPage";

const MyPlaybookPage = (props) => {

    let params = useParams();

    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // refs
    const [infoForm] = useForm();
    const DrawerNewTask = useRef();
    const DrawerElementEdit = useRef();
    const ModalEditComment = useRef();
    const NewVariableDrawer = useRef();
    const runModal = useRef();
    const refAuthorSelectDrawer = useRef();
    const refOperatorSelectDrawer = useRef();

    // state
    const [Iteration, SetIteration] = useState(0);
    const [PlaybookState, SetPlaybookState] = useState();
    const [VariablesV2, SetVariablesV2] = useState();
    const [EditingAdmins, SetEditingAdmins] = useState(false);
    const [PlaybookOperators, SetPlaybookOperators] = useState([]);
    const [PlaybookAuthors, SetPlaybookAuthors] = useState([]);
    const [TestConditions, SetTestConditions] = useState([]);

    // React Query
    const {
        data: Playbook,
        isLoading: isLoading
    } = useQuery(
        ["playbook", params.id],
        async() => await API.getPlaybook(params.id),
        {
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }
    );

    // Playbook Meta
    const {
        data: Meta,
        isLoading: isLoadingMeta,
    } = useQuery(
        ["meta"],
        async() => await API.get("meta/PlaybookTask"),
        {
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        if(Playbook !== undefined)
        {
            SetPlaybookState(Playbook);
            SetPlaybookOperators(Playbook.Operators);
            SetPlaybookAuthors(Playbook.Authors);

            if(Playbook.VariablesV2 !== undefined && Playbook.VariablesV2 !== null)
            {
                SetVariablesV2(Playbook.VariablesV2);
            } else {
                SetVariablesV2([]);
            }

        }
    }, [Playbook])

    const {
        data: Versions,
        isLoading: LoadingVersions
    } = useQuery(
        ["playbookversions", params.id],
        async() => await API.getPlaybookVersions(params.id),
         {
            refetchIntervalInBackground: false, refetchOnWindowFocus: false
        }
    );

    const PerformSave = (comment) => {
        const modal = Modal.success({
            title: 'Saving',
            content: `Your playbook is being saved, please hold.`,
        });

        /// Modification

        let Payload = {
            Tasks: PlaybookState.tasks,
            TasksPreperation: PlaybookState.TasksPreperation,
            TasksCleanup: PlaybookState.TasksCleanup,
            State: infoForm.getFieldValue("State"),
            VariablesV2: VariablesV2,
            VersionComments: comment,
            IncidentOptions: PlaybookState.IncidentOptions,
            RunConditions: PlaybookState.RunConditions,
            Operators: PlaybookOperators,
            Authors:PlaybookAuthors
        };

        if(infoForm.isFieldTouched("Tags"))
        {
            Payload.Tags = infoForm.getFieldValue("Tags")
        } else {
            Payload.Tags = Playbook.Tags;
        }

        if(infoForm.isFieldTouched("Name"))
        {
            Payload.Name = infoForm.getFieldValue("Name")
        } else {
            Payload.Name = Playbook.Name;
        }

        if(infoForm.isFieldTouched("Description"))
        {
            Payload.Description = infoForm.getFieldValue("Description")
        } else {
            Payload.Description = Playbook.Description;
        }

        console.dir(Payload);

        API.editPlaybook(PlaybookState.id,Payload)
            .then((t) =>
            {
                modal.destroy();
            })
            .catch((error) => {
                message.error("An error occurred and the playbook could not be saved");
                modal.destroy();
            });

    }

    const Play = () => {
        runModal.current.run(params.id);
    }

    const Save = async () => {

        console.dir(infoForm);

        let comment = await ModalEditComment.current.show();

        PerformSave(comment);


        /*console.log("Saving");

        Template.Name = form.getFieldValue("Name");
        Template.Description = form.getFieldValue("Description");
        Template.Language = form.getFieldValue("Language");;
        Template.BaseData = editor.getValue();

        console.dir(Template);*/

        //await API.putFileTemplate(params.id, Template);
    }

    // Add new task to playbook
    const onAddTask = (item) => {
        console.dir(item);

        let newPlaybook = PlaybookState;

        // Find a free item ID
        let maxID = 0;

        if(newPlaybook.tasks.length > 0)
        {
            const ids = newPlaybook.tasks.map(object => {
                return object.id;
            });
            maxID = Math.max(...ids);
        }

        // Construct a new task
        let newTask = {
            "$type": item.Type,
            "id": maxID + 1,
            "description": item.Description,
            "dependsDelayMin": item.Delay,
            "Name": item.Name,
            "depends": item.Depends
        }

        newPlaybook.tasks.push(newTask);

        SetPlaybookState(newPlaybook);
        //this.setState({Playbook: newPlaybook});
        increaseIteration();
    }

    const deleteItem = (item) => {
        let newPlaybook = PlaybookState;
        let newPlaybookTasks = [];
        let allowRemoval = true;

        // pre-check for dependencies
        newPlaybook.tasks.forEach((t) => {
            if(t.depends.find(e => e == item.id) !== undefined)
            {
                // Allow removal if dependency count > 1
                if(t.depends.length===1)
                {
                    message.error("Cannot remove task because it has dependencies");
                    allowRemoval = false;
                }
            }
        });

        if(allowRemoval)
        {
            newPlaybook.tasks.forEach((t) => {
                if(t.id != item.id)
                {

                    console.log(t.id + " " + item.id);
                    console.dir(t);

                    // Remove references to deleted task in depends
                    t.depends = t.depends.filter(number => number != item.id);

                    // Push to array
                    newPlaybookTasks.push(t);

                    console.dir(t);
                }
            })

            newPlaybook.tasks = newPlaybookTasks;
            SetPlaybookState(newPlaybook);
            increaseIteration();
        }

    }

    // Edits existing item
    const editItemSave = (task,Phase) => {

        if(Phase==="Main")
        {
            let newPlaybook = PlaybookState;
            let taskIndex = newPlaybook.tasks.findIndex(item => item.id === task.id);

            if (taskIndex !== -1)
            {
                newPlaybook.tasks[taskIndex] = task;
            }
            else {
                message.error("Uncovered error saving, couldn't find task index");
            }

            SetPlaybookState(newPlaybook);
            increaseIteration();
        }

        if(Phase==="Preperation")
        {
            console.log("Preperation return");
        }

    }

    // Add Dependency
    const addDependency = (source,target) => {
        let newPlaybook = PlaybookState;

        // Find the target node
        const sourceIndex = newPlaybook.tasks.findIndex(x => x.id == source);
        const targetIndex = newPlaybook.tasks.findIndex(x => x.id == target);

        console.log("Adding dependency from " + newPlaybook.tasks[sourceIndex].description + " to " + newPlaybook.tasks[targetIndex].description);

        newPlaybook.tasks[targetIndex].depends.push(source);

        SetPlaybookState(newPlaybook);
        increaseIteration();

    }

    const handleVariableAdd = (variable) => {
        let oldVariables = VariablesV2;
        oldVariables.push(variable);
        SetVariablesV2(oldVariables);

        increaseIteration();
    }

    const handleVariableRemove = (id) => {
        console.log("Remove " + id);
        SetVariablesV2(VariablesV2.filter(item => item.Name !== id));
        increaseIteration();
    }

    // Informs child objects of changes
    const increaseIteration = () => {
        SetIteration(Iteration + 1);
    }

    const handleIncidentOptionsChange = (IncidentOptions) => {
        let newPlaybook = PlaybookState;
        PlaybookState.IncidentOptions = IncidentOptions;
        SetPlaybookState(newPlaybook);
    }

    const handleRunConditionsChange = (RunConditions) => {
        console.dir(RunConditions)
        let newPlaybook = PlaybookState;
        PlaybookState.RunConditions = RunConditions;
        SetPlaybookState(newPlaybook);
    }

    const handleClickAddAuthor = () => refAuthorSelectDrawer.current.showDrawer(Playbook.Authors)

    const handleClickAddAdmin = () => refOperatorSelectDrawer.current.showDrawer(Playbook.Operators)

    const handleAuthorChange = (ret) => SetPlaybookAuthors(ret);

    const handleOperatorChange = (ret) => SetPlaybookOperators(ret);

    if(!isLoading && PlaybookState)
    {

       return (
            <>
                <PageHeader
                    Title={PlaybookState.Name}
                    Buttons={[
                        <Space>
                            <Button icon={<SaveOutlined />} onClick={Save} outline type="primary">Save</Button>
                            <Button icon={<PlayCircleOutlined />} onClick={Play} outline>Run</Button>
                        </Space>
                    ]}
                />

                <AttackRunModalNew ref={runModal} closeOnComplete={true}/>
                <PlaybookEditComment ref={ModalEditComment} />
                <PlaybookNewTaskDrawer ref={DrawerNewTask} onAdd={onAddTask} />
                <PlaybookElementDrawer ref={DrawerElementEdit} onSave={editItemSave} Meta={Meta}/>
                <PlaybookNewVariableDrawer ref={NewVariableDrawer} onAdd={handleVariableAdd} existingVariables={VariablesV2}/>

                <AdminsSelectDrawer ref={refAuthorSelectDrawer} Title="Manage Playbook Authors" Admins={PlaybookAuthors} onChange={(val) => handleAuthorChange(val)}/>
                <AdminsSelectDrawer ref={refOperatorSelectDrawer} Title="Manage Playbook Operators" Admins={PlaybookOperators} onChange={(val) => handleOperatorChange(val)}/>

                <Card>
                    <Tabs
                        defaultActiveKey="detail"
                        items={[
                            {
                                label:
                                    <span>
                                        <BulbOutlined />
                                        Detail
                                    </span>,
                                key: "detail",
                                children:
                                    <Form
                                        layout="vertical"
                                        form={infoForm}
                                        initialValues={PlaybookState}>

                                        <Form.Item name="Name" label="Name" required tooltip="Playbook Name">
                                            <Input placeholder="Name of Playbook"/>
                                        </Form.Item>

                                        <Form.Item name="Description" label="Description of Playbook" required
                                                   tooltip="Provide a descriptive name of this playbook">
                                            <Input placeholder="Playbook for doing blah, blah"/>
                                        </Form.Item>

                                        <Form.Item name="Tags" label="Playbook tags" required
                                                   tooltip="Playbook tags">
                                            <Select
                                                mode="tags"
                                                style={{ width: '100%' }}
                                                placeholder="Tags Mode"
                                            />
                                        </Form.Item>

                                        <Form.Item name="State" label="Publish State">
                                            <Select
                                                defaultValue={1}
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: 'Draft',
                                                    },
                                                    {
                                                        value: 2,
                                                        label: 'Published',
                                                    }
                                                ]}
                                            />
                                        </Form.Item>

                                        <Form.Item name="authors" label="Authors">
                                            <Avatar.Group size="large">
                                                <>
                                                    { PlaybookAuthors && PlaybookAuthors.map((a) => <AdminDisplay Admin={a} showAvatar="true" />) }

                                                    <Avatar style={{ backgroundColor: '#1890ff' }} icon={<PlusOutlined />} size="small" onClick={handleClickAddAuthor}/>
                                                </>
                                            </Avatar.Group>
                                        </Form.Item>

                                        <Form.Item name="admins" label="Read Admins">
                                            <Avatar.Group size="large">
                                                <>
                                                    { PlaybookOperators && PlaybookOperators.map((a) => <AdminDisplay Admin={a} showAvatar="true" />) }

                                                    <Avatar style={{ backgroundColor: '#1890ff' }} icon={<PlusOutlined />} size="small" onClick={handleClickAddAdmin}/>
                                                </>

                                            </Avatar.Group>
                                        </Form.Item>

                                    </Form>
                            },
                            {
                                label:
                                    <Space>
                                        <EditOutlined/>
                                        Editor
                                    </Space>,
                                key: "Editor",
                                children:
                                    <ReactFlowProvider>
                                        <PlaybookEditorMap
                                            Height={1000}
                                            Width={1200}
                                            Playbook={PlaybookState}
                                            Iteration={Iteration}
                                            onEditItem={(item) => DrawerElementEdit.current.showDrawer(item, VariablesV2, PlaybookState.tasks, "Main")}
                                            onAddItem={(item) => DrawerNewTask.current.showDrawer(item)}
                                            onDeleteItem={deleteItem}
                                            onAddDependency={addDependency}
                                        />
                                    </ReactFlowProvider>
                            },
                            {
                                label:
                                    <Space>
                                        <CarryOutOutlined />
                                        Preparation
                                    </Space>,
                                key: "PrepTasks",
                                children: <>
                                    <Alert
                                        message="Preperation Tasks"
                                        description="Preperation tasks are run first, in no particular order. These can prepare environments in order for the main phase to run."
                                        type="info"
                                        style={{marginBottom: 20}}
                                        showIcon
                                        closable={true}
                                    />
                                    <PlaybookTaskTable
                                        Tasks={PlaybookState.TasksPreperation}
                                        onEditItem={(item) => DrawerElementEdit.current.showDrawer(item, VariablesV2, PlaybookState.tasks, "Preperation")}/>
                                </>
                            },
                            {
                                label:
                                    <Space>
                                        <CarryOutOutlined />
                                        Defender Options
                                    </Space>,
                                key: "DefOptions",
                                children: <>
                                    <PlaybookIncidentOptions
                                        onChange={(v) => handleIncidentOptionsChange(v)}
                                        IncidentOptions={PlaybookState.IncidentOptions}/>
                                </>
                            },
                            {
                                label:
                                    <Space>
                                       <AuditOutlined />
                                        Run Conditions
                                    </Space>,
                                key: "RunConditions",
                                children: <>
                                    <PlaybookRunConditions
                                        onChange={(v) => handleRunConditionsChange(v)}
                                        RunConditions={PlaybookState.IncidentOptions}/>
                                </>
                            },
                            {
                                label:
                                    <Space><EditOutlined/>Variables </Space>,
                                key: "Variables",
                                children: <>
                                    <PageHeader Buttons={<Button icon={<PlusCircleOutlined />} outline onClick={() => NewVariableDrawer.current.showDrawer()}>New Variable</Button>}/>
                                    <PlaybookVariableTable Variables={VariablesV2} Iteration={Iteration} onRemove={handleVariableRemove}/>
                                </>
                            },
                            {
                                label:
                                    <Space><FileOutlined/>Payloads</Space>,
                                key: "Payloads",
                                children: <>
                                    <PlaybookPayloadsTable PlaybookID={params.id} showAdd />
                                </>
                            },
                            {
                                label:
                                    <Space>
                                            <FontAwesomeIcon icon={faVial} />
                                            Tests
                                        </Space>,
                                key: "Tests",
                                children: <PlaybookTestsPage Tests={TestConditions} onChange={(rules) => SetTestConditions(rules)} PlaybookVariables={VariablesV2}/>
                            },
                            {
                                label:
                                    <Space>
                                        <CloudOutlined /> Versions
                                    </Space>,
                                key: "Versions",
                                children:
                                    <Table
                                        dataSource={Versions}
                                        rowKey="id"
                                        pagination={false}
                                        loading={LoadingVersions}ƒ
                                        columns={[
                                            {
                                                title: "Version",
                                                key: 'Version',
                                                dataIndex: 'Version',
                                                render: (text) => <Text>{text}</Text>
                                            },
                                            {
                                                title: "Hash",
                                                key: 'Hash',
                                                dataIndex: 'VersionHash',
                                                render: (text) => <Text type="secondary">{text}</Text>
                                            },
                                            {
                                                title: "Comments",
                                                key: 'Comments',
                                                dataIndex: 'VersionComments',
                                                render: (text) => <Text>{text}</Text>
                                            },
                                            {
                                                title: "Author",
                                                key: 'Author',
                                                dataIndex: 'VersionAuthor',
                                                render: (text) => <Text>{text}</Text>
                                            },
                                            {
                                                title: "Date",
                                                key: 'VersionDate',
                                                dataIndex: 'VersionDate',
                                                render: (text) => <DateTimeView DateTime={text}/>
                                            },
                                            {
                                                key: 'IsBackup',
                                                dataIndex: 'IsBackup',
                                                render: (text) => {
                                                    if(text)
                                                    {
                                                        return <Tag>Backup</Tag>
                                                    } else {
                                                        return <Tag>Primary</Tag>
                                                    }
                                                }
                                            },
                                            {
                                                key:'actions',
                                                render:(record) => {
                                                    if(record.IsBackup)
                                                    {
                                                        return <Button onClick={() => this.handleVersionRestore(record.id)}>Restore</Button>
                                                    }
                                                }
                                            }
                                        ]}
                                    />
                            }]}
                    />
                </Card>

            </>
        );
    }
}
export default MyPlaybookPage