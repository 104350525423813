import React, {useContext, useEffect, useRef, useState} from 'react'
import {Image, List, Popover, Space, Spin, Tag, Typography} from "antd";
import SitePayload from "../../../Models/Websites/SitePayload";
import {
    CheckOutlined,
    ClockCircleOutlined, LoadingOutlined,
    RocketOutlined, WarningOutlined,

} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";

interface HealthTagProps {
    ObjectID: string;
    ObjectType: string
}

const HealthTag = ({ObjectID, ObjectType}:HealthTagProps) => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [PopoverOpen,setPopOverOpen] = useState(false)

    const {
        data: HealthState,
        isLoading: isLoading
    } = useQuery(
        {
            queryKey: ["health", ObjectType, ObjectID],
            queryFn: async () => await APIT.getHealth(ObjectType, ObjectID)
        }
    );

    const {
        data: HealthStateDetail,
        isLoading: isLoadingDetail
    } = useQuery(
        {
            queryKey: ["healthdetail", ObjectType, ObjectID],
            enabled: PopoverOpen,
            queryFn: async () => await APIT.getHealthDetail(ObjectType, ObjectID)
        }
    );

    // Determine additional data to load
    let statusco = "default";
    let name = "Unknown";

    let Ico = <LoadingOutlined style={{ fontSize: 12 }} spin />;

    let Phase = "Main";


    if (HealthState !== undefined)
    {
        if(HealthState)
        {
            statusco = "green";
            name = "Healthy";
            Ico = <CheckOutlined />
        } else {
            statusco = "red";
            name = "Unhealthy";
            Ico = <WarningOutlined />
        }
    }

    if(HealthState == undefined && !isLoading)
    {
        statusco = "red";
        name = "Error checking health";
        Ico = <WarningOutlined />
    }


    return (
        <Popover
            title="Health Checks"
            overlayStyle={{
                width: "15vw"
            }}
            onOpenChange={setPopOverOpen}
            content={
                <List
                    itemLayout="horizontal"
                    bordered={false}
                    loading={isLoadingDetail}
                    dataSource={HealthStateDetail !== undefined && HealthStateDetail.Checks ? HealthStateDetail.Checks : undefined}
                    renderItem={(item) => {
                        let liIcon = <WarningOutlined color="red"/>

                        if(item.Healthy)
                        {
                            liIcon = <CheckOutlined />
                        }

                        return <List.Item>
                            <List.Item.Meta
                                avatar={liIcon}
                                title={item.Name}
                                description={item.Reason != undefined ? item.Reason : undefined}
                                />

                        </List.Item>
                    }}
                />
            }>
            <Tag icon={Ico} color={statusco}>{name}</Tag>
        </Popover>
    )

}
export default HealthTag;