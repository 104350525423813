import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import PageHeader from "../misc/PageHeader";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import DomainsTable from "./DomainsTable";
import {DomainEditAddModal, DomainEditAddModalRef} from "./DomainEditAddModal";

const DomainsPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // Refs
    const refDrawerAgentAdd = useRef<DomainEditAddModalRef>(null);

    // return on change properties

    return (
        <>
            <DomainEditAddModal ref={refDrawerAgentAdd} />

            <PageHeader
                Title="Domains"
                Buttons={
                    <Button icon={<PlusCircleOutlined />} onClick={() => refDrawerAgentAdd.current !== null ? refDrawerAgentAdd.current.showModal() : null}>Add</Button>
                }
            />

            <DomainsTable />
        </>

    )
}

export default DomainsPage;