import React, {useEffect, useState} from "react";
import {Button, Form, Input, Select, Space, Tag} from "antd";
import {useForm} from "antd/es/form/Form";
import TestConditionRule from "../../../../Models/TestConditions/TestConditionRule";
import TestConditionRuleSource from "../../../../Models/TestConditions/TestConditionRuleSource";
import {HomeOutlined, SaveOutlined} from "@ant-design/icons";
import TaskItemInput from "../../../PlaybookInputs/TaskItemInput";

interface PlaybookTestsFormProps {
    onChange: any,
    Rule: TestConditionRule | undefined,
    PlaybookVariables: any,
    DependencyVariables: any
}

const PlaybookTestsForm = (props:PlaybookTestsFormProps) => {

    const [Rule, SetRule] = useState<TestConditionRule>();

    // Refs
    const [form] = useForm();

    const handleSave = () => {

        let vals = form.getFieldsValue();

        props.onChange(vals);

    }

    useEffect(() => {
        if(props.Rule === undefined)
        {
            SetRule({
                id: crypto.randomUUID(),
                Source: TestConditionRuleSource.TenantAH,
                ContextTenant: undefined,
                WaitFor: 0,
                Repeat: 0,
                Query: "",
                Description: "",
                UseFieldForTime: undefined
            });
        } else {
            SetRule(props.Rule);
        }
    }, [props.Rule])

    useEffect(() => {
        form.setFieldsValue(Rule);
    },[Rule]);

    console.dir(props.PlaybookVariables);

    // @ts-ignore
    return <>
        <Form
            layout="vertical"
            form={form}>

        <Form.Item
            label="id"
            name="id"
            hidden={true}
            rules={[{ required: true, message: 'Please enter a id' }]}>
            <Input disabled={true} />
        </Form.Item>

        <Form.Item
            label="Source"
            name="Source"
            rules={[{ required: true, message: 'Please select source' }]}>
            <Select
                style={{ width: '100%' }}
                placeholder="Select a source">
                <Select.Option value={1}>
                    <Space>
                        <HomeOutlined/>
                        Tenant Advanced Hunting
                    </Space>
                </Select.Option>
            </Select>
        </Form.Item>

        <TaskItemInput Meta={{
            id: "ContextTenant",
            Name: "Advanced Hunting Context",
            Type: ["Identity"]
        }} PlaybookVariables={props.PlaybookVariables} DependencyVariables={props.DependencyVariables}/>

        <Form.Item
            label="Description of rule / what this is looking for"
            name="Description"
            rules={[{ required: true, message: 'Please enter a human readable description' }]}>
            <Input.TextArea />
        </Form.Item>

        <Form.Item
            label="Query"
            name="Query"
            rules={[{ required: true, message: 'Please enter query' }]}>
            <Input.TextArea />
        </Form.Item>

        <Form.Item
            label="Wait seconds to run"
            name="WaitFor">
            <Input />
        </Form.Item>

        <Form.Item
            label="Repeat times untill failure"
            name="Repeat">
            <Input />
        </Form.Item>

        <Form.Item
            label="Use field for pass time"

            name="UseFieldForTime">
            <Input />
        </Form.Item>

    </Form>
        <Button type={"primary"} icon={<SaveOutlined />} onClick={() => handleSave()}>Save</Button>
        </>

};

export default PlaybookTestsForm;