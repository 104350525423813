import React, {useContext} from 'react'
import Text from "antd/es/typography/Text";
import {Button, Dropdown, Menu, message, Popconfirm, Table} from "antd";
import {CopyOutlined, DeleteOutlined, DownOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import AdminDisplay from "../../admins/AdminDisplay";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";

const SiteTable = (props) => {

    let Navigate = useNavigate();

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    const handleDelete = async (id) => {
        await API.deletePage(id);
        props.onChange();
    }

    return <Table
        pagination={{ pageSize: 15 }}
        dataSource={props.Sites}
        rowKey="id"
        loading={props.Loading}
        columns={[
            {
                key: 'Author',
                dataIndex: 'Author',
                width: 20,
                render:(text) => <AdminDisplay Admin={text} showAvatar={true} />
            },
            {
                key: 'name',
                title: 'Name',
                dataIndex:'Name',
                render:(text) => <Text>{text}</Text>
            },
            {
                key: 'Description',
                title: 'Description',
                dataIndex:'Description',
                render:(text) => <Text>{text}</Text>
            },
            {
                key: 'Type',
                title: 'Type',
                render:(record) => {
                    console.dir(record);
                    let type = record["$type"];

                    if(record["$type"] === "FileTemplateNim")
                    {
                        type = "NIM Source";
                    }

                    return <Text>{type}</Text>
                }
            },
            {
                key:'actions',
                render:(record) => {
                    return (
                        <Dropdown overlay={

                            <Menu>
                                <Menu.Item key="edit"
                                           onClick={() => Navigate(record.id)}>
                                    <ExclamationCircleOutlined/> Edit
                                </Menu.Item>

                                <Menu.Item key="delete">
                                    <Popconfirm
                                        title="Are you sure to delete this template? This is unrecoverable and may break any playbooks using this template."
                                        onConfirm={() => handleDelete(record.id)}
                                        okText="Yes"
                                        cancelText="No">
                                        <DeleteOutlined/> Delete
                                    </Popconfirm>
                                </Menu.Item>

                                <Menu.Item key="copy"
                                           onClick={() => {
                                               navigator.clipboard.writeText(record.id);
                                               message.success("Copied Page ID to clipboard");
                                           }}>
                                    <CopyOutlined/> Copy Page ID to Clipboard
                                </Menu.Item>


                            </Menu>

                        }>
                            <Button>
                                <DownOutlined/>
                            </Button>
                        </Dropdown>
                    )
                }
            }
        ]}
    />

}
export default SiteTable