import React from "react";
import {Button, Space, Table} from "antd";
import Text from "antd/es/typography/Text";
import {DevicesOutlined, InputOutlined} from "@mui/icons-material";
import {CheckSquareOutlined, FileOutlined, LaptopOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";


const PlaybookVariableTable = (props) =>
{

    return (
        <Table
            dataSource={props.Variables}
            rowKey="Name"
            pagination={false}
            key={props.Iteration}
            columns={[
                {
                    title: "Type",
                    key: 'Type',
                    dataIndex: 'Type',
                    defaultSortOrder: 'ascend',
                    sorter: (a, b) => a.Type.localeCompare(b.Type),
                    render: (text) => {
                        let icon = <InputOutlined />;
                        let textType = text;

                        switch (text) {
                            case "Identity":
                                icon = <UserOutlined />
                                break;
                            case "Agent":
                                icon = <LaptopOutlined />
                                break;
                            case "EmailTemplate":
                                icon = <MailOutlined />
                                textType = "Email Template";
                                break;
                            case "Boolean":
                                icon = <CheckSquareOutlined />
                                break;
                            case "FileReference":
                                textType = "File";
                                icon = <FileOutlined />
                                break;
                        }

                        return <Space>
                            {icon}
                            {textType}
                        </Space>
                    }
                },
                {
                    title: "Name",
                    key: 'id',
                    dataIndex: 'Name',
                    render: (text) => <Text>{text}</Text>
                },
                {
                    title: "Description",
                    key: 'Description',
                    dataIndex: 'Description',
                    render: (text) => <Text>{text}</Text>
                },                {
                    title: "Value (Pre-filled)",
                    key: 'Data',
                    dataIndex: 'Data',
                    render: (text) => {
                        if(text !== undefined && text !== null)
                        {
                            return <Text>Has default value</Text>
                        }
                    }
                },
                {
                    key: 'Remove',
                    render: (record) => <Button onClick={() => props.onRemove(record.Name)}>Remove</Button>
                },
            ]}
            />
    )
}

export default PlaybookVariableTable;