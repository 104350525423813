import React, {useContext, useRef} from "react";
import APITProvider from "../../../service/api";
import {AppContext} from "../../../AppProvider";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import PageHeader from "../../misc/PageHeader";
import LabEnvironmentUsersTable from "./components/LabEnvironmentUsersTable";

const LabEnvironmentUsersPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refDrawerLabAdd = useRef<DrawerRef>(null);

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Lab Environments"
            />
            <LabEnvironmentUsersTable />
        </>

    )
}

export default LabEnvironmentUsersPage;