import React, {useContext, useEffect, useRef, useState} from "react";


import {Button, Col, Form, Row, Select, Tag} from 'antd';
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import PageHeader from "../misc/PageHeader";
import {PlusCircleOutlined} from "@ant-design/icons";
import IdentityTable from "./IdentityTable.react";
import IdentityAddDrawer from "./IdentityAdd/IdentityAddDrawer.react";

const { Option } = Select;

const IdentityPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // Refs
    const RefIdentityAddDrawer = useRef();

    const {
        data: Identities,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["identities"],
        async () => await API.getIdentities()
    );

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Identities"
                Buttons={
                    <Button icon={<PlusCircleOutlined />} onClick={() => RefIdentityAddDrawer.current.showDrawer()} outline>Add</Button>
                }
            />

            <IdentityAddDrawer ref={RefIdentityAddDrawer} onChange={refetch} />

            <IdentityTable
                Identities={Identities}
                onChange={() => refetch()}
                //onDelete={playbookDelete}
                //onPlaybookEdit={handleEdit}
                //onPlaybookRun={(id) => PlaybookRunModal.current.run(id)}
            />
        </>

    )
}

export default IdentityPage;