import React, {useContext, useEffect, useState} from "react";


import {Col, Row, Select, Tag} from 'antd';
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APITProvider from "../../service/api";

const { Option } = Select;

const DomainSelect2 = (props:any) =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Domain, setDomain] = useState<string|undefined>(undefined);

    // React query
    const {
        data: Domains,
        isLoading: isLoading,
        isRefetching: isRefetching,
        refetch: refetch,
    } = useQuery({
        queryKey: ["domains"],
        queryFn: async () => await APIT.getDomains(),
    });

    const selectDomain = (domain:string) =>
    {
        setDomain(domain);
        props.onChange(domain);
    }

    const getDomainOpts = () => {
        let DomainOpts:any = [];

        if(Domains !== undefined)
        {
            Domains.map((u,i) => {
                let Add = true;

                if(props.Use !== undefined)
                {
                    if(!u.Use.includes(props.Use))
                    {
                        Add = false;
                    }
                }

                if(Add)
                {
                    DomainOpts.push({
                        value: u.id,
                        label: <>
                            <Row gutter={5} align="middle">
                                <Col>
                                    {u.id}
                                </Col>
                                <Col>
                                    {
                                        u.Use && u.Use.map((use) => {
                                            return <Tag>{use}</Tag>
                                        })
                                    }
                                </Col>
                            </Row>
                        </>
                    })
                }

            });
        }

        return DomainOpts;
    }

    return (
        <Select
            showSearch
            optionLabelProp="label"
            optionFilterProp="children"
            filterOption={(input, option:any) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
            }
            style={{ width: '100%' }}
            placeholder="Select a domain"
            value={props.value}
            options={getDomainOpts()}
            onChange={(e) => selectDomain(e)}/>
    )
}

export default DomainSelect2;