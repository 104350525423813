import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Badge, Descriptions,
    Modal, Space,
    Spin, Table, Tabs, Tag,
    Typography,
} from "antd";
import {
    ApartmentOutlined, BulbOutlined, CheckCircleOutlined, GroupOutlined,
} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../../service/api";
import WebsitePayloadTag from "../WebsitePayloadTag";
import AdminDisplay from "../../admins/AdminDisplay";
import date from "date-and-time";
import WebsiteSessionTable from "../WebsiteSessionTable/WebsiteSessionTable";
import SitePayload from "../../../Models/Websites/SitePayload";
const { Text, Title } = Typography;

export interface WebsiteDetailModalRef {
    showModal: any
}

interface WebsiteDetailModalPayloads {
    Rule: string,
    Payload: SitePayload|null
}


export const WebsiteDetailModal = forwardRef<WebsiteDetailModalRef>(({}, ref) => {
    useImperativeHandle(ref, () => ({
        showModal: showModal
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [WebsiteID, SetWebsiteID] = useState<string | undefined>(undefined);
    const [Payloads, SetPayloads] = useState<WebsiteDetailModalPayloads[]>();

    // Refs
    const refTaskModal = useRef(null);

    // React queries
    const {
        data: Website,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["website", WebsiteID],
            queryFn: WebsiteID !== undefined ? async() => await APIT.websiteGet(WebsiteID) : undefined,
            enabled: WebsiteID !== undefined
        }
    );

    // Use Effects - Parse Website Info
    useEffect(() => {

        if(Website!==undefined)
        {
            let newPayloads = [];
            newPayloads.push({
                Rule: "Normal",
                Payload: Website.NormalPayload !== undefined ? Website.NormalPayload : null
            });

            newPayloads.push({
                Rule: "Weaponized",
                Payload: Website.WeaponizedPayload !== undefined ? Website.WeaponizedPayload : null
            });

            SetPayloads(newPayloads);
        }

    }, [Website])

    // Funcs
    const showModal = (id:string) => {
        SetWebsiteID(id);
        refetch().then(() => {
            SetOpen(true);
        });
    }

    const handleClose = () => {
        SetOpen(false);
        SetWebsiteID(undefined);
    }

    const handleTaskOpen = (node: any) => {
        if(refTaskModal.current !== undefined && refTaskModal.current !== null)
        {
            // @ts-ignore
            refTaskModal.current.open(node.id)
        }
    }

    return (
        <Modal
            title={Website === undefined ? "Loading" : <Space><WebsitePayloadTag Payload={Website.NormalPayload}  Weaponized={Website.weaponized}/> {Website.id}</Space>}
            width={ window.innerWidth - 200}
            closable={true}
            onCancel={handleClose}
            onOk={handleClose}
            open={Open}>

            {Loading && <Spin/>}

            {!Loading && Website !== undefined &&
                <Tabs defaultActiveKey="1">

                    <Tabs.TabPane
                        tab={
                            <Space>
                                <BulbOutlined/>
                                Detail
                            </Space>
                        }
                        key="1">

                        <Descriptions title="Information" bordered column={2}>

                            <Descriptions.Item label="ID">{Website.id}</Descriptions.Item>
                            <Descriptions.Item label="Creator"><AdminDisplay Admin={Website.Operator} showAvatar={true} showName={true}/></Descriptions.Item>

                            {Website.Endpoints && Website.Endpoints.map((endpoint) =>
                                    <Descriptions.Item label="Path" span={2}>
                                        {endpoint}
                                    </Descriptions.Item>
                                )}
                        </Descriptions>

                        <Typography.Title level={5} style={{ margin: 0, marginTop:10, marginBottom: 10 }}>
                            Payloads
                        </Typography.Title>

                        <Table
                            dataSource={Payloads}
                            rowKey="id"
                            pagination={false}
                            columns={[
                                {
                                    title: 'Rule',
                                    key: 'rule',
                                    render: (record) => {
                                        return record.Rule
                                    },
                                },
                                {
                                    title: 'Type',
                                    key: 'type',
                                    render: (record) => <WebsitePayloadTag Payload={record.Payload} />
                                },
                                {
                                    title: 'State',
                                    key: 'state',
                                    render: (record) => {

                                        let Active = true;

                                        if (record.Rule === "Weaponized" && Website.weaponized === false) {
                                            Active = false;
                                        }

                                        if (record.Rule === "Normal" && Website.weaponized === true) {
                                            Active = false;
                                        }

                                        if (Active) {
                                            return <Tag icon={<CheckCircleOutlined/>} color="success">
                                                Active
                                            </Tag>
                                        } else {
                                            return <Tag>
                                                Waiting Activation
                                            </Tag>
                                        }

                                    },
                                }
                            ]}/>


                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <Space>
                                <GroupOutlined />
                                Sessions
                                <Badge count={Website.SessionCount} color={Website.SessionCount !== 0 ? "lightgrey" : undefined}/>
                            </Space>
                        }
                        key="2">

                        <WebsiteSessionTable Website={Website.id} />

                    </Tabs.TabPane>


                </Tabs>
            }
        </Modal>

    )

});