// @flow

import React, {Component} from "react";


import {Form, Input, Modal} from 'antd';

class ContainerAdd extends Component {
  state = {
    Container:[]
  }

  ResetState(Container)
  {
    if(Container==null)
    {
      Container = {
        id: null,
        Description: null,
        Expires: false
      }
    }

    this.setState({Container: Container});

  }

  Close()
  {
    this.setState({ModalOpen: false});
  }

  Open(Container)
  {
    this.ResetState(Container);
    this.setState({ModalOpen: true});
  }

  Add()
  {

    this.Close();

    this.props.onAdd(this.state.Container);
  }

  setValue(id,val)
  {
    this.setState(prevState => {
      let Container = { ...prevState.Container };
      Container[id]= val;
      return { Container }
    });
  }

  render() {
    return (

      <Modal
        title="Container Add"
        onCancel={() => this.Close()}
        onOk={() => this.Add()}
        visible={this.state.ModalOpen}
      >
  
        <Form
          layout="vertical"
        >
              <Form.Item label="Container Name" required tooltip="Full Container name e.g contoso.com">
                <Input id="id" placeholder="Unique Container Name" value={this.state.Container.id} onChange={event => this.setValue(event.target.id,event.target.value)}/>
              </Form.Item>
  
      </Form>
    </Modal>
  
    );
  }
}

export default ContainerAdd;
