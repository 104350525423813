import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Form, Input,
    Modal, Select, Spin,
    Typography,
} from "antd";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../service/api";
import SitePayload from "../../Models/Websites/SitePayload";
import TenantSelect from "../tenants/TenantSelect/TenantSelect.react";
const { Text, Title } = Typography;

export interface DomainEditAddModalRef {
    showModal: any
}

export const DomainEditAddModal = forwardRef<DomainEditAddModalRef>(({}, ref) => {
    useImperativeHandle(ref, () => ({
        showModal: showModal
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [DomainID, SetDomainID] = useState<string | undefined>(undefined);
    const [IsTenantDomain, SetIsTenantDomain] = useState<boolean>(false);

    // Form
    const [form] = Form.useForm();

    // React queries
    const {
        data: Domain,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["domain", DomainID],
            queryFn: DomainID !== undefined ? async() => await APIT.domainGet(DomainID) : undefined
        }
    );

    // Funcs
    const showModal = (id:string|undefined) => {
        if(id!==undefined)
        {
            SetDomainID(id);
        } else {
            form.setFieldsValue({});
            SetOpen(true);
        }
    }

    const DetermineType = () => {
        if(Domain !== undefined && Domain.Use.find((x:string) => x == "Office365"))
        {
            TypeChange(Domain.Use);
        }
    }

    const TypeChange = (x:string[]) => {
        console.dir(x);
        if(x.find(y => y === "Office365"))
        {
            SetIsTenantDomain(true);
        } else {
            SetIsTenantDomain(false);
        }
    }

    // Use effects
    useEffect(() => {

        form.resetFields();

        if(Domain !== undefined && Domain.id === DomainID)
        {
            form.setFieldsValue(Domain);
            DetermineType();
            SetOpen(true);
        }
    }, [Domain])

    const handleClose = () => {
        SetOpen(false);
        SetDomainID(undefined);
    }

    const handleSave = (values:any) => {
        // PUT if DomainID is not undefined, POST if undefined

        const modal = Modal.success({
            title: 'Saving',
            content: `Domain is being created, please hold.`,
        });

        console.dir(values);

        // POST
        if(DomainID == undefined)
        {
            console.log("POST!!")
            APIT.domainPost(values).then(() => {
                modal.destroy();
                handleClose();
            })
        }

        // PUT
        if(DomainID !== undefined)
        {
            console.log("PUT!")
            APIT.domainPut(DomainID, values).then(() => {
                modal.destroy();
                handleClose();
            })
        }
    }

    return (
        <Modal
            title={DomainID}
            closable={true}
            onCancel={handleClose}
            afterClose={() => form.resetFields()}

            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        handleSave(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            open={Open}>

            {(Loading === true && DomainID !== undefined) && <Spin />}

            {(Loading === false || (Domain == undefined && DomainID == undefined)) &&
                <Form initialValues={Domain} form={form} layout="vertical">
                    <Form.Item name="id" label="Domain Name" rules={[{ required: true, message: 'Please enter domain name' }]}>
                        <Input disabled={DomainID !== undefined}/>
                    </Form.Item>
                    <Form.Item name="Use" label="Domain Usage" rules={[{ required: true, message: 'Domain must have atleast one use' }]}>
                        <Select
                            mode="multiple"
                            onChange={(x) => TypeChange(x)}
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Please select">
                            <Select.Option key={"Spoof"}>Spoof</Select.Option>
                            <Select.Option key={"SiteFriendly"}>Friendly Sites</Select.Option>ßß
                            <Select.Option key={"SitePhishing"}>Phishing Sites</Select.Option>
                            <Select.Option key={"SiteMalware"}>Malware Sites</Select.Option>
                            <Select.Option key={"SMTP"}>SMTP</Select.Option>ß
                            <Select.Option key={"Office365"}>Office 365</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="Managed" label="Domain Management">
                        <Select
                            style={{width: '100%'}}
                            placeholder="Please select management option">
                            <Select.Option key={"false"}>Self Managed</Select.Option>
                            <Select.Option key={"true"}>CAttack Managed Domain</Select.Option>
                        </Select>
                    </Form.Item>

                    {IsTenantDomain && <Form.Item name="TenantID" label="Tenant domain belongs to" rules={[{ required: true, message: 'Tenant is required when use is Office 365' }]}>
                        <TenantSelect/>
                    </Form.Item>

                    }
                </Form>
            }

        </Modal>

    )

});