import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Form, Input,
    Modal, Select, Spin,
    Typography,
} from "antd";
import EmailTemplatePage from "./EmailTemplatePage";
const { Text, Title } = Typography;

export interface EmailTemplateModal {
    showModal: any
}

export const EmailTemplateModal = forwardRef<EmailTemplateModal>(({}, ref) => {
    useImperativeHandle(ref, () => ({
        showModal: showModal
    }));
    // State
    const [Open, SetOpen] = useState(false);
    const [Template, SetTemplateID] = useState<string|undefined>(undefined);


    // Funcs
    const showModal = (id:string|undefined) => {
        if(id!==undefined)
        {
            SetTemplateID(id);
            SetOpen(true);
        }
    }

    const handleClose = () => {}

    return (
        <Modal
            closable={true}
            onCancel={handleClose}
            width={800}
            open={Open}>

            {Template !== undefined && <EmailTemplatePage id={Template} hideUseSimulation={true} />}


        </Modal>

    )

});