import React, {FC, useContext, useRef, useState} from 'react'
import { Editor as TinyEditor} from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import Editor from "@monaco-editor/react";

import {Button, Card, Dropdown, Input, MenuProps, Space, Tabs} from "antd";
import {DownOutlined, LoginOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import PlaybookVariableDropdownMenu from "../Support/PlaybookVariableDropdownMenu";

interface OnChangeHandler {
    (e:any): void;
}

interface VariableList {
    id: string,
    entryText: string,
    Name: string,
    Type: string,
    VarType: string,
    Description: string,
    OutputTask: string
}

interface HTMLInputProps {
    value?: string | undefined;
    onChange?: OnChangeHandler;
    showVariableButton: boolean;
    DependencyOutputs: VariableList[] | undefined;
    PlaybookVariables: VariableList[] | undefined;
}


const HTMLInput: FC<HTMLInputProps> = ({value, onChange, PlaybookVariables, DependencyOutputs, showVariableButton = false}: HTMLInputProps) => {

    const editorRef = useRef<TinyMCEEditor | null>(null);

    // State
    const [ActiveTab,SetActiveTab] = useState<string|undefined>("WYSIWYG");

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if(onChange !== undefined)
        {
            if(value)
            {
                onChange(value + e);
            } else {
                onChange(value);
            }

        }
    };

    return  <Card
        style={{ width: '100%' }}
        tabList={
            [
                {
                    key: 'WYSIWYG',
                    label: 'WYSIWYG',
                },
                {
                    key: 'HTML',
                    label: 'HTML',
                },
            ]
        }
        activeTabKey={ActiveTab}
        onTabChange={SetActiveTab}>

        {ActiveTab === "WYSIWYG" && <>

            {showVariableButton === true &&
                <Space style={{paddingBottom:5}}>
                    <PlaybookVariableDropdownMenu DependencyOutputs={DependencyOutputs} PlaybookVariables={PlaybookVariables} onClick={handleMenuClick}>
                        <Button icon={<LoginOutlined/>}>
                            <Space>
                                Variables
                                <DownOutlined />
                            </Space>
                        </Button>
                    </PlaybookVariableDropdownMenu>
                </Space>
            }

            <TinyEditor
                apiKey='k6vqmiu4bm8wacz9mtdbmbk6ewtqebcmas17ymwhpggdezj8'
                onInit={(evt, editor) => editorRef.current = editor}
                // the following prevents the editor from manipulating when it's not in focus
                value={ActiveTab === "WYSIWYG" ? value : undefined}
                onEditorChange={onChange}
                init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | code | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </>}

        {ActiveTab === "HTML" && <>

            {showVariableButton === true &&
                <Space style={{paddingBottom:5}}>
                    <PlaybookVariableDropdownMenu DependencyOutputs={DependencyOutputs} PlaybookVariables={PlaybookVariables} onClick={handleMenuClick}>
                        <Button icon={<LoginOutlined/>}>
                            <Space>
                                Variables
                                <DownOutlined />
                            </Space>
                        </Button>
                    </PlaybookVariableDropdownMenu>
                </Space>
            }

            <Editor
                height="30vh"
                value={value}
                onChange={onChange}
                />

        </>
        }

    </Card>
}

export default HTMLInput;