import React, {useContext, useRef, useState} from 'react'
import PageHeader from "../misc/PageHeader";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";
import {useNavigate} from "react-router-dom";
import {Button, Col, Form, Input, message, Modal, Row, Select, Timeline} from "antd";
import FileReferenceSelector from "../files/FileReferenceSelector";
import {useForm} from "antd/es/form/Form";
import AttackRunModalNew from "../attack/AttackRunModalNew";
import {useQuery} from "@tanstack/react-query";
import {ExclamationCircleOutlined, UserOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import FileGenerationSelector from "../files/FileGenerationSelector";
import ApiError from "../ApiError.react";
import AttackScheduleEditModal from "./AttackScheduleEditModal";
import AttackScheduleTable from "./AttackScheduleTable";

const AttackSchedulePage = (props) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const {
        data: PlaybookSchedules,
        isLoading: isLoading,
        refetch: refetch,
    } = useQuery({
            queryKey: ["schedules"],
            queryFn: async () => await API.getPlaybookSchedules(),
        }
    );

    const [form] = useForm();
    const AttackEditModal = useRef();

    const openEdit = (id) => {
        AttackEditModal.current.edit(id);
    }

    const deleteSchedule = (id) => {
        Modal.confirm({
            title: 'Confirm delete',
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Text>Deleting the playbook schedule is permanent</Text>
            </>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => API.deletePlaybookSchedule(id).then(() => {
                refetch();
            })
        });
    }

    const setScheduleState = (item,state) =>
    {
        if(state==="enable")
        {
            API.enablePlaybookSchedule(item).then(() => {
                refetch();
            })
            .catch((error) => {
                message.error(error.message);
            })
        }

        if(state==="disable")
        {
            API.disablePlaybookSchedule(item).then(() => {
                refetch();
            })
            .catch((error) => {
                message.error(error.message);
            })
        }
    }

    return (
        <>
            <PageHeader Title="Scheduled Attacks"/>
            <AttackRunModalNew ref={AttackEditModal} />

            <AttackScheduleTable
                Loading={isLoading}
                Schedules={PlaybookSchedules}
                onEnable={(id) => setScheduleState(id,"enable")}
                onDisable={(id) => setScheduleState(id,"disable")}
                onEdit={(id) => openEdit(id)}
                onDelete={(id) => deleteSchedule(id)}/>
        </>

    )
}
export default AttackSchedulePage