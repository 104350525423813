import React, {useCallback, useEffect, useRef, useState} from 'react';

import ReactFlow, {Background, Controls, useReactFlow} from 'react-flow-renderer';

import dagre from 'dagre';
import PlaybookItemTypes from "../../Playbook/PlaybookItemTypes";
import {Drawer, theme} from "antd";
import BasicEditorNode from "./components/EditorNodes/BasicEditorNode";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidthDefault = 300;
const nodeHeight = 100;

const { useToken } = theme;

/*

This playbook map is used for EDITING

 */

const PlaybookEditorMap = ({ Playbook, Iteration, Height, Width, onEditItem, onAddItem, onAddDependency, onDeleteItem }) => {

    const reactFlowWrapper = useRef(null);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [open, setOpen] = useState(false);
    const [lastID, setLastID] = useState(0);

    const nodeTypes = { basic: BasicEditorNode };

    // Where we are connecting to
    const connectingNodeId = useRef(null);
    const { project } = useReactFlow();

    // Get theme token
    const { token } = useToken();


    // For automapping elements
    const getLayoutedElements = (nodes, edges, direction = "TB") => {
        const isHorizontal = direction === "LR";

        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));
        dagreGraph.setGraph({ rankdir: "TB" });

        nodes.forEach((n) => {

            let nodeWidth = nodeWidthDefault;

            // determine width based on text
            if(n.data !== undefined && n.data.description !== undefined && n.data.null)
            {
                nodeWidth = nodeWidthDefault;
            }

            console.log(n);
            dagreGraph.setNode(n.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((e) => {
            dagreGraph.setEdge(e.source, e.target);
        });

        dagre.layout(dagreGraph);

        nodes.map((el) => {
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = isHorizontal ? 'left' : 'top';
            el.sourcePosition = isHorizontal ? 'right' : 'bottom';

            let nodeWidth = nodeWidthDefault;
            // determine width based on text
            if(el.data !== undefined && el.data.description !== undefined && el.data.description !== null)
            {
                nodeWidth = nodeWidthDefault;
            }

            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. Moreover we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            el.position = {
                x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        });

    };

    const EdgesFromTasks = (Tasks,Nodes) => {

        const redges = [];

        console.dir(Nodes);
        console.dir(Tasks);

        if(Tasks != null)
        {
            Tasks.forEach((task) => {

                // create connectors

                // find the node for this task
                const Node = Nodes.find(x => x.data.id === task.id);
                console.dir(Node);

                //const AddPathTo0 = false;

                if (task.depends !== null && typeof task.depends !== "undefined" && task.depends.length > 0) {
                    // undefinedAray is not empty

                    task.depends.forEach((dep) => {

                        console.log("Finding dependant node " + dep);

                        // find sourcenode
                        const SourceNode = Nodes.find(x => x.data.id == dep);

                        console.dir(task);
                        console.dir(SourceNode);

                        const ElPath = {
                            id: dep + "to" + task.id,
                            source: SourceNode.id,
                            target: Node.id
                        };

                        if(task.dependsDelayMin!=null)
                        {
                            if(task.dependsDelayMin!==0) ElPath.label = task.dependsDelayMin + " min";
                        }

                        redges.push(ElPath);

                    });

                } else {
                    redges.push({
                        id: "0to" + task.id,
                        source: 'start',
                        target: Node.id
                    });
                }
            });

        }

        return redges;
    }

    const NodesFromTasks = (Tasks) => {

        const rnodes = [
            {
                id: 'start',
                type: "basic",
                data: {
                    label: 'Playbook Start',
                    isStartElement: true,
                    onAddDependantClick: onAddItem
                },
                position: {x: 250, y: 0}
            }
        ];

        if(Tasks != null)
        {

            let newLastID = 0;

            // push the elements themselves
            Tasks.forEach((task) => {


                const PlaybookItemType = PlaybookItemTypes.find(i => i.TaskType === task.$type);

                if(PlaybookItemType === undefined || PlaybookItemType === null)
                {
                    console.log("Undefined " + task.$type);
                }

                console.log(task.id);

                // The task here finds if this has dependencies underneath
                let hasDepends = false;

                if(Tasks.find(i => i.depends !== null && i.depends.includes(task.id)) !== undefined)
                {
                    hasDepends = true;
                }

                // template new element
                const newElement = {
                    id: '' + task.id,
                    position: {x: 0, y: 0},
                    type: "basic",
                    data: {
                        id: task.id,
                        t: task.$type,
                        hasDepends: hasDepends,
                        Task: task,
                        description: task.description,
                        onEditClick: onEditItem,
                        onDeleteClick: onDeleteItem,
                        onAddDependantClick: onAddItem
                    }
                };

                if(task.id > newLastID)
                {
                    newLastID = task.id;
                }

                rnodes.push(newElement);

            });

            setLastID(newLastID);
        }

        console.dir(rnodes);

        return rnodes;

    }

    useEffect(() => {
        // tell parent to re-build playbook
        console.log("Playbook changed. Iteration " + Iteration);

        if(Playbook != null)
        {

            const n = NodesFromTasks(Playbook.tasks);
            const e = EdgesFromTasks(Playbook.tasks, n);

            setNodes(n);
            setEdges(e);

            // auto move
            getLayoutedElements(n,e);
        }

    },[Iteration]);

    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const onLoad = (rf) => {
        console.dir(rf);
        setReactFlowInstance(rf);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    // Inform parent we are adding dependency
    const onConnect = useCallback((params) => onAddDependency(params.source,params.target));

    return (


            <div style={{height: "80vh"}} ref={reactFlowWrapper}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onLoad={onLoad}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    fitView={true}>
                    <Controls/>
                    <Background
                        variant="dots"
                        gap={15}
                        color={token.colorBorder}
                        size={1}
                    />
                </ReactFlow>
            </div>

    );
};
export default PlaybookEditorMap;