import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Button, Form, Input, Select, Space, Table,
} from "antd";
import Text from "antd/es/typography/Text";
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined} from "@ant-design/icons";

const HashTableInput = ({value, onChange, KeyLabel, ValueLabel}) => {

    const [InternalState, SetInternalState] = useState([]);
    const [Loaded, SetIsLoaded] = useState(false);

    useEffect(() => {



        if((value !== null && value !== undefined) && !Loaded)
        {
            console.log("Value change first load");

            console.dir(value);

            let newVals = [];

            Object.keys(value).forEach((k) => {
                newVals.push({
                    id: Math.floor(Math.random() * 100000000),
                    Key: k,
                    Value: value[k]
                })
            })

            SetInternalState(newVals);
            SetIsLoaded(true);

        }
    }, [value, Loaded])

    useEffect(() => {

        // Construct the value prop
        let ValueProp = {};
        InternalState.map((item) => {
            ValueProp[item.Key] = item.Value;
        });

        onChange(ValueProp);

    }, [InternalState])

    const handleAdd = () => {
        let newValues = InternalState;

        if(newValues == undefined || newValues == null)
        {
            newValues = [];
        }

        let newItem = {
            "id": Math.floor(Math.random() * 100000000),
            "Key":"",
            "Value":""
        }

        SetInternalState(oldArray => [...oldArray, newItem]);
    }

    const handleRowChange = (newRow) => {
        // Remove
        let filteredArray = InternalState.filter(item => item.id !== newRow.id)
        filteredArray.push(newRow);
        SetInternalState(filteredArray);
    }

    const handleRemove = (record) => {
        let filteredArray = InternalState.filter(item => item.id !== record.id)
        SetInternalState(filteredArray);
    }

    const handlePropertyChange = (record,property,value) => {
        record[property] = value;
        handleRowChange(record);

    }

    return (
        <Form>
            <Button onClick={handleAdd}>Add Action</Button>
            <Table
                dataSource={InternalState}
                rowKey="id"
                pagination={false}
                sortDirections="ascend"
                columns={[
                    {
                        title: KeyLabel,
                        key: 'Key',
                        render: (record)  => <Input value={record.Key} onChange={(v,x) => handlePropertyChange(record, "Key", v.target.value)}/>
                    },
                    {
                        title: ValueLabel,
                        key: 'Value',
                        render: (record)  => <Input value={record.Value} onChange={(v,x) => handlePropertyChange(record, "Value", v.target.value)}/>
                    },
                    {
                        title: "",
                        key: 'Opts',
                        render: (record) => {
                            return (
                                <Space>
                                    <DeleteOutlined onClick={() => handleRemove(record)} />
                                </Space>
                            )
                        }
                    }
                ]}
            />
            </Form>
    )
};

export default HashTableInput;