import React, {FC, useContext, useState} from 'react'
import APITProvider from "../../../service/api";
import {AppContext} from "../../../AppProvider";
import {Select, Space, Typography, Image} from "antd";
import {useQuery} from "@tanstack/react-query";

interface OnChangeHandler {
    (e:any): void;
}

interface ProxyPhishletSelectProps {
    value?: string[];
    onChange?: OnChangeHandler;
}

const ProxyPhishletSelect: FC<ProxyPhishletSelectProps> = ({value, onChange}: ProxyPhishletSelectProps) => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    const {
        data: ProxyPhishlets,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["proxyphishlets"],
        async () => await APIT.getProxyPhishlets()
    );

    return <Select
        style={{ width: '100%' }}
        placeholder="Select Phishlet"
        value={value}
        onChange={onChange}
        optionLabelProp="label">

        {ProxyPhishlets && ProxyPhishlets.map((p) => {

            let imgUrl = "https://www.google.com/s2/favicons?domain=" + p.Destination;
            let label = <Space>
                <Image src={imgUrl}/>
                <Typography.Text>{p.Name}</Typography.Text>
            </Space>

            return <Select.Option value={p.id} label={label}>{label}</Select.Option>
        })
        }

    </Select>
}

export default ProxyPhishletSelect;