import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Col, Collapse, Drawer, Form, Input, Modal, Row, Select, Space, Tag} from "antd";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {useForm} from "antd/es/form/Form";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import DrawerAddProps from "../../classes/DrawerAdd/DrawerAddProps";
import {useQuery} from "@tanstack/react-query";
import Text from "antd/es/typography/Text";
import WebsitePayloadFormItem from "./WebsitePayloadFormItem/WebsitePayloadFormItem";
import SitePayload from "../../Models/Websites/SitePayload";
import WebsitePayloadTag from "./WebsitePayloadTag";

const WebsiteAddDrawer = forwardRef<DrawerRef,DrawerAddProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [FormChanged, SetFormChanged] = useState(false);
    const [submittable, setSubmittable] = React.useState(false);
    const [MechType, SetMechType] = useState<string|undefined>(undefined);
    const [WeaponizedPayload, SetWeaponizedPayload] = useState<SitePayload|undefined>(undefined);
    const [NormalPayload, SetNormalPayload] = useState<SitePayload|undefined>(undefined);


    const [Hack, SetHack] = useState<number>(0);
    // Refs
    const [form] = useForm();

    // Queries
    const {
        data: Domains,
        isLoading: isLoading
    } = useQuery(
        {
            queryKey: ["WebsiteDomains"],
            queryFn: async () => await API.getRedirectDomains(true)
        }
    );


    // Funcs
    const showDrawer = () => {
        SetMechType(undefined);
        SetWeaponizedPayload(undefined);
        SetNormalPayload(undefined);
        setSubmittable(false);
        SetOpen(true);
    }

    const setDeliveryMechanism = (t:string) => SetMechType(t)

    const handleSave = async (vals:any) => {
        // Create dto edit object

        let dto = form.getFieldsValue();

        if(dto.RegexPath!==undefined)
        {
            dto.Endpoints = ["regex:" + dto.RegexPath];
        }

        const modal = Modal.success({
            title: 'Saving',
            content: `Website is being created, please hold.`,
        });

        await API.createRedirectUrl(dto);

        modal.destroy();
        handleClose();

        if(onChange !== undefined)
        {
            onChange();
        }

    }

    const parseValueChanges = (changedValues:any, values:any) => {
        SetFormChanged(true);

        if(changedValues.NormalPayload !== undefined)
        {
            SetNormalPayload(changedValues.NormalPayload);
        }

        if(changedValues.WeaponizedPayload !== undefined)
        {
            SetWeaponizedPayload(changedValues.WeaponizedPayload);
        }

        // Validate forms
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true);
            },
            () => {
                setSubmittable(false);
            },
        );
    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    return (
        <Drawer
            title="Create new website"
            maskClosable={false}
            onClose={handleClose}
            destroyOnClose={true}
            width={800}
            open={Open}>

            <Form
                onValuesChange={parseValueChanges}
                form={form}
                layout="vertical"
                onFinish={handleSave}>

                <Form.Item name="Domain" label="Domain">
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Select a base domain">
                        {
                            Domains && Domains.map((d:any) => {

                                const tagName = "Normal";
                                const tagColor = "default";

                                return (
                                    <Select.Option value={d.id} label={d.id}>
                                        <Row gutter={5} align="middle">
                                            <Col>
                                                <Tag color={tagColor}>{tagName}</Tag>
                                            </Col>
                                            <Col>
                                                {d.id}
                                            </Col>
                                        </Row>
                                    </Select.Option>
                                )
                            })
                        }

                    </Select>
                </Form.Item>

                <Form.Item label="Payload Delivery Mechanism" required>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Select a delivery mechanism"
                        onChange={event => setDeliveryMechanism(event)}
                        optionLabelProp="label">

                        <Select.Option value="NormalWeaponized" label="Normal / Weaponized">
                            <Space direction="vertical">
                                <Text strong>Normal/Weaponized</Text>
                                <Text type="secondary">Two payload slots, normal and weaponized. Site can be weaponized via UI, API, or Playbook task.</Text>
                            </Space>
                        </Select.Option>
                        <Select.Option value="Rules" label="Rules Based" disabled={true}>
                            <Space direction="vertical">
                                <Text strong>Rules Based</Text>
                                <Text type="secondary">Rule based delivery, as many payloads as you want. Use a rule to determine which is delivered.</Text>
                            </Space>
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Collapse defaultActiveKey={["NormalPayload","WeaponizedPayload"]} accordion>
                {MechType === "NormalWeaponized" &&
                    <>

                    <Collapse.Panel key="NormalPayload" header="Normal Payload" extra={NormalPayload !== undefined ? <WebsitePayloadTag Payload={NormalPayload} /> : undefined}>
                        <Form.Item name="NormalPayload" required>
                            <WebsitePayloadFormItem />
                        </Form.Item>
                    </Collapse.Panel>

                    <Collapse.Panel key="WeaponizedPayload" header="Weaponized Payload" extra={WeaponizedPayload !== undefined ? <WebsitePayloadTag Payload={WeaponizedPayload} Weaponized={true}/> : undefined}>
                        <Form.Item name="WeaponizedPayload" required>
                            <WebsitePayloadFormItem />
                        </Form.Item>
                    </Collapse.Panel>
                    </>
                }

                    <Collapse.Panel key="AdvancedOptions" header="Advanced Options">
                        <Form.Item
                            name="RegexPath"
                            label="Regex Path Match"
                            hasFeedback
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
console.log("Evaluating");
                                        if(value==="")
                                        {
                                            console.log("Value empty");
                                            return Promise.resolve();
                                        }

                                        let isRegex = true;
                                        let blankMatch = false;

                                        try {
                                            let pattern = new RegExp(value);
                                            if(pattern.test(""))
                                            {
                                                blankMatch = true;
                                            }
                                        } catch(e) {
                                            isRegex = false;
                                        }

                                        if(blankMatch === true)
                                        {
                                            console.log("Matches blank string");
                                            return Promise.reject(new Error('Regex pattern matches a blank string.'));
                                        }

                                        if(isRegex === true)
                                        {
                                            return Promise.resolve();
                                        } else {
                                            console.log("Not regex pattern");
                                            return Promise.reject(new Error('Must be a valid regex pattern'));
                                        }
                                    },
                                }),
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Collapse.Panel>
                </Collapse>

                <Button type="primary" htmlType="submit" style={{marginTop:10}}>
                    Submit
                </Button>
            </Form>

        </Drawer>

    )

});

export default WebsiteAddDrawer;