import React, {useContext, useRef, useState} from 'react'
import PageHeader from "../misc/PageHeader";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";
import {useNavigate} from "react-router-dom";
import {Button, Col, Form, Input, message, Row, Select, Timeline} from "antd";
import FileReferenceSelector from "../files/FileReferenceSelector";
import {useForm} from "antd/es/form/Form";
import AttackRunModalNew from "../attack/AttackRunModalNew";
import {useQuery} from "@tanstack/react-query";
import {UserOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import FileGenerationSelector from "../files/FileGenerationSelector";
import EmailActionInput from "../PlaybookInputs/EmailActions/EmailActionInput.react";
import DefenderProductTypes from "../defender/DefenderProductTypes";
import ExpectedAlertsInput from "../PlaybookInputs/ExpectedAlerts/ExpectedAlertsInput.react";
import EmailTemplateInput from "../PlaybookInputs/EmailTemplate/EmailTemplateInput.react";
import ProxyPhishletSelect from "../FormItems/ProxyPhishletSelect/ProxyPhishletSelect";
import HTMLInput from "../FormItems/HTMLInput/HTMLInput";
import PlaybookTextInput from "../PlaybookInputs/PlaybookTextInput/PlaybookTextInput";
import TaskLogTable from "../tasks/TaskLog/TaskLogTable";

const TestPage = (props) => {

    const [form] = useForm();

    const valuesChange = () => {
        console.dir(form.getFieldsValue())
    }

    /*
                        dependencyVariables.push({
                        id: "${Task[" + dep + "]." + output.id + "}",
                        entryText: "${Task[" + dep + "]." + output.id + "}",
                        Name: "Task " + dep + " " + output.id,
                        Type: output.Type,
                        VarType: output.Type,
                        Description: output.Description,
                        OutputTask: dep
                    })

     */

    const TestVars = [
        {
            id: "${Task[1].VAR1}",
            entryText: "${Task[1].VAR1}",
            Name:"Task 1 URLFULL",
            Type:"String",
            VarType:"String",
            Description:"Some URL full",
            OutputTask: "1"
        },
        {
            id: "${Task[1].VAR2}",
            entryText: "${Task[1].VAR2}",
            Name:"Task 1 VAR 2",
            Type:"String",
            VarType:"String",
            Description:"Some URL full",
            OutputTask: "1"
        },
        {
            id: "${Task[2].VAR1}",
            entryText: "${Task[2].VAR2}",
            Name:"Task 2 URLFULL",
            Type:"String",
            VarType:"String",
            Description:"Some URL full",
            OutputTask: "2"
        },
    ]

    return (

        <TaskLogTable TaskID="14b44b3321a04e558008a2e9b56c07c5" />

    )
}
export default TestPage