// @flow

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import App from "./App.react";
import AppProvider from "./AppProvider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

// React query
const queryClient = new QueryClient();

ReactDOM.render(
    <AppProvider>
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </AppProvider>, document.getElementById("root"));