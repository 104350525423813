import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Col, Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Tag,
    Typography
} from "antd";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import {FileOutlined, PlusOutlined} from "@ant-design/icons";
import FileReferenceSelector from "./FileReferenceSelector";
import FileReferenceTemplateSelector from "./FileReferenceTemplateSelector";
import VariableValueEntries from "../Variables/VariableValueEntryForm/VariableValueEntries";
import PlaybookTextInput from "../PlaybookInputs/PlaybookTextInput/PlaybookTextInput";

const FileGenerationSelector = ({value, onChange, DependencyOutputs, PlaybookVariables}) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateType, SetTemplateType] = useState(undefined);
    const [SelectedTemplateCapability, SetSelectedTemplateCapability] = useState(0);
    const [SelectedCompressionType, SetSelectedCompressionType] = useState(0);
    const [SelectedFileTemplate, SetSelectedFileTemplate] = useState(undefined);

    // React Queries
    const {
        data: FileTemplate,
        isLoading: isLoadingFileTemplate
    } = useQuery({
            queryKey:["filetemplate", SelectedFileTemplate],
            queryFn: async() => await API.getFileTemplate(SelectedFileTemplate),
            enabled: SelectedFileTemplate !== undefined
    }
    );

    const handleCancel = () => {
        setIsModalOpen(false);
        reset();
    };

    const [innerForm] = useForm();
    const [variablesForm] = useForm();

    const reset = () => {
        onChange(undefined);
        SetSelectedFileTemplate(undefined);
        SetSelectedTemplateCapability(0);
        SetSelectedCompressionType(0);
    }

    const onFinish = (values) => {

        let type = undefined;

        if(SelectedTemplateCapability & 1024)
        {
            type = "FileGenerationOptionsExecutable"
        }

        // Create a file generation object
        let fileReference = {
            "$type": type !== undefined ? type : undefined,
            "SourceTemplate": values.File,
            "Filename": values.Filename,
            "cid": values.CID,
            "OS": values.OS !== undefined ? values.OS : undefined,
            "Arch": values.Arch !== undefined ? values.Arch : undefined,
            "Variables": []
        }

        // Get values from variables form if exist
        let VariablesEntry = variablesForm.getFieldsValue();

        fileReference.Variables = [];
        Object.keys(VariablesEntry).forEach((key) => {
            fileReference.Variables.push({
                Name: key,
                Data: VariablesEntry[key]
            })
        })

        if(SelectedCompressionType > 0)
        {
            fileReference.Package = {
                "Format": values.CompressFormat,
                "Password": values.CompressPassword,
                "Filename": values.CompressArchiveName,
            }
        }

        console.dir(fileReference);

        onChange(fileReference);

        setIsModalOpen(false);
    }

    const handleTemplateTypeChange = (val) => {
        console.dir(val);
    }

    const FileReferenceTemplateChange = (fileRef) => {
        if(fileRef == undefined || fileRef == null)
        {
            SetSelectedFileTemplate(undefined);
        } else {
            SetSelectedFileTemplate(fileRef.TemplateID);
        }
    }

    console.dir(SelectedFileTemplate);

    if (onChange !== undefined) {

        let showCompilation = SelectedTemplateCapability & 1024 ? true : false;
        let showCompression = SelectedCompressionType > 0 ? true : false;

        let showInlineCID = true;

        return (
            <>
                <Modal title="File Generator" open={isModalOpen} onOk={innerForm.submit} onCancel={handleCancel}>
                    <Form
                        form={innerForm}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        onFinish={onFinish}>

                        <Form.Item name="BaseType" label="Base File Type">
                            <Select onChange={SetTemplateType}>
                                <Select.Option value="Template">Template</Select.Option>
                                <Select.Option value="Static" disabled={true}>Static File</Select.Option>
                            </Select>
                        </Form.Item>

                        {templateType === "Template" &&
                            <>
                                <Form.Item name="File" label="Template"
                                           rules={[{required: true, message: 'Please select a file'}]}>
                                    <FileReferenceTemplateSelector onTemplateCapabilityChange={(v) => SetSelectedTemplateCapability(v)} onChange={(fileRef) => FileReferenceTemplateChange(fileRef)}/>
                                </Form.Item>

                                <Form.Item name="Filename" label="Filename override">
                                    <PlaybookTextInput PlaybookVariables={PlaybookVariables} DependencyOutputs={DependencyOutputs} />
                                </Form.Item>
                            </>
                        }

                        {showInlineCID === true &&
                            <Form.Item name="CID" label="Inline Content ID" tooltip="Inline content ID's allow inline addition to emails. Mainly used for images">
                                <PlaybookTextInput PlaybookVariables={PlaybookVariables} DependencyOutputs={DependencyOutputs} />
                            </Form.Item>
                        }

                        {showCompilation &&
                            <>
                                <Form.Item name="OS" label="Compilation OS">
                                    <Select>
                                        <Select.Option value="1">Windows</Select.Option>
                                        <Select.Option value="2">Linux</Select.Option>
                                        <Select.Option value="3">Mac</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item name="Arch" label="Compilation Architecture">
                                    <Select>
                                        <Select.Option value="1">X86</Select.Option>
                                        <Select.Option value="2">X86-64</Select.Option>
                                        <Select.Option value="3">ARM</Select.Option>
                                    </Select>
                                </Form.Item>
                            </>
                        }

                        <Form.Item name="CompressFormat" label="Compress">
                            <Select onChange={SetSelectedCompressionType}>
                                <Select.Option value={1}>Zip</Select.Option>
                            </Select>
                        </Form.Item>

                        {showCompression &&
                            <>
                                <Form.Item name="CompressArchiveName" label="Archive Name">
                                    <PlaybookTextInput PlaybookVariables={PlaybookVariables} DependencyOutputs={DependencyOutputs} />
                                </Form.Item>

                                <Form.Item name="CompressPassword" label="Compression Password">
                                    <PlaybookTextInput PlaybookVariables={PlaybookVariables} DependencyOutputs={DependencyOutputs} />
                                </Form.Item>
                            </>
                        }

                    </Form>


                    {FileTemplate !== undefined && FileTemplate.Variables !== undefined && FileTemplate.Variables !== null &&
                        <Form
                            form={variablesForm}
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}>
                            <VariableValueEntries Variables={FileTemplate.Variables} PlaybookVariables={PlaybookVariables} DependencyOutputs={DependencyOutputs}/>
                        </Form>
                    }
                </Modal>

                <>

                    {!value &&
                        <Tag onClick={() => setIsModalOpen(true)} className="site-tag-plus"
                             style={{padding: 10, borderStyle: "dashed", background: "#fff"}}>
                            <PlusOutlined/> Select File
                        </Tag>
                    }

                    {value &&
                        <Tag icon={<FileOutlined/>} style={{padding: 10}} closable={true}
                             onClose={() => reset()}>{value.Filename}</Tag>
                    }

                    <Divider type="vertical"/>

                </>

            </>
        )
    }

};

export default FileGenerationSelector;