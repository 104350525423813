import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Button, Drawer, Form, Input, Modal, Select, Space, Spin, Tabs} from "antd";
import {BulbOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import ContainerSelect from "../../containers/ContainerSelect.react";
import Text from "antd/es/typography/Text";

const AdminEdit = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [AdminID, SetAdminID] = useState(null);

    // Refs
    const [form] = useForm();

    // React queries
    const {
        data: Admin,
        isLoading: isLoading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["admin", AdminID],
            queryFn: async() => await API.getOperator(AdminID),
            enabled: AdminID !== null ? true : false
        }
    );

    const {
        data: RoleGroups,
        isLoading: isLoadingRoleGroups,
    }  = useQuery({
            queryKey: ["rolegroups"],
            queryFn: async() => await API.getRoleGroups(),
        }
    );

    // Funcs
    const showDrawer = (id) => {
        SetAdminID(id);

        refetch().then(() => {
            form.resetFields();
            SetOpen(true);
        });
    }

    const handleSave = async () => {
        // Create dto edit object

        let dtoEditObject = form.getFieldsValue(true);

        const modal = Modal.success({
            title: 'Saving',
            content: `Admin is being saved, please hold.`,
        });

        await API.putAdmin(AdminID, dtoEditObject);

        modal.destroy();
        handleClose();

        props.onChange();
    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
        SetAdminID(null);
    }

    return (
        <Drawer
            {...props}
            title="Edit Admin"
            closable={AdminID}
            width={600}
            closeable={true}
            onClose={handleClose}
            extra={
                <Space>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={handleSave}>Save</Button>
                </Space>
            }
            open={Open}>

            {!isLoading &&
                <Form
                    initialValues={Admin}
                    form={form}
                    labelAlign="left"
                    labelWrap={true}
                    layout="vertical">

                    <Tabs defaultActiveKey="detail">
                        <Tabs.TabPane
                            tab={
                                <span><BulbOutlined/>Details</span>
                            }
                            key="taskitemprop">

                            <Form.Item name="id" label="Email">
                                <Input disabled={true}/>
                            </Form.Item>

                            <Form.Item name="firstname" label="Firstname">
                                <Input disabled={true}/>
                            </Form.Item>

                            <Form.Item name="surname" label="Lastname">
                                <Input disabled={true}/>
                            </Form.Item>

                            <Form.Item name="DefaultContainer" label="Default Container">
                                <ContainerSelect all={true}/>
                            </Form.Item>

                            <Form.Item name="RoleGroups" label="Role Groups" tooltip="Role Groups for users">
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select Role Groups">

                                    {RoleGroups && RoleGroups.map((rg) =>
                                        <Select.Option value={rg.id}>
                                            <Space direction="horizontal">
                                                <Text>{rg.id}</Text>
                                                <Text type="secondary">{rg.description}</Text>
                                            </Space>
                                        </Select.Option>
                                    )}

                                </Select>

                            </Form.Item>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            }
        </Drawer>

    )

});

export default AdminEdit;