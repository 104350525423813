import {
    Button,
    Card,
    Col,
    Dropdown,
    Menu,
    Modal,
    Progress,
    Row,
    Space,
    Spin,
    Statistic,
    Table,
    Tag,
    Typography
} from "antd";
import React, {useContext, useRef} from "react";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined, DownOutlined,
    PlusCircleOutlined,
    UserOutlined,
    WarningOutlined
} from "@ant-design/icons";
import LabEnvironmentUsersTable from "../../lab/user/components/LabEnvironmentUsersTable";
import PageHeader from "../../misc/PageHeader";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import LabEnvironmentUserAddDrawer from "../../lab/user/components/LabEnvironmentUserAddDrawer";
import LabEnvironmentUserEditDrawer from "../../lab/user/components/LabEnvironmentUserEditDrawer";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import LabUserType from "../../../Models/Labs/LabUserType";
import AdminDisplay from "../../admins/AdminDisplay";
import LabUserAttestationText from "../../lab/user/components/LabUserAttestationText";
import dtoLabUserNew from "../../../Models/Labs/dtoLabUserNew";

const HomeMyLabs = () => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refUserAddDrawer = useRef<DrawerRef>(null);
    const refUserEditDrawer = useRef<DrawerRef>(null);

    const {
        data: MyLabUsers,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["MyLabUsers"],
        async () => await APIT.myLabUsersGet()
    );

    const {
        data: LabEnvironments,
        isLoading: isLoadingEnvironments,
        refetch: refetchEnvironments
    } = useQuery(
        ["labenvironments"],
        async () => await APIT.labsGet()
    );

    const handleCreate = async (id:string) => {
        const modal = Modal.info({
            title: 'Creating',
            closable: false,
            okButtonProps: {
                loading: true,
                disabled: true,
            },
            content: `Lab user is being created, please hold.`,
        });

        let dtoObject: dtoLabUserNew = {
            LabID: id,
        }

        await APIT.labUserPost(dtoObject);

        modal.destroy();

        const modalDone = Modal.success({
            title: 'Account created',
            content: `Account has been created. You will receive an email shortly with instructions for attestation, after attestation the password is generated. You must immediately log on to the account to reset the password.`,
        });

        refetch();
        refetchEnvironments();
    }


    return (
        <>
            <PageHeader
                Title="Available Lab Environments"
            />

            <LabEnvironmentUserAddDrawer ref={refUserAddDrawer} onChange={refetch} />
            <LabEnvironmentUserEditDrawer ref={refUserEditDrawer} onChange={refetch} />

            <Table
                dataSource={LabEnvironments}
                rowKey="id"
                pagination={false}
                loading={isLoadingEnvironments}
                columns={[
                    {
                        title: "Name",
                        key: 'Name',
                        width:400,
                        render: (record) => {
                            return <Space>
                                <FontAwesomeIcon icon={faUser} />
                                <Typography.Text>{record.Name}</Typography.Text>
                            </Space>
                        },
                    },
                    {
                        title: "My Usage",
                        key: 'Usage',
                        width:400,
                        render: (record) => {

                            if(MyLabUsers !== undefined)
                            {
                                const usersInLab = MyLabUsers.filter((element) => element.LabID === record.id);

                                var countUsersInLab = 0;

                                if(usersInLab !== undefined)
                                {
                                    countUsersInLab = usersInLab.length;
                                }

                                var pctUsed = (countUsersInLab / record.MaxOwnedAccounts) * 100 ;


                                return <>
                                <Row>
                                    <Typography.Text type={"secondary"}>{countUsersInLab} of {record.MaxOwnedAccounts} accounts</Typography.Text>
                                </Row>
                                <Row>
                                    <Progress percent={pctUsed} showInfo={false}/>
                                </Row>

                                </>
                            }


                            return <Spin/>
                        }
                    },
                    {
                        render: (record) => {

                            return <Button onClick={() => handleCreate(record.id)}>Create User</Button>

                        }
                    }
                ]}
            />
        </>

    )
}

export default HomeMyLabs;