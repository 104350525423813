import React, {useContext, useState} from 'react'
import {useParams} from "react-router-dom";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import {Button, Card, Form, Input, Modal, Select, Tabs} from "antd";
import {EditFilled, SaveOutlined} from "@ant-design/icons";
import Editor from "@monaco-editor/react";
import {useForm} from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import PageHeader from "../../misc/PageHeader";


const SitePage = (props) => {

    let params = useParams();

    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [editor, SetEditor] = useState();
    const [monaco, SetMonaco] = useState();

    // Language for editor
    const [language, SetLanguage] = useState();

    // refs
    const [form] = useForm();

    // React Query
    const {
        data: Template,
        isLoading: isLoading
    } = useQuery(
        ["site", params.id],
        async() => await API.getPage(params.id)
    );

    const handleEditorDidMount = (editor, monaco) => {

        // Define nim language
        monaco.languages.register({ id: "nim "});

        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        console.dir("handleEditorDidMount");
        SetEditor(editor);
        SetMonaco(monaco);
    }

    const Save = async() => {
        const modal = Modal.success({
            title: 'Saving',
            content: `Your page is being saved, please hold.`,
        });

        let Payload = form.getFieldsValue(true);

        await API.putPage(params.id, Payload);

        modal.destroy();
    }

    if(!isLoading)
    {

        return (
            <>
                <PageHeader
                    Title={Template.Description}
                    showBack={true}
                    Buttons={[
                        <Button icon={<SaveOutlined />} onClick={Save} outline>Save</Button>
                    ]}
                />

                <Card loading={isLoading}>

                    {!isLoading &&
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={Template}>

                            <Form.Item name="Name" label="Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="Description" label="Description" rules={[{ required: true }]}>
                                <TextArea />
                            </Form.Item>

                            <Form.Item name="Flags" label="Flags">
                                <Select mode="tags">
                                    <Select.Option value="Benign">Benign</Select.Option>
                                    <Select.Option value="Phish">Phish</Select.Option>
                                    <Select.Option value="Malware">Malware</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name="Tags" label="Tags">
                                <Select mode="tags" />
                            </Form.Item>

                            <Form.Item name="HTML" label="Editor">
                                <Editor
                                    height="60vh"
                                    language="html"
                                    onMount={handleEditorDidMount}/>
                            </Form.Item>
                        </Form>
                    }

                </Card>
            </>
        );
    }
}
export default SitePage