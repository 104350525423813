import React, {useContext, useState} from "react";
import {Button, Dropdown, Menu, Space, Table, Tag} from 'antd';
import Text from "antd/es/typography/Text";
import AdminDisplay from "../admins/AdminDisplay";
import date from "date-and-time";
import {
    ClockCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import AttacksTable from "../attack/AttacksTable";
import PlaybookTextInput from "../PlaybookInputs/PlaybookTextInput/PlaybookTextInput";
import PlaybookTextLabel from "../Playbook/PlaybookTextLabel/PlaybookTextLabel";

const AttackScheduleTable = (props) =>
{

    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [ExpandedAttacks,SetExpandedAttacks] = useState([]);
    const [ExpandedAttacksLoading,SetExpandedAttacksLoading] = useState(false);
    const [ExpandedID, SetExpandedID] = useState([]);

    const minutesToString = (seconds) =>
    {
        let value = seconds;

        const units = {
            "year": 24 * 60 * 365,
            "month": 24 * 60 * 30,
            "week": 24 * 60 * 7,
            "day": 24 * 60,
            "minute": 1
        };

        const result = [];

        for(let name in units) {
            const p = Math.floor(value / units[name]);
            if(p === 1) result.push(" " + p + " " + name);
            if(p >= 2) result.push(" " + p + " " + name + "s");
            value %= units[name]
        }

        return result;

    }

    const expandedRowRender = (record) => {
        return <AttacksTable Attacks={ExpandedAttacks} Loading={ExpandedAttacksLoading} />
    }

    const onExpand = (expanded, record) => {
        const keys = [];
        if (expanded) {
            keys.push(record.id);

            // Load attacks
            // Get the Attacks from API
            SetExpandedAttacksLoading(true);
            SetExpandedAttacks([]);

            API.getAttacksBySchedule(record.id).then((attacks) => {
                SetExpandedAttacksLoading(false);
                SetExpandedAttacks(attacks);
            })

        } else {
            SetExpandedAttacksLoading(false);
            SetExpandedAttacks([]);
        }

        SetExpandedID(keys);

    }

    return (
        <Table
            dataSource={props.Schedules}
            rowKey="id"
            pagination={15}
            loading={props.Loading}
            expandable={{
                expandedRowRender: record => expandedRowRender(record),
                onExpand: (expanded, record) => onExpand(expanded, record),
                expandedRowKeys: ExpandedID,
            }}
            columns={[
                {
                    key: 'avatar',
                    render: record => <AdminDisplay Admin={record.Owner} showAvatar={true}/>,
                },
                {
                    title: "Name",
                    dataIndex: "Name",
                    key: 'name',
                    render: text => <Text>{text}</Text>,
                },
                {
                    title: "Playbook",
                    dataIndex: "Playbook",
                    key: 'playbook',
                    render: text => <PlaybookTextLabel PlaybookId={text}/>,
                },
                {
                    key: 'sch',
                    title: 'Schedule',
                    render: record => {
                        const dt = new Date(record.NextRun);

                        const schTimeString = date.format(dt, "ddd D MMMM, h[:]mm A");

                        return (
                            <Space direction="vertical">
                                <Text>{schTimeString}</Text>
                                <Text type="secondary">Repeats every {minutesToString(record.RepeatEveryMinutes)}</Text>
                            </Space>
                        )
                    },
                },
                {
                    key: 'tag',
                    render: record => {
                        let status = "Unknown";
                        let statusco = "grey";
                        let Ico = <ClockCircleOutlined/>;

                        if(record.Status & 1)
                        {
                            status="Active";
                            statusco = "blue";
                        }

                        if(record.Status & 2)
                        {
                            status="Disabled";
                            statusco="grey";
                            Ico = <CloseCircleOutlined />
                        }

                        return <Tag icon={Ico} color={statusco}>{status}</Tag>
                    },
                },
                {
                    key:'actions',
                    render:(record) => {

                        let enabled = false;

                        if(record.Status === 1)
                        {
                            enabled = true;
                        }

                        return (
                            <Dropdown overlay={

                                <Menu>

                                    { enabled &&
                                        <Menu.Item key="disable" onClick={() => props.onDisable(record.id)}>
                                            <ExclamationCircleOutlined /> Disable
                                        </Menu.Item>
                                    }

                                    { !enabled &&
                                        <Menu.Item key="enable" onClick={() => props.onEnable(record.id)}>
                                            <ExclamationCircleOutlined /> Enable
                                        </Menu.Item>
                                    }

                                    <Menu.Item key="edit" onClick={() => props.onEdit(record.id)}>
                                        <EditOutlined /> Edit
                                    </Menu.Item>

                                    <Menu.Item key="delete" onClick={() => props.onDelete(record.id)}>
                                        <DeleteOutlined /> Delete
                                    </Menu.Item>
                                </Menu>

                            }>
                                <Button>
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        )
                    }
                }
            ]}
        />
    )
}

export default AttackScheduleTable;