// @flow

import React, {Component} from "react";
import FilePayloadAddModal from "./FilePayloadAddModal";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import ApiError from "../ApiError.react";
import {Button, Dropdown, Menu, message, Popconfirm, Table, Tag} from "antd";
import {
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    FileOutlined,
    PlusCircleOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullseye, faVirus} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../misc/PageHeader";

class FilePayloadsPage extends Component {
  constructor(props) {
    super(props);
    this.FilePayloadAddModal = React.createRef();
  }

  state = {
    Files: [],
    FilesLoading: false
  }

  // Global AppContext contains token and operator info
  static contextType = AppContext;

  componentDidMount() {

    this.API = new APIProvider(this.context.token, true);

    this.API.getFiles("id,Name,Description,Flags").then((Files) => {
      this.setState({Files: Files});
    })
    .catch((error) => {

    })

  }

  refreshFiles() {
    this.setState({FilesLoading: true});
    this.API.getFiles("id,Name,Description,Flags").then((Files) => {
      this.setState({Files: Files});
      this.setState({FilesLoading: false});
    })
        .catch((error) => {

        })
  }

  openAddFile(id) {

    if (id != null) {
      this.API.getFile(id).then((file) => {
        this.FilePayloadAddModal.current.Open(file);
      })
          .catch((error) => {
            console.dir(error);
            this.setState({error: error.message})
          })
    } else {
      this.FilePayloadAddModal.current.Open();
    }


  }

  addFile(payload) {
    this.API.addFile(payload).then(() => {
      this.refreshFiles();
    })
    .catch((error) => {
    })
  }

  editFile(id, payload) {
    this.API.putFile(id, payload).then((file) => {
      this.refreshFiles();
    })
        .catch((error) => {
          console.dir(error);
          this.setState({error: error.message})
        })
  }

  deleteFile(id) {
    this.API.deleteFile(id).then(() => {
      this.refreshFiles();
    })
        .catch((error) => {
          console.dir(error);
          this.setState({error: error.message})
        })
  }

  copyFileId(id)
  {
    navigator.clipboard.writeText(id).then(() => {
      message.success("Copied Task ID to clipboard");
    });
  }

  render() {
  return (
    <>
      <FilePayloadAddModal ref={this.FilePayloadAddModal} onFileAdd={(payload) => this.addFile(payload)} onFileEdit={(id,payload) => this.editFile(id,payload)}/>

      <PageHeader
          Title="File Payloads"
          Buttons={[
            <Button icon={<PlusCircleOutlined />} onClick={() => this.openAddFile()} outline type="primary">Add</Button>
          ]}
      />

      <ApiError error={this.state.error}/>

      <Table
          pagination={{ pageSize: 15 }}
          dataSource={this.state.Files}
          rowKey="id"
          loading={this.state.FilesLoading}
          columns={[
            {
              key: 'name',
              title: 'Name',
              dataIndex:'name',
              render:(text) => <Text>{text}</Text>
            },
            {
              key: 'description',
              title: 'Description',
              dataIndex:'Description',
              render:(text) => <Text type="secondary">{text}</Text>
            },
            {
              key: 'description',
              title: 'Description',
              render:(record) =>
              {
                  const flags = [];

                  if(record.Flags.includes("Normal"))
                {
                  flags.push("Normal");
                }

                if(record.Flags.includes("ZeroDay"))
                {
                  flags.push("Zero Day");
                }

                if(record.Flags.includes("Phish"))
                {
                  flags.push("Phishing");
                }

                if(record.Flags.includes("Malware"))
                {
                  flags.push("Malware");
                }

                if(record.Flags.includes("SignatureMalware"))
                {
                  flags.push("Signature Malware");
                }

                if(record.Flags.includes("Script"))
                {
                  flags.push("Script");
                }

                if(record.Flags.includes("PowerShell"))
                {
                  flags.push("PowerShell");
                }

                if(record.Flags.includes("PatchRequired"))
                {
                  flags.push("Requires Patching");
                }

                if(record.Flags.includes("Simulation"))
                {
                  flags.push("Simulation");
                }

                if(record.Flags.includes("PUA"))
                {
                  flags.push("PUA");
                }

                if(record.Flags.includes("SharePointUpload"))
                {
                  flags.push("SharePointUpload");
                }

                return (
                    <>
                      {record.Flags && flags.map((f, i) => {

                        if(f==="Malware")
                        {
                          return <Tag color="red" icon={<FontAwesomeIcon icon={faVirus}/>}>Malware</Tag>
                        }

                        if(f==="Phishing")
                        {
                          return <Tag color="red" icon={<FontAwesomeIcon icon={faBullseye}/>}>Phishing</Tag>
                        }

                        if(f==="SharePointUpload")
                        {
                          return <Tag icon={<FileOutlined icon={faBullseye}/>}>SharePoint File</Tag>
                        }

                        return (
                            <Tag>{f}</Tag>
                        );
                      })}
                    </>
                )
              }
            },
            {
              key:'actions',
              render:(record) => {
                return (
                    <Dropdown overlay={

                      <Menu>
                        <Menu.Item key="edit" onClick={() => this.openAddFile(record.id)}>
                          <ExclamationCircleOutlined/> Edit
                        </Menu.Item>

                        <Menu.Item key="copytaskid" onClick={() => this.copyFileId(record.id)} icon={<CopyOutlined />}>
                          Copy File ID
                        </Menu.Item>

                        <Menu.Item key="delete">
                          <Popconfirm
                              title="Are you sure to delete this file? This is unrecoverable and may break any playbooks using this file."
                              onConfirm={() => this.deleteFile(record.id)}
                              okText="Yes"
                              cancelText="No">
                            <DeleteOutlined/> Delete
                          </Popconfirm>
                        </Menu.Item>

                      </Menu>

                    }>
                      <Button>
                        <DownOutlined/>
                      </Button>
                    </Dropdown>
                )
              }
            }
          ]}
      />
    </>
  );
  }
}

export default FilePayloadsPage;
