import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Button, Form, Input, Select, Space, Table,
} from "antd";
import Text from "antd/es/typography/Text";
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined} from "@ant-design/icons";
import PlaybookTextInput from "../PlaybookTextInput/PlaybookTextInput";
import TaskItemInput from "../TaskItemInput";

const WebSessionAction = ({value, onChange, VariablesPlaybook, VariablesDependencies }) => {

    const [Actions, SetActions] = useState([]);
    const [Loaded, SetIsLoaded] = useState(false);

    useEffect(() => {

        console.log("Value change");

        if((value !== null || value !== undefined) && !Loaded)
        {
            // Assign each value an id
            if(Array.isArray(value))
            {
                value.forEach((p) => {

                    // Change 0 Order to 1
                    if(p.Order===0)
                    {
                        p.Order=1;
                    }

                    p.id = Math.floor(Math.random() * 100000000)
                })

                SetActions(value);
                SetIsLoaded(true);
            }

        }
    }, [value, Loaded])

    useEffect(() => {
        onChange(Actions);
    }, [Actions])

    const handleAdd = () => {
        let newValues = Actions;

        if(newValues == undefined || newValues == null)
        {
            newValues = [];
        }

        // new object should be +1 highest Order
        let highestOrder = 0;

        // Sort by Order order
        newValues = newValues.sort((a, b) => a.Order - b.Order);

        // Get the last Order order
        if(newValues.length > 0)
        {
            highestOrder = newValues[newValues.length-1].Order;
        }

        let newAction = {
            "id": Math.floor(Math.random() * 100000000),
            "Order":highestOrder+1,
            "$type":"simEventWebControlActionClick"
        }

        SetActions(oldArray => [...oldArray, newAction]);
    }

    const handleTypeChange = (record,v) => {
        console.log("Changing type " + record.id + " to " + v);
        // $type must be first, so we need to reconstruct the record
        let newRecord = {
            "$type": v
        }

        Object.keys(record).forEach(prop => {
            if(prop!=="$type")
            {
                newRecord[prop] = record[prop];
            }
        });

        console.dir(newRecord);

        handleRowChange(newRecord);
    }

    const handleRowChange = (newRow) => {
        // Remove
        let filteredArray = Actions.filter(item => item.id !== newRow.id)
        filteredArray.push(newRow);
        SetActions(filteredArray);
    }

    const handleProgressChange = (direction,record) => {

        console.dir(record);

        let oldOrder = record.Order;
        let newOrder = record.Order;
        let newActions = Actions.sort((a, b) => a.Order - b.Order);;

        // Remove existing
        newActions = newActions.filter(item => item.id !== record.id)

        if(direction==="up")
        {
            if(newOrder === 1)
            {
                return false;
            }

            newOrder = oldOrder -1;
        }

        if(direction==="down")
        {
            let highestOrder = Actions[Actions.length-1].Order;

            if(record.Order >= highestOrder)
            {
                // Already highest Order
                return false;
            }

            newOrder = oldOrder + 1;
        }

        // Find anything at this Order already
        let currentOrderItem = Actions.find(item => item.Order === newOrder);

        if(currentOrderItem !== undefined)
        {

            // switch the priorities around
            newActions = newActions.filter(item => item.id !== currentOrderItem.id)
            currentOrderItem.Order = oldOrder;
            newActions.push(currentOrderItem);
        }

        // Set Order
        record.Order = newOrder;

        newActions.push(record);

        // Set
        SetActions(newActions);

    }

    const handleRemove = (record) => {
        let filteredArray = Actions.filter(item => item.id !== record.id)
        SetActions(filteredArray);
    }

    const handlePropertyChange = (record,property,value) => {
        console.log("Change " + record["$type"] + " property " + property + " to " + value);

        record[property] = value;
        handleRowChange(record);

    }

    const ActionTypeOptions = [
        {
            label: "Button Click",
            value: "simEventWebControlActionClick"
        },
        {
            label: "Data Entry",
            value: "simEventWebControlActionDataEntry"
        },
        {
            label: "Set Cookie",
            value: "simEventWebControlActionSetCookie"
        },
        {
            label: "Refresh Page",
            value: "simEventWebControlActionRefresh"
        }
    ]

    return (
        <>
            <Button onClick={handleAdd}>Add Action</Button>
            <Table
                dataSource={Actions}
                rowKey="id"
                pagination={false}
                sortDirections="ascend"
                columns={[
                    {
                        title: "Order",
                        key: 'Order',
                        width:5,
                        dataIndex: 'Order',
                        sorter: (a, b) => a.Order - b.Order,
                        sortDirections: "ascend",
                        defaultSortOrder: "ascend",
                        render: text => text
                    },
                    {
                        title: "Action",
                        width:200,
                        key: 'Action',
                        render: (record) => {
                            return <Select value={record["$type"]} options={ActionTypeOptions} onChange={(v) => handleTypeChange(record,v)}/>
                        }
                    },
                    {
                        title: "",
                        key: 'EditProps',
                        //sorter: (a, b) => a.Order - b.Order,
                        //defaultSortOrder: "ascend",
                        render: (record) => {


                            if(record["$type"] === "simEventWebControlActionClick")
                            {
                                return <Input value={record.ElementID} onChange={(v) => handlePropertyChange(record,"ElementID", v.target.value)}/>
                            }

                            if(record["$type"] === "simEventWebControlActionDataEntry")
                            {
                                return <>
                                    <Input value={record.ElementID} onChange={(v) => handlePropertyChange(record,"ElementID", v.target.value)}/>
                                    <PlaybookTextInput
                                        PlaybookVariables={VariablesPlaybook}
                                        DependencyOutputs={VariablesDependencies}
                                        showContextVariables={true}
                                        value={record.Data} onChange={(v) => handlePropertyChange(record,"Data", v)}/>
                                    </>
                            }

                            if(record["$type"] === "simEventWebControlActionSetCookie")
                            {
                                return <>
                                    <Input value={record.Name} onChange={(v) => handlePropertyChange(record,"Name", v)}/>
                                    <PlaybookTextInput
                                        PlaybookVariables={VariablesPlaybook}
                                        DependencyOutputs={VariablesDependencies}
                                        value={record.Value} onChange={(v) => handlePropertyChange(record,"Value", v)}/>
                                    <PlaybookTextInput
                                        PlaybookVariables={VariablesPlaybook}
                                        DependencyOutputs={VariablesDependencies}
                                        value={record.Domain} onChange={(v) => handlePropertyChange(record,"Domain", v)}/>
                                    <PlaybookTextInput
                                        PlaybookVariables={VariablesPlaybook}
                                        DependencyOutputs={VariablesDependencies}
                                        value={record.Path} onChange={(v) => handlePropertyChange(record,"Path", v)}/>
                                </>
                            }
                        }
                    },
                    {
                        title: "",
                        key: 'Opts',
                        render: (record) => {
                            return (
                                <Space>
                                    <CaretUpOutlined onClick={() => handleProgressChange("up", record)} />
                                    <CaretDownOutlined onClick={() => handleProgressChange("down", record)} />
                                    <DeleteOutlined onClick={() => handleRemove(record)} />
                                </Space>
                            )
                        }
                    }
                ]}
            />
        </>
    )
};

export default WebSessionAction;