import React, {useEffect, useState} from "react";


import {Select} from 'antd';
import {Editor} from "@tinymce/tinymce-react";

const { Option } = Select;

const EmailEditorWYSIWYG = (props) =>
{
    const [Domain, setDomain] = useState(null);
    const [Content, setContent] = useState();
    const [InitLoaded, setInitLoad] = useState(false);
    const [editorKey, setEditorKey] = useState();
    const [Playbook, setPlaybook] = useState();
    const [menuPlaybook, setMenuPlaybook] = useState();

    const [menu, setMenu] = useState({
        custom: {
            title: 'Insert Variable',
            items: 'insertPayloadURL insertSenderFullname insertSenderFirstname insertSenderLastname insertSenderRole insertSenderPhone insertSenderEmail insertSenderCompany insertRecipientName insertRecipientEmail insertRecipientFirstName insertRecipientLastname insertRecipientRole insertRecipientDepartment insertRecipientPhone insertRecipientCompany insertRecipientCity insertRecipientCountry insertYear insertMonth insertTomorrow insertRanLocation insertRanDate insertRanDigit'
        },
        menuPlaybook: {
            title: "Playbook Variables",
            items: ""
        },
        menuCommandVariable: {
            title: 'Insert Command Variable', items: 'cvDateTime cvObfuscate'
        },
    })

    const handleEditorChange = (e) =>
    {
        props.onChange(e);
    }

    // Rebuild playbook menu
    useEffect(() => {

        console.log("Rebuilding playbook menu options");

        if (props.Playbook !== null && props.Playbook !== undefined)
        {

            if(props.Playbook.variables !== null && props.Playbook.variables !== undefined)
            {
                let newMenu = menu;

                // clear existing menu
                newMenu.menuPlaybook.items = " ";

                props.Playbook.variables.map((v) => {

                    let rawName = v.id.replaceAll("%","");

                    newMenu.menuPlaybook.items += " " + rawName;
                });

                setMenuPlaybook(newMenu);
                setEditorKey(props.Playbook.id);

            }

        }

    },[props.Playbook]);

    useEffect(() => {
        console.log("WYSIWYG Content change " + props.Content);
        setContent(props.Content);
    }, [props.Content])

    return (
        <Editor
            apiKey='k6vqmiu4bm8wacz9mtdbmbk6ewtqebcmas17ymwhpggdezj8'
            value={props.Content}
            key={editorKey}
            init={{
                height: 500,
                menu: menu,
                plugins: [
                    'advlist autolink lists link image',
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount'
                ],
                menubar: ' custom menuPlaybookVariable menuCommandVariable menuPlaybook',
                setup: function(editor) {

                    // Command Variable
                    editor.ui.registry.addMenuItem('cvDateTime', {
                        text: 'Create Date Time',
                        onAction: function() {
                            editor.insertContent('%%url%%');
                        }
                    });

                    editor.ui.registry.addMenuItem('cvObfuscate', {
                        text: 'Obfuscate Text',
                        onAction: function() {
                            editor.insertContent('%%url%%');
                        }
                    });

                    // Url
                    editor.ui.registry.addMenuItem('insertPayloadURL', {
                        text: 'Payload URL',
                        onAction: function() {
                            editor.insertContent('%%url%%');
                        }
                    });

                    // Senders full name
                    editor.ui.registry.addMenuItem('insertSenderFullname', {
                        text: 'Sender Fullname',
                        onAction: function() {
                            editor.insertContent('%%myname%%');
                        }
                    });

                    // Senders firstname
                    editor.ui.registry.addMenuItem('insertSenderFirstname', {
                        text: 'Sender Firstname',
                        onAction: function() {
                            editor.insertContent('%%fname%%');
                        }
                    });

                    // Senders lastname
                    editor.ui.registry.addMenuItem('insertSenderLastname', {
                        text: 'Sender Lastname',
                        onAction: function() {
                            editor.insertContent('%%lname%%');
                        }
                    });

                    // Senders role
                    editor.ui.registry.addMenuItem('insertSenderRole', {
                        text: 'Sender Role',
                        onAction: function() {
                            editor.insertContent('%%role%%');
                        }
                    });

                    // Senders phone
                    editor.ui.registry.addMenuItem('insertSenderPhone', {
                        text: 'Sender Phone Number',
                        onAction: function() {
                            editor.insertContent('%%phone%%');
                        }
                    });

                    // Senders email
                    editor.ui.registry.addMenuItem('insertSenderEmail', {
                        text: 'Sender Email',
                        onAction: function() {
                            editor.insertContent('%%email%%');
                        }
                    });

                    // Senders company
                    editor.ui.registry.addMenuItem('insertSenderCompany', {
                        text: 'Sender Company',
                        onAction: function() {
                            editor.insertContent('%%mycompany%%');
                        }
                    });

                    // Recipients name
                    editor.ui.registry.addMenuItem('insertRecipientName', {
                        text: 'Recipient Name',
                        onAction: function() {
                            editor.insertContent('%%rcpname%%');
                        }
                    });
                    // Recipients email
                    editor.ui.registry.addMenuItem('insertRecipientEmail', {
                        text: 'Recipient Email',
                        onAction: function() {
                            editor.insertContent('%%rcpemail%%');
                        }
                    });

                    // Recipients firstname name
                    editor.ui.registry.addMenuItem('insertRecipientFirstName', {
                        text: 'Recipient Firstname',
                        onAction: function() {
                            editor.insertContent('%%rcpfname%%');
                        }
                    });
                    // Recipients lastname
                    editor.ui.registry.addMenuItem('insertRecipientLastname', {
                        text: 'Recipient Lastname',
                        onAction: function() {
                            editor.insertContent('%%rcplname%%');
                        }
                    });
                    // Recipients role
                    editor.ui.registry.addMenuItem('insertRecipientRole', {
                        text: 'Recipient Role',
                        onAction: function() {
                            editor.insertContent('%%rcprole%%');
                        }
                    });
                    // Recipients department
                    editor.ui.registry.addMenuItem('insertRecipientDepartment', {
                        text: 'Recipient Department',
                        onAction: function() {
                            editor.insertContent('%%rcpdepartment%%');
                        }
                    });
                    // Recipients Phone
                    editor.ui.registry.addMenuItem('insertRecipientPhone', {
                        text: 'Recipient Phone',
                        onAction: function() {
                            editor.insertContent('%%rcpphone%%');
                        }
                    });
                    // Recipients Company
                    editor.ui.registry.addMenuItem('insertRecipientCompany', {
                        text: 'Recipient Company',
                        onAction: function() {
                            editor.insertContent('%%rcpcompany%%');
                        }
                    });
                    // Recipients City
                    editor.ui.registry.addMenuItem('insertRecipientCity', {
                        text: 'Recipient City',
                        onAction: function() {
                            editor.insertContent('%%rcpcity%%');
                        }
                    });
                    // Recipients Country
                    editor.ui.registry.addMenuItem('insertRecipientCountry', {
                        text: 'Recipient Country',
                        onAction: function() {
                            editor.insertContent('%%rcpcountry%%');
                        }
                    });

                    // Random year
                    editor.ui.registry.addMenuItem('insertYear', {
                        text: 'Year',
                        onAction: function() {
                            editor.insertContent('%%year%%');
                        }
                    });
                    // Random year
                    editor.ui.registry.addMenuItem('insertMonth', {
                        text: 'Month',
                        onAction: function() {
                            editor.insertContent('%%month%%');
                        }
                    });
                    // Random year
                    editor.ui.registry.addMenuItem('insertTomorrow', {
                        text: 'Tomorrow',
                        onAction: function() {
                            editor.insertContent('%%tomorrow%%');
                        }
                    });
                    // Random location
                    editor.ui.registry.addMenuItem('insertRanLocation', {
                        text: 'Random Location',
                        onAction: function() {
                            editor.insertContent('%%ranloc%%');
                        }
                    });
                    // Random Date
                    editor.ui.registry.addMenuItem('insertRanDate', {
                        text: 'Random Date',
                        onAction: function() {
                            editor.insertContent('%%randate%%');
                        }
                    });
                    // Random Digit
                    editor.ui.registry.addMenuItem('insertRanDigit', {
                        text: 'Random Digit',
                        onAction: function() {
                            editor.insertContent('%%randigit%%');
                        }
                    });

                    // Test
                    editor.ui.registry.addMenuItem('test123', {
                        text: 'Test123',
                        onAction: function() {
                            editor.insertContent('%%randigit%%');
                        }
                    });

                    if(props.Playbook != null && props.Playbook !== undefined)
                    {
                        if (props.Playbook !== null && props.Playbook !== undefined && props.Playbook.variables !== undefined) {
                            props.Playbook.variables.map((v) => {

                                console.log("Rebuilding playbook menu main");

                                if(v.varType === "String")
                                {
                                    var rawName = v.id.replaceAll("%", "").toUpperCase().trim();
                                    var rawNameUpper = v.id.replaceAll("%", "").toUpperCase().trim();

                                    editor.ui.registry.addMenuItem(rawName, {
                                        text: 'Var: ' + rawNameUpper,
                                        onAction: function() {
                                            editor.insertContent('${Attack.Var.' + rawNameUpper + "}");
                                        }
                                    });

                                }

                                if (v.varType === "User" || v.varType === "Identity") {
                                    var rawName = v.id.replaceAll("%", "").toUpperCase().trim();

                                    editor.ui.registry.addNestedMenuItem(rawName, {
                                        text: rawName,
                                        getSubmenuItems: function () {

                                            const rawName = v.id.replaceAll("%", "").toLowerCase().trim();
                                            const rawNameUpper = v.id.replaceAll("%", "").toUpperCase().trim();
                                            const upperName = v.id.toUpperCase();

                                            return [
                                                {
                                                    type: 'menuitem',
                                                    text: "Given Name",
                                                    onAction: function () {
                                                        editor.insertContent('${Attack.Entity.' + rawNameUpper + ".GIVENNAME}");
                                                    }
                                                },
                                                {
                                                    type: 'menuitem',
                                                    text: "Surname",
                                                    onAction: function () {
                                                        editor.insertContent('${Attack.Entity.' + rawNameUpper + ".SURNAME}");
                                                    }
                                                },
                                                {
                                                    type: 'menuitem',
                                                    text: "Phone Number",
                                                    onAction: function () {
                                                        editor.insertContent('${Attack.Entity.' + rawNameUpper + ".PHONE}");
                                                    }
                                                },
                                                {
                                                    type: 'menuitem',
                                                    text: "Country Code",
                                                    onAction: function () {
                                                        editor.insertContent('${Attack.Entity.' + rawNameUpper + ".COUNTRYCODE}");
                                                    }
                                                },
                                                {
                                                    type: 'menuitem',
                                                    text: "Email",
                                                    onAction: function () {
                                                        editor.insertContent('${Attack.Entity.' + rawNameUpper + ".EMAIL}");
                                                    }
                                                }
                                            ];
                                        }
                                    });

                                }

                            });

                        }
                    }
                },
                toolbar:
                    'undo redo | code | formatselect | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent | help | image'
            }}
            onEditorChange={(e) => handleEditorChange(e)}
        />
    )
}

export default EmailEditorWYSIWYG;