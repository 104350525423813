import {Tag} from "antd";
import LabUser from "../../../../Models/Labs/LabUser";
import LabUserState from "../../../../Models/Labs/LabUserState";
import {CheckCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import React from "react";

interface LabUserStateTagProps {
    LabUser: LabUser
}

const LabUserStateTag = (props:LabUserStateTagProps) => {
    if(props.LabUser.State === LabUserState.New || props.LabUser.State == LabUserState.Disabled)
    {
        return <Tag>Disabled (Attestation Required)</Tag>
    }

    if(props.LabUser.State === LabUserState.Warn)
    {
        return <Tag icon={<InfoCircleOutlined/>} color="warning">Warn (Attest Needed)</Tag>
    }

    if(props.LabUser.State === LabUserState.Active)
    {
        return <Tag icon={<CheckCircleOutlined/>} color="success">Active</Tag>
    }

    return <Tag>Unknown</Tag>
}

export default LabUserStateTag;