import React, {useState} from "react";


import {Col, Form, Row, Select, Tag} from 'antd';

const { Option } = Select;

interface WebsiteCodeSelectProps {
    value?: any,
    onChange?: any
}

const WebsiteCodeSelect = ({value,onChange}:WebsiteCodeSelectProps) =>
{

    // return on change properties

    let Codes = [
        {
            "Code": 200,
            "Name": "OK"
        },
        {
            "Code": 204,
            "Name": "No Content"
        },
        {
            "Code": 400,
            "Name": "Bad Request"
        },
        {
            "Code": 401,
            "Name": "Unauthorized"
        },
        {
            "Code": 403,
            "Name": "Forbid"
        },
        {
            "Code": 404,
            "Name": "Not Found"
        },
        {
            "Code": 409,
            "Name": "Conflict"
        },
        {
            "Code": 418,
            "Name": "I'm a teapot"
        }
    ]

    return (

        <Select
            style={{ width: '100%' }}
            onChange={onChange}
            value={value}
            optionLabelProp="label">
            {
                Codes.map((c, i) => {

                    return (
                        <Option value={c.Code} label={

                            <Row gutter={5} align="middle">
                                <Col>
                                    <Tag>{c.Code}</Tag>
                                </Col>
                                <Col>
                                    {c.Name}
                                </Col>
                            </Row>

                        }>
                            <Row gutter={5} align="middle">
                                <Col>
                                    <Tag>{c.Code}</Tag>
                                </Col>
                                <Col>
                                    {c.Name}
                                </Col>
                            </Row>
                        </Option>
                    )
                })
            }

        </Select>

    )
}

export default WebsiteCodeSelect;