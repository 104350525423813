import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Alert,
    Badge,
    Descriptions,
    Modal,
    Space,
    Spin,
    Table,
    Tabs,
    Typography,
    Tag
} from "antd";
import {
    AimOutlined, AlertOutlined,
    ApartmentOutlined,
    BarsOutlined,
    BulbOutlined, CheckOutlined, CloseOutlined, CodepenOutlined, FileOutlined, ForkOutlined,
    InfoCircleOutlined, LaptopOutlined, LinkOutlined, MailOutlined, SafetyCertificateOutlined, UserOutlined
} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import DateTimeView from "../../misc/DateTimeView";
import TaskList from "../../tasks/TaskList";
import TaskModal from "../../tasks/TaskModal";
import AttackMap from "../AttackMap";
import AdminDisplay from "../../admins/AdminDisplay";
import ContainerDisplay from "../../containers/ContainerDisplay";
import DefenderProductTypes from "../../defender/DefenderProductTypes";
import DefenderMitreTypes from "../../defender/MitreTypes";
import AttackIncidentTable from "../AttackIncident/AttackIncidentTable";
import AttackEmailsTable from "../AttackEmailsTable";
import APITProvider from "../../../service/api";
import DefenderIncidentMap from "../../../Models/DefenderMapping/DefenderIncidentMap";
import {PresetStatusColorType} from "antd/es/_util/colors";
import Emitter from "../../../Emitter/Emitter";
import AttackFilesTable from "./AttackFilesTab/AttackFilesTab";
import AttackTestsTab from "./AttackTestsTab/AttackTestsTab";
import {faVial} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlaybookTextLabel from "../../Playbook/PlaybookTextLabel/PlaybookTextLabel";

const { Text, Title } = Typography;

interface AttackDetailModalRef {
    showModal: any
}


const AttackDetailModal = forwardRef<AttackDetailModalRef>(({}, ref) => {
    useImperativeHandle(ref, () => ({
        showModal: showModal
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [AttackID, _SetAttackID] = useState<string>("");
    const [EmitterConfigured,SetEmitterConfigured] = useState<boolean>(false);

    // Refs
    const refTaskModal = useRef(null);

    // Ref for listener
    const AttackIDStateRef = React.useRef(AttackID);
    const SetAttackID = (data: string) => {
        AttackIDStateRef.current = data;
        _SetAttackID(data);
    }

    // React queries
    const {
        data: Attack,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["attacks", AttackID],
            queryFn: async() => await APIT.getAttack(AttackID),
            enabled: Open
        }
    );

    const {
        data: Tasks,
        isLoading: LoadingTasks,
        refetch: refetchTasks,
    }  = useQuery({
            queryKey: ["attacktasks", AttackID],
            queryFn: async() => await API.getAttackTasks(AttackID),
            enabled: Open
        }
    );

    const {
        data: Emails,
        isLoading: LoadingEmails,
        refetch: refetchEmails,
    }  = useQuery({
            queryKey: ["attackemails", AttackID],
            queryFn: async() => await APIT.getAttackEmails(AttackID),
            enabled: Open
        }
    );

    const {
        data: CorrelatedIncident,
        isLoading: LoadingCorrelatedIncident,
        refetch: refetchCorrelatedIncident,
    }  = useQuery({
            queryKey: ["attackincidents", AttackID],
            queryFn: async() => await APIT.getAttackDefenderIncidents(AttackID),
            enabled: Open
        }
    );

    // Use Effects
    useEffect(() => {
        if(!EmitterConfigured)
        {
            SetEmitterConfigured(true);
            Emitter.on("AttackUpdate", (m:string) => handleRefetch(m));
        }
    }, [EmitterConfigured])

    // Funcs
    const showModal = (id:string) => {
        SetAttackID(id);
        refetch().then(() => {
            SetOpen(true);
        });
    }

    const handleClose = () => {
        SetOpen(false);
        SetAttackID("");
    }

    const handleTaskOpen = (node: any) => {
        if(refTaskModal.current !== undefined && refTaskModal.current !== null)
        {
            // @ts-ignore
            refTaskModal.current.open(node.id)
        }
    }

    // Received update
    const handleRefetch = (m: string) => {
        if(AttackIDStateRef.current === m)
        {
            refetch();
            refetchTasks();
            refetchEmails();
            refetchCorrelatedIncident();
        }
    }

    // Resume task
    const taskResume = async (id: string) => await APIT.taskResume(id);

    return (
        <Modal
            title={Attack === undefined ? "Loading" : Attack.Name}
            width={ window.innerWidth - 200}
            closable={true}
            onCancel={handleClose}
            onOk={handleClose}
            open={Open}>

            {Loading && <Spin/>}

            {!Loading && Attack !== undefined &&
                <Tabs defaultActiveKey="1">

                    <Tabs.TabPane
                        tab={
                            <Space>
                                <ApartmentOutlined />
                                Map
                            </Space>
                        }
                        key="1">

                        {Tasks &&
                            <>
                                <TaskModal ref={refTaskModal}/>
                                <AttackMap Tasks={Tasks} onNodeClick={handleTaskOpen} onTaskResume={(id:string) => taskResume(id)}/>
                            </>
                        }
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <Space>
                                <InfoCircleOutlined/>
                                Detail
                            </Space>
                        }
                        key="Detail">

                        <Descriptions bordered column={2}>
                            <Descriptions.Item label="Name">{Attack.Name}</Descriptions.Item>
                            <Descriptions.Item label="Operator">
                                <AdminDisplay Admin={Attack.Operator} showAvatar={true} showName={true} />
                            </Descriptions.Item>

                            <Descriptions.Item label="Playbook" span={2}><PlaybookTextLabel PlaybookId={Attack.playbook}/></Descriptions.Item>
                            <Descriptions.Item label="Container" span={2}><ContainerDisplay Container={Attack.Container}/></Descriptions.Item>
                            <Descriptions.Item label="Start Time"><DateTimeView DateTime={Attack.StartTime}/></Descriptions.Item>
                            <Descriptions.Item label="Completion Time"><DateTimeView DateTime={Attack.CompletionTime}/></Descriptions.Item>
                        </Descriptions>

                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <Space>
                              <BarsOutlined/>
                              Tasks
                            </Space>
                        }
                        key="2">
                        <TaskList Tasks={Tasks} showPlaybookExecution={true}/>
                    </Tabs.TabPane>


                    <Tabs.TabPane
                        tab={
                            <Space>
                                <AimOutlined/>
                                Entities
                            </Space>
                        }
                        key="3">

                        <Alert
                            message="Entities are configured when you run the playbook. It might be the user that executes the attack, or the template to use for an email."
                            type="info" showIcon closable/>

                        <Table
                            dataSource={Attack.entities}
                            rowKey="id"
                            loading={LoadingTasks}
                            pagination={false}
                            columns={[
                                {
                                    title: 'Value',
                                    key:'Value',
                                    render: (record) => {

                                        let value = record.value;

                                        if (record.EntityType === "User" || record.EntityType === "Identity") {
                                            return (
                                                <Space>
                                                    <UserOutlined/>
                                                    <Text>{value}</Text>
                                                </Space>
                                            )
                                        }

                                        if (record.EntityType === "File") {

                                            if(typeof value === 'object')
                                            {
                                                // Is a populated entity type
                                                value = value.Filename;
                                            }

                                            return (
                                                <Space>
                                                    <FileOutlined/>
                                                    <Text>{value}</Text>
                                                </Space>
                                            )
                                        }

                                        if (record.EntityType === "Agent") {
                                            return (
                                                <Space>
                                                    <LaptopOutlined/>
                                                    <Text>{value}</Text>
                                                </Space>
                                            )
                                        }

                                        return (
                                            <Space>
                                                <CodepenOutlined/>
                                                <Text>{value}</Text>
                                            </Space>
                                        )
                                    }
                                },
                                {
                                    title: 'Type',
                                    dataIndex: 'EntityType',
                                    key: 'EntityType',
                                    render: text => <Text>{text}</Text>,
                                },
                                {
                                    title: 'Description',
                                    key: 'Description',
                                    dataIndex: 'Description',
                                    render: text => <Text>{text}</Text>
                                }
                            ]}
                        />

                    </Tabs.TabPane>

                    {Attack.Evidence &&
                        <Tabs.TabPane
                            tab={
                                <Space>
                                    <BulbOutlined/> Evidence
                                </Space>
                            }
                            key="5">

                            <Table
                                dataSource={Attack.Evidence}
                                rowKey="id"
                                pagination={false}
                                columns={[
                                    {
                                        title: 'Type',
                                        key: 'type',
                                        render: (record) => {

                                            let ico = <ForkOutlined />
                                            let text = record.Type;

                                            if(text==="MailMessage")
                                            {
                                                ico = <MailOutlined />
                                            }

                                            if(text==="Url")
                                            {
                                                ico = <LinkOutlined />
                                            }

                                            if(text==="sha256")
                                            {
                                                ico = <FileOutlined />
                                            }

                                            return <Space>
                                                {ico}
                                                {text}
                                            </Space>

                                        }
                                    },
                                    {
                                        title: 'Tenant',
                                        dataIndex: 'Tenant',
                                        key: 'tenant',
                                        render: text => <Text type="secondary">{text}</Text>,
                                    },
                                    {
                                        title: 'Evidence',
                                        key: 'evidence',
                                        dataIndex: 'Evidence',
                                        render: text => <Text>{text}</Text>
                                    }
                                ]}
                            />
                        </Tabs.TabPane>
                    }

                    {Attack && Attack.ExpectedAlerts &&
                        <Tabs.TabPane
                            tab={
                                <Space>
                                    <AlertOutlined/>
                                    Expected Alerts
                                </Space>
                            }
                            key="expectedAlerts">

                            <Table
                                dataSource={Attack.ExpectedAlerts}
                                rowKey="id"
                                pagination={false}
                                columns={[
                                    {
                                        title: 'Title',
                                        dataIndex: 'title',
                                        key: 'title',
                                        render: text => <Text>{text}</Text>,
                                    },
                                    {
                                        title: 'Source',
                                        key: 'serviceSource',
                                        dataIndex: 'serviceSource',
                                        render: (record) => {

                                            let name = record;

                                            let DefenderProductType = DefenderProductTypes.find(i => i.SourceName === record);

                                            if(DefenderProductType !== undefined && DefenderProductType !== null)
                                            {
                                                name = DefenderProductType.FriendlyName;
                                            }

                                            return <Text type="secondary">{name}</Text>;
                                        }
                                    },
                                    {
                                        title: 'Category',
                                        key: 'category',
                                        dataIndex: 'category',
                                        render: (record) => {

                                            let name = record;

                                            let MitreType = DefenderMitreTypes.find(i => i.Name === record);

                                            if(MitreType !== undefined && MitreType !== null)
                                            {
                                                name = MitreType.FriendlyName;
                                            }

                                            return <Text type="secondary">{name}</Text>;
                                        }
                                    },
                                    {
                                        title: 'Matched Alert',
                                        key: 'foundAlert',
                                        dataIndex: 'State',
                                        render: (record) => {

                                            console.dir(record);

                                            if(record === 0)
                                            {
                                                return <Tag>Dependant task not ran</Tag>
                                            }

                                            if(record === 1)
                                            {
                                                return <Tag icon={<CloseOutlined />}>No</Tag>
                                            }

                                            if(record === 2)
                                            {
                                                return <Tag icon={<CheckOutlined />}>Yes</Tag>
                                            }

                                            if(record === 3)
                                            {
                                                return <Tag icon={<CheckOutlined />}>Yes / Explicit</Tag>
                                            }

                                        }
                                    },
                                    {
                                        title: 'Time to Alert',
                                        key: 'tta',
                                        render: (record) => {

                                            if(record.State === 3 && record.TimeToAlert !== 0)
                                            {

                                                var d = Number(record.TimeToAlert);
                                                var h = Math.floor(d / 3600);
                                                var m = Math.floor(d % 3600 / 60);
                                                var s = Math.floor(d % 3600 % 60);

                                                var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
                                                var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
                                                var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

                                                return <Text>{hDisplay} {mDisplay} {sDisplay}</Text>
                                            } else {
                                                return <Text type="secondary">N/A</Text>
                                            }


                                        }
                                    }
                                ]}
                            />
                        </Tabs.TabPane>

                    }

                    {Attack &&
                        <Tabs.TabPane
                            tab={
                                <Space>
                                  <FileOutlined/>
                                  Files
                                </Space>
                            }
                            key="AttackFiles">
                            <AttackFilesTable AttackID={Attack.id} />
                        </Tabs.TabPane>}

                    {CorrelatedIncident && CorrelatedIncident.map((Incident : DefenderIncidentMap) => {

                        const tabKey = "inc_ " + Incident.id;

                        let incidentBadge:PresetStatusColorType = "default";

                        if(Incident.status === "Active")
                        {
                            incidentBadge = "success";
                        }

                        return (
                            <Tabs.TabPane
                                tab={
                                    <Space>
                                        <SafetyCertificateOutlined /> Incident
                                    </Space>
                                }
                                key={tabKey}>

                                <Descriptions title="Information" bordered column={2}>

                                    <Descriptions.Item label="ID">{Incident.incidentId}</Descriptions.Item>
                                    <Descriptions.Item label="Status">
                                        <Badge status={incidentBadge} text={Incident.status}/>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Tenant">{Incident.Tenant}</Descriptions.Item>
                                    <Descriptions.Item label="Name" span={2}>{Incident.DisplayName}</Descriptions.Item>

                                </Descriptions>

                                <Title level={5}>Alerts</Title>

                                <AttackIncidentTable Incident={Incident} />

                            </Tabs.TabPane>
                        );

                    })}


                        <Tabs.TabPane
                            tab={
                                <Space>
                                  <MailOutlined/>
                                  Emails
                                </Space>
                            }
                            key="8">

                            {LoadingEmails && <Spin />}

                            {!LoadingEmails &&
                                <AttackEmailsTable Emails={Emails} Loading={LoadingEmails}/>
                            }

                        </Tabs.TabPane>

                    {Attack &&
                        <Tabs.TabPane
                            tab={
                                <Space>
                                    <FontAwesomeIcon icon={faVial} />
                                    Tests
                                </Space>
                            }
                            key="AttackTests">
                            <AttackTestsTab AttackID={Attack.id} />
                        </Tabs.TabPane>}

                </Tabs>
            }
        </Modal>

    )

});

export default AttackDetailModal;