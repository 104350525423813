import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Button, Drawer, Form, Input, Menu, Slider} from "antd";
import PlaybookItemTypes from "../Playbook/PlaybookItemTypes";
import {PlusCircleOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";

const PlaybookNewTaskDrawer = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // Construct the menu from all item types
    let menuItems = [];

    const [ShowSelectItem, SetShowSelectItem] = useState(true);
    const [ItemCreationType, SetItemCreationType] = useState();
    const [open, setOpen] = useState(false);

    const [Parent, SetParent] = useState();

    // Drawer open close
    const showDrawer = (parent) => {

        // Creating underneath
        if(parent !== undefined)
        {
            SetParent(parent.id);
        }

        SetItemCreationType(undefined);
        SetShowSelectItem(true);
        formDetail.resetFields();
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    // Details form
    const [formDetail] = Form.useForm();

    PlaybookItemTypes.map((itemType) => {
        menuItems.push({
            label: <text strong>{itemType.Display}</text>,
            key: itemType.TaskType,
            icon: itemType.Icon
        });
    });

    const handleItemSelect = (item) => {
        SetShowSelectItem(false);
        SetItemCreationType(item.key);
    }

    const handleFinish = () => {


        let creationData = {
            Type: ItemCreationType,
            Name: formDetail.getFieldValue("Name"),
            Description: formDetail.getFieldValue("Description"),
            Delay: formDetail.getFieldValue("Delay"),
        }

        if(Parent !== undefined && Parent !== 0)
        {
            creationData.Depends = [Parent];
        } else {
            creationData.Depends = [];
        }

        props.onAdd(creationData);
        onClose();

    }

    return (
        <Drawer
            {...props}
            title="Add Task"
            closable={true}
            onClose={onClose}
            open={open}>

            {ShowSelectItem &&
                <Menu items={menuItems} onClick={handleItemSelect}/>
            }

            {ItemCreationType &&
                <Form layout="vertical" onFinish={handleFinish} form={formDetail}>
                    <Form.Item label="Name" name="Name" rules={[
                        { required: true, message: 'Enter a short, descriptive name for this task' },
                        { max: 20, message: "Must be less than 20 chars"}
                    ]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label="Description" name="Description">
                        <Input/>
                    </Form.Item>

                    <Form.Item label="Delay" name="Delay" tooltip="Amount of minutes to delay after dependant tasks have completed.">
                        <Slider defaultValue={0} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" icon={<PlusCircleOutlined/>}>
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            }

        </Drawer>

    )

});

export default PlaybookNewTaskDrawer;