import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Drawer, Form, Input, Modal, Select} from "antd";
import DrawerRef from "../../../../classes/DrawerRef/DrawerRef";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import {useForm} from "antd/es/form/Form";
import DrawerAddProps from "../../../../classes/DrawerAdd/DrawerAddProps";
import LabEnvironment from "../../../../Models/Labs/LabEnvironment";
import AdminSelect from "../../../admins/AdminSelect/AdminSelect.react";
import {SaveOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";

const LabEnvironmentUserAddDrawer = forwardRef<DrawerRef,DrawerAddProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Labs
    const {
        data: Labs,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["labs"],
        async () => await APIT.labsGet()
    );

    // State
    const [Open, SetOpen] = useState(false);

    // Refs
    const [form] = useForm();

    // Funcs
    const showDrawer = () => {
        SetOpen(true);
    }

    const handleSave = async (vals:LabEnvironment) => {
        // Create dto edit object

        const modal = Modal.success({
            title: 'Saving',
            content: `Lab user is being created, please hold.`,
        });

        await APIT.labUserPost(vals);

        modal.destroy();
        handleClose();

        if(onChange !== undefined)
        {
            onChange();
        }

    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    return (
        <Drawer
            title="Add Lab User"
            onClose={handleClose}
            open={Open}>

            <Form
                form={form}
                onFinish={handleSave}>

                <Form.Item
                    label="Lab Environment"
                    name="LabID"
                    rules={[{ required: true, message: 'Define the owner' }]}>
                    <Select>
                        {!isLoading && Labs && Labs.map((labEnvironment) => <Select.Option key={labEnvironment.id}>{labEnvironment.Name}</Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Owner"
                    name="OwnerID"
                    rules={[{ required: true, message: 'Define the owner' }]}>
                    <AdminSelect />
                </Form.Item>

                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                    Add
                </Button>


            </Form>

        </Drawer>

    )

});

export default LabEnvironmentUserAddDrawer;