import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Drawer, Form, Input, Modal} from "antd";
import DrawerRef from "../../../../classes/DrawerRef/DrawerRef";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import {useForm} from "antd/es/form/Form";
import TenantSelect from "../../../tenants/TenantSelect/TenantSelect.react";
import DrawerEditProps from "../../../../classes/DrawerEdit/DrawerEditProps";
import {useQuery} from "@tanstack/react-query";
import LabUser from "../../../../Models/Labs/LabUser";
import AdminSelect from "../../../admins/AdminSelect/AdminSelect.react";
import {SaveOutlined} from "@ant-design/icons";
import dtoLabUserEdit from "../../../../Models/Labs/dtoLabUserEdit";
import LabUserType from "../../../../Models/Labs/LabUserType";

const LabEnvironmentUserEditDrawer
    = forwardRef<DrawerRef,DrawerEditProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [LabUserID, SetLabUserID] = useState<string | undefined>(undefined);

    // React queries
    const {
        data: User,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["labuser", LabUserID],
            queryFn: LabUserID !== undefined ? async() => await APIT.labUserGet(LabUserID) : () => {},
            enabled: LabUserID !== undefined && LabUserID !== null ? true : false
        }
    );

    // Refs
    const [form] = useForm();

    // Funcs
    const showDrawer = (UserID: string) => {
        SetLabUserID(UserID);
        SetOpen(true);
    }

    const handleSave = async (vals:dtoLabUserEdit) => {
        // Create dto edit object

        if(LabUserID !== undefined)
        {
            const modal = Modal.success({
                title: 'Saving',
                content: `Lab user is being saved, please hold.`,
            });

            await APIT.labUserPut(LabUserID, vals);

            modal.destroy();
            handleClose();

            if(onChange !== undefined)
            {
                onChange();
            }
        }

    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    return (
        <Drawer
            title="Edit User"
            onClose={handleClose}
            open={Open}>

            {User !== undefined &&
                <Form
                    form={form}
                    initialValues={User}
                    onFinish={handleSave}>

                    <Form.Item
                        label="Owner"
                        name="OwnerID"
                        rules={[{ required: true, message: 'Define the owner' }]}>
                        <AdminSelect />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                        Save
                    </Button>


                </Form>
            }

        </Drawer>

    )

});

export default LabEnvironmentUserEditDrawer;