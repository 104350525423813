import React, {useContext} from "react";
import {
   Select
} from "antd";
import {useQuery} from "@tanstack/react-query";
import APIProvider from "../../service/api2";
import {AppContext} from "../../AppProvider";

const ContainerSelect = ({value=undefined, onChange = undefined, all = false}) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const {
        data: Containers,
        isLoading: isLoading
    } = useQuery(
        [(all ? "containers" : "mycontainers")],
        (all ? async () => await API.getContainers() : async () => await API.getMyContainers())
    );

    return (
        <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a container"
            optionLabelProp="label"
            onChange={onChange}
            value={value}
            filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
            }>
            {
                Containers && Containers.map((c) => {

                    return (
                        <Select.Option value={c.id} label={c.Name}>{c.Name}</Select.Option>
                    )
                })
            }

        </Select>
    )
};

export default ContainerSelect;