import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Alert, Button, Drawer, Form, Input, Modal, Space} from "antd";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import DrawerAddProps from "../../../classes/DrawerAdd/DrawerAddProps";

const AgentAddDrawer = forwardRef<DrawerRef,DrawerAddProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);

    // Refs
    const [form] = useForm();

    // Funcs
    const showDrawer = () => {
        SetOpen(true);
    }

    const handleSave = async (vals:any) => {
        // Create dto edit object

        const modal = Modal.success({
            title: 'Saving',
            content: `Agent is being created, please hold.`,
        });

        await API.provisionAgent(vals);

        modal.destroy();
        //handleClose();

        if(onChange !== undefined)
        {
            onChange();
        }

    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
    }

    return (
        <Drawer
            title="Add new agent"
            onClose={handleClose}
            open={Open}>

            <Form
                form={form}
                onFinish={handleSave}>

                <Alert
                    message="Creating agent"
                    description="After clicking create, the agent configuration file will be downloaded to your machine. Download the agent updater, and place the config file in to the config directory and name it config.json. The agent updater requires dotnet core 6 + 7 runtimes."
                    type="info"
                    showIcon
                    style={{marginBottom: 20}}
                />

                <Form.Item
                    label="Agent Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                </Form.Item>

                <Space>
                    <Button type="primary" htmlType="submit">
                        Add
                    </Button>

                    <Button onClick={() => API.downloadAgentSoftware("Win32Updater")}>
                        Download Agent Updater
                    </Button>
                </Space>


            </Form>

        </Drawer>

    )

});

export default AgentAddDrawer;