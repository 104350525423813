import React from "react";
import axios from 'axios';
import Cookies from 'js-cookie'
import {message} from "antd";
import Text from "antd/es/typography/Text";

export default class APIProvider extends React.Component
{

    token = null;

    // Container we want to execute on
    Container = null;
    ContainerName = null;

    useMessageErrorHandler = false;

    constructor(token, useMessageErrorHandler) {
        super();
        this.token = token;
        this.useMessageErrorHandler = useMessageErrorHandler;
    }

    urlApi = process.env.REACT_APP_urlApi;

    handleError(error, rejector) {
        console.dir(error);

        let MainText = "Unhandled";
        let Message = ":( Oh oh! Unhandled error occurred!";

        if(error !== undefined)
        {

            if(error.response !== undefined && error.response.data !== undefined)
            {

                // Specific response code handling
                if(error.response.status === 401) {
                    // Default message
                    MainText = "Unauthorized";
                    Message = "Unauthorized. You may not have the permissions required to perform this operation :(";
                } else if (error.response.status===400) {
                    MainText = "Bad Request";
                    Message = "Something that you tried to do wasn't right. The API didn't tell us what it was though, this is a bug, log it. :("
                }

                if (typeof error.response.data === "string") {
                    Message = error.response.data;
                }

            }


        }

        Message = <span><Text strong>{MainText} </Text><Text>{Message}</Text> </span>;

        if(this.useMessageErrorHandler)
        {
            let messageConfig = {
                content: Message,
                duration: 15,
            }

            console.dir(messageConfig);

            message.error(messageConfig);
        }
        

    }

    getAttestationData(id, akey)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "LabUser/" + id + "/attestationdata?k=" + akey, {
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    attest(id,dto)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "LabUser/" + id + "/attest", dto, {

            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    login(username,password)
    {

        return new Promise((resolve, reject) => {

            console.dir(this.context);

            const loginPayload = {
                id: username,
                password: password
            };

            axios.post(this.urlApi + "Operator/authenticate", loginPayload)
                .then((res) => {
                    Cookies.set('token', res.data.token);

                    if(this.Container == null)
                    {
                        Cookies.set('container', res.data.DefaultContainer);
                        this.Container = res.data.DefaultContainer;
                    }

                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error); reject(error);
                })
        })
    }

    loginfed(username,token)
    {

        return new Promise((resolve, reject) => {

            console.dir(this.context);

            const loginPayload = {
                id: username,
                federatedToken: token
            };

            axios.post(this.urlApi + "Operator/authenticate", loginPayload)
                .then((res) => {
                    Cookies.set('token', res.data.token);
                    Cookies.set('fed', true);

                    if(this.Container == null)
                    {
                        Cookies.set('container', res.data.DefaultContainer);
                        this.Container = res.data.DefaultContainer;
                    }

                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })
        })
    }

    GetSetContainer()
    {
        return Cookies.get('container');
    }


    GetSetContainerName()
    {
        return Cookies.get('containername');
    }

    // Gets the warning state - e.g if warning has been agreed to
    WarningState()
    {
        const Warn = Cookies.get('WarnComplete');

        if(Warn === undefined || Warn === null)
        {
            return true;
        } else {
            return !Cookies.get('WarnComplete')
        }
    }

    // Set warning state to true
    ConfirmWarning()
    {
        Cookies.set('WarnComplete',true);
    }

    SetContainer(id)
    {

        console.log(id);

        // Get the container
        return new Promise((resolve, reject) => {

            // Get the container
            this.getContainer(id).then((container) => {
                Cookies.set('container', id);
                Cookies.set('containername', container.Name);
                this.Container = id;
                this.ContainerName = container.Name;
                resolve();
            }).catch(() => {
                reject();
            })

        });

    }

    LogOut()
    {
        this.token = null;
        Cookies.set('token', null);
        Cookies.set('container', null);
        Cookies.set('WarnComplete', null);

        // logout of fed if required
        window.location.reload();
    }

    MyToken()
    {
        return JSON.parse(atob(this.token.split('.')[1]));
    }

    RawToken()
    {
        return this.token;
    }

    GetAvatarUrl(email)
    {
        // asd
        return "https://cattackblob.blob.core.windows.net/avatars/" + email;
    }

    getMyContainers()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Operator/me/container", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getHeaders()
    {
        return {
            'X-Token': this.token,
            'Content-Type': "application/json",
            'Container': this.GetSetContainer()
        }
    }

    /* Agents */

    agentDrainstop(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Agent/" + id + "/drainstop",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    agentWake(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Agent/" + id + "/pong",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    agentHealthCheck(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Agent/" + id + "/healthcheck",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getAgent(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Agent/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getAgentTasks(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Agent/" + id + "/tasks", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getAgents(getGlobal=false)
    {
        return new Promise((resolve, reject) => {

            let url = this.urlApi + "Agent";

            if(getGlobal)
            {
                url += "?getGlobal=true";
            }

            axios.get(url, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    agentNewConfig(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Agent/" + id + "/configfile", {
                headers: this.getHeaders()
            })
            .then((res) => {
                const fileDownload = require('js-file-download');
                fileDownload(JSON.stringify(res.data), 'config.json'); // dodgy, axios refuses to fix this bug :(
                resolve();
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    /* IP Info */

    getIPInfo(ip)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "ipinfo/" + ip, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Relationships */

    getIdentityRelationships(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "identity/" + id + "/relationship", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    addIdentityAccount(id,account,provision)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "identity/" + id + "/account?Provision=" + provision, account,{
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    addIdentityRelationship(id,relate)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "identity/" + id + "/relationship/" + relate, null,{
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deleteIdentityRelationship(id,relate)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "identity/" + id + "/relationship/" + relate,{
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }


    /* Operator */

    getOperator(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Operator/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    updateProfile(profile)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Operator/me/profile",profile, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    addOperator(operator)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Operator",operator, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    editOperator(id,operator)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Operator/" + id,operator, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    setAdminEnableDisable(admin,state)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Operator/" + admin + "/" + state,null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    putAdmin(id,dto)
    {
        if(id === null || dto === null)
        {
            throw "ID and DTO must be specified";
        }

        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Operator/" + id, dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getOperators()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Operator", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deleteOperator(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Operator/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Role Groups */

    getRoleGroups()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "RoleGroup", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Users */

    getIdentities(RequestOptions)
    {

        if (RequestOptions === undefined)
        {
            RequestOptions = {
                ReturnGlobal: false,
                ReturnAll: false
            }
        } else {
            if(RequestOptions.ReturnAll === undefined) RequestOptions.ReturnAll = false;
            if(RequestOptions.ReturnGlobal === undefined) RequestOptions.ReturnGlobal = false;
        }

        let RequestPath = "Identity?Global=" + RequestOptions.ReturnGlobal + "&All=" + RequestOptions.ReturnAll;

        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + RequestPath, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getIdentity(id)
    {
        return new Promise((resolve, reject) => {

            if(id === null || id === undefined)
                throw("Must specify id");

            axios.get(this.urlApi + "Identity/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    putIdentity(id, data)
    {
        return new Promise((resolve, reject) => {

            if(id === null || id === undefined)
                throw("Must specify id");

            if(data === null || data === undefined)
                throw("Must specify data");

            axios.put(this.urlApi + "Identity/" + id, data,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    changeIdentityDetail(identity,detail)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Identity/" + identity + "/detail", detail,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    addIdentity(identity)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Identity", identity,{
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deleteIdentity(identity)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Identity/" + identity,{
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Tenant Users */

    /*getTenantUsers()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "TenantUser", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }*/

    addTenantUser(user)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "TenantUser",user, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /*

    TENANT

    */

    setTenantFlag(Tenant,Flag,BoolValue)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Tenant/" + Tenant + "/flags/" + Flag + "/" + BoolValue,null, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deleteTenant(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Tenant/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /*

    AGENT

    */

    adoptAgent(id)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Agent/" + id + "/adopt",null, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deleteAgent(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Agent/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    releaseAgentIsolation(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Agent/" + id + "/releaseisolation", null,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    downloadAgentSoftware(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "AgentSoftware/" + id + "/requestlatest", {
                responseType: 'json',
                headers: this.getHeaders()
            })
                .then((response) => {
                    const fileDownload = require('js-file-download');

                    axios.get(response.data.Location, {
                        responseType: 'blob',
                    })
                    .then((res) => {
                        fileDownload(res.data, response.data.Filename);
                        resolve();
                    })
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    provisionAgent(agent)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Agent",agent, {
                responseType: 'json',
                headers: this.getHeaders()
            })
                .then((response) => {
                    const fileDownload = require('js-file-download');
                    fileDownload(JSON.stringify(response.data), agent.name+'.json'); // dodgy, axios refuses to fix this bug :(
                    resolve();
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    adoptAgentCapability(id,name)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Agent/" + id + "/capability/" + name +"/adopt",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    /* User Behaviours */

    createUserBehaviours(behaviour)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "UserBehaviour", behaviour, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deleteUserBehaviours(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "UserBehaviour/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }


    getUserBehaviours()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "UserBehaviour", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getUserBehaviour(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "UserBehaviour/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getDefenderAlertTypes()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "DefenderAlertType", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    /* redirect domains */

    getRedirectDomains(HealthyOnly=false)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Domain?Use=SiteFriendly&HealthyOnly=" + HealthyOnly, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    enableDomain(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Domain/" + id + "/enable", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    disableDomain(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Domain/" + id + "/disable", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }


    /* redirect domains */

    getPage(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Page/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getPages(ContainerOnly=false)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Page?ContainerOnly=" + ContainerOnly, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    postPage(page)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Page",page, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    putPage(id,page)
    {
        return new Promise((resolve, reject) => {

            if(id === null || page === null)
            {
                throw("Mandatory props not set");
            }

            axios.put(this.urlApi + "Page/" + id ,page, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deletePage(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Page/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* email templates */

    getTemplateEmails()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "templateEmail", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getTemplateEmail(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "templateEmail/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getTemplateEmailReplies(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "templateEmail/" + id + "/replies", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    get(url)
    {
        return new Promise((resolve, reject) => {

            let fullUrl = this.urlApi + url;

            console.log(fullUrl);
            axios.get(fullUrl, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }


    createTemplateEmail(template)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "templateEmail",template, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deleteTemplateEmail(id)
    {

        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "templateEmail/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    /* redirect Urls RedirectUrl */

    getRedirectUrls()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Website", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getWebsite(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Website/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getWebsiteVisits(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Website/" + id + "/visits", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getWebsiteRecons(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Website/" + id + "/recon", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getWebsiteSessions(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Website/" + id + "/sessions", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    createRedirectUrl(rurl)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Website",rurl, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deleteRedirectUrl(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Website/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    weaponizeRedirectUrl(id,weaponizeval)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Website/" + id + "/weaponized/" + weaponizeval, null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    /* Statistics */

    getStats()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Stats", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Tasks */

    getTask(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Task/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getTaskFile(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "File/" + id + "/rawfile", {
                responseType: 'blob',
                headers: this.getHeaders()
            })
            .then(response => {
                resolve( resolve( new File([response.data], id)))
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getTaskEmails(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Task/" + id + "/emails", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getTasks(oDataSelect="",oDataFilter="")
    {
        return new Promise((resolve, reject) => {

            let QueryString = "";

            if(oDataSelect!=="")
            {
                QueryString += "?$select=" + oDataSelect;
            }

            if(oDataFilter!=null)
            {
                if(oDataFilter!=="")
                {
                    QueryString += "&$filter=" + oDataFilter;
                }
            }


            axios.get(this.urlApi + "Tasks" + QueryString, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data.value)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* File Templates */
    getFileTemplates()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "filetemplate", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getFileTemplate(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "filetemplate/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data[0])
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    postFileTemplate(template)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "filetemplate", template, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    putFileTemplate(id,template)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "filetemplate/" + id, template, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data[0])
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    /* Files */

    getFiles(oDataSelect="",oDataFilter="")
    {
        return new Promise((resolve, reject) => {

            let QueryString = "";

            if(oDataSelect!=="")
            {
                QueryString += "?$select=" + oDataSelect;
            }

            if(oDataFilter!=="")
            {
                QueryString += "&$filter=" + oDataFilter;
            }

            axios.get(this.urlApi + "Files" + QueryString, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data.value)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getFile(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "File/"+ id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }


    addFile(file)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "File",file, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    putFile(id,file)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "File/" + id,file, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deleteFile(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "File/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deleteFileTemplate(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "FileTemplate/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }


    /* Tenant */

    getTenants()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Tenant", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getTenant(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Tenant/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getTenantDomains(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Tenant/" + id + "/domains", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    /* Containers */
    getContainers()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Container", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getContainer(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Container/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    expireContainer(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Container/" + id + "/expirenow", null,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getContainerAdmins(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Container/" + id + "/admin", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    removeContainerAdmin(container,id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Container/" + container + "/admin/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    addContainerAdmin(container,id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Container/" + container + "/admin/" + id, null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    postContainer(Container)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Container",Container, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }


    /* Domains */

    getDomains()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Domain", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getDomainsByUse(Use)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Domain?Use=" + Use, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    postDomain(Domain)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Domain",Domain, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    putDomain(id,Domain)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Domain/" + id,Domain, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deleteDomain(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Domain/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Defender Incidents */

    getCorrelatedDefIncidents()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "DefenderIncident?Correlated=true", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Operators */

    getMe()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Operator/me", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getMeAvatarUploadURL()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Operator/me/profile/avatar", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    GetAvatarUrl(email)
    {
        // asd
        return "https://cattackblob.blob.core.windows.net/avatars/" + email;
    }

    /* Playbook Schedule  PlaybookSchedule*/

    getPlaybookSchedules()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "PlaybookSchedule", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getPlaybookSchedule(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "PlaybookSchedule/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    editPlaybookSchedule(id,schedule)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "PlaybookSchedule/" + id, schedule, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deletePlaybookSchedule(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "PlaybookSchedule/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    enablePlaybookSchedule(id)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "PlaybookSchedule/" + id + "/enable", null, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    disablePlaybookSchedule(id)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "PlaybookSchedule/" + id + "/disable", null, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }


    /* Playbooks */

    getAttacks()
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    AttackRefresh(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Attack/" + id + "/refresh", null,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getAttacksBySchedule(ScheduleID)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "PlaybookSchedule/" + ScheduleID + "/attack", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deleteAttack(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Attack/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getAttack(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getAttackDefenderIncidents(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/defenderincidents", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getAttackTasks(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/task", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getAttackEmails(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/emails", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getPlaybooks(ShowGlobal=false,
                 OnlyMine=false,
                 OnlyAuthored = false,
                 ShowDeleted=false)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Playbook?ShowGlobal=" + ShowGlobal + "&OnlyAuthored=" + OnlyAuthored + "&OnlyMine=" + OnlyMine + "&ShowDeleted=" + ShowDeleted, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getEventSchema(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "schema/events/" + id, {
                headers: this.getHeaders(),

            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getPlaybook(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Playbook/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    deletePlaybook(id)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Playbook/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    postPlaybook(playbook)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Playbook", playbook,{
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getPlaybookVersions(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Playbook/" + id + "/versions", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    restorePlaybookVersion(playbookID,versionID)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Playbook/" + playbookID + "/versions/" + versionID + "/restore",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    editPlaybook(id,playbookEdit)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Playbook/" + id, playbookEdit, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    dupePlaybook(id)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Playbook/" + id + "/dupe", null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    editPlaybookTasks(id,tasks)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Playbook/" + id + "/tasks", tasks, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    replayAttack(id)
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/replay", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    attackCancel(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Attack/" + id + "/cancel",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    runPlaybook(id,payload)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Playbook/" + id + "/run",payload, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    runTask(id)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Task/" + id + "/runnow",null, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    taskRestart(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Task/" + id + "/restart",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }


    taskResume(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Task/" + id + "/resume",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    taskTrigger(id)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Task/" + id + "/trigger",null, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    postTenant(tenant)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Tenant",tenant, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    /* Base stuff */

    Authenticated()
    {

        this.token = Cookies.get('token');
        this.container = Cookies.get('container');

        console.log("Token in cookie " + this.token);

        if(Cookies.get('token')==="null" || Cookies.get('token')==null)
        {
            return false;
        } else {
            return true;
        }
    }
}