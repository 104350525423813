import React from 'react'
import {Col, Row, Space} from "antd";
import Title from "antd/es/typography/Title";

const PageHeader = (props) => {

    return (
        <Row wrap={false} align="middle" style={{ marginBottom: '5px'}}>

            <Col flex="none">
                <Title level={3}>{props.Title}</Title>
            </Col>

            <Col flex="auto"/>

            <Col flex="none">
                <Space>
                    {props.Buttons}
                </Space>
            </Col>
        </Row>
    )

}
export default PageHeader