// @flow

import React, {Component} from "react";

import {Alert, message,} from "antd";

class ApiError extends Component {
  render() {

    if(this.props.error)
    {

        let errorMessage = this.props.error.message;
        console.dir(this.props.error);

      if(this.props.error.response !== undefined)
      {
        if(this.props.error.response.data)
        {
          if(this.props.error.response.data.title !== undefined)
          {
            errorMessage = errorMessage + " - " + this.props.error.response.data.title;
          } else {
            errorMessage = errorMessage + " - " + this.props.error.response.data;
          }

        }
      } else {
        errorMessage = this.props.error.message;
      }

      message.error(errorMessage);


      return (
        <Alert
        message="Error"
        description={errorMessage}
        type="error"
        showIcon
        closable
        />
      );
    }
    else
    {
      return null;
    }
  }
}

export default ApiError;
