import React, {useContext, useEffect, useRef} from "react";

import {Dropdown, Menu, Modal, Space, Table, Tag} from 'antd';
import Text from "antd/es/typography/Text";
import {
    CheckSquareOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    DeploymentUnitOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    PartitionOutlined,
    PhoneOutlined,
    ThunderboltOutlined
} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import IdentityEdit from "./IdentityEdit/IdentityEdit.react";
import IdentityRelationshipDrawer from "./IdentityRelationshipDrawer/IdentityRelationshipDrawer";
import HealthTag from "../health/HealthTag/HealthTag";
import APITProvider from "../../service/api";
import IdentityEdit2FA from "./IdentityEdit2FA/IdentityEdit2FA.react";

const IdentityTable = ({Identities, Loading, onChange}) =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);
    const APIT = new APITProvider(token, true);

    // Modal refs
    const IdentityEditDrawer = useRef();
    const IdentityEdit2FADrawer = useRef();
    const IdentityRelationshipDrawerRef = useRef();

    const Delete = async (id) => await API.deleteIdentity(id).then(() => onChange());

    return (
        <>
            <IdentityEdit ref={IdentityEditDrawer} onChange={onChange}/>
            <IdentityRelationshipDrawer ref={IdentityRelationshipDrawerRef} onChange={() => {}} />
            <IdentityEdit2FA ref={IdentityEdit2FADrawer} onChange={onChange}/>
            <Table
                dataSource={Identities}
                rowKey="id"
                pagination={false}
                loading={Loading}
                columns={[
                    {
                        title: "ID",
                        key: 'id',
                        dataIndex: 'id',
                        render: (text) => text,
                    },
                    {
                        title: "Tags",
                        key: 'tags',
                        render: record => {
                            return <>
                                { record.Tags && record.Tags.map((t) => {
                                        var icon = <></>;
                                        if(t==="Scheduled")
                                        {
                                            icon = <ClockCircleOutlined />
                                        }
                                        return (<Tag icon={icon}>{t}</Tag>);
                                    }
                                )}
                            </>
                        }
                    },
                    {
                        key: 'behaviour',
                        title: 'Behaviour',
                        dataIndex:'BehaviourID',
                        render:(text) => <Text>{text}</Text>
                    },
                    {
                        key: 'Healthy',
                        title: 'Healthy',
                        render:(record) => <HealthTag ObjectType={"Identity"} ObjectID={record.id}/>
                    },
                    {
                        key: 'Flags',
                        title: 'Flags',
                        render:(record) => {

                            const flags = [];

                            if(record.Simulated)
                            {
                                flags.push("Simulated");
                            }

                            if(record.Compromised)
                            {
                                flags.push("Compromised");
                            }

                            let M365Account = record.Accounts.find(o => o.id === "Microsoft365");
                            let SMTPAccount = record.Accounts.find(o => o.id === "SMTP");

                            if(M365Account !== undefined)
                            {
                                flags.push("Microsoft 365");
                            }

                            if(SMTPAccount !== undefined)
                            {
                                flags.push("SMTP");
                            }

                            return (
                                <>
                                    {flags.map((f, i) => {

                                        if(f==="Simulated")
                                        {
                                            return <Tag icon={<DeploymentUnitOutlined />}>Simulated</Tag>
                                        }

                                        if(f==="Compromised")
                                        {
                                            return <Tag color="red" icon={<ThunderboltOutlined />}>Compromised</Tag>
                                        }

                                        return (
                                            <Tag>{f}</Tag>
                                        );
                                    })}
                                </>
                            )

                        }
                    },
                    {
                        key:'actions',
                        render:(record) => {

                            const items = [
                                {
                                    label: 'Edit',
                                    icon: <EditOutlined/>,
                                    key: 'Edit',
                                },
                                {
                                    label: 'Enable 2FA',
                                    icon: <PhoneOutlined />,
                                    key: '2FA',
                                },
                                {
                                    label: 'Manage Relationships',
                                    icon: <PartitionOutlined/>,
                                    key: 'Relationships',
                                },
                                {
                                    label: 'Recheck Health',
                                    icon: <CheckSquareOutlined/>,
                                    key: 'health',
                                },
                                {
                                    label: 'Delete',
                                    icon: <DeleteOutlined/>,
                                    key: 'Delete',
                                },
                            ];

                            const onClick = (key) => {

                                console.log(key);

                                if(key.key==="Edit")
                                {
                                    IdentityEditDrawer.current.showDrawer(record.id);
                                }

                                if(key.key==="2FA")
                                {
                                    IdentityEdit2FADrawer.current.showDrawer(record.id);
                                }

                                if(key.key==="Relationships")
                                {
                                    IdentityRelationshipDrawerRef.current.showDrawer(record.id);
                                }

                                if(key.key==="health")
                                {
                                    Modal.confirm({
                                        title: 'Confirm health check',
                                        icon: <InfoCircleOutlined />,
                                        content: <>
                                            <Text>Health checks are performed asynchronously. Once ran, come back to this page to check soon</Text>
                                        </>,
                                        okText: 'Run',
                                        cancelText: 'Cancel',
                                        onOk: async () => APIT.putHealthCheck("Identity",record.id)
                                    });
                                }

                                if(key.key==="Delete")
                                {
                                    // Confirm delete
                                    Modal.confirm({
                                        title: 'Confirm delete',
                                        icon: <ExclamationCircleOutlined />,
                                        content: <>
                                            <Text>Deleting </Text>
                                            <Text strong>{record.id} </Text>
                                            <Text>is permanent.</Text>
                                        </>,
                                        okText: 'Delete',
                                        cancelText: 'Cancel',
                                        onOk: () => Delete(record.id)
                                    });
                                }
                            }

                            return (

                                <Dropdown menu={{ items, onClick }}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            Options
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            )
                        }
                    }
                ]}
            />
        </>

    )
}

export default IdentityTable;