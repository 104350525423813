import React, {useContext, useRef} from 'react'
import {Button, Tabs} from "antd";
import {GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import EmailTemplateTable from "./EmailTemplateTable";
import EmailTemplateAddDrawer from "./EmailTemplateAddDrawer";
import PageHeader from "../../misc/PageHeader";
import {useNavigate} from "react-router-dom";
import ProxyPhishletTable from "../ProxyPhishlets/ProxyPhishletTable";

const EmailTemplatesPage = (props) => {

    let Navigate = useNavigate();

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const EmailTemplateAddDrawerRef = useRef();

    // React Query
    const { data: Templates, isLoading: isLoading, refetch: refresh } = useQuery(
        ["emailtemplate"],
        async() => await API.getTemplateEmails()
    );

    return (
        <>
            <PageHeader
                Title="Email Templates"
                Buttons={[
                    <Button icon={<PlusCircleOutlined />} onClick={() => EmailTemplateAddDrawerRef.current.showDrawer()} outline>Add</Button>
                ]}
            />

            <EmailTemplateAddDrawer ref={EmailTemplateAddDrawerRef} onAdd={refresh}/>


            <Tabs defaultActiveKey="local">

                <Tabs.TabPane
                    key="local"
                    tab={<span><HomeOutlined/>Container Payloads</span>
                    }>

                    <EmailTemplateTable
                        Templates={Templates !== undefined ? Templates.filter(x => x.Container == API.GetSetContainer()) : []}
                        Loading={isLoading} onChange={refresh} onEditClick={(email) => Navigate(email)}/>

                </Tabs.TabPane>

                <Tabs.TabPane
                    key="global"
                    tab={<span><GlobalOutlined />Shared Payloads</span>
                    }>

                    <EmailTemplateTable Templates={Templates} Loading={isLoading} onChange={refresh} onEditClick={(email) => Navigate(email)}/>

                </Tabs.TabPane>

            </Tabs>
        </>
    );

}
export default EmailTemplatesPage