import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import {AppContext} from "./AppProvider";
import APIProvider from "./service/api2";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./fedAuthConfig"
import Emitter from "./Emitter/Emitter";
import {PublicClientApplication} from "@azure/msal-browser";
import SiteWrapper from "./SiteWrapper.react";
import MyPlaybooksPage from "./components/MyPlaybooks/MyPlaybooksPage";
import AttackSchedulePage from "./components/attackschedule/AttackSchedulePage.react";
import SitesPage from "./components/payloads/Sites/SitesPage";
import SitePage from "./components/payloads/Sites/SitePage";
import FilePayloadsPage from "./components/payloads/FilePayloadsPage.react";
import FileTemplatesPage from "./components/payloads/FileTemplate/FileTemplatesPage";
import FileTemplatePage from "./components/payloads/FileTemplate/FileTemplatePage";
import EmailTemplatesPage from "./components/payloads/EmailTemplate/EmailTemplatesPage";
import EmailTemplatePage from "./components/payloads/EmailTemplate/EmailTemplatePage";
import AdminsPage from "./components/admins/AdminsPage.react";
import ContainersPage from "./components/containers/ContainersPage.react";
import BehaviourPage from "./components/behaviour/BehaviourPage.react";
import MyPlaybookPage from "./components/MyPlaybooks/MyPlaybookPage";
import TestPage from "./components/misc/TestPage";
import IdentityPage from "./components/identities/IdentityPage.react";
import AgentsPage from "./components/agents/AgentsPage";
import AttackPage from "./components/attack/AttackPage";
import TenantsPage2 from "./components/tenants/TenantsPage/TenantsPage";
import ProxyPhishletsPage from "./components/payloads/ProxyPhishlets/ProxyPhishletsPage";
import WebsitesPage from "./components/Website/WebsitesPage";
import { ConfigProvider, theme, Button, Card } from "antd";
import DomainsPage from "./components/domains/DomainsPage";
import AIPage from "./components/ai/AIPage";
import NonAuthenticatedWrapper from "./NonAuthenticatedWrapper";
import AttestPage from "./pages/AttestPage";
import LabEnvironment from "./Models/Labs/LabEnvironment";
import LabEnvironmentUsersPage from "./components/lab/user/LabEnvironmentPage";
import LabEnvironmentPage from "./components/lab/environment/LabEnvironmentPage";
import AttestMyPage from "./components/myattest/AttestMyPage";
import HomePage from "./components/home/HomePage";

const App = (Props) => {

  const {setMe, me, token, srConnection, setsrConnection, DarkMode, SetDarkMode} = useContext(AppContext);

    const [apiEstablished,setapiEstablished] = useState();

    const { defaultAlgorithm, darkAlgorithm } = theme;

  const msalInstance = new PublicClientApplication(msalConfig);

  let connection = null;

  const messageAttackUpdate = (message) => {
    console.log("App AttackUpdate " + message);
    Emitter.emit("AttackUpdate",message);
  };

  useEffect(() => {
    if(token != null)
    {
        const urlApi = process.env.REACT_APP_urlApi;

        const connection = new signalR.HubConnectionBuilder()
          .withUrl(urlApi + "notify/operator",
              {
                headers: {
                  "X-Token": token
                }
              })
          .withAutomaticReconnect()
          .build();

      connection
          .start()
          .catch(err => console.dir(err));

      connection.on('AttackUpdate', (message) => messageAttackUpdate(message));

    }

    if(localStorage.getItem("darkmode")==="false")
    {
        SetDarkMode(false)
    } else {
        SetDarkMode(true)
    }



  },[]);

  if(token === null || token === "null" || token === undefined)
  {
    // return log on page as token is null <LoginPage/>
    return (
        <MsalProvider instance={msalInstance}>
            <Router>
            <NonAuthenticatedWrapper/>
            </Router>
        </MsalProvider>
    );
  }
  else
  {

    if (me == null)
    {
        const API = new APIProvider(token);

        API.getMe().then((me) => {
            setMe(me);
        })
        .catch((error) => {
            console.dir(error);
        })
    }

    console.log(DarkMode);

    return (
        <React.StrictMode>
          <Router>
              <ConfigProvider theme={{
                  algorithm: DarkMode ? darkAlgorithm : defaultAlgorithm,
              }}>
              <SiteWrapper>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/agents" element={<AgentsPage />}  />
                    <Route exact path="/websites" element={<WebsitesPage />}  />
                    <Route exact path="/attacks" element={<AttackPage />}/>
                    <Route exact path="/playbooks" element={<MyPlaybooksPage />}  />
                    <Route exact path="/playbooks/:id" element={<MyPlaybookPage />} />

                    <Route exact path="/attest" element={<AttestMyPage />}  />
                    <Route exact path="/playbooksch" element={<AttackSchedulePage />}  />

                    <Route exact path="/test" element={<TestPage />} />

                    <Route exact path="/labs" element={<LabEnvironmentPage />} />
                    <Route exact path="/labuser" element={<LabEnvironmentUsersPage />} />

                    <Route exact path="/site" element={<SitesPage />} />
                    <Route path="/site/:id" element={<SitePage/>} />

                    <Route exact path="/filepayloads" element={<FilePayloadsPage />}  />
                    <Route exact path="/proxyphishlets" element={<ProxyPhishletsPage />}  />

                    <Route exact path="/filetemplate" element={<FileTemplatesPage />} />
                    <Route path="/filetemplate/:id" element={<FileTemplatePage/>} />

                    <Route exact path="/emailtemplate" element={<EmailTemplatesPage />} />
                    <Route path="/emailtemplate/:id" element={<EmailTemplatePage/>} />

                    <Route exact path="/admins" element={<AdminsPage/>}  />

                    <Route exact path="/containers" element={<ContainersPage/>}  />

                    <Route exact path="/tenants" element={<TenantsPage2 />}  />
                    <Route exact path="/identity" element={<IdentityPage />}  />

                    <Route exact path="/domains" element={<DomainsPage/>}  />
                    <Route exact path="/behaviour" element={<BehaviourPage/>}  />

                    <Route exact path="/ai" element={<AIPage/>} />

                </Routes>
              </SiteWrapper>
              </ConfigProvider>
          </Router>
        </React.StrictMode>
    );

  }

  /*


                    <Route exact path="/emails" element={<EmailsPage/>} />


   */

  // set up the SignalR connection
  /*
  var connection =
      new signalR.HubConnectionBuilder()
          .withUrl(urlApi + "notify/operator",
              {
                headers: {
                  "X-Token": api.RawToken()
                }
              })
          .withAutomaticReconnect()
          .build();

    connection.start().catch(err => console.dir(err));

    setConnection(connection);

  /*props.notify.on('broadcastMessage', function (name, msg) {
    message.success(name + ": " + msg);
  });

  props.notify.on('MessageSuccess', function (msg) {
    message.success(msg);
  });

  props.notify.on('MessageError', function (msg) {
    message.error(msg);
  });

  const connection = new signalR.HubConnectionBuilder()
      .withUrl(urlApi + "notify/operator",
          {
            headers: {
              "X-Token": api.RawToken()
            }
          })
      .withAutomaticReconnect()
      .build();*/


}

export default App;
