import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {Button, Drawer, Form, Input, Modal, Select, Space, Spin, Switch, Tabs, Typography} from "antd";
import {BulbOutlined, PhoneOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../../service/api";

const IdentityEdit2FA = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [IdentityID, SetUserID] = useState(null);

    // Refs
    const [form] = useForm();

    // React queries
    const {
        data: Identity,
        isLoading: isLoading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["identity", IdentityID],
            queryFn: async() => await APIT.identityGet(IdentityID),
            enabled: IdentityID !== null && IdentityID !== undefined ? true : false
        }
    );

    // Funcs
    const showDrawer = (id) => {
        SetUserID(id);

        SetOpen(true);
    }

    const handleSave = async () => {
        // Create dto edit object

        let dtoEditObject = form.getFieldsValue(true);

        const modal = Modal.success({
            title: 'Saving',
            content: `Identity is being saved, please hold.`,
        });

        await APIT.putIdentityAccount2FA(IdentityID, "Microsoft365", dtoEditObject);

        modal.destroy();
        handleClose();

        props.onChange();
    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
        SetUserID(null);
    }

    useEffect(() => {
        form.resetFields();
    }, [Identity])

    return (
        <Drawer
            {...props}
            title="Add MFA Options"
            closable={IdentityID}
            width={800}
            closeable={true}
            onClose={handleClose}
            extra={
                <Space>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={handleSave}>Save</Button>
                </Space>
            }
            open={Open}>

            {!isLoading &&
                <Form
                    initialValues={Identity}
                    form={form}
                    labelAlign="left"
                    labelWrap={true}
                    labelCol={{span: 4}}>

                    <Tabs defaultActiveKey="MFA">
                        <Tabs.TabPane
                            tab={
                                <Space>
                                    <PhoneOutlined/>
                                    MFA
                                </Space>
                            }
                            key="taskitemprop">

                            <Form.Item name="Email" label="Email">
                                <Input disabled={true}/>
                            </Form.Item>

                            <Form.Item name="totpSecret" label="TOTP Secret">
                                <Input />
                            </Form.Item>

                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            }
        </Drawer>

    )

});

export default IdentityEdit2FA;