import React, {useContext} from 'react'
import {AppContext} from "../../AppProvider";
import Text from "antd/es/typography/Text";
import {Badge, Descriptions, Popover, Space, Spin} from "antd";
import APIProvider from "../../service/api2";
import DateTimeView from "../misc/DateTimeView";
import {useQuery} from "@tanstack/react-query";
import ContainerDisplay from "../containers/ContainerDisplay";

const AgentDisplay = (props) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // React Query
    const {
        data: Agent,
        isLoading: isLoading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["agent", props.Agent],
            queryFn: props.Agent !== undefined && props.Agent !== null ? async() => await API.getAgent(props.Agent) : undefined,
            enabled: props.Agent !== undefined && props.Agent !== null
        }
    );

    if(props.Agent === null)
    {
        return <></>
    }

    if(isLoading) {
        return <Space>
            <Spin />
            <Text type="secondary">{props.Agent}</Text>
        </Space>
    }

    if(Agent !== undefined)
    {
        return (
            <Popover
                content={
                    <Descriptions bordered column={1} size="small">
                        <Descriptions.Item label="Name">
                            {Agent.Name}
                        </Descriptions.Item>

                        <Descriptions.Item label="ID">
                            {Agent.id}
                        </Descriptions.Item>

                        <Descriptions.Item label="Container">
                            <ContainerDisplay Container={Agent.Container}/>
                        </Descriptions.Item>

                        <Descriptions.Item label="Tasks In Progress / Assigned">
                            {Agent.tasksInProgressCount} / {Agent.tasksAssigned}
                        </Descriptions.Item>

                        <Descriptions.Item label="Last Seen">
                            <DateTimeView DateTime={Agent.checkInTime}/>
                        </Descriptions.Item>
                    </Descriptions>
                }>

                {Agent.Healthy && <Badge status="success" text={Agent.Name}/>}

                {!Agent.Healthy && <Badge status="default" text={Agent.Name}/>}

            </Popover>
        );
    }

}
export default AgentDisplay