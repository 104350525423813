import React, {useContext} from 'react'
import {Spin, Tag} from "antd";
import {
    ApartmentOutlined,
} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";

interface PlaybookTextLabelProps {
    PlaybookId: string|undefined|null;
}

const PlaybookTextLabel = ({PlaybookId}:PlaybookTextLabelProps) => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    const {
        data: Attack,
        isLoading: isLoading
    } = useQuery(
        {
            queryKey: ["playbook", PlaybookId],
            enabled: PlaybookId !== undefined && PlaybookId !== null,
            queryFn: PlaybookId !== undefined && PlaybookId !== null ? async () => await APIT.playbookGet(PlaybookId) : undefined
        }
    );

    // Determine additional data to load

    let name = "Unknown";

    if(isLoading)
        return <Spin />

    return (
        <Tag icon={<ApartmentOutlined />}>{Attack?.Name}</Tag>
    )

}
export default PlaybookTextLabel;