import {Modal} from 'antd';

import React, {useEffect} from "react";
import Text from "antd/es/typography/Text";
import {ExclamationCircleOutlined} from "@ant-design/icons";


const CAttackWarning = (props) =>
{

    const [modal, contextHolder] = Modal.useModal();
    const { confirm } = Modal;

    function showConfirm() {
        confirm({
            title: 'Warning! Disclaimer!',
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Text strong>CAttack is not intended as a hacking tool, it is intended as a simulation tool. Subsequently, CAttack contains real malicious content intended to simulate bad activity within a tenant.</Text>
                    <br/><br/>
                    <Text>DO NOT run any content generated by CAttack. Ensure any environments are isolated from each other if needing to use the content in a simulation.</Text>
                </>
            ),
            onOk() {
                props.onOk();
            },
            onCancel() {
                props.onCancel();
            }
        });
    }


    useEffect(() => {
        showConfirm();
    })

    return null;
}

export default CAttackWarning;