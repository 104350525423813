// Builds the Navigation Bar based on roles

import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {createSidebar, findActiveItem} from "activate-menu-item";
import {Divider, Menu, Typography} from "antd";

import {
    ApartmentOutlined,
    ClockCircleOutlined,
    FileOutlined,
    HomeOutlined,
    LinkOutlined,
    PhoneOutlined, RocketOutlined,
} from "@ant-design/icons";
import useEffect from "rc-util/es/hooks/useEffect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBrain, faFlask} from '@fortawesome/free-solid-svg-icons'

const { SubMenu } = Menu;

// Builds the Nav bar based on the logged in user
const NavBar = (props) => {

    const [NavItems, setNavItems] = useState([]);
    const [DisabledNavItems, setDisabledNavItems] = useState([]);

    // Determine if is in role for menu item to display
    const isInRoles = (roles) => {
        let isInRole = false;

        if(Array.isArray(roles))
        {
            roles.forEach(r => {
                if(props.me.RoleGroups.includes(r)) {
                    isInRole = true;
                }
            });
        }

        return isInRole;
    }

    const allMenuItems = [
        {
            requireAny: ["Full Administrator", "Read Only Operator", "Container Administrator"],
            menuItem: {
                title: "Agents",
                path: "/agents",
                icon: <PhoneOutlined/>,
                activefor: [
                    {
                        path: "/agents"
                    }
                ]
            }
        },
        {
            requireAny: ["Full Administrator", "Read Only Operator", "Container Administrator"],
            menuItem: {
                title: "URLs",
                path: "/websites",
                icon: <LinkOutlined/>,
                activefor: [
                    {
                        path: "/websites"
                    }
                ]
            }
        },
        {
            requireAny: ["Full Administrator", "Read Only Operator", "Container Administrator"],
            menuItem: {
                title: "Attacks",
                path: "/attacks",
                icon: <RocketOutlined/>,
                activefor: [
                    {
                        path: "/attacks"
                    }
                ]
            }
        },
        {
            requireAny: ["Full Administrator", "Read Only Operator", "Container Administrator"],
            menuItem: {
                title: "Playbooks",
                path: "/playbooks",
                icon: <ApartmentOutlined/>,
                activefor: [
                    {
                        path: "/playbooks"
                    }
                ]
            }
        },
        {
            requireAny: ["Full Administrator", "Read Only Operator", "Container Administrator"],
            menuItem: {
                title: "Schedules",
                path: "/playbooksch",
                icon: <ClockCircleOutlined/>,
                activefor: [
                    {
                        path: "/playbooksch"
                    }
                ]
            }
        },
        {
            requireAny: ["Full Administrator", "Read Only Operator", "Payload Authors", "Container Administrator"],
            menuItem: {
                title: "Payloads",
                icon: <FileOutlined/>,
                children: [
                    {
                        title: "Sites",
                        path: "/site",
                        activefor: [
                            {
                                path: "/site"
                            }
                        ]
                    },
                    {
                        title: "Files",
                        path: "/filepayloads",
                        activefor: [
                            {
                                path: "/filepayloads"
                            }
                        ]
                    },
                    {
                        title: "File Templates",
                        path: "/filetemplate",
                        activefor: [
                            {
                                path: "/filetemplate"
                            }
                        ]
                    },
                    {
                        title: "Emails",
                        path: "/emailtemplate",
                        activefor: [
                            {
                                path: "/emailtemplate"
                            }
                        ]
                    },
                    {
                        title: "Phishlets",
                        path: "/proxyphishlets",
                        activefor: [
                            {
                                path: "/proxyphishlets"
                            }
                        ]
                    },
                ]
            }
        },
        {
            requireAny:  ["Full Administrator", "Read Only Operator", "Container Administrator"],
            menuItem: {
                title: "A.I",
                path: "/ai",
                icon: <FontAwesomeIcon icon={faBrain} />,
                activefor: [
                    {
                        path: "/ai"
                    }
                ]
            }
        },
        {
            requireAny: ["Full Administrator", "Read Only Operator", "Container Administrator"],
            menuItem: {
                title: "Labs",
                icon: <FontAwesomeIcon icon={faFlask} />,
                children: [
                    {
                        title: "Environments",
                        path: "/labs",
                        activefor: [
                            {
                                path: "/labs"
                            }
                        ]
                    },
                    {
                        title: "Users",
                        path: "/labuser",
                        activefor: [
                            {
                                path: "/labuser"
                            }
                        ]
                    }
                ]
            }
        },
        {
            requireAny: ["Full Administrator"],
            menuItem: {
                title: "Settings",
                icon: <FontAwesomeIcon icon={faFlask}/>,
                children: [
                    {
                        title: "Administrators",
                        path: "/admins",
                        activefor: [
                            {
                                path: "/admins"
                            }
                        ]
                    },
                    {
                        title: "Containers",
                        path: "/containers",
                        activefor: [
                            {
                                path: "/containers"
                            }
                        ]
                    },
                    {
                        title: "Tenants",
                        path: "/tenants",
                        activefor: [
                            {
                                path: "/tenants"
                            }
                        ]
                    },
                    {
                        title: "Identities",
                        path: "/identity",
                        activefor: [
                            {
                                path: "/identity"
                            }
                        ]
                    },
                    {
                        title: "Behaviours",
                        path: "/behaviour",
                        activefor: [
                            {
                                path: "/behaviour"
                            }
                        ]
                    },
                    {
                        title: "Domains",
                        path: "/domains",
                        activefor: [
                            {
                                path: "/domains"
                            }
                        ]
                    }
                ]
            }
        }
    ];

    useEffect(() => {
        const navEnabled = [{
            title: "Home",
            path: "/",
            icon: <HomeOutlined/>,
            activefor: [
                // this item active for '/' and '/home'
                {
                    path: "/"
                },
                {
                    path: "/home"
                }
            ]
        }];
        const navDisabled = [];

        if(props.me != null && props.me.RoleGroups != null) {

            allMenuItems.map((m) => {
                if(isInRoles(m.requireAny)===true)
                {
                    console.log("in role");
                    navEnabled.push(m.menuItem);
                } else {
                    console.log("not in role");
                    m.menuItem.path = undefined;
                    navDisabled.push(m.menuItem);
                }
            })

        }

        setNavItems(navEnabled);
        setDisabledNavItems(navDisabled);

    }, [props.me])

    const ItemSidebar = ({ data, ...others }) => {
        // dont forget to pass others data to return component before key property
        return (
            <Menu.Item {...others} key={data.path} icon={data.icon}>
                <Link to={data.path} style={{ textDecoration: 'none' }}>{data.title}</Link>
            </Menu.Item>
        );
    };

    const SubmenuSidebar = ({ data, ...others }) => {
        // dont forget to pass others data to return component before key property
        return (
            <SubMenu {...others} key={data.id} title={data.title} icon={data.icon}>
                {others.children}
            </SubMenu>
        );
    };

    const location = useLocation();
    const { activePath, openKeysItems } = findActiveItem(
        NavItems,
        location.pathname
    );

    return (
        <>
            <Menu defaultOpenKeys={openKeysItems} selectedKeys={[activePath]} mode="inline">
                {createSidebar(ItemSidebar, SubmenuSidebar, NavItems)}
            </Menu>
            <Divider></Divider>
            <Menu defaultOpenKeys={openKeysItems} selectedKeys={[activePath]} mode="inline" disabled={true}>
                {createSidebar(ItemSidebar, SubmenuSidebar, DisabledNavItems)}
            </Menu>
        </>

    );
}

export default NavBar;