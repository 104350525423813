import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {Alert, Button, Space, Tabs} from "antd";
import {GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import APITProvider from "../../../service/api";
import {AppContext} from "../../../AppProvider";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import PageHeader from "../../misc/PageHeader";
import LabEnvironmentTable from "./components/LabEnvironmentTable";

const LabEnvironmentPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refDrawerLabAdd = useRef<DrawerRef>(null);

    const {
        data: Labs,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["labs"],
        async () => await APIT.labsGet()
    );

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Lab Environments"
            />
            <LabEnvironmentTable />
        </>

    )
}

export default LabEnvironmentPage;