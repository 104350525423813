import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import { Button,  Space, Table, Typography} from "antd";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import DrawerRef from "../../../../classes/DrawerRef/DrawerRef";
import LabEnvironmentAddDrawer from "./LabEnvironmentAddDrawer";
import {PlusCircleOutlined} from "@ant-design/icons";
import {faFlask} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const LabEnvironmentTable = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refEnvironmentAddDrawer = useRef<DrawerRef>(null);

    const {
        data: Labs,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["labs"],
        async () => await APIT.labsGet()
    );

    // return on change properties

    return (
        <>

            <LabEnvironmentAddDrawer ref={refEnvironmentAddDrawer} onChange={refetch} />

            <Button icon={<PlusCircleOutlined />} onClick={() => refEnvironmentAddDrawer.current !== null ? refEnvironmentAddDrawer.current.showDrawer() : null}>Add</Button>

            <Table
                dataSource={Labs}
                rowKey="id"
                pagination={false}
                loading={isLoading}
                columns={[
                    {
                        title: "Name",
                        key: 'Name',
                        width:400,
                        render: (record) => {
                            return <Space>
                                <FontAwesomeIcon icon={faFlask} />
                                <Typography.Text>{record.Name}</Typography.Text>
                            </Space>
                        },
                    },
                    {
                        title: "License Usage",
                        key: 'Message',
                        dataIndex: 'Message',
                        render: (text) => text,
                    }
                ]}
            />
        </>
    )
}

export default LabEnvironmentTable;