import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Drawer} from "antd";
import TestConditionRule from "../../../../Models/TestConditions/TestConditionRule";
import PlaybookTestsForm from "./PlaybookTestsForm";

interface PlaybookTestsDrawerRef {
    showDrawer: any
}
interface PlaybookTestsDrawerProps {
    Title: string,
    onChange: any,
    PlaybookVariables: any,
    DependencyVariables: any
}

const PlaybookTestsDrawer = forwardRef<PlaybookTestsDrawerRef,PlaybookTestsDrawerProps>((props:PlaybookTestsDrawerProps, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // State
    const [Open, SetOpen] = useState(false);
    const [DrawerTitle,SetDrawerTitle] = useState<string>("");
    const [Rule, SetRule] = useState<TestConditionRule>();

    const showDrawer = (val:TestConditionRule | undefined) => {
        SetRule(val);
        SetOpen(true);
    }

    const handleClose = () => {
        SetRule(undefined);
        SetOpen(false)
    };

    const handleSave = (val: TestConditionRule) => {
        if(props.onChange)
            props.onChange(val);

        handleClose();
    }

    return <Drawer
        placement="right"
        open={Open}
        width={600}
        onClose={handleClose}
        title={props.Title}>

        <PlaybookTestsForm Rule={Rule} onChange={handleSave} PlaybookVariables={props.PlaybookVariables} DependencyVariables={props.DependencyVariables}/>

    </Drawer>

});
export default PlaybookTestsDrawer;