import React, {useContext, useRef} from "react";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import {useQuery} from "@tanstack/react-query";
import {Card, Form, Input, Modal} from "antd";
import IdentityInput from "../../../PlaybookInputs/Identity/IdentityInput.react";
import {SaveOutlined} from "@ant-design/icons";

interface AgentEditSetupTabProps {
    AgentID:string;
}

const AgentEditSetupTab =
    ({AgentID}: AgentEditSetupTabProps ) => {

        // Construct token
        const {token} = useContext(AppContext);
        const APIT = new APITProvider(token, true);

        // Forms
        const [form] = Form.useForm();

        const {
            data: AgentInteractiveSetup,
            isLoading: Loading,
            refetch: refetch,
        }  = useQuery({
                queryKey: ["AgentInteractiveSetup", AgentID],
                queryFn: async() => await APIT.getAgentInteractiveSetup(AgentID),
                enabled: AgentID !== undefined && AgentID !== null ? true : false
            }
        );

        const handleSave = () => {
            const modal = Modal.success({
                title: 'Saving',
                content: `Saving client options`,
            });

            let formVals = form.getFieldsValue();

            APIT.putAgentInteractiveSetup(AgentID,formVals).then(() => modal.destroy());

        }

        return <Card
            title="Interactive Client Options"
            loading={Loading}
            actions={[
                <SaveOutlined key="save" title="Save" label="Save" onClick={handleSave}/>,
                     ]}>
            {!Loading &&
                <Form initialValues={AgentInteractiveSetup} form={form} layout="vertical">
                    <Form.Item name="Win32AutoLogonTenantUser" label="Auto logon as user" tooltip="Causes windows agents to auto logon as this managed cattack user">
                        <IdentityInput />
                    </Form.Item>

                    <Form.Item name="Win32AutoLogonForceUsername" label="Username override" tooltip="Only applicable if Auto logon selected. Allows username override">
                        <Input />
                    </Form.Item>
                </Form>
            }
        </Card>
    }

export default AgentEditSetupTab;