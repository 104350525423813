import React from "react";
import axios from "axios";
import {message} from "antd";
import Admin from "../Models/Admin/Operator";
import Attack from "../Models/Attack/Attack";
import InFlightEmail from "../Models/InFlight/InFlightEmail";
import Cookies from "js-cookie";
import DefenderIncidentMap from "../Models/DefenderMapping/DefenderIncidentMap";
import Tenant from "../Models/Tenants/Tenant";
import dtoTenantEdit from "../Models/Tenants/dtoTenantEdit";
import Domain from "../Models/Domain/Domain";
import Identity from "../Models/Identity/Identity";
import IdentityRelationship from "../Models/Identity/IdentityRelationship";
import ProxyPhishlet from "../Models/ProxyPhishlet/ProxyPhishlet";
import WebSession from "../Models/WebSession/WebSession";
import Website from "../Models/Websites/Website";
import WebRequest from "../Models/WebRequest/WebRequest";
import Text from "antd/es/typography/Text";
import cattackFile from "../Models/File/cattackFile";
import cattackTask from "../Models/Task/cattaskTask";
import TemplateEmail from "../Models/TemplateEmail/TemplateEmail";
import TaskLog from "../Models/Task/TaskLog";
import AgentInteractiveSetup from "../Models/Agent/AgentInteractiveSetup";
import dtoObjectHealthResult from "../Models/Health/dtoObjectHealthResult";
import CGContext from "../Models/CGContent/CGContext";
import LabEnvironment from "../Models/Labs/LabEnvironment";
import LabUser from "../Models/Labs/LabUser";
import DtoLabUserEdit from "../Models/Labs/dtoLabUserEdit";
import LabUserAttestation from "../Models/Labs/LabUserAttestation";
import dtoLabUserNew from "../Models/Labs/dtoLabUserNew";
import dtoContainerStat from "../Models/Container/dtoContainerStat";
import dtoIdentity2FA from "../Models/Identity/dtoIdentity2FA";
import dtoAttackTcr from "../Models/Attack/dtoAttackTcr";
import dtoPlaybook from "../Models/Playbooks/dtoPlaybook";

export default class APITProvider
{
    constructor(token: string, useMessageErrorHandler: boolean) {
        this.token = token;
        this.useMessageErrorHandler = useMessageErrorHandler;

        if(this.urlApi === undefined)
        {
            throw "API URL is empty";
        }
    }

    // Token
    private token: string;

    // Handle errors here
    private useMessageErrorHandler: boolean;

    // API URL
    private urlApi : string | undefined = process.env.REACT_APP_urlApi;

    GetSetContainer()
    {
        return Cookies.get('container');
    }

    getHeaders()
    {
        return {
            'X-Token': this.token,
            'Content-Type': "application/json",
            'Container': this.GetSetContainer()
        }
    }

    handleError(error:any, rejector:any) {
        console.dir(error);

        let MainText = "Unhandled";
        let Message  = ":( Oh oh! Unhandled error occurred!";

        if(error !== undefined)
        {

            if(error.response !== undefined && error.response.data !== undefined)
            {

                // Specific response code handling
                if(error.response.status === 401) {
                    // Default message
                    MainText = "Unauthorized";
                    Message = "Unauthorized. You may not have the permissions required to perform this operation :(";
                } else if (error.response.status===400) {
                    MainText = "Bad Request";
                    Message = "Something that you tried to do wasn't right. The API didn't tell us what it was though, this is a bug, log it. :("
                } else if (error.response.status===404) {
                    MainText = "Not Found";
                    Message = "We tried to fetch something that the API said didn't exist. Maybe it's been deleted, or maybe it's in another container"
                }

                if (typeof error.response.data === "string") {
                    Message = error.response.data;
                }

            }


        }

        if(this.useMessageErrorHandler)
        {
            let messageConfig = {
                content: <span><Text strong>{MainText} </Text><Text>{Message}</Text> </span>,
                duration: 15,
            }
            message.error(messageConfig);
        }


    }

    thisContainerStatGet() : Promise<dtoContainerStat>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Container/" + this.GetSetContainer() + "/Stat", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    // Get Content Generation Contexts
    CGContextsGet() : Promise<CGContext[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "CGContext", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    CGContextsPost(dto:CGContext) : Promise<CGContext>
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "CGContext", dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    //// IDENTITY
    // Get Identity by ID
    identityGet(id: string) : Promise<Identity>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Identity/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    putIdentityAccount2FA(id: string, account: string, dto: dtoIdentity2FA) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Identity/" + id + "/account/" + account + "/mfa", dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Get Attack by ID
    getAttack(id: string) : Promise<Attack>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getAttackTcr(id: string) : Promise<dtoAttackTcr>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/tcr", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }


    // Get Attack Emails by ID
    getAttackEmails(id: string) : Promise<InFlightEmail>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/emails", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Get Attack Files by ID
    getAttackFiles(id: string) : Promise<cattackFile[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/files", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Get Incidents correlated to Attack ID
    getAttackDefenderIncidents(id: string) : Promise<DefenderIncidentMap[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Attack/" + id + "/defenderincidents", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Resume task
    taskResume(id: string)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Task/" + id + "/resume",null, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    taskGet(id: string)  : Promise<cattackTask>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Task/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Get Task Log
    getTaskLog(id: string) : Promise<TaskLog[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "task/" + id + "/log", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Get Tenants
    getTenants() : Promise<Tenant[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Tenant", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Delete tenant
    deleteTenant(id:string)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Tenant/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getTenant(id: string) : Promise<Tenant>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Tenant/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Edit tenant
    putTenant(id: string, dto: dtoTenantEdit) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Tenant/" + id, dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getAdmins() : Promise<Admin[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Operator", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getAgentInteractiveSetup(id: string) : Promise<AgentInteractiveSetup>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Agent/" + id + "/interactivesetup", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    putAgentInteractiveSetup(id: string, data: AgentInteractiveSetup) : Promise<AgentInteractiveSetup>
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Agent/" + id + "/interactivesetup", data,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    putHealthCheck(type: string, id: string) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "objecthealth/" + type + "/" + id, null,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getHealth(type: string, id:string) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "objecthealth/" + type + "/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    getHealthDetail(type: string, id:string) : Promise<dtoObjectHealthResult>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "objecthealth/" + type + "/" + id + "/detail", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Get a list of relationships for this identity
    getIdentityRelationships(id:string) : Promise<IdentityRelationship[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Identity/" + id + "/relationship", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    postIdentityRelationship(SourceUser:string, TargetUser: string)
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "identity/" + SourceUser + "/relationship/" + TargetUser, null,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    putIdentityUnsecure(User:string, Setting: boolean)
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "identity/" + User + "/unsecure/" + Setting, null,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deleteIdentityRelationship(SourceUser:string, TargetUser: string)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "identity/" + SourceUser + "/relationship/" + TargetUser,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getIdentities() : Promise<Identity[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Identity", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getDomains() : Promise<Domain[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Domain", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getProxyPhishlets() : Promise<ProxyPhishlet[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "ProxyPhishlet", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    getProxyPhishlet(id: string) : Promise<ProxyPhishlet>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "ProxyPhishlet/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    postPhishlet(vals: ProxyPhishlet) {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "ProxyPhishlet", vals,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    phishletPut(id: string, vals: ProxyPhishlet) {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "ProxyPhishlet/" + id, vals,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    deleteProxyPhishlet(id: string) {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "ProxyPhishlet/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    WebsiteSessionsGet(WebsiteID: string) : Promise<WebSession[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "website/" + WebsiteID + "/sessions", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    WebsiteSessionRequestsGet(WebsiteID: string, SessionID: string) : Promise<WebRequest[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "website/" + WebsiteID + "/session/" + SessionID + "/requests", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    WebsiteSessionCookiesGet(WebsiteID: string, SessionID: string) : Promise<WebSession[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "website/" + WebsiteID + "/session/" + SessionID + "/cookies", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    websitesGet() : Promise<Website[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "website", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    websiteGet(id: string) : Promise<Website>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "website/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    domainsGet() : Promise<Domain[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Domain", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    playbookGet(id: string) : Promise<dtoPlaybook>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Playbook/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    playbookEmailsGet(id: string) : Promise<TemplateEmail[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Playbook/" + id + "/payloads/emails", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    // Get the domain by ID
    domainGet(id: string) : Promise<Domain>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "Domain/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Domain delete
    domainDelete(id: string)
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "Domain/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }


    domainPut(id: string, dto: Domain) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Domain/" + id, dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    domainDeployPut(id: string) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "Domain/" + id + "/manage/deploy", null,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }



    domainPost(dto: Domain) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Domain", dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    // Get the domain by ID
    labsGet() : Promise<LabEnvironment[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "LabEnvironment", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    labPost(dto: LabEnvironment) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "LabEnvironment", dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    labUsersGet() : Promise<LabUser[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "LabUser", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    myLabUsersGet() : Promise<LabUser[]>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "LabUser?my=true", {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    labUserGet(id: string) : Promise<LabUser>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "LabUser/" + id, {
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    labUserDelete(id: string) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.delete(this.urlApi + "LabUser/" + id, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    labUserAttestationGet(id: string) : Promise<LabUserAttestation>
    {
        return new Promise((resolve, reject) => {

            axios.get(this.urlApi + "LabUser/" + id + "/attest", {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    labUserPut(id: string, dto: DtoLabUserEdit) : Promise<LabUser>
    {
        return new Promise((resolve, reject) => {

            axios.put(this.urlApi + "LabUser/" + id, dto, {
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }

    labUserPost(dto: dtoLabUserNew) : Promise<boolean>
    {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "LabUser", dto,{
                headers: this.getHeaders()
            })
                .then((res) => {
                    resolve(true)
                })
                .catch((error) => {
                    this.handleError(error,reject); reject();
                })

        })
    }

    async adminPost(dto: any) {
        return new Promise((resolve, reject) => {

            axios.post(this.urlApi + "Operator", dto,{
                headers: this.getHeaders()
            })
            .then((res) => {
                resolve(true)
            })
            .catch((error) => {
                this.handleError(error,reject); reject();
            })

        })
    }
}