import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Alert,
    Button, Form, Input, Select, Space, Switch, Table,
} from "antd";
import {useForm} from "antd/es/form/Form";

const PlaybookIncidentOptions = ({IncidentOptions, onChange}) => {

    const [form] = useForm();

    const handleChange = () => {
        onChange(form.getFieldsValue());
    }

    return (
        <>
            <Alert
                message="Defender Incident Options"
                description="When attacks are ran in tenants using playbooks, CAttack correlates the evidence inside them. These options below allow you to customise the defender incident that has been correlated to an attack ran using this playbook."
                type="info"
                style={{marginBottom: 20}}
                showIcon
                closable={true}
            />

            <Form
                form={form}
                labelWrap={true}
                initialValues={IncidentOptions}
                onChange={handleChange}
                labelAlign="left" labelCol={{span: 6}}>

                <Form.Item name="AddTags" label="Add tags when correlation occurs" tooltip="These tags are added to the incident after correlation">
                    <Select mode="tags" onChange={handleChange}/>
                </Form.Item>

                <Form.Item name="Rename" label="Rename incident (Currently not working)" tooltip="Renames incidents correlated to whats in this input">
                    <Input />
                </Form.Item>

                <Form.Item name="ClosePreviousIncidents" label="Close Previous Incidents"  tooltip="Closes off incidents in the tenant that were created based on this playbook.">
                    <Switch />
                </Form.Item>
            </Form>
        </>
    )
};

export default PlaybookIncidentOptions;