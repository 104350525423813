import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Alert, Button, Col, Drawer, Row, Select, Space, Table} from "antd";
import Text from "antd/es/typography/Text";
import {CloseOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import APITProvider from "../../../service/api";

interface IdentityRelationshipDrawerRef {
    showDrawer: any
}

interface IdentityRelationshipDrawerProps {
    Title: string,
    onChange: any
}

const IdentityRelationshipDrawer = forwardRef<IdentityRelationshipDrawerRef,IdentityRelationshipDrawerProps>((props:IdentityRelationshipDrawerProps, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [UserID, SetUserID] = useState<string|undefined>(undefined);
    const [SelectedAdmin, SetSelectedAdmin] = useState<string|undefined>(undefined);
    const [ReturnFunc, SetReturnFunc] = useState<any>();
    const [ActionInProgress, SetActionInProgress] = useState<boolean>(false);
    const [AddDisabled, SetAddDisabled] = useState<boolean>(true);
    const [RelationshipsSelected, SetRelationshipsSelected] = useState<any>([]);
    const [DrawerTitle,SetDrawerTitle] = useState<string>("");

    // Funcs
    const showDrawer = (uid:string) => {
        SetDrawerTitle(uid + " relationships");
        SetUserID(uid);
        handleAdminSelectionChange(undefined);
        SetOpen(true);
    }

    // React queries
    const {
        data: Identities,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["identities"],
        async () => await APIT.getIdentities()
    );

    const {
        data: Relationships,
        isLoading: isLoadingRelationships,
        refetch: refetchRelationships
    } = useQuery(
        ["relationships", UserID],
        async () => UserID != undefined ? await APIT.getIdentityRelationships(UserID) : []
    );

    const handleAdd = () => {
        if(SelectedAdmin !== undefined && UserID !== undefined)
        {
            SetActionInProgress(true);
            APIT.postIdentityRelationship(UserID,SelectedAdmin).then(() => {
                refetchRelationships().then(() => {
                    SetActionInProgress(false);
                });
            })
        }

        // Reset selection
        handleAdminSelectionChange(undefined);
    }

    const handleRemove = (id:string) => {
        if(id !== undefined && UserID !== undefined)
        {
            SetActionInProgress(true);
            APIT.deleteIdentityRelationship(UserID,id).then(() => {
                refetchRelationships().then(() => {
                    SetActionInProgress(false);
                });
            })
        }
        //props.onChange(Relationships.filter(item => item !== id));
    }

    const handleAdminSelectionChange = (id:string|undefined) => {
        SetAddDisabled(!(id!=="undefined" && id !== ""))
        SetSelectedAdmin(id);
    }

    const handleClose = () => SetOpen(false);

    useEffect(() => {
        if(Relationships!==undefined)
        {
            SetRelationshipsSelected(Relationships.map(x => x.id));
        }
    },[Relationships]);

    return (
        <Drawer placement="right" open={Open} width={600} onClose={handleClose} title={DrawerTitle}>
            <Space direction="vertical" style={{display: 'flex'}}>
                <Alert
                    message="About relationships"
                    description="Relationships between users allows these users to communicate in simulations. Without a relationship, nothing will happen on these user accounts in a simulation."
                    type="info"
                    showIcon
                    closable={true}
                />
                <Row gutter={5}>
                    <Col span={19}>
                        <Select
                            showSearch
                            optionLabelProp="label"
                            optionFilterProp="children"
                            filterOption={(input, option:any) =>
                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select a user"
                            value={SelectedAdmin}
                            onChange={(x) => handleAdminSelectionChange(x)}>

                            {
                                Identities && Identities.map((u, i) => {

                                    let Show = (u.id !== UserID);

                                    // already in list?
                                    if(Show)
                                    {
                                        if(Array.isArray(Relationships))
                                        {
                                            if(RelationshipsSelected.findIndex((x: string) => x === u.id) > -1)
                                            {
                                                Show = false;
                                            }
                                        }
                                    }

                                    // only show if i can
                                    if(Show === true)
                                    {
                                        return (
                                            <Select.Option value={u.id} label={u.id}>
                                                <Text>{u.id}</Text>
                                            </Select.Option>
                                        )
                                    }
                                })
                            }

                        </Select>
                    </Col>
                    <Col>
                        <Button onClick={handleAdd} disabled={AddDisabled}>Add</Button>
                    </Col>

                </Row>

                <Table
                    pagination={{ pageSize: 15 }}
                    dataSource={RelationshipsSelected}
                    rowKey="id"
                    showHeader={false}
                    loading={isLoadingRelationships || ActionInProgress}
                    columns={[
                        {
                            key: 'id',
                            render:(id) => <Text>{id}</Text>
                        },
                        {
                            key:'remove',
                            render:(id) => {
                                return <CloseOutlined onClick={() => handleRemove(id)}/>
                            }
                        }
                    ]}
                />
            </Space>
        </Drawer>
    )

});

export default IdentityRelationshipDrawer;