import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Col, Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Tag
} from "antd";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import {FileOutlined, PlusOutlined} from "@ant-design/icons";

const FileReferenceTemplateSelector = ({value, onChange, onTemplateCapabilityChange}) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const {
        data: Templates,
        isLoading: isLoading
    } = useQuery(
        ["filetemplates"],
        async () => await API.getFileTemplates()
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [innerForm] = useForm();

    useEffect(() => {
        if(value !== null && value !== undefined && !isLoading && onTemplateCapabilityChange !== undefined)
        {
            // Add capability of file
            let fileSelected = Templates.find(x => x.id == value.TemplateID);

            onTemplateCapabilityChange(fileSelected.Flags);
        }
    },[Templates, onTemplateCapabilityChange]);

    const onFinish = (values) => {



        // Create a file reference object
        let fileReference = {
            "$type": "FileRefHostedTemplate",
            "TemplateID": values.File
        }

        console.dir(fileReference);

        // Add capability of file
        let fileSelected = Templates.find(x => x.id == values.File);

        if(onTemplateCapabilityChange !== undefined)
        {
            console.dir(fileSelected);

            onTemplateCapabilityChange(fileSelected.Flags);
        }

        onChange(fileReference);

        setIsModalOpen(false);
    }

    if (onChange !== undefined) {
        return (
            <>
                <Modal title="Select File Template" open={isModalOpen} onOk={innerForm.submit} onCancel={handleCancel}>
                    <Form
                        form={innerForm}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        onFinish={onFinish}>

                        <Form.Item name="File" label="Hosted File" rules={[{required: true, message: 'Please select a file'}]}>
                            <Select
                                style={{width: '100%'}}
                                placeholder="Select a base file"
                                optionLabelProp="label">
                                {Templates && Templates.map((f, i) => {

                                    console.dir(f);

                                    const tagName = "Normal";
                                    const tagColor = "default";
                                    const modificationOptions = "";
                                    let Flags = [];

                                    if (f.Flags & 1) {
                                        Flags.push({
                                            Name: "Text",
                                        });
                                    }

                                    return (
                                        <Select.Option value={f.id} label={
                                            <Row gutter={5} align="middle">
                                                <Col>
                                                    <strong>{f.Name}</strong>
                                                </Col>
                                                <Col>
                                                    {
                                                        Flags.map((flag, i) => {
                                                            return (
                                                                <Tag color={flag.Color}
                                                                     key={flag.Name}>{flag.Name}</Tag>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                        }>
                                            <Row>
                                                <strong>{f.Name}</strong>
                                            </Row>
                                            <Row>
                                                {f.Description}
                                            </Row>
                                            <Row>
                                                {
                                                    Flags.map((flag, i) => {
                                                        return (
                                                            <Tag color={flag.Color}>{flag.Name}</Tag>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Select.Option>
                                    )
                                })
                                }

                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                <>

                    {!value &&
                        <Tag onClick={() => setIsModalOpen(true)} className="site-tag-plus"
                             style={{padding: 10, borderStyle: "dashed", background: "#fff"}}>
                            <PlusOutlined/> Select File
                        </Tag>
                    }

                    {value &&
                        <Tag icon={<FileOutlined/>} style={{padding: 10}} closable={true}
                             onClose={() => onChange(undefined)}>{value.FileID}</Tag>
                    }

                    <Divider type="vertical"/>

                </>

            </>
        )
    }

};

export default FileReferenceTemplateSelector;