import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import PageHeader from "../misc/PageHeader";
import AgentAddDrawer from "./AgentAdd/AgentAddDrawer";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import AgentTable from "./AgentTable";

const AgentsPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // Refs
    const refDrawerAgentAdd = useRef<DrawerRef>(null);

    const {
        data: Agents,
        isLoading: isLoading,
        refetch: refetch
    } = useQuery(
        ["agents"],
        async () => await API.getAgents()
    );

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Agents"
                Buttons={
                    <Button icon={<PlusCircleOutlined />} onClick={() => refDrawerAgentAdd.current !== null ? refDrawerAgentAdd.current.showDrawer() : null}>Add</Button>
                }
            />

            <AgentAddDrawer ref={refDrawerAgentAdd} />

            <AgentTable
                Agents={Agents}
                onChange={() => refetch()}
                Loading={isLoading}                //onDelete={playbookDelete}
                //onPlaybookEdit={handleEdit}
                //onPlaybookRun={(id) => PlaybookRunModal.current.run(id)}
            />
        </>

    )
}

export default AgentsPage;