import React, {useEffect, useRef, useState} from "react";
import {Button, Dropdown, Modal, Space, Table, Typography} from "antd";
import PageHeader from "../../misc/PageHeader";
import TestConditionRule from "../../../Models/TestConditions/TestConditionRule";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import LabEnvironmentAddDrawer from "../../lab/environment/components/LabEnvironmentAddDrawer";
import PlaybookTestsDrawer from "./PlaybookTestsDrawer/PlaybookTestsDrawer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask} from "@fortawesome/free-solid-svg-icons";
import {
    CheckSquareOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {AddOutlined} from "@mui/icons-material";

interface PlaybookTestsPageProps {
    Tests: TestConditionRule[],
    onChange: (rules: TestConditionRule[]) => void;
    PlaybookVariables: any;
    DependencyVariables:any
}

const PlaybookTestsPage = (props:PlaybookTestsPageProps) => {

    const refDrawerTestAddEdit = useRef<DrawerRef>(null);

    // Temp
    const [Rules,SetRules] = useState<TestConditionRule[]>(props.Tests);

    // Open drawer for new test condition
    const handleOpenNew = () => {
        if(refDrawerTestAddEdit.current)
            refDrawerTestAddEdit.current.showDrawer();
    }

    const handleChange = (val:TestConditionRule) => {
        SetRules((prevRules) => {

            if(!Array.isArray(prevRules))
                prevRules = [];

            const index = prevRules.findIndex(item => item.id === val.id);
            if (index !== -1) {
                // Update the existing item
                return prevRules.map(item =>
                    item.id === val.id ? val : item
                );
            } else {
                // Add the new item
                return [...prevRules, val];
            }
        });
    }

    const handleRemove = (id:string) => {

    }

    useEffect(() => {
        if(props.onChange !== undefined)
        {
            props.onChange(Rules);
        }
    }, [Rules]);

    return (<>
        <PageHeader Buttons={<Button onClick={() => handleOpenNew()} > Add Test Rule </Button>} />
        <PlaybookTestsDrawer ref={refDrawerTestAddEdit} Title={"New"} onChange={(val:TestConditionRule) => handleChange(val)} DependencyVariables={props.DependencyVariables} PlaybookVariables={props.PlaybookVariables}/>
        <Table dataSource={Rules}
               rowKey="id"
               pagination={false}
               columns={[
                   {
                       title: "Description",
                       key: 'Description',
                       render: (record) => <Typography.Text>{record.Description}</Typography.Text>
                   },
                   {
                       title: "Query",
                       key: 'Query',
                       render: (record) => <Typography.Text type={"secondary"}>{record.Query}</Typography.Text>
                   },
                   {
                       key:'actions',
                       render:(record) => {

                           let items = [
                               {
                                   label: 'Edit',
                                   icon: <EditOutlined />,
                                   key: 'edit',
                               },
                               {
                                   label: 'Delete',
                                   icon: <DeleteOutlined/>,
                                   key: 'Delete',
                               }
                           ];

                           const onClick = (key:any) => {

                               if(key.key==="edit")
                               {
                                   if(refDrawerTestAddEdit.current)
                                       refDrawerTestAddEdit.current.showDrawer(record);
                               }

                               if(key.key==="Delete")
                               {
                                   // Confirm delete
                                   Modal.confirm({
                                       title: 'Confirm delete',
                                       icon: <ExclamationCircleOutlined />,
                                       content: <>
                                           <Text>Deleting </Text>
                                           <Text strong>{record.id} </Text>
                                           <Text>is permanent.</Text>
                                       </>,
                                       okText: 'Delete',
                                       cancelText: 'Cancel',
                                       onOk: () => handleRemove(record.id)
                                   });
                               }
                           }

                           return (

                               <Dropdown menu={{ items, onClick }}>
                                   <Button onClick={(e) => e.preventDefault()}>
                                       <DownOutlined />
                                   </Button>
                               </Dropdown>
                           )
                       }
                   }
                   ]}
        />
    </>);
}

export default PlaybookTestsPage;