import React, {useContext, useEffect, useState} from "react";
import {Divider, Dropdown, Menu, Space, Switch} from "antd";
import {CodeSandboxOutlined, LogoutOutlined} from "@ant-design/icons";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import {AppContext} from "./AppProvider";

const { Item, SubMenu } = Menu;

export const MenuItems = (props) => {

    const {DarkMode, SetDarkMode} = useContext(AppContext);

    const handleClick = (e) => {
        if(e.key === "ContainerSwitch")
        {
            props.onContainerSwitch();
        }
        if(e.key === "Logout")
        {
            props.onLogout();
        }
    }

    const menu = (
        <Menu onClick={(e) => handleClick(e)}>
            <Menu.Item icon={<CodeSandboxOutlined/>} key="ContainerSwitch">
                    Switch Containers
            </Menu.Item>
            <Menu.Item icon={<LogoutOutlined />} key="Logout">
                    Log out
            </Menu.Item>
            <Divider>Options</Divider>
            <Space style={{paddingLeft:5,paddingBottom:5}}>
                <Switch size="small" checked={DarkMode} onChange={SetDarkMode} />
                <Text>Dark Mode</Text>
            </Space>
        </Menu>
    );


    const [avatar, setAvatar] = useState(null);
    const [firstname, setFirstname] = useState(null);

    useEffect(() => {
        if(props.me !== undefined && props.me != null)
        {
            setFirstname(props.me.firstname);
            if(props.me.Avatar != null && props.me.Avatar !== undefined)
            {
                setAvatar(props.me.Avatar);
            }
        }
    }, [props.me])

    return (

        <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomLeft">
            <div onClick={e => e.preventDefault()}>
                <Space size="middle">
                    <Text type="secondary">{props.Container}</Text>
                    <Avatar
                        size="large"
                        src={avatar}
                    />
                </Space>
            </div>
        </Dropdown>

    );
};

export default MenuItems;