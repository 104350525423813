// @flow

import React, {Component} from "react";


import {Alert, Form, Input, Modal, Select, Tabs, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';

class FilePayloadAddModal extends Component {
  state = {
    ID: null,
    ModalOpen: false,
    Name:null,
    FileB64: null,
    MimeType: null,
    Description: null,
    FlagsText: [],
    FileType: [],
    FlagsNum: 0,
    fileList: [],
    New: true,
    Edit: false
    }

  getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  handleFileInputChange = e => {

    let { file } = this.state;

    file = e.file;
    this.setState({MimeType: file.type})

    this.getBase64(file)
      .then(result => {
        this.setState({FileB64: result.split(",")[1]});
      })

    this.setState({
      file: file
    });

  };

  fileinputChange(e)
  {

    
  }

  Close()
  {
    this.setState({ModalOpen: false});
  }

  Open(file)
  {

    if(file==null)
    {
      this.setState({New: true});
      this.setState({Edit: false});

      this.setState({ID: null});
      this.setState({Name: ""});
      this.setState({FileB64: null});
      this.setState({Description: ""});
      this.setState({MimeType: ""});
      this.setState({fileList: []});
      this.setState({FlagsNum: 0});
      this.setState({FlagsText: []});
      this.setState({ModifiersArray: ["1"]});
      this.setState({ModifiersNum: 1})
      this.setState({patchOffset: 0})

    }
    else
    {

      console.dir(file);

      this.setState({ID: file.id});
      this.setState({Name: file.name});
      this.setState({FileB64: null});
      this.setState({Description: file.description});
      this.setState({MimeType: file.mime});
      this.setState({FileType: file.fileType});
      this.setState({fileList: []});
      this.setState({FlagsNum: file.flags});
      this.setState({patchOffset: file.patchOffset})

      this.setState({New: false});
      this.setState({Edit: true});

      // build the modifiers array

      const newModifiersArray = [];
      if(file.modificationOptions & 1) { newModifiersArray.push("1")};
      if(file.modificationOptions & 2) { newModifiersArray.push("2")};
      if(file.modificationOptions & 4) { newModifiersArray.push("4")};
      if(file.modificationOptions & 8) { newModifiersArray.push("8")};
      if(file.modificationOptions & 16) { newModifiersArray.push("16")};
      if(file.modificationOptions & 64) { newModifiersArray.push("64")};

      this.setState({ModifiersArray: newModifiersArray});
      this.setState({ModifiersNum: file.modificationOptions});

    }

    this.setState({ModalOpen: true});
  }

  buildPayload(newFile)
  {

    // Scaffold payload with properties applicable to both edits and new files
    const payload = {
        Description: this.state.Description,
        name: this.state.Name,
        FileType: this.state.FileType,
        data: this.state.FileB64
    };

    if(newFile)
    {
     // applicable for creating a new file
      payload.id = this.state.ID;
      payload.mime = this.state.MimeType;
      payload.patchOffset = this.state.patchOffset;
      payload.ModificationOptions = this.state.ModifiersNum;
    }

    return payload;
  }

  onOK()
  {
    if(this.state.Edit === true)
    {
      this.props.onFileEdit(this.state.ID,this.buildPayload());
    }
    else
    {
      this.props.onFileAdd(this.buildPayload(true));
    }

    this.Close();
  }

  changeModifiers(e)
  {
    console.log(e);

    this.setState({ModifiersArray: e});

    let newFlagsNum = this.state.ModifiersNum;

    // supported flags for removal if not in e
    const supportedFlags = ["1", "2", "4", "8", "64"];

      // add flags
    e.forEach(function(number) {
      // convert to int and bitwise add
      newFlagsNum |= parseInt(number);
    });

    // remove flags
    supportedFlags.forEach(function(number) {
      if(!e.includes(number))
      {
        newFlagsNum &= ~parseInt(number);
      }
    });

    console.log(newFlagsNum);

    this.setState({ModifiersNum: newFlagsNum});


  }


  changeFlags(e)
  {

    this.setState({FileType: e});

  }

  render() {

    let RequireOffset = false;

    if(this.state.ModifiersNum & 16)
    {
      RequireOffset = true;
    }

    // determine allowed modifications based on mime type

      const supportedModifiers = [];

      const modifyPrependHashedText = {
          id: 2,
          name: "Prepend random hashed text",
          disabled: true
      };

      const modifyShashedText = {
          id: 4,
          name: "Prepend random slashed text",
          disabled: true
      };

      const modifyPrependString = {
          id: 8,
          name: "Prepend string",
          disabled: true
      };

      const modifyReplacer = {
          id: 64,
          name: "Agent replace %% variables",
          disabled: true
      };

      const modifyPatchString = {
          id: 16,
          name: "Patch with string",
          disabled: true
      };

      if(this.state.MimeType==="text/plain" || this.state.MimeType==="text/html")
      {
        modifyPrependHashedText.disabled = false;
        modifyShashedText.disabled = false;
        modifyPrependString.disabled = false;
        modifyReplacer.disabled = false;
        modifyPatchString.disabled = false;
      }

    supportedModifiers.push(modifyPrependHashedText);
    supportedModifiers.push(modifyShashedText);
    supportedModifiers.push(modifyPrependString);
    supportedModifiers.push(modifyReplacer);
    supportedModifiers.push(modifyPatchString);

    supportedModifiers.push({
      id: 1,
      name: "No Modifications",
      disabled:false
    });

  return (

    <Modal
      title="File Add/Edit"
      onCancel={() => this.Close()}
      onOk={() => this.onOK()}
      visible={this.state.ModalOpen}
    >

      <Form
        layout="vertical"
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Basic" key="1">
            <Form.Item label="Name" required tooltip="File name">
              <Input placeholder="something.exe" value={this.state.Name} onChange={event => this.setState({ Name: event.target.value })}/>
            </Form.Item>

            <Form.Item label="Description" required tooltip="Descriptive name for this file">
              <Input placeholder="Descriptive name for this file" value={this.state.Description} onChange={event => this.setState({ Description: event.target.value })}/>
            </Form.Item>

            <Form.Item label="File Flags">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={this.state.FileType}
                  onChange={(e) => this.changeFlags(e)}>
                  <Select.Option key={"Normal"}>Normal</Select.Option>
                  <Select.Option key={"Malware"}>Malware</Select.Option>
                  <Select.Option key={"Phishing"}>Phishing</Select.Option>
                  <Select.Option key={"PUA"}>PUA</Select.Option>
                  <Select.Option key={"Script"}>Script</Select.Option>
                  <Select.Option key={"PowerShell"}>PowerShell</Select.Option>
                  <Select.Option key={"Simulation"}>Simulation</Select.Option>
                  <Select.Option key={"SharePointUpload"}>SharePoint Upload</Select.Option>
                </Select>
              </Form.Item>

            <Form.Item label="File">
              <Upload.Dragger fileList={this.state.fileList} customRequest={this.handleFileInputChange} onChange={this.fileinputChange} multiple="false">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Upload.Dragger>
              </Form.Item>


            <Form.Item label="Mime Type">
              <Input disabled placeholder="Auto populated" value={this.state.MimeType} onChange={event => this.setState({ MimeType: event.target.value })}/>
            </Form.Item>

          </Tabs.TabPane>

          <Tabs.TabPane tab="Modification" key="2">
            <Alert message="Modifications allow cattack to modify this attachment on the fly. Depending on the type of attachment, this option may not be available." />
          
            <Form.Item label="Supported modifications">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={this.state.ModifiersArray}
                  onChange={(e) => this.changeModifiers(e)}>

                  { supportedModifiers.map((mod,i) => {
                    return (
                      <Select.Option key={mod.id} title={mod.name} disabled={mod.disabled}>{mod.name}</Select.Option>
                    );

                  })
                  
                  }

                </Select>
              </Form.Item>

            { RequireOffset &&

                <Form.Item label="Patch Offset" required tooltip="What byte offset to start patching at">
                  <Input placeholder="1" value={this.state.patchOffset} onChange={event => this.setState({ patchOffset: event.target.value })}/>
                </Form.Item>
            }


          </Tabs.TabPane>
      </Tabs>
    </Form>
  </Modal>

  )
  }
}

export default FilePayloadAddModal;
