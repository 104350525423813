import React, {useContext, useRef} from "react";
import APIProvider from "../../../service/api2";
import {AppContext} from "../../../AppProvider";
import Tenant from "../../../Models/Tenants/Tenant";
import {Button, Dropdown, Menu, message, Modal, Popconfirm, Space, Table, Tag} from "antd";
import Text from "antd/es/typography/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVirus} from "@fortawesome/free-solid-svg-icons";
import {
    CheckCircleOutlined, CheckSquareOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined, InfoCircleOutlined
} from "@ant-design/icons";
import TenantEditDrawer from "../TenantEdit/TenantEditDrawer";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import APITProvider from "../../../service/api";
import HealthTag from "../../health/HealthTag/HealthTag";

interface TenantsTableProps {
    Loading: boolean,
    Tenants: Tenant[] | undefined,
    onChange?: () => void
}

const TenantsTable = ({Loading, Tenants, onChange}:TenantsTableProps) =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);
    const APIT = new APITProvider(token, true);

    // Refs
    const refTenantEditDrawer = useRef<DrawerRef>(null);

    // return on change properties

    const [messageApi, contextHolder] = message.useMessage();

    // funcs
    const deleteTenant = (id: string) => {
        Modal.confirm({
            title: 'Confirm health check',
            icon: <InfoCircleOutlined />,
            content: <>
                <Text>Deleting the tenant is a permanent action! This may cause attacks to fail if the tenant is referenced.</Text>
            </>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: async () => {
                const hide = message.loading('Deleting tenant ' + id);

                APIT.deleteTenant(id).then(() => {
                    hide();
                    if(onChange !== undefined)
                    {
                        onChange();
                    }
                }).catch(() => {
                    hide();
                })
            }
        });
    }
    const openTenant = (id: string) => refTenantEditDrawer.current !== null ? refTenantEditDrawer.current.showDrawer(id) : null;
    const openConsentURL = (record: Tenant) => {
        if(record.App.ConsentURL !== undefined) {
            Modal.confirm({
                title: 'Consent to CAttack',
                icon: <InfoCircleOutlined/>,
                content: <Space direction="vertical">
                    <Text>The consent URL is shown below. Copy this URL over to a browser logged on as a Global
                        Administrator in the tenant</Text>
                    <Text></Text>
                    <Text>{record.App.ConsentURL}</Text>
                </Space>,
                okText: 'Copy Consent URL',
                cancelText: 'Close',
                onOk: () => {
                    if(record.App.ConsentURL !== undefined) {
                        navigator.clipboard.writeText(record.App.ConsentURL);
                        message.success("Copied consent URL to clipboard");
                    }
                }
            });
        } else {
            message.error("Attempt to open consent URL but no App Consent details.")
        }
    }

    return (
        <>
            {contextHolder}
            <TenantEditDrawer ref={refTenantEditDrawer}/>
            <Table
                pagination={{ pageSize: 15 }}
                dataSource={Tenants}
                rowKey="id"
                loading={Loading}
                columns={[
                    {
                        key: 'name',
                        title: 'Name',
                        render:(record) => {
                            return (
                                <>
                                    <Space direction="vertical">
                                        <Text>{record.Name}</Text>
                                        <Text type="secondary">{record.Description}</Text>
                                    </Space>
                                </>
                            )
                        }
                    },
                    {
                        key: 'Tenant Domain',
                        title: 'Tenant Domain',
                        dataIndex:'TenantDomain',
                        render:(text) => <Text>{text}</Text>
                    },
                    {
                        key: 'flags',
                        render:(record) => {

                            const flags = [];

                            // Is Office 365 tenant
                            if(record.TenantType === 1)
                            {
                                flags.push("Office 365");
                                if(!record.App.OwnApplication && !record.App.Consented) {
                                    flags.push("Consent Required");

                                }
                            }

                            if(record.TenantType === 2)
                            {
                                flags.push("SMTP");
                            }

                            if(record.TenantType === 3)
                            {
                                flags.push("Office 365 | Unmanaged");
                            }

                            return (
                                <>
                                    {flags.map((f, i) => {

                                        if(f==="Malware")
                                        {
                                            return <Tag color="red" icon={<FontAwesomeIcon icon={faVirus}/>}>Malware</Tag>
                                        }

                                        if(f==="Consent Required")
                                        {
                                            return <Tag icon={<ExclamationCircleOutlined />} color="red">Consent Required</Tag>
                                        }

                                        return (
                                            <Tag>{f}</Tag>
                                        );
                                    })}
                                </>
                            )

                        }
                    },
                    {
                        key: 'Simulation',
                        title: 'Simulation',
                        dataIndex:'SimulationEnabled',
                        render:(text) => <Text>{text}</Text>
                    },
                    {
                        key: 'Healthy',
                        title: 'Healthy',
                        render:(record) => <HealthTag ObjectType={"Tenant"} ObjectID={record.id}/>
                    },
                    {
                        key: 'actions',
                        render:(record) => {

                            // Determine ConsentRequirement
                            let ConsentNeeded = false;
                            if(record.TenantType === 1) {
                                if (!record.App.OwnApplication && !record.App.Consented) {
                                    ConsentNeeded = true;
                                }
                            }

                            return (
                                <Dropdown  overlay={

                                    <Menu>

                                        <Menu.Item key="delete" onClick={() => deleteTenant(record.id)}>
                                            <DeleteOutlined/> Delete
                                        </Menu.Item>

                                        <Menu.Item key="healthcheck" onClick={() => {
                                            Modal.confirm({
                                                title: 'Confirm health check',
                                                icon: <InfoCircleOutlined />,
                                                content: <>
                                                    <Text>Health checks are performed asynchronously. Once ran, come back to this page to check soon</Text>
                                                </>,
                                                okText: 'Run',
                                                cancelText: 'Cancel',
                                                onOk: async () => APIT.putHealthCheck("Tenant",record.id)
                                            });
                                        }}>
                                            <CheckSquareOutlined /> Run Health Check
                                        </Menu.Item>


                                        <Menu.Item key="edit" onClick={() => openTenant(record.id)}>
                                            <EditOutlined /> Edit
                                        </Menu.Item>

                                        <Menu.Item key="consent" onClick={() => openConsentURL(record)}>
                                            <CheckCircleOutlined /> Consent
                                        </Menu.Item>


                                    </Menu>

                                }>
                                    <Button>
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )
                        }
                    },
                ]}
            />

        </>

    )
}

export default TenantsTable;