import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Col, Row, Select, Spin, Tag
} from "antd";
import Text from "antd/es/typography/Text";
import {
    UserOutlined
} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";

const IdentityInput = ({value=undefined, onChange=undefined }) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const {
        data: Identities,
        isLoading: isLoading
    } = useQuery(
        ["identities"],
        async () => await API.getIdentities()
    );

    return (
        <>
            {isLoading && <Spin/>}
            {!isLoading && <Select
            showSearch
            loading={isLoading}
            style={{width: '100%'}}
            placeholder="Select a identity"
            optionLabelProp="label"
            optionFilterProp="children"
            allowClear={true}
            value={value}
            onChange={onChange}
            filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
            }>
            {
                Identities.map((u) => {

                    let display = u.id;

                    console.dir(u);


                    if(u.detail !== undefined && u.detail !== null)
                    {
                        display = u.detail.givenName + " " + u.detail.surname;
                    }

                    return (
                        <Select.Option value={u.id} label={display}>
                            <Row gutter={5} align="middle">
                                <Col>
                                    <UserOutlined />
                                </Col>
                                <Col>
                                    <Text>{display}</Text>
                                </Col>
                                <Col>
                                    {u.Tags && u.Tags.map((t) => <Tag>{t}</Tag>)}
                                </Col>
                            </Row>
                        </Select.Option>
                    )
                })
            }

        </Select>}
        </>
    )
};

export default IdentityInput;