import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Dropdown, MenuProps, Modal, Space, Table} from "antd";
import Text from "antd/es/typography/Text";
import {
    DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleOutlined,
    FileOutlined,
    MailOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import APITProvider from "../../../service/api";
import {AppContext} from "../../../AppProvider";
import {useQuery} from "@tanstack/react-query";
import PageHeader from "../../misc/PageHeader";
import {EmailTemplateModal} from "../../payloads/EmailTemplate/EmailTemplateModal";

interface PlaybookPayload {
    id: string;
    type: string;
    description: string;
}

const PlaybookPayloadsTable = ({PlaybookID, showAdd}: { PlaybookID: string, showAdd: boolean }) => {

    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // State
    const [PlaybookPayloads, SetPlaybookPayloads] = useState<undefined | PlaybookPayload[]>([]);

    const {
        data: Emails,
        isLoading: isLoading,
        refetch: refetchEmails
    } = useQuery(
        ["PlaybookEmails", PlaybookID],
        async () => await APIT.playbookEmailsGet(PlaybookID)
    );

    // Refs
    const refEmailEditModal = useRef<EmailTemplateModal>(null);

    const items: MenuProps['items'] = [
        {
            key: 'Email',
            disabled: true,
            onClick: () => console.log("Add Email!"),
            label: <Space><MailOutlined/> <Text>Email</Text></Space>
        },
        {
            key: 'File',
            disabled: true,
            onClick: () => console.log("Add File!"),
            label: <Space><FileOutlined/> <Text>File</Text></Space>
        }
    ];

    // Use Effects
    useEffect(() => {

        var newPayloads:PlaybookPayload[] = [];

        if(Emails !== undefined)
        {
            Emails.map((email) => {
                newPayloads.push({
                    id: email.id,
                    type: "Email",
                    description: email.Name
                })
            })
        }

        SetPlaybookPayloads(newPayloads);
    }, [Emails])

    return (
        <>
            <EmailTemplateModal ref={refEmailEditModal} />
            {showAdd && <PageHeader Buttons={
                <Dropdown menu={{ items }} placement="bottomRight" arrow>
                    <Button icon={<PlusCircleOutlined/>} style={{paddingBottom: 20}}>Add</Button>
                </Dropdown>
            }/>}
            <Table
                dataSource={PlaybookPayloads}
                rowKey="Name"
                pagination={false}
                columns={[
                    {
                        key: 'ico',
                        dataIndex: 'type',
                        width: 20,
                        render: (text) => {

                            if(text === "Email")
                            {
                                return <MailOutlined />
                            }

                            return <FileOutlined />

                        }
                    },
                    {
                        title: "Type",
                        key: 'type',
                        dataIndex: 'type',
                        width: 100,
                        render: (text) => <Text>{text}</Text>
                    },
                    {
                        title: "id",
                        key: 'id',
                        dataIndex: 'id',
                        render: (text) => <Text>{text}</Text>
                    },
                    {
                        title: "Description",
                        key: 'Description',
                        dataIndex: 'description',
                        render: (text) => <Text>{text}</Text>
                    },
                    {
                        key:'actions',
                        render:(record) => {

                            let items = [
                                {
                                    label: 'Edit',
                                    icon: <EditOutlined/>,
                                    key: 'edit',
                                },
                                {
                                    label: 'Delete',
                                    icon: <DeleteOutlined/>,
                                    key: 'Delete',
                                }
                            ];

                            const onClick = (key:any) => {

                                if(key.key=="edit" && refEmailEditModal.current !== null)
                                {
                                    refEmailEditModal.current.showModal(record.id);
                                }

                                if(key.key==="Delete")
                                {
                                    // Confirm delete
                                    Modal.confirm({
                                        title: 'Confirm delete',
                                        icon: <ExclamationCircleOutlined />,
                                        content: <>
                                            <Text>Deleting </Text>
                                            <Text strong>{record.id} </Text>
                                            <Text>is permanent. It could break this playbook!</Text>
                                        </>,
                                        okText: 'Delete',
                                        cancelText: 'Cancel',
                                        //onOk: () => API.deleteRedirectUrl(record.id).then(() => refetch())
                                    });
                                }
                            }

                            return (

                                <Dropdown menu={{ items, onClick }}>
                                    <Button onClick={(e) => e.preventDefault()}>
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )
                        }
                    }
                ]}
            />
        </>

    )

}

export default PlaybookPayloadsTable;