import React, {useContext, useEffect, useRef} from "react";
import {Button, Col, Dropdown, Modal, Row, Space, Table, Tag} from 'antd';
import Text from "antd/es/typography/Text";
import {
    CheckOutlined,
    DeleteOutlined,
    DownOutlined, EditOutlined, ExclamationCircleOutlined, StopOutlined,
} from "@ant-design/icons";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import date from "date-and-time";
import AdminDisplay from "../admins/AdminDisplay";
import AdminEdit from "./AdminEdit/AdminEdit";
import ContainerDisplay from "../containers/ContainerDisplay";
import AdminAddDrawer from "./components/AdminAddDrawer";

const AdminTable = ({Admins, Loading, onChange}) =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // Modal refs
    const AdminEditDrawer = useRef();
    const refAdminAddDrawer = useRef();

    const Delete = async (id) => await API.deleteOperator(id).then(() => onChange());
    const Disable = async (id) => await API.setAdminEnableDisable(id,"disable").then(() => onChange());
    const Enable = async (id) => await API.setAdminEnableDisable(id,"enable").then(() => onChange());

    return (
        <>
            <AdminEdit ref={AdminEditDrawer} onChange={onChange} />
            <AdminAddDrawer ref={refAdminAddDrawer} onChange={() => {}}/>

            <Row style={{paddingBottom:10}}>
                <Col flex="auto"/>
                <Col flex="100px" style={{textAlign:"right"}}><Button onClick={() => refAdminAddDrawer.current.showDrawer()}>Add</Button></Col>
            </Row>
            <Table
                dataSource={Admins}
                rowKey="id"
                pagination={false}
                loading={Loading}
                columns={[
                    {
                        key: 'avatar',
                        dataIndex: 'id',
                        width: 20,
                        render:(text) => <AdminDisplay Admin={text} showAvatar={true} />
                    },
                    {
                        title: "ID",
                        key: 'id',
                        dataIndex: 'id',
                        render: (text) => text,
                    },
                    {
                        title: "Default Container",
                        key: 'DefaultContainer',
                        dataIndex: 'DefaultContainer',
                        render: (text) => <ContainerDisplay Container={text} />
                    },
                    {
                        title: 'Last Seen',
                        key: 'lastauth',
                        render: record => {

                            const dt = new Date(record.LastAuth);

                            const timeString = date.format(dt, "h[:]mm A");
                            const dateString = date.format(dt, "D MMMM");

                            return <Text type="secondary">{dateString} {timeString}</Text>
                        },
                    },
                    {
                        key: 'flags',
                        render:(record) => {

                            const flags = [];

                            // Is Office 365 tenant
                            if(record.Federated === true)
                            {
                                flags.push("Federated");
                            }

                            // Is Office 365 tenant
                            if(record.Enabled === false)
                            {
                                flags.push("Disabled");
                            }

                            return (
                                <>
                                    {flags.map((f, i) => {

                                        if(f==="Disabled")
                                        {
                                            return <Tag icon={<ExclamationCircleOutlined />} color="red">Disabled</Tag>
                                        }

                                        return (
                                            <Tag>{f}</Tag>
                                        );
                                    })}
                                </>
                            )

                        }
                    },
                    {
                        key:'actions',
                        render:(record) => {

                            let items = [
                                {
                                    label: 'Edit',
                                    icon: <EditOutlined/>,
                                    key: 'Edit',
                                },
                                {
                                    label: 'Delete',
                                    icon: <DeleteOutlined/>,
                                    key: 'Delete',
                                }
                            ];

                            // Add enable or disable
                            if(record.Enabled)
                            {
                                items.push({
                                    label: 'Disable',
                                    icon: <StopOutlined />,
                                    key: 'disable'
                                })
                            } else {
                                items.push({
                                    label: 'Enable',
                                    icon: <CheckOutlined />,
                                    key: 'enable'
                                })
                            }

                            const onClick = (key) => {

                                if(key.key==="enable")
                                {
                                    Enable(record.id);
                                }

                                if(key.key==="disable")
                                {
                                    Disable(record.id);
                                }

                                if(key.key==="Edit")
                                {
                                    AdminEditDrawer.current.showDrawer(record.id);
                                }

                                if(key.key==="Delete")
                                {
                                    // Confirm delete
                                    Modal.confirm({
                                        title: 'Confirm delete',
                                        icon: <ExclamationCircleOutlined />,
                                        content: <>
                                            <Text>Deleting </Text>
                                            <Text strong>{record.id} </Text>
                                            <Text>is permanent.</Text>
                                        </>,
                                        okText: 'Delete',
                                        cancelText: 'Cancel',
                                        onOk: () => Delete(record.id)
                                    });
                                }
                            }

                            return (

                                <Dropdown menu={{ items, onClick }}>
                                    <Button onClick={(e) => e.preventDefault()}>
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )
                        }
                    }
                ]}
            />
        </>

    )
}

export default AdminTable;