import React, {useContext, useEffect, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {Alert, Button, Card, Checkbox, Form, Image, Input, message, Modal, Space, Tabs, Typography} from "antd";
import {GlobalOutlined, HomeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {AppContext} from "../../AppProvider";
import APITProvider from "../../service/api";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import PageHeader from "../misc/PageHeader";
import AttestPage from "../../pages/AttestPage";
import imgLogo from "../../img/logo.webp";
import AttestAPI from "../../service/attestapi";
import APIProvider from "../../service/api2";
import {useNavigate} from "react-router-dom";

const AttestMyPage = () =>
{

    var params = new URLSearchParams(window.location.search)

    var id = params.get('id');
    var akey = params.get('k');

    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);
    const [form] = Form.useForm();
    const [Attested, SetAttested] = useState(false);
    const [AttestationData, SetAttestationData] = useState(undefined);
    const [AttestationResponse, SetAttestationResponse] = useState();

    const navigate = useNavigate();

    const handleAttest = (values) => {
        const modal = Modal.info({
            title: 'Attesting',
            closable: false,
            okButtonProps: {
                loading: true,
                disabled: true,
            },
            content: `Lab user is being attested. If this is the first time, passwords are being generated, please wait.`,
        });

        API.attest(id, values).then((r) => {
            SetAttested(true);
            SetAttestationResponse(r);
            modal.destroy();
        })
    }

    useEffect(() => {
        API.getAttestationData(id, akey).then((r) => {
            SetAttestationData(r);
        }).catch((err) => {
            Modal.error({
                title: "Error",
                onOk: () => navigate("/"),
                content: "Could not consent. Are you using an old consent URL? Consent via the CAttack UI or the latest attestation email"
            });

        })
    }, [id, akey]);

    // return on change properties

    return (
        <>
            <PageHeader
                Title="Attest"
            />
            {AttestationData &&
                <Card>
                    <Space direction="vertical" size="large">

                        <Image src={imgLogo} preview={false} width={179} height={50}/>

                        <Typography.Title level={2}>Lab Name: {AttestationData.LabName}</Typography.Title>

                        {Attested &&
                            <Typography.Title level={3}>Thank you!</Typography.Title>
                        }

                        {AttestationResponse && AttestationResponse.IsNewAccount &&
                            <>
                                <Typography.Text>Your account has been enabled and your TEMPORARY password is below. You must login within the next hour and will be required to change your password and set up second factors of authentication. The password below is not saved.</Typography.Text>
                                <Typography.Title level={2}>{AttestationResponse.TemporaryPassword}</Typography.Title>
                                <Typography.Text>Remember, do NEVER share credentials.</Typography.Text>
                            </>
                        }

                        {!Attested && AttestationData.Required && <>
                            <Typography.Title level={3}>Security Brief</Typography.Title>
                            <ul>
                                <li>
                                    CAttack Labs should be used for testing, lab and demonstration purposes only.
                                </li>
                                <li>
                                    All accounts must have secure passwords
                                </li>
                                <li>
                                    All accounts must have multifactor authentication, accounts without MFA are disabled
                                </li>
                                <li>
                                    Credentials are not to be shared
                                </li>
                                <li>
                                    Credentials are never to be stored clear text anywhere. Use an approved password manager.
                                </li>
                                <li>
                                    No business data AT ALL can be located on this tenant.
                                </li>
                                <li>
                                    You understand any data stored in this account can be wiped without notice.
                                </li>
                            </ul>

                            <p><strong>Failure to adhere to your attestation will result in accounts being disabled, and managers being contacted. These are serious security violations.</strong></p>

                            <Typography.Title level={3}>Attestation</Typography.Title>

                            <Form form={form} name="attestation" layout="vertical" autoComplete="off" onFinish={handleAttest}>
                                <Form.Item
                                    name="Name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="BusinessCase"
                                    label="Business Case"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 10,
                                            message: "Please enter a valid business case, be descriptive."
                                        }
                                    ]}>
                                    <Input />
                                </Form.Item>


                                <Form.Item
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                        },
                                    ]}>
                                    <Checkbox>
                                        I agree to the conditions of use
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Attest
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                        }

                        {!Attested && !AttestationData.Required &&
                            <Typography.Title level={3}>Thank you! </Typography.Title>
                        }
                        {!Attested && !AttestationData.Required && !AttestationData.AdditionalInfo &&
                            <Typography.Text>Attestation is not required on this acocunt.</Typography.Text>
                        }

                        {!Attested && !AttestationData.Required && AttestationData.AdditionalInfo &&
                            <Typography.Text>{AttestationData.AdditionalInfo}</Typography.Text>
                        }
                    </Space>
                </Card>


            }
        </>

    )
}

export default AttestMyPage;