import React, {useContext, useEffect, useRef, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import PageHeader from "../misc/PageHeader";
import DrawerRef from "../../classes/DrawerRef/DrawerRef";
import Emitter from "../../Emitter/Emitter";
import AttacksTable from "./AttacksTable";

const AttackPage = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    // State
    const [EmitterConfigured,SetEmitterConfigured] = useState<boolean>(false);
    
    // React query
    const {
        data: Attacks,
        isLoading: isLoading,
        isRefetching: isRefetching,
        refetch: refetch,
    } = useQuery({
            refetchOnWindowFocus: false,
            queryKey: ["attacks"],
            queryFn: async () => await API.getAttacks(),
    });

    // UseEffects

    // Configure emitter, use state to ensure emitter not configured twice.
    useEffect(() => {
        if(!EmitterConfigured)
        {
            SetEmitterConfigured(true);
            Emitter.on("AttackUpdate", (m:string) => handleRefetch());
        }
    }, [EmitterConfigured])

    // return on change properties

    const handleRefetch = () => {
        if(!isRefetching)
        {
            console.log("Refetching");
            refetch();
        } else {
            console.log("preventing simultanous refetch");
        }
    }


    return (
        <>
            <PageHeader
                Title="Running Attacks"
            />

            <AttacksTable Loading={isLoading} Attacks={Attacks} onAttackChange={refetch}/>

        </>

    )
}

export default AttackPage;