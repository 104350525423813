const DefenderMitreTypes = [
    {
        Name: "InitialAccess",
        FriendlyName: "Initial Access"
    },
    {
        Name: "Collection",
        FriendlyName: "Collection"
    },
    {
        Name: "LateralMovement",
        FriendlyName: "Lateral Movement"
    },
    {
        Name:"CredentialAccess",
        FriendlyName: "Credential Access"
    },
    {
        Name:"DefenseEvasion",
        FriendlyName: "Defense Evasion"
    }
]

export default DefenderMitreTypes;