import * as React from "react";
import {AppContext} from "./AppProvider";
import ContainerSwitch from "./components/containers/ContainerSwitch.react";
import CAttackWarning from "./components/misc/CAttackWarning";

import APIProvider from "./service/api2";

import {Badge, Image, Layout, Menu, Tag} from "antd"

import ProfileEditModal from "./components/profile/ProfileEditModal";

import './index.css';
import NavBar from "./NavBar";

import imgLogo from "./img/logo.webp";
import imgLogoDark from "./img/logo-dark.webp";
import imgSmallLogo from "./img/cat.png";
import ContainerSwitchModal from "./components/containers/ContainerSwitchModal.react";
import HeaderBar from "./HeaderBar";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class SiteWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.ProfileEditModal = React.createRef();
    this.ContainerSwitchModal = React.createRef();
}

  state = {
    Me: {
      firstname: "",
      surname: ""
    },
    collapsed: false,
    ProfileCompleted: false,
    navLoaded: false,
    navBar: null,
    notifyComplete: false,
    DarkMode: true
  };

  // Global AppContext contains token and operator info
  static contextType = AppContext;

  componentDidMount() {

    this.API = new APIProvider(this.context.token);

  }

  // Refresh my profile details
  /*refreshProfile() {

    var api = new APIProvider(this.context.token);

    api.getMe().then((me) => {
      this.setState({Me: me});
    })
    .catch((error) => {
      console.dir(error);
      this.setState({error: error})
    })
  }*/

  logout()
  {
    sessionStorage.clear();
    this.API.LogOut();
  }

  onProfileUpdate()
  {
    const {setMe} = this.context;

    this.API.getMe().then((me) => {
      setMe(me);
      this.setState({ProfileCompleted: true})
    })
    .catch((error) => {
          console.dir(error);
    })
  }

  render() {

    const {me, token, DarkMode} = this.context;

    const {api} = this.context;

    let container = "";
    let containerName  = "";
    let needsWarning = false;

    if(this.API)
    {
      container = this.API.GetSetContainer();
      containerName = this.API.GetSetContainerName();
      needsWarning = this.API.WarningState();
    }

    if(me !== undefined)
    {
      if(me.ProfileComplete !== this.state.ProfileCompleted)
      {
        this.setState({ProfileCompleted: me.ProfileComplete});
      }
    }

    return (

        <Layout style={{ minHeight: '100vh' }}>

          { me && !this.state.ProfileCompleted &&
              <ProfileEditModal api={this.API} profile={me} onUpdated={() => this.onProfileUpdate()} />
          }

          <Sider collapsible theme="light" collapsed={this.state.collapsed} onCollapse={() => this.setState(prevState => ({collapsed: !prevState.collapsed}))}>
            <div style={{padding:20}}>
              { this.state.collapsed && <Image preview={false} src={imgSmallLogo} height={32}/>}
              { !this.state.collapsed && !DarkMode && <Image preview={false} src={imgLogo} height={32}/>}
              { !this.state.collapsed && DarkMode && <Image preview={false} src={imgLogoDark} height={32}/>}
            </div>
            <NavBar me={this.context.me}/>
          </Sider>

          <Layout>

            <HeaderBar containerName={containerName} onLogout={() => this.logout()} onContainerSwitch={() => this.ContainerSwitchModal.current.Open()} DarkModeSelected={this.props.DarkModeSelected} DarkModeChange={() => this.props.DarkModeChange}/>

            <Content style={{ marginLeft: '25px', marginRight: '25px', marginBottom: '25px' }}>
              {this.props.children}
            </Content>

            <Footer style={{ textAlign: 'center' }}>Cattack Cam Murray & Dan Mozes MSec CAT team</Footer>
          </Layout>

          <ContainerSwitchModal ref={this.ContainerSwitchModal} />
          {needsWarning && <CAttackWarning onOk={() => this.API.ConfirmWarning()}/>}

        </Layout>
    )

  }
}

export default SiteWrapper;
