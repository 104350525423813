import React from "react";
import {Space} from "antd";
import {AndroidFilled, AppleFilled, ChromeFilled, ChromeOutlined, WindowsFilled} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdge, faFirefox, faLinux, faSafari} from "@fortawesome/free-brands-svg-icons";
import {UAParser} from "ua-parser-js";
import {ComputerOutlined} from "@mui/icons-material";

interface UserAgentViewProps {
    UserAgent: string,
    ShowOS?: boolean,
    ShowBrowser?: boolean
}

const UserAgentView = ({UserAgent, ShowOS, ShowBrowser}:UserAgentViewProps) =>
{
    let parser = new UAParser(UserAgent);
    let uaResult = parser.getResult();

    console.dir(uaResult);

    // return on change properties

    let osFamily = "Unknown";
    let osMajor = "Unknown";
    let uaFamily = "Unknown";

    let icon = <ComputerOutlined />;
    let text = "Unknown";

    if(uaResult !== undefined) {

        if(uaResult.os !== undefined && ShowOS)
        {
            if (uaResult.os.name !== undefined) {
                if (uaResult.os.name === "Mac OS") {
                    return (
                        <Space>
                            <AppleFilled/>
                            <Text>Mac OS X {uaResult.os.version}</Text>
                        </Space>
                    );
                }
            }
        }

        if(uaResult.browser !== undefined && ShowBrowser)
        {
            if(uaResult.browser.name === "Edge")
            {
                return (
                    <Space>
                        <FontAwesomeIcon icon={faEdge} /><Text>Edge {uaResult.browser.version}</Text>
                    </Space>
                )
            }

            if(uaResult.browser.name==="Safari")
            {
                return (
                    <Space>
                        <FontAwesomeIcon icon={faSafari} /><Text>Safari {uaResult.browser.version}</Text>
                    </Space>
                )
            }

            if(uaResult.browser.name==="Firefox")
            {
                return (
                    <Space>
                        <FontAwesomeIcon icon={faFirefox} /><Text>Firefox {uaResult.browser.version}</Text>
                    </Space>
                )
            }
            if(uaResult.browser.name==="Chrome")
            {
                return (
                    <Space>
                        <ChromeFilled /><Text>Chrome {uaResult.browser.version}</Text>
                    </Space>
                )
            }
        }

    }

        /*if(props.UA.OS.Family!=null)
        {
            const osicon = props.UA.OS.Family.toLowerCase();

            osFamily = props.UA.OS.Family;
            osMajor = props.UA.OS.Major;

            if(props.UA.OS.Family==="Mac OS X")
            {
                return (
                    <Space>
                        <AppleFilled />
                        <Text>{osFamily} {osMajor}</Text>
                    </Space>
                );
            }

            if(props.UA.OS.Family==="iOS")
            {
                return (
                    <Space>
                        <AppleFilled />
                        <Text>{osFamily} {osMajor}</Text>
                    </Space>
                );
            }

            if(props.UA.OS.Family==="Windows")
            {
                return (
                    <Space>
                        <WindowsFilled />
                        <Text>{osFamily} {osMajor}</Text>
                    </Space>
                );
            }

            if(props.UA.OS.Family==="Linux")
            {
                return (
                    <Space>
                        <FontAwesomeIcon icon={faLinux} />
                        <Text>{osFamily} {osMajor}</Text>
                    </Space>
                );
            }

            if(props.UA.OS.Family==="Android")
            {
                return (
                    <Space>
                        <AndroidFilled />
                        <Text>{osFamily} {osMajor}</Text>
                    </Space>
                );
            }
        }

       /* const browsericon = props.UA.UserAgent.Family.toLowerCase();

        if(browsericon==="chrome") return <ChromeOutlined />*/

    /*if(props.UA !== null && props.UA !== undefined && props.show === "browser")
    {
        uaFamily =props.UA.UserAgent.Family;



        if(uaFamily==="Edge")
        {

        }



    }*/

    return <Space>
        {icon}
        <Text>{text}</Text>
    </Space>

}

export default UserAgentView;