import React, {useContext} from 'react'
import {Spin, Tag} from "antd";
import {
    CheckOutlined,
    ClockCircleOutlined, FolderOpenOutlined, LoadingOutlined, RocketOutlined, WarningOutlined
} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";

interface AttackTagProps {
    AttackID: string|undefined|null;
}

const AttackTag = ({AttackID}:AttackTagProps) => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    const {
        data: Attack,
        isLoading: isLoading
    } = useQuery(
        {
            queryKey: ["attack", AttackID],
            enabled: AttackID !== undefined && AttackID !== null,
            queryFn: AttackID !== undefined && AttackID !== null ? async () => await APIT.getAttack(AttackID) : undefined
        }
    );

    // Determine additional data to load

    let status = "Scheduled";
    let statusco = "orange";
    let statusico = "clock";
    let name = "Unknown";

    let Ico = <ClockCircleOutlined/>;

    let Phase = "Main";

    if(Attack != undefined)
    {

        name = Attack.Name;

        if(Attack.Phase === 1)
        {
            Phase = "Preperation";
        }

        if(Attack.Phase === 2)
        {
            Phase = "Main";
        }

        if(Attack.Phase === 4)
        {
            Phase = "Clean-up";
        }

        if(Attack.Status & 1)
        {
            status="Running | " + Phase;
            statusco = "blue";
            Ico = <LoadingOutlined />
        }

        if(Attack.Status & 1 && Attack.Errors)
        {
            status="Running | " + Phase + " | Errors";
            statusco = "blue";
            Ico = <LoadingOutlined />
        }

        if(Attack.Status & 2)
        {
            status="Completed";
            statusco="green";
            Ico=<CheckOutlined />
        }

        if(Attack.Status & 4)
        {
            status="Cancelled";
            statusco="lightgray";
            Ico=<WarningOutlined />
        }

        if(Attack.Status & 8)
        {
            status="Scheduled";
            statusco="grey";
            Ico = <ClockCircleOutlined />;
        }

        if(Attack.Status & 128)
        {
            status="Archived";
            statusco="lightgray";
            Ico = <FolderOpenOutlined />;
        }

        if(Attack.Errors && Attack.Status & 2)
        {
            status="Completed | Errors";
            statusco="red";
            Ico=<WarningOutlined />
        }

    }

    if(isLoading)
        return <Spin />

    return (
        <Tag icon={<RocketOutlined />}>Attack | {name}</Tag>
    )

}
export default AttackTag;