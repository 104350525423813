import React, {forwardRef, useContext, useImperativeHandle, useState} from 'react'
import {Button, Drawer, Form, Input, Select, Space} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import Text from "antd/es/typography/Text";
import TextArea from "antd/es/input/TextArea";

const SiteAddDrawer = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // State
    const [open, setOpen] = useState(false);
    const [IsSubmitting, setIsSubmitting] = useState(false);

    // Refs
    const [form] = Form.useForm();

    // Variables

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const finishAdd = async (values) => {
        // post values

        setIsSubmitting(true);
        let x = await API.postPage(values);
        setIsSubmitting(false);

        // If new object created inform parent and close
        if(x !== undefined)
        {
            props.onAdd();
            onClose();
        }
    }

    return (
        <Drawer
            {...props}
            title="Add Site"
            closable={true}
            onClose={onClose}
            open={open}>

            <Form form={form} layout="vertical" onFinish={finishAdd}>

                <Form.Item name="Name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="Description" label="Description" rules={[{ required: true }]}>
                    <TextArea />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<PlusCircleOutlined/>} loading={IsSubmitting}>
                        Add
                    </Button>
                </Form.Item>
            </Form>



        </Drawer>
    );

});

export default SiteAddDrawer