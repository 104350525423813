import React, {useContext, useEffect, useRef, useState} from 'react'
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import {Table} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";
import IPDisplay from "../../ipinfo/IPDisplay";
import WebSession from "../../../Models/WebSession/WebSession";
import DateTimeView from "../../misc/DateTimeView";
import WebsessionDetail from "../WebsessionDetail/WebsessionDetail";

interface WebsiteSessionTableProps {
    Website: string;
}

const WebsiteSessionTable = ({Website}:WebsiteSessionTableProps) => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token,true);

    const {
        data: Sessions,
        isLoading: isLoading
    } = useQuery(
        {
            queryKey: ["WebSessions",Website],
            queryFn: async () => await APIT.WebsiteSessionsGet(Website)
        }
    );

    const expandedRowRender = (record:WebSession) => <WebsessionDetail SessionID={record.id} WebsiteID={Website} />

    return <Table
        dataSource={Sessions}
        rowKey="id"
        pagination={false}
        loading={isLoading}
        expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ['0'],
        }}
        columns={[
            {
                key:'avatar',
                width:50,
                render: (record) => <CloudUploadOutlined />
            },
            {
                title: "ID",
                key: 'id',
                dataIndex: 'id',
                render: (text) => text,
            },
            {
                title: "First Seen",
                key: 'StartDate',
                dataIndex: 'StartDate',
                render: (text) => <DateTimeView DateTime={text} type="secondary"/>,
            },
            {
                title: "IPs",
                key: 'ips',
                render: (record) => {
                    return <>
                        {record.Detail && record.Detail.map((detail:any) => {
                            return <IPDisplay IP={detail.IPAddress}/>
                        })}
                    </>
                }
            },
            {
                title: "Requests",
                key: 'Requests',
                dataIndex: 'Requests',
                render: (text) => text,
            },
            {
                title: "Captured Cookies",
                key: 'CapturedCookies',
                dataIndex: 'CapturedCookies',
                render: (text) => text,
            }
            ]}
    />

}
export default WebsiteSessionTable;