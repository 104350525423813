import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {Alert, AutoComplete, Button, Collapse, Drawer, Form, message, Space, Tabs} from "antd";
import PlaybookItemTypes from "../Playbook/PlaybookItemTypes";
import {BulbOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import Editor from "@monaco-editor/react";
import {AppContext} from "../../AppProvider";
import APIProvider from "../../service/api2";
import PlaybookElementVariableTable from "./PlaybookElementVariableTable";
import {useForm} from "antd/es/form/Form";
import TaskItemInput from "../PlaybookInputs/TaskItemInput";
import {faVial} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlaybookTestsPage from "../PlaybookEditing/PlaybookTests/PlaybookTestsPage";

const PlaybookElementDrawer = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // Construct the menu from all item types
    let menuItems = [];

    const [ShowSelectItem, SetShowSelectItem] = useState(true);
    const [ItemCreationType, SetItemCreationType] = useState();
    const [open, setOpen] = useState(false);

    const [Parent, SetParent] = useState();

    const [formTask] = useForm();
    const [formTaskItem] = useForm();

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [Task,SetTask] = useState();
    const [TaskJSON,SetTaskJSON] = useState();

    const [validationErrors,SetValidationErrors] = useState([]);
    const [editor, SetEditor] = useState();
    const [monaco, SetMonaco] = useState();
    const [schema, SetSchema] = useState();
    const [modified, SetModified] = useState(false);
    const [Description, setDescription] = useState();
    const [PlaybookVariables, SetPlaybookVariables] = useState([]);
    const [DependencyVariables, SetDependencyVariables] = useState([]);

    const [VariableOptions,SetVariableOptions] = useState([]);

    const [TaskID, SetTaskID] = useState(0);
    const [TaskMeta, SetTaskMeta] = useState([]);
    const [TaskItemMeta, SetTaskItemMeta] = useState([]);

    const [Phase, SetPhase] = useState();

    const [TestRules, SetTestRules] = useState([]);

    // Drawer open close
    const showDrawer = (task, pVars2, pTasks, Phase) => {

        // Reset forms if TaskID has changed
        if(task.id !== TaskID)
        {
            console.log("Different task ID");
            formTaskItem.resetFields();
            formTask.resetFields();
        }

        // Get the events schema
        if(task!==undefined && task["$type"] !== undefined)
        {
            API.getEventSchema(task["$type"]).then((r) => {
                SetSchema(r);
            })

            SetTask(task);
            SetTaskID(task.id);
            setDescription(task.description);
            SetTestRules(task.TestRules)
        }

        SetTaskJSON(JSON.stringify(task));
        SetModified(false)
        SetPhase(Phase);

        // Create a list of available vars
        BuildVariableList(pVars2, pTasks,task.id);

        setOpen(true);

    };

    const loadFormIntoTask = () => {
        let newTask = Task;

        const touched1 = formTask.getFieldsValue();
        const touched2 = formTaskItem.getFieldsValue();

        Object.keys(touched1).forEach((key) => {
            newTask[key] = touched1[key];
        })

        Object.keys(touched2).forEach((key) => {
            newTask[key] = touched2[key];
        })

        newTask.TestRules = TestRules;

        SetTask(newTask);

    }

    const BuildVariableList = (pVars2,pTasks,thisTaskId) => {

        let Vars = [];

        // Add PVars2
        pVars2.forEach((pv2) => {
            Vars.push({
                id: pv2.Name,
                entryText: "${Var." + pv2.Name + "}",
                Name: "Attack Entity " + pv2.Name,
                Description: pv2.Description,
                VarType: pv2.Type,
            })
        });

        SetPlaybookVariables(Vars);

        // Get dependency variables

        let dependencyVariables = [];

        // Determine all of my dependencies
        let depIds = new Set(GetTaskDependencies(thisTaskId,pTasks,true).sort());

        // loop
        depIds.forEach((dep) => {
            // Get the dependant task
            let depTask = pTasks.find(x => x.id == dep);

            // Get the dependant task type from the meta data
            let depTaskMeta = props.Meta.find(x => x.id == depTask["$type"]);

            if(depTaskMeta !== null && depTaskMeta !== undefined)
            {
                depTaskMeta.Outputs.forEach((output) => {
                    dependencyVariables.push({
                        id: "${Task[" + dep + "]." + output.id + "}",
                        entryText: "${Task[" + dep + "]." + output.id + "}",
                        Name: "Task " + dep + " " + output.id,
                        Type: output.Type,
                        VarType: output.Type,
                        Description: output.Description,
                        OutputTask: dep
                    })
                });
            }

        })

        SetDependencyVariables(dependencyVariables);
    }

    // Gets nested variable output - looped function
    const GetTaskDependencies = (TaskID,Tasks,IgnoreFirst) => {
        // get current task

        let varOutput = [];

        let currentTask = Tasks.find(x => x.id == TaskID);

        if(currentTask === null || currentTask === undefined)
        {
            console.log("Couldnt find " + TaskID);
            console.dir(Tasks)
        } else {
            if(!IgnoreFirst)
            {
                varOutput.push(TaskID);
            }

            // Add all dependencies
            if(currentTask.depends !== null)
            {
                currentTask.depends.forEach((dep) => {
                    varOutput = varOutput.concat(GetTaskDependencies(dep,Tasks,false));
                })
            }

        }

        return varOutput;
    }

    useEffect(() => {

        if(schema !== undefined && editor !== undefined && monaco !== undefined)
        {
            monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: true,
                "schemas": [
                    {
                        uri: "http://myserver/foo-schema.json", // id of the first schema
                        fileMatch: ["*"],
                        // associate with our model
                        schema: schema
                    }
                ]
            });
        }

    }, [schema, editor, monaco])

    useEffect(() => {

        if(editor !== undefined && editor !== null)
        {
            setTimeout(function() {
                if(editor !== undefined && editor !== null && editor.getAction !== undefined && editor.getAction !== null)
                {
                    try {
                        editor.getAction('editor.action.formatDocument').run();
                    } catch {}

                }
            }, 300);

        }

    }, [editor,TaskJSON])

    // Update meta info
    useEffect(() => {

        if(props.Meta !== undefined && props.Meta !== null && Task !== undefined && Task !== null)
        {
            let playbookItem = props.Meta.findIndex(item => item.id === "PlaybookTask");
            let playbookTaskItem = props.Meta.findIndex(item => item.id === Task["$type"]);

            if(playbookItem !== -1)
            {
                // Set the meta
                SetTaskMeta(props.Meta[playbookItem]);
            }

            if(playbookTaskItem !== -1)
            {
                // Set the meta
                SetTaskItemMeta(props.Meta[playbookTaskItem]);
            }

            // Set form values
            formTask.setFieldsValue(Task);
            formTaskItem.setFieldsValue(Task);

        }

    },[props.Meta,Task])

    useEffect(() => {
        let newVarOptions = [];

        PlaybookVariables.forEach((i) => {
            newVarOptions.push({
                value: i.entryText,
                label: <Space>
                    <Text>{i.entryText}</Text>
                    <Text>{i.Description}</Text>
                </Space>
            })
        })

        DependencyVariables.forEach((i) => {
            newVarOptions.push({
                value: i.id,
                label: <Space>
                    <Text>{i.id}</Text>
                    <Text>{i.Description}</Text>
                </Space>
            })
        })

        SetVariableOptions(newVarOptions);

    }, [PlaybookVariables,DependencyVariables])

    const handleEditorDidMount = (editor, monaco) => {
        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        SetEditor(editor);
        SetMonaco(monaco);
    }

    const handleEditorChange = (data) => {

        var newErrors = [];

        let parsedObject = {};

        try {
            parsedObject = JSON.parse(data);

            if(parsedObject["$type"]!==Task["$type"])
            {
                // Cannot change type
                newErrors.push("Cannot change type of task in JSON!");
            }

            if(parsedObject["id"]!==Task["id"])
            {
                // Cannot change type
                newErrors.push("Cannot change id of task in JSON!");
            }

            SetTask(parsedObject);



        } catch {
            newErrors.push("Invalid JSON");
        };

        // Reset the form
        formTaskItem.resetFields();
        formTask.resetFields();

        SetModified(true);
        SetValidationErrors(newErrors);

    }

    const onClose = () => {
        SetTask(undefined);
        setOpen(false);
    };

    // Details form
    const [formDetail] = Form.useForm();

    PlaybookItemTypes.map((itemType) => {
        menuItems.push({
            label: <text strong>{itemType.Display}</text>,
            key: itemType.TaskType,
            icon: itemType.Icon
        });
    });

    const handleItemSelect = (item) => {
        SetShowSelectItem(false);
        SetItemCreationType(item.key);
    }

    const handleAddClick = () => {


        let creationData = {
            Type: ItemCreationType,
            Description: formDetail.getFieldValue("Description"),
            Delay: formDetail.getFieldValue("Delay"),
        }

        if(Parent !== undefined && Parent !== 0)
        {
            creationData.Depends = [Parent];
        } else {
            creationData.Depends = [];
        }

        props.onAdd(creationData);
        onClose();

    }

    const handleSave = () => {
        loadFormIntoTask();

        if(validationErrors.length > 0)
        {
            message.error("Cannot save! There are validation errors on this task that need to be fixed");
        }
        else
        {
            props.onSave(Task, Phase);

            onClose();
        }
    }

    const handleTabChange = (tab) => {
        loadFormIntoTask();
        if(tab === "json")
        {
            SetTaskJSON(JSON.stringify(Task));
        }
    }

    // Create sections for task items
    let Sections = {};

    // Groups meta data in to sections
    if(TaskItemMeta && TaskItemMeta.Fields)
    {
        TaskItemMeta.Fields.map((field) => {

            let SectionKey = field.Section;

            if(!SectionKey)
            {
                SectionKey = "Unsectioned";
            }

            if(!Sections[SectionKey])
            {
                Sections[SectionKey] = [];
            }

            Sections[SectionKey].push(field);
        })
    }

    console.dir(Sections);

    // Build the accordion list for sections

    //                                {TaskItemMeta && TaskItemMeta.Fields && TaskItemMeta.Fields.map((Meta) => <TaskItemInput Meta={Meta} VariableOptions={VariableOptions} PlaybookVariables={PlaybookVariables} DependencyVariables={DependencyVariables}/>)}
    let accordionSections = [];

    if(Sections)
    {
        Object.keys(Sections).map((key) => {
            let accordionSection = {
                key: key,
                label: key,
                children: []
            };

            Sections[key].map((Meta) => {
                accordionSection.children.push(<TaskItemInput Meta={Meta} VariableOptions={VariableOptions} PlaybookVariables={PlaybookVariables} DependencyVariables={DependencyVariables}/>);
            })

            accordionSections.push(accordionSection);
        });
    }



        return (
            <Drawer
                {...props}
                title="Add Task"
                closable={true}
                onClose={onClose}
                width={800}
                key={TaskID}
                extra={
                    <Space>
                        <Button type="primary" icon={<SaveOutlined/>} onClick={handleSave}>Save</Button>
                        <Button icon={<SelectOutlined/>}>Revert</Button>
                    </Space>
                }
                open={open}>

                {validationErrors &&
                    <Space direction="vertical" style={{display: 'flex', marginBottom: "10"}}>
                        {validationErrors.map((er) => {
                            return <Alert message={er} type="error" showIcon/>
                        })}
                    </Space>
                }

                <Tabs defaultActiveKey="detail" onChange={handleTabChange}>

                    {TaskMeta &&
                        <Tabs.TabPane
                            tab={
                                <Space><BulbOutlined/>Task Properties</Space>
                            }
                            key="taskprop">
                            <Form
                                initialValues={Task}
                                form={formTask}
                                labelCol={{span: 4}}
                                key={TaskID}
                            >

                                {TaskMeta && TaskMeta.Fields && TaskMeta.Fields.map((Meta) => <TaskItemInput Meta={Meta} VariableOptions={VariableOptions} PlaybookVariables={PlaybookVariables} DependencyVariables={DependencyVariables}/>)}

                            </Form>

                        </Tabs.TabPane>
                    }

                    {TaskItemMeta &&
                        <Tabs.TabPane
                            tab={
                                <Space><BulbOutlined/>{TaskItemMeta.Name}</Space>
                            }
                            key="taskitemprop">
                            <Form initialValues={Task} form={formTaskItem} key={TaskID}
                                  labelCol={{span: 4}}>
                                <Collapse items={accordionSections} defaultActiveKey={Object.keys(Sections)}/>
                            </Form>

                        </Tabs.TabPane>
                    }

                    <Tabs.TabPane
                        tab={
                            <Space><BulbOutlined/>JSON</Space>
                        }
                        key="json">
                        {modified && <Text>Unsaved changes</Text>}
                        <Editor
                            height="80vh" // By default, it fully fits with its parent
                            language="json"
                            value={TaskJSON}
                            onChange={handleEditorChange}
                            onMount={handleEditorDidMount}
                        />

                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <Space><BulbOutlined/>Variables</Space>
                        }
                        key="variables">
                        <PlaybookElementVariableTable PlaybookVariables={PlaybookVariables}/>

                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <Space><FontAwesomeIcon icon={faVial} />Tests</Space>
                        }
                        key="tests">

                        <PlaybookTestsPage Tests={TestRules} onChange={SetTestRules} PlaybookVariables={PlaybookVariables} DependencyVariables={DependencyVariables}/>
                    </Tabs.TabPane>


                    {DependencyVariables &&
                        <Tabs.TabPane
                            tab={
                                <Space><BulbOutlined/>Dependency Outputs</Space>
                            }
                            key="depoutputs">
                            <PlaybookElementVariableTable PlaybookVariables={DependencyVariables}/>

                        </Tabs.TabPane>
                    }


                </Tabs>
            </Drawer>

        )

});

export default PlaybookElementDrawer;