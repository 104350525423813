import React, {useContext, useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useQuery} from "@tanstack/react-query";
import {Alert, Button, Card, Form, Input, Modal, Select, Space, Spin, Tabs} from "antd";
import {EditFilled, PlusCircleOutlined, SaveOutlined} from "@ant-design/icons";
import Editor from "@monaco-editor/react";
import {useForm} from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import PageHeader from "../../misc/PageHeader";
import Text from "antd/es/typography/Text";
import PlaybookVariableTable from "../../PlaybookEditing/PlaybookVariableTable";
import PlaybookNewVariableDrawer from "../../Playbook/PlaybookNewVariableDrawer";

const FileTemplatePage = (props) => {

    let params = useParams();

    const {token} = useContext(AppContext);
    const API = new APIProvider(token,true);

    const [editor, SetEditor] = useState();
    const [monaco, SetMonaco] = useState();

    // Language for editor
    const [language, SetLanguage] = useState();

    // refs
    const [form] = useForm();
    const NewVariableDrawer = useRef();

    // state
    const [Variables, SetVariables] = useState([]);
    const [Iteration, SetIteration] = useState(0);
    const [ShowEditor, SetShowEditor] = useState(false);
    const [ShowLanguageSelection, SetShowLanguageSelection] = useState(false);

    // React Query
    const {
        data: Template,
        isLoading: isLoading
    } = useQuery(
        ["filetemplate", params.id],
        async() => await API.getFileTemplate(params.id)
    );

    useEffect(() => {
        if(Template !== undefined)
        {
            SetVariables(Template.Variables)
        }
    }, [Template])

    // Informs child objects of changes
    const increaseIteration = () => {
        SetIteration(Iteration + 1);
    }

    const handleEditorDidMount = (editor, monaco) => {

        // Define nim language
        monaco.languages.register({ id: "nim "});

        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        console.dir("handleEditorDidMount");
        SetEditor(editor);
        SetMonaco(monaco);
    }

    const Save = () => {
        console.log("Saving");

        console.dir(Template);

        Template.Name = form.getFieldValue("Name");
        Template.Description = form.getFieldValue("Description");

        if(ShowLanguageSelection)
        {
            Template.Language = form.getFieldValue("Language");
        }

        if(ShowEditor)
        {
            Template.BaseData = editor.getValue();
        }

        if(Template["$type"]=="FileTemplateQRCode")
        {
            Template["Text"] =  form.getFieldValue("Text");
            Template["PixelsPerModule"] = form.getFieldValue("PixelsPerModule");

            console.dir(Template);
        }


        Template.Variables = Variables;

        const modal = Modal.success({
            title: 'Saving',
            content: <Space>
                <Spin/>
                <Text>Your file is being saved, please wait</Text>
            </Space>
        });

        API.putFileTemplate(params.id, Template).then((r) => {
            modal.destroy();
        }).catch((error) => {
            modal.destroy();
        });
    }

    const HandleLanguageChange = (changeTo) => {
        SetLanguage(changeTo);
        monaco.editor.setModelLanguage(editor.getModel(), changeTo);

    }

    const handleVariableAdd = (variable) => {
        let oldVariables = Variables;
        oldVariables.push(variable);
        SetVariables(oldVariables);

        increaseIteration();
    }


    if(!isLoading)
    {

        if(Template["$type"] === "FileTemplateScript" && language !== Template.Language)
        {
            SetLanguage(Template.Language);
            SetShowEditor(true);
            SetShowLanguageSelection(true);
        }

        if(Template["$type"] === "FileTemplateNim" && language !== "nim")
        {
            SetLanguage("nim");
            SetShowEditor(true);
            SetShowLanguageSelection(true);
        }

        return (
            <>
                <PageHeader
                    Title={Template.Name}
                    Buttons={[
                        <Button icon={<SaveOutlined />} onClick={Save} outline type="primary">Save</Button>
                    ]}
                />

                <PlaybookNewVariableDrawer ref={NewVariableDrawer} onAdd={handleVariableAdd} existingVariables={Variables}/>

                <Card>
                    <Form
                        form={form}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 16}}
                        labelAlign="left"
                        initialValues={Template}>

                        {ShowLanguageSelection &&
                            <Form.Item name="Language" label="Script Language" rules={[{ required: true }]}>
                                <Select style={{ width: 300}} onChange={HandleLanguageChange} defaultValue={language}>

                                    <Select.Option value="nim">NIM</Select.Option>
                                    <Select.Option value="powershell">PowerShell</Select.Option>

                                </Select>

                            </Form.Item>
                        }


                        <Form.Item name="Name" label="Name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="Description" label="Description" rules={[{ required: true }]}>
                            <TextArea />
                        </Form.Item>

                    <Tabs defaultActiveKey="vars">

                        {ShowEditor &&
                            <Tabs.TabPane tab="Edit" key="1" icon={<EditFilled/>}>

                                <Editor
                                    height="60vh"
                                    language={language}
                                    value={Template.BaseData}
                                    onMount={handleEditorDidMount}/>

                            </Tabs.TabPane>
                        }

                        {Template["$type"] == "FileTemplateQRCode" &&
                            <Tabs.TabPane tab="QR Code" key="QR" icon={<EditFilled/>}>
                                <Form.Item name="Text" label="Text" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="PixelsPerModule" label="Pixels per module" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Tabs.TabPane>
                        }

                        <Tabs.TabPane tab="Variables" key="vars">
                            <PageHeader Buttons={<Button icon={<PlusCircleOutlined />} outline onClick={() => NewVariableDrawer.current.showDrawer()}>New Variable</Button>}/>
                            <Alert
                                message="Variables"
                                description="You can use variables in your file templates by referencing ${FileVariable.Name}. Variables are filled on the agent when generating the file. When using a file template in a playbook, you must specify the value of the variable or use a playbook variable."
                                type="info"
                                showIcon
                            />
                            <PlaybookVariableTable Variables={Variables} Iteration={Iteration}/>
                        </Tabs.TabPane>

                    </Tabs>
                    </Form>
                </Card>

            </>
        );
    }
}
export default FileTemplatePage