import React, {useEffect} from 'react';

import ReactFlow, {ReactFlowProvider, useEdgesState, useNodesState,} from 'react-flow-renderer';

import dagre from 'dagre';
import {Badge, Button, Space, theme} from "antd";
import {
    CheckOutlined,
    ClockCircleOutlined,
    FastForwardOutlined,
    LoadingOutlined,
    PauseOutlined, StopOutlined,
    WarningOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import "./AttackMapStyleSheet.css";
import {CancelOutlined, PlayArrowOutlined} from "@mui/icons-material";

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: "TB" });

  nodes.forEach((n) => {
    dagreGraph.setNode(n.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((e) => {
    dagreGraph.setEdge(e.source, e.target);
  });

  dagre.layout(dagreGraph);

  nodes.map((el) => {
    const nodeWithPosition = dagreGraph.node(el.id);
    el.targetPosition = isHorizontal ? 'left' : 'top';
    el.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // unfortunately we need this little hack to pass a slightly different position
    // to notify react flow about the change. Moreover we are shifting the dagre node position
    // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
    el.position = {
      x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
  });

};

;

const EdgesFromTasks = (Tasks, token) => {

    const redges = [];

    if(Tasks != null)
    {
        Tasks.forEach((task) => {

            if(task.Playbook.Phase===2)
            {
                // create connectors

                const AddPathTo0 = false;

                if (typeof task.depends !== "undefined" && task.depends.length > 0) {
                    // undefinedAray is not empty

                    task.depends.forEach((dep) => {

                        let ElPath = {
                            id: dep + "to" + task.id,
                            source: dep,
                            target: task.id,
                            //color: "#595959",
                            color: "#ab3f3f",
                            animated: !task.complete
                        };

                        // task was skipped
                        if(task.Status & 2048)
                        {
                            ElPath.label = "x";
                            ElPath.animated = false;
                            ElPath.style = {
                                stroke: "#eaeaea",
                            }
                        }

                        if(task.dependsDelayMin!=null)
                        {
                            if(task.dependsDelayMin!==0)
                                ElPath.label = task.dependsDelayMin + " min";
                        }

                        redges.push(ElPath);

                    });

                } else {
                    redges.push({
                        id: "0to" + task.id,
                        source: '0',
                        target: task.id,
                        color: "#595959",
                        animated: !task.complete
                    });
                }
            }

        });

    }


  return redges;
}

const NodesFromTasks = (Tasks, onTaskResume, token) => {



    if(Tasks != null)
  {
    var rnodes = [
      {
        id: '0',

        type: 'input',
        position: { x: 0, y: 0 },
          style: {
              color: token.colorText,
              border: '1px solid',
              borderColor: token.colorBorder,
              backgroundColor: token.colorBgElevated
          },
        data: {
          label: (
              <>
                Playbook Start
              </>
          ),
        }
      }
    ];

    // push the elements themselves
    Tasks.forEach((task) => {

        if(task.Playbook.Phase===2)
        {
            // Place holders, get replaced depending if complete or not
            const style = {};
            const ElementIconArea = null;

            // default icon
            let Ico = <ClockCircleOutlined/>;
            let BoltOn = <></>;

            let textColour = token.colorText
            let borderColour = token.colorBorder
            let backColour = token.colorBgBase
            let borderStyle = "dashed"

            // template new element
            const newElement = {
                id: task.id,
                position: {x: 0, y: 0},
                data: {
                    label: null
                },
                style: {
                    borderStyle: 'dotted',
                    width: 180,
                }
            };

            // Task in progress, use in progress style

            // Task is complete, use complete style.
            if(task.complete)
            {
                textColour = token.colorSuccessText
                borderColour = token.colorSuccessBorder
                backColour = token.colorSuccessBg
                borderStyle = "solid";

                Ico = <CheckOutlined style={{color: textColour}} />
            }

            // Task is wait for intervention and is assigned
            if(task.Status & 256)
            {

                textColour = token.colorText
                borderColour = token.colorBorder
                backColour = token.colorBgElevated
                borderStyle = "solid";

                BoltOn = <Button onClick={() => onTaskResume(task.id)}><PlayArrowOutlined/></Button>
                Ico = <PauseOutlined style={{color: textColour}} />
            }

            // Was skipped
            if(task.Status & 2048)
            {
                textColour = token.colorText
                borderColour = token.colorBorder
                backColour = token.colorBgElevated
                borderStyle = "solid";

                Ico = <FastForwardOutlined style={{color: textColour}} />
                BoltOn = <Text type="secondary">(skip)</Text>
            }

            if((typeof(task.taskflags) == "string" && task.taskflags.includes("NoSuitableAgent")) || (typeof(task.taskflags) == "number" && task.taskflags & 256))
            {
                textColour = token.colorText
                borderColour = token.colorBorder
                backColour = token.colorBgElevated
                borderStyle = "solid";

                Ico = <WarningOutlined />
            }

            if((typeof(task.taskflags) == "string" && task.taskflags.includes("InProgress")) || (typeof(task.taskflags) == "number" && task.taskflags & 16))
            {
                textColour = token.colorInfoText
                borderColour = token.colorInfoBorder
                backColour = token.colorInfoBg
                borderStyle = "solid";

                Ico = <LoadingOutlined />
            }

            if(task.Cancelled)
            {
                textColour = token.colorText
                borderColour = token.colorBorder
                backColour = token.colorBgElevated
                borderStyle = "dotted";

                Ico = <StopOutlined />
            } else if(task.Errors)
            {
                borderStyle = "solid";

                if(task.SoftFail===true)
                {
                    textColour = token.colorWarningText
                    borderColour = token.colorWarningBorder
                    backColour = token.colorWarningBg
                } else {
                    textColour = token.colorErrorText
                    borderColour = token.colorErrorBorder
                    backColour = token.colorErrorBg
                }

                Ico = <WarningOutlined style={{color: textColour}} />
            }

            newElement.style = {
                color: textColour,
                background: backColour,
                border: '1px ' + borderStyle + " " + borderColour,
                width: 180,
            };

            newElement.data.label = (<>
                <Space>
                    {Ico}
                    <Text style={{color: textColour}}>{task.Name}</Text>
                    {BoltOn}
                </Space>
            </>);

            rnodes.push(newElement);
        }

    });
  }

  return rnodes;

}

const AttackMap = ({ Tasks, onNodeClick, onTaskResume }) => {

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

    // Get theme token
    const { useToken } = theme
    const { token } = useToken();


    useEffect(() => {

      const n = NodesFromTasks(Tasks, onTaskResume, token);
      const e = EdgesFromTasks(Tasks, token);

      setNodes(n);
    setEdges(e);

    if(n != null && e != null)
    {
      getLayoutedElements(n,e);
    }
  },[Tasks]);


    return (
        <div style={{ height: 1000, backgroundColor: token.colorBgContainer }}>
          <ReactFlowProvider>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                //onNodeClick={(mouse,node) => onNodeClick(node)}
                fitView
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
            />
          </ReactFlowProvider>
        </div>
    );


};
export default AttackMap;