const DefenderProductTypes = [
    {
        SourceName: "AzureAdIdentityProtection",
        FriendlyName: "Azure AD Identity Protection",
        ShortName: "AADIP"
    },
    {
        SourceName: "MicrosoftDefenderForOffice",
        FriendlyName: "Defender for Office 365",
        ShortName: "MDO"
    },
    {
        SourceName: "MicrosoftDefenderForIdentity",
        FriendlyName: "Defender for Identity",
        ShortName: "MDI"
    },
    {
        SourceName: "MicrosoftDefenderForOffice365",
        FriendlyName: "Defender for Office 365",
        ShortName: "MDO"
    },
    {
        SourceName: "MicrosoftDefenderForCloudApps",
        FriendlyName: "Defender for Cloud Apps",
        ShortName: "MDCA"
    },
    {
        SourceName: "MicrosoftDefenderForEndpoint",
        FriendlyName: "Defender for Endpoint",
        ShortName: "MDE"
    },
    {
        SourceName: "Microsoft365Defender",
        FriendlyName: "Microsoft Defender",
        ShortName: "M365D"
    },
]

export default DefenderProductTypes;