import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Button, Drawer, Form, Input, Select, Space, Switch} from "antd";
import Text from "antd/es/typography/Text";
import {FileOutlined, LaptopOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import AgentSelect from "../agents/AgentSelect";
import FileReferenceSelector from "../files/FileReferenceSelector";
import IdentityInput from "../PlaybookInputs/Identity/IdentityInput.react";
import EmailTemplateInput from "../PlaybookInputs/EmailTemplate/EmailTemplateInput.react";

const PlaybookNewVariableDrawer = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // Construct the menu from all item types
    let menuItems = [];

    // Details form
    const [form] = Form.useForm();

    const [open, setOpen] = useState(false);

    const [SelectedItemType, setSelectedItemType] = useState(null);

    // Drawer open close
    const showDrawer = (parent) => {
        form.resetFields();
        setSelectedItemType(null);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleAddClick = (vals) => {

        let creationData = {
            Name: form.getFieldValue("Name"),
            Type: form.getFieldValue("Type"),
            Description: form.getFieldValue("Description"),
            Data: form.getFieldValue("Data")
        }

        props.onAdd(creationData);
        onClose();

    }

    return (
        <Drawer
            {...props}
            title="Add Variable"
            closable={true}
            onClose={onClose}
            open={open}>

            <Form layout="vertical"
                  form={form}
                  onFinish={() => handleAddClick()}
                  autoComplete="off">

                <Form.Item label="Variable Name (No Spaces)"
                           name="Name"
                           tooltip="The identifier is how you reference the variable in the playbook"
                           rules={[
                               { required: true },
                               {
                                   // Validate no existing entities with this name
                                   message: 'Name must be unique, there is already a variable with this name',
                                   validator: (_, value) => {
                                       if(props.existingVariables !== undefined)
                                       {
                                           let existingItem = props.existingVariables.find(x => x.Name == value);
                                           if(existingItem!==undefined)
                                           {
                                               return Promise.reject();
                                           } else {
                                               return Promise.resolve();
                                           }
                                       }

                                       return Promise.resolve();
                                   }
                               },
                               {
                                   message: 'Name must not contain spaces or special characters',
                                   validator: (_, value) => {
                                       if (/^[a-zA-Z0-9]+$/.test(value)) {
                                           return Promise.resolve();
                                       } else {
                                           return Promise.reject();
                                       }
                                   }
                               }
                           ]}>
                    <Input/>
                </Form.Item>

                <Form.Item name="Type" label="Variable Type" rules={[{ required: true }]}>
                    <Select style={{ width: 330}} listHeight={512} onChange={(v) => setSelectedItemType(v)}>

                        <Select.OptGroup label="Entities">
                            <Select.Option value="Identity">
                                <Space>
                                    <UserOutlined />
                                    <Text>Identity</Text>
                                </Space>
                            </Select.Option>

                            <Select.Option value="Agent">
                                <Space>
                                    <LaptopOutlined />
                                    <Text>Agent</Text>
                                </Space>
                            </Select.Option>

                            <Select.Option value="FileReference">
                                <Space>
                                    <FileOutlined />
                                    <Text>File Reference</Text>
                                </Space>
                            </Select.Option>

                            <Select.Option value="EmailTemplate">
                                <Space>
                                    <MailOutlined />
                                    <Text>Email Template</Text>
                                </Space>
                            </Select.Option>

                        </Select.OptGroup>

                        <Select.OptGroup label="Free form variables">
                            <Select.Option value="String">
                                <Space direction="vertical">
                                    <Text>String</Text>
                                    <Text type="secondary">Text only fields</Text>
                                </Space>
                            </Select.Option>

                            <Select.Option value="Boolean">
                                <Space direction="vertical">
                                    <Text>Boolean</Text>
                                    <Text type="secondary">True, False, Yes, No</Text>
                                </Space>
                            </Select.Option>
                        </Select.OptGroup>
                    </Select>

                </Form.Item>

                <Form.Item label="Description / Use" name="Description"  rules={[{ required: true }]}>
                    <Input/>
                </Form.Item>

                {SelectedItemType === "String" &&
                    <Form.Item label="Default Option" name="Data" tooltip="Select default string (optional)">
                        <Input/>
                    </Form.Item>
                }

                {SelectedItemType === "Boolean" &&
                    <Form.Item label="Default Option" name="Data" tooltip="Select default boolean (optional)">
                        <Switch />
                    </Form.Item>
                }

                {SelectedItemType === "FileReference" &&
                    <Form.Item label="Default Option" name="Data" tooltip="Select default file reference (optional)">
                        <FileReferenceSelector />
                    </Form.Item>
                }

                {SelectedItemType === "Identity" &&
                    <Form.Item label="Default Option" name="Data" tooltip="Select default identity (optional)">
                        <IdentityInput />
                    </Form.Item>
                }

                {SelectedItemType === "EmailTemplate" &&
                    <Form.Item label="Default Option" name="Data" tooltip="Select default email template (optional)">
                        <EmailTemplateInput />
                    </Form.Item>
                }

                <Form.Item>
                    <Button type="primary" htmlType="submit">Create</Button>
                </Form.Item>
            </Form>

        </Drawer>

    )

});

export default PlaybookNewVariableDrawer;