import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Badge, Button, Descriptions, Drawer, Form, Input, Modal, Select, Space, Spin, Tabs, Tag} from "antd";
import {BulbOutlined, ControlOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import {useForm} from "antd/es/form/Form";
import {useQuery} from "@tanstack/react-query";
import DateTimeView from "../../misc/DateTimeView";
import PlaybookTaskTable from "../../MyPlaybooks/PlaybookTaskTable";
import TaskList from "../../tasks/TaskList";
import DrawerEditProps from "../../../classes/DrawerEdit/DrawerEditProps";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import {SettingsOutlined} from "@mui/icons-material";
import AgentEditSetupTab from "./AgentEditSetupTab/AgentEditSetupTab";

const AgentEditDrawer = forwardRef<DrawerRef, DrawerEditProps>(({onChange}, ref) => {
    useImperativeHandle(ref, () => ({
        showDrawer: showDrawer
    }));

    // API
    const {token} = useContext(AppContext);
    const API = new APIProvider(token, true);

    // State
    const [Open, SetOpen] = useState(false);
    const [AgentID, SetAgentID] = useState<string | undefined>(undefined);

    // Refs
    const [form] = useForm();

    // React queries
    const {
        data: Agent,
        isLoading: Loading,
        refetch: refetch,
    }  = useQuery({
            queryKey: ["agent", AgentID],
            queryFn: async() => await API.getAgent(AgentID),
            enabled: AgentID !== undefined && AgentID !== null ? true : false
        }
    );

    const {
        data: AgentTasks,
        isLoading: LoadingTasks,
    }  = useQuery({
            queryKey: ["agenttasks", AgentID],
            queryFn: async() => await API.getAgentTasks(AgentID),
            enabled: AgentID !== undefined && AgentID !== null ? true : false
        }
    );

    // Funcs
    const showDrawer = (id:string) => {
        SetAgentID(id);

        refetch().then(() => {
            form.resetFields();
            SetOpen(true);
        });
    }

    const handleSave = async () => {
        // Create dto edit object

        let dtoEditObject = form.getFieldsValue(true);

        const modal = Modal.success({
            title: 'Saving',
            content: `Agent is being saved, please hold.`,
        });

        //await API.(AdminID, dtoEditObject);

        modal.destroy();
        handleClose();

        if(onChange !== undefined)
        {
            onChange();
        }
    }

    const handleClose = () => {
        form.resetFields();
        SetOpen(false);
        SetAgentID(undefined);
    }

    return (
        <Drawer
            title={Agent === undefined ? "Loading" : Agent.Name}
            width={ window.innerWidth - 200}
            closable={true}
            onClose={handleClose}
            open={Open}>

            {Loading && <Spin/>}

            {!Loading && Agent !== undefined && Agent !== null &&
                <Tabs defaultActiveKey="1">

                    <Tabs.TabPane
                        tab={
                            <span>
                            <BulbOutlined/>
                            Detail
                        </span>
                        }
                        key="1">

                        <Descriptions title="Information" bordered column={2}>

                            <Descriptions.Item label="ID">{Agent.id}</Descriptions.Item>
                            <Descriptions.Item label="Creator">{Agent.operator}</Descriptions.Item>
                            <Descriptions.Item label="Last Seen">
                                <DateTimeView DateTime={Agent.checkInTime}/>
                            </Descriptions.Item>

                        </Descriptions>


                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <span>
                            <BulbOutlined/>
                            Capabilities
                        </span>
                        }
                        key="2">
                        {
                            Agent.capability && Agent.capability.map((capability:any) => {
                                if (capability.adopted) {
                                    var color = "blue";
                                    var addonIcon = "check"

                                    // special capabilities
                                    if (capability.name === "TOR") {
                                        color = "orange";
                                        addonIcon = "activity";
                                    }

                                    if (capability.name === "Debug") {
                                        color = "orange";
                                        addonIcon = "activity";
                                    }
                                } else {
                                    var color = "grey";
                                    var addonIcon = ""
                                }
                                return (
                                    <Tag color={color}>{capability.name}</Tag>
                                );
                            })
                        }

                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <span>
                            <BulbOutlined/>
                            Characteristics
                        </span>
                        }
                        key="3">


                        {
                            Agent.Characteristics && Agent.Characteristics.map((chara:any) => {
                                return (
                                    <Tag color="orange">{chara}</Tag>
                                );
                            })
                        }
                    </Tabs.TabPane>

                    {Agent.Characteristics && Agent.Characteristics.some((x:string) => x == "Interactive") &&
                        <Tabs.TabPane tab={
                            <span>
                            <ControlOutlined />
                            Interactive Setup
                            </span>
                        }
                      key="setup">
                            <AgentEditSetupTab AgentID={Agent.id} />

                        </Tabs.TabPane>
                    }

                    <Tabs.TabPane
                        tab={

                                <span>
                                    <BulbOutlined/>
                                    Tasks
                                </span>
                        }
                        key="tasks">

                        <TaskList Tasks={AgentTasks} />

                    </Tabs.TabPane>

                </Tabs>
            }
        </Drawer>

    )

});

export default AgentEditDrawer;