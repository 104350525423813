enum LabUserState {
    /// <summary>
    /// Account is unmanaged
    /// </summary>
    Unmanaged=0,

    /// <summary>
    /// Account is new and has not attested
    /// </summary>
    New=1,

    /// <summary>
    /// Account is active and has attested
    /// </summary>
    Active=2,

    /// <summary>
    /// Account is in warning state (attestation not performed)
    /// </summary>
    Warn=3,

    /// <summary>
    /// Account is disabled
    /// </summary>
    Disabled=4,

    /// <summary>
    /// Account is deleted
    /// </summary>
    Deleted=5
}

export default LabUserState;