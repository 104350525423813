import React, {useContext, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {Alert, Button, Dropdown, Image, Modal, Space, Table, Typography} from "antd";
import {AppContext} from "../../../AppProvider";
import DrawerRef from "../../../classes/DrawerRef/DrawerRef";
import APITProvider from "../../../service/api";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusCircleOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWandMagicSparkles} from "@fortawesome/free-solid-svg-icons";
import ProxyPhishletAddDrawer from "../ProxyPhishletAdd";
import CGContextsAddDrawer from "./CGContextsAddDrawer";


const CGContextsSegment = () =>
{

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // Refs
    const refCGContextsAddDrawer = useRef<DrawerRef>(null);

    const {
        data: CGContexts,
        isLoading: isLoadingContexts,
        refetch: refetch
    } = useQuery(
        ["cgcontexts"],
        async () => await APIT.CGContextsGet()
    );

    // return on change properties

    return (
        <>

            <CGContextsAddDrawer ref={refCGContextsAddDrawer} onChange={refetch} />

            <Alert
                message="What are content generation contexts?"
                description="Content generation contexts are prompts that are used against LLM's to generate content."
                type="info"
                showIcon
                closable={true}
                style={{marginBottom:10}}
            />

            <Button icon={<PlusCircleOutlined />} onClick={() => refCGContextsAddDrawer.current !== null ? refCGContextsAddDrawer.current.showDrawer() : null}>Add</Button>

            <Table
                dataSource={CGContexts}
                rowKey="id"
                pagination={false}
                loading={isLoadingContexts}
                columns={[
                    {
                        title: "ID",
                        key: 'id',
                        width:150,
                        render: (record) => {
                            return <Space>
                                <FontAwesomeIcon icon={faWandMagicSparkles} />
                                <Typography.Text>{record.id}</Typography.Text>
                            </Space>
                        },
                    },
                    {
                        title: "Parent",
                        key: 'ParentContextID',
                        width:150,
                        render: (record) => {
                            return <Typography.Text>{record.ParentContextID}</Typography.Text>
                        },
                    },
                    {
                        title: "Message",
                        key: 'Message',
                        dataIndex: 'Message',
                        render: (text) => text,
                    }
                ]}
            />
        </>
    )
}

export default CGContextsSegment;