import React, {FC } from 'react'
import { Input } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import PlaybookVariableDropdownMenu from "../../FormItems/Support/PlaybookVariableDropdownMenu";

interface OnChangeHandler {
    (e:any): void;
}

interface PlaybookTextInput {
    value?: string | undefined;
    onChange?: OnChangeHandler;
    showContextVariables?: boolean;
    DependencyOutputs: VariableList[];
    PlaybookVariables: VariableList[];
}

interface VariableList {
    id: string,
    entryText: string,
    Name: string,
    Type: string,
    VarType: string,
    Description: string,
    OutputTask: string
}

const PlaybookTextInput: FC<PlaybookTextInput> = ({value, onChange, PlaybookVariables, DependencyOutputs, showContextVariables}: PlaybookTextInput) => {

    const handleMenuClick = (e:string) => {
        if(onChange !== undefined)
        {
            if(value)
            {
                onChange(value + e);
            } else {
                onChange(e);
            }
        }
    };

    return <Input value={value} onChange={onChange} addonAfter={
        <PlaybookVariableDropdownMenu DependencyOutputs={DependencyOutputs} PlaybookVariables={PlaybookVariables} showContextVariables={showContextVariables} onClick={handleMenuClick}>
            <LoginOutlined/>
        </PlaybookVariableDropdownMenu>
    }/>
}

export default PlaybookTextInput;