import React, {useEffect, useState} from "react";
import {Card, Col, Form, Input, Modal, Row, Slider, Switch} from 'antd';

const BehaviourUserModal = (props) =>
{
    const [BehaviourUser, setBehaviourUser] = useState(null);
    const [Loaded,setLoaded] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {

        if(props.Behaviour !== undefined)
        {
            setBehaviourUser(props.Behaviour);
            form.initialValues = props.Behaviour;
            setLoaded(true);
        }

    },[props.Behaviour]);

    const handleFinish = values => {
        console.dir(values);
    };

    return (
            <Modal
                title="User Behaviour"
                width={1000}
                onCancel={props.onCancel}
                visible={true}
                onOk={form.submit}>

                { Loaded &&
                    <Form
                        layout="vertical"
                        initialValues={BehaviourUser}
                        form={form}
                        onFinish={handleFinish}>

                        <Form.Item name="id" label="Unique Behaviour Name" required tooltip="Descriptive unique name">
                            <Input />
                        </Form.Item>

                        <Row gutter={24}>
                            <Col span={12}>
                                <Card title="Normal User Behaviour" bordered={true}>
                                    <Form.Item name="emailsPerUser" label="Emails per relationship"><Slider /></Form.Item>
                                    <Form.Item name="linkClickPct" label="Links clicked %"><Slider /></Form.Item>
                                    <Form.Item name="linkClickPctClickthrough" label="Link click through %"><Slider /></Form.Item>
                                    <Form.Item name="linkClickPctCompromiseBad" label="% of bad links clicked which compromise"><Slider /></Form.Item>
                                    <Form.Item name="linkClickPctCompromiseOK" label="% of good links clicked which compromise"><Slider /></Form.Item>
                                    <Form.Item name="phishReportPct" label="% of phish email reported"><Slider /></Form.Item>
                                    <Form.Item name="startDayCompromisedPct" label="% of days user starts compromised"><Slider /></Form.Item>
                                    <Form.Item name="checksJunk" label="Checks junk folder" valuePropName="checked"><Switch /></Form.Item>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card title="Compromised User Behaviour" bordered={true}>
                                    <Form.Item name="compromisedPhishPerUser" label="Phish emails to send per relationship"><Slider /></Form.Item>
                                    <Form.Item name="compromisedMalwarePerUser" label="Malware emails to send per relationship"><Slider /></Form.Item>
                                    <Form.Item name="compromisedMalwarePctSig" label="% of malware signature based"><Slider /></Form.Item>
                                    <Form.Item name="compromisedPhishCampaigns" label="Campaigns to send per day"><Slider /></Form.Item>
                                    <Form.Item name="compromisedPhishWADPct" label="% of links weaponized post delivery"><Slider /></Form.Item>
                                </Card>
                            </Col>
                        </Row>

                    </Form>
                }
            </Modal>

    )
}

export default BehaviourUserModal;