import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Button, Form, Input, Select, Space, Table,
} from "antd";
import Text from "antd/es/typography/Text";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    CheckCircleTwoTone,
    CheckOutlined,
    CloseCircleOutlined, CloseCircleTwoTone,
    DeleteOutlined
} from "@ant-design/icons";
import ProxyPhishletModification from "../../../../../Models/ProxyPhishlet/ProxyPhishletModification";
import ProxyPhishletModificationEditItem from "./ProxyPhishletModificationEditItem";

// @ts-ignore
const ProxyPhishletModificationEdit = ({value, onChange }) => {

    const [Actions, SetActions] = useState<any[]>([]);
    const [Loaded, SetIsLoaded] = useState(false);

    useEffect(() => {

        console.log("Value change");

        if((value !== null || value !== undefined) && !Loaded)
        {
            // Assign each value an id
            if(Array.isArray(value))
            {
                value.forEach((p) => {

                    // Change 0 Order to 1
                    if(p.Priority===0)
                    {
                        p.Priority=1;
                    }

                    p.id = Math.floor(Math.random() * 100000000)
                })

                SetActions(value);
                SetIsLoaded(true);
            }

        }
    }, [value, Loaded])

    useEffect(() => {
        onChange(Actions);
    }, [Actions])

    const handleAdd = () => {
        let newValues = Actions;

        if(newValues == undefined || newValues == null)
        {
            newValues = [];
        }

        // new object should be +1 highest Order
        let highestOrder = 0;

        // Sort by Order order
        newValues = newValues.sort((a, b) => a.Priority - b.Priority);

        // Get the last Order order
        if(newValues.length > 0)
        {
            highestOrder = newValues[newValues.length-1].Priority;
        }

        let newAction = {
            "id": Math.floor(Math.random() * 100000000),
            "Priority":highestOrder+1
        }

        SetActions(oldArray => [...oldArray, newAction]);
    }

    const handleRowChange = (newRow:any) => {
        // Remove
        let filteredArray = Actions.filter(item => item.id !== newRow.id)
        filteredArray.push(newRow);
        SetActions(filteredArray);
    }

    const handleProgressChange = (direction:any,record:any) => {

        console.dir(record);

        let oldOrder = record.Priority;
        let newOrder = record.Priority;
        let newActions = Actions.sort((a:ProxyPhishletModification, b:ProxyPhishletModification) => a.Priority - b.Priority);

        // Remove existing
        newActions = newActions.filter((item:any) => item.id !== record.id)

        if(direction==="up")
        {
            if(newOrder === 1)
            {
                return false;
            }

            newOrder = oldOrder -1;
        }

        if(direction==="down")
        {
            let highestOrder = Actions[Actions.length-1].Priority;

            if(record.Priority >= highestOrder)
            {
                // Already highest Order
                return false;
            }

            newOrder = oldOrder + 1;
        }

        // Find anything at this Order already
        let currentOrderItem = Actions.find(item => item.Priority === newOrder);

        if(currentOrderItem !== undefined)
        {

            // switch the priorities around
            newActions = newActions.filter(item => item.id !== currentOrderItem.id)
            currentOrderItem.Priority = oldOrder;
            newActions.push(currentOrderItem);
        }

        // Set Order
        record.Priority = newOrder;

        newActions.push(record);

        // Set
        SetActions(newActions);

    }

    const handleRemove = (record:any) => {
        let filteredArray = Actions.filter((item:any) => item.id !== record.id)
        SetActions(filteredArray);
    }

    return (
        <>
            <Button onClick={handleAdd}>Add Rule</Button>
            <Table
                dataSource={Actions}
                rowKey="id"
                pagination={false}
                sortDirections={["ascend"]}
                expandable={{
                    expandedRowRender: (record) => <ProxyPhishletModificationEditItem value={record} onChange={handleRowChange}/>,
                }}
                columns={[
                    {
                        title: "Priority",
                        key: 'Priority',
                        width:5,
                        dataIndex: 'Priority',
                        sorter: (a:ProxyPhishletModification, b:ProxyPhishletModification) => a.Priority - b.Priority,
                        sortDirections: ["ascend"],
                        defaultSortOrder: "ascend",
                        render: text => text
                    },
                    {
                        title: "Applies To (URL Match)",
                        key: 'Match',
                        dataIndex: 'Match',
                        render: text => text
                    },
                    {
                        title: "Cookie Capture",
                        key: 'CookieCaptures',
                        dataIndex: 'CookieCaptures',
                        render: text => text !== undefined && text !== null && text.length > 0 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleOutlined style={{color: "#d4d2d2"}} />
                    },
                    {
                        title: "Cookie Manipulation",
                        key: 'ManipulateCookies',
                        dataIndex: 'ManipulateCookies',
                        render: text => text == true ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleOutlined style={{color: "#d4d2d2"}} />
                    },
                    {
                        title: "Auto Proxied URLs",
                        key: 'CaptureURLs',
                        dataIndex: 'CaptureURLs',
                        render: text => {
                            return (
                            <Space direction="vertical">
                                {text && text.map((item: string) => {
                                        return (
                                            <Text>{item}</Text>
                                        )
                                    })
                                }
                            </Space>
                            )
                        }
                    },
                    {
                        title: "",
                        key: 'Opts',
                        render: (record) => {
                            return (
                                <Space>
                                    <CaretUpOutlined onClick={() => handleProgressChange("up", record)} />
                                    <CaretDownOutlined onClick={() => handleProgressChange("down", record)} />
                                    <DeleteOutlined onClick={() => handleRemove(record)} />
                                </Space>
                            )
                        }
                    }
                ]}
            />
        </>
    )
};

export default ProxyPhishletModificationEdit;