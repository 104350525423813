import {Spin, Tag} from "antd";
import {useQuery} from "@tanstack/react-query";
import React, {useContext} from "react";
import {AppContext} from "../../../AppProvider";
import APITProvider from "../../../service/api";
import {faVial} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface AttackTCRTagProps {
    AttackID: string;
}
const AttackTCRTag = (props:AttackTCRTagProps) => {

    // Construct token
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // React query
    const {
        data: AttackTCR,
        isLoading: isLoading
    } = useQuery(
        {
            queryKey: ["attacktcr", props.AttackID],
            enabled: props.AttackID !== undefined && props.AttackID !== null,
            queryFn: props.AttackID !== undefined && props.AttackID !== null ? async () => await APIT.getAttackTcr(props.AttackID) : undefined
        }
    );

    if(props.AttackID && !isLoading)
    {

        let tagColour = undefined;
        let tagText = "";

        // Total tests is 0
        if(AttackTCR?.countTotal === 0)
        {
            return <></>
        }

        if(AttackTCR?.Complete)
        {
            if(AttackTCR.Pass)
            {
                tagColour = "success";
                tagText = "Pass";
            } else {
                tagColour = "error";
                tagText = AttackTCR.countFail +" fail / " + AttackTCR.countTotal;
            }
        } else {
            tagText = "pending";
        }

        if(AttackTCR !== undefined)
        {
            return <>
                {AttackTCR.countPass !== 0 && <Tag icon={<FontAwesomeIcon icon={faVial} style={{paddingRight:5}} />} color="success">{AttackTCR.countPass}</Tag> }
                {AttackTCR.countFail !== 0 && <Tag icon={<FontAwesomeIcon icon={faVial} style={{paddingRight:5}}/>} color="error">{AttackTCR.countFail}</Tag> }
                {AttackTCR.countPending !== 0 && <Tag icon={<FontAwesomeIcon icon={faVial} style={{paddingRight:5}}/>}>{AttackTCR.countPending}</Tag> }
            </>
        }


        return <Tag icon={<FontAwesomeIcon icon={faVial} />} color={tagColour}>{tagText}</Tag>
    }

    if(isLoading) {
        return <Spin/>
    }

    return <></>
}

export default AttackTCRTag;