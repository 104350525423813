import React, {useEffect, useState} from "react";
import {Space, Table} from "antd";
import Text from "antd/es/typography/Text";
import {DevicesOutlined} from "@mui/icons-material";
import {DesktopOutlined, FileOutlined, LinkOutlined, LoginOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";


const PlaybookElementVariableTable = (props) =>
{

    const [Variables,SetVariables] = useState();

    useEffect(() => {

        let newVariables = []

        props.PlaybookVariables.forEach((v) => {
            v.Source = "Playbook";
            newVariables.push(v);
        })

        SetVariables(newVariables);

        console.dir(newVariables);

    },[props.PlaybookVariables])


    return (
        <Table
            dataSource={Variables}
            rowKey="id"
            pagination={false}
            columns={[
                {
                    title: "Source",
                    key: 'Source',
                    render: (record) => {
                        if(record.OutputTask)
                        {
                            return <Text>Task {record.OutputTask}</Text>
                        }
                        return <Text>Playbook</Text>
                    }
                },
                {
                    title: "Type",
                    key: 'VarType',
                    dataIndex: 'VarType',
                    width:20,
                    render: (text) => {
                        let icon = <LoginOutlined />;

                        switch (text) {
                            case "Identity":
                                icon = <UserOutlined />
                                break;
                            case "WebsiteReference":
                                icon = <LinkOutlined />
                                break;
                            case "FileReference":
                                icon = <FileOutlined />
                                break;
                            case "Agent":
                                icon = <DesktopOutlined />
                                break;
                        }

                        return <Space>
                            {icon}
                            {text}
                        </Space>
                    }
                },
                {
                    title: "Name",
                    key: 'id',
                    dataIndex: 'id',
                    render: (text) => <Text>{text}</Text>
                },
                {
                    title: "Description",
                    key: 'Description',
                    dataIndex: 'Description',
                    render: (text) => <Text>{text}</Text>
                }
            ]}
        />
    )
}

export default PlaybookElementVariableTable;