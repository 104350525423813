import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Button, Checkbox, Form, Input, Modal, Select, Space, Switch, Table,
} from "antd";
import Text from "antd/es/typography/Text";
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../AppProvider";
import APIProvider from "../../../service/api2";
import DefenderProductTypes from "../../defender/DefenderProductTypes";
import {useForm} from "antd/es/form/Form";
import defenderProductTypes from "../../defender/DefenderProductTypes";

const ExpectedAlertsInput = ({value, onChange }) => {

    // Construct token
    const {token} = useContext(AppContext);
    const API = new APIProvider(token);

    const [Loaded, SetIsLoaded] = useState(false);
    const [ModalOpen, SetModalOpen] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const {
        data: DefenderAlertTypes,
        isLoading: isLoading
    } = useQuery(
        ["defenderalerttypes"],
        async () => await API.getDefenderAlertTypes()
    );

    const getFilterTags = () => {
        let array = [];

        DefenderProductTypes.forEach((p) => {
            array.push({
                text: p.FriendlyName,
                value: p.SourceName
            })
        });

        return array;
    }

    const handleShowModal = () => {
        // Find existing entities

        if(value!==undefined && value !== null)
        {
            value.forEach((alert) => {
                if(alert.id === undefined)
                {
                    // find based on sourcename and title
                    let foundAlert = DefenderAlertTypes.find(x => x.serviceSource === alert.serviceSource && x.Title === alert.title);

                    if(foundAlert!==undefined)
                    {
                        setSelectedRowKeys(current => [...current, foundAlert.id]);
                    }

                } else {
                    setSelectedRowKeys(current => [...current, alert.id]);
                }
            })
        }

        SetModalOpen(true);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    // Set state
    const handleModalOk = () => {
        let newValues = [];
        selectedRowKeys.forEach((id) => {
            // Find item
            let item = DefenderAlertTypes.find(x => x.id == id);
            if(item !== undefined)
            {
                newValues.push({id: id, serviceSource: item.serviceSource, title: item.Title})
            }

        })
        onChange(newValues);
        SetModalOpen(false);
    }

    return (
        <>
            <Button onClick={handleShowModal}>Change</Button>
            <Modal open={ModalOpen} onCancel={() => SetModalOpen(false)} onOk={handleModalOk} title="Select available alerts" width={800}>
                    <Table
                        dataSource={DefenderAlertTypes}
                        rowKey="id"
                        rowSelection={rowSelection}
                        columns={[
                            {
                                title: "Source",
                                dataIndex: 'serviceSource',
                                filters: getFilterTags(),
                                onFilter: (value, record) => record.serviceSource !== null && record.serviceSource.includes(value),
                                render: (text) => {
                                    // try to find in defender product types
                                    let humanText = text;
                                    let product = DefenderProductTypes.find(p => p.SourceName === text);

                                    if (product !== undefined) {
                                        humanText = product.FriendlyName;
                                    }

                                    return <Text>{humanText}</Text>
                                }
                            },
                            {
                                title: "Title",
                                dataIndex: 'Title',
                                render: (text) => text
                            }
                        ]}
                    />
            </Modal>
            <Table
                dataSource={value}
                rowKey="title"
                pagination={false}
                sortDirections="ascend"
                columns={[
                    {
                        title: "Source",
                        dataIndex: 'serviceSource',
                        render: (text) => {
                            // try to find in defender product types
                            let humanText = text;
                            let product = DefenderProductTypes.find(p => p.SourceName === text);

                            if(product !== undefined)
                            {
                                humanText = product.FriendlyName;
                            }

                            return <Text>{humanText}</Text>
                        }
                    },
                    {
                        title: "Title",
                        dataIndex: 'title',
                        render: (text) => text
                    }
                ]}
            />
        </>
    )
};

export default ExpectedAlertsInput;