import {Table, Tag} from "antd";
import React, {useContext} from "react";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../../../../AppProvider";
import APITProvider from "../../../../service/api";
import Text from "antd/es/typography/Text";
import TaskTag from "../../../tasks/TaskTag/TaskTag";
import WebSessionActionScreenshot from "../../../tasks/WebSessionAction/WebSessionActionScreenshot.react";

interface AttackTestsTabProps {
    AttackID: string
}

const AttackFilesTable = ({AttackID}:AttackTestsTabProps) => {

    // API
    const {token} = useContext(AppContext);
    const APIT = new APITProvider(token, true);

    // React queries
    const {
        data: AttackTCR,
        isLoading: isLoading
    } = useQuery(
        {
            queryKey: ["attacktcr", AttackID],
            enabled: AttackID !== undefined && AttackID !== null,
            queryFn: AttackID !== undefined && AttackID !== null ? async () => await APIT.getAttackTcr(AttackID) : undefined
        }
    );

    return <Table
        dataSource={AttackTCR?.Tests}
        rowKey="id"
        pagination={false}
        //expandable={{ expandedRowRender }}
        loading={isLoading}
        columns={[
            {
                title: "Description",
                key: 'Description',
                dataIndex: "Description",
                render: text => <Text>{text}</Text>
            },
            {
                title: "Task",
                key: 'TaskID',
                dataIndex: "TaskID",
                render: text => <TaskTag TaskID={text}/>
            },
            {
                title: "Time to Pass",
                key: 'TimeToPass',
                dataIndex: "TimeToPass",
                render: text => {
                    if(!text)
                    {
                        return "N/A";
                    } else {
                        return text;
                    }
                }
            },
            {
                title: "State",
                key: 'State',
                render: record => {
                    let tagColour = undefined;
                    let tagText = "Pending";

                    if(!record.Pass && record.Complete)
                    {
                        tagColour = "error";
                        tagText = "Failed";
                    }

                    if(record.Pass && record.Complete)
                    {
                        tagColour = "success";
                        tagText = "Pass";
                    }

                    return <Tag color={tagColour}>{tagText}</Tag>
                }
            },
        ]}
    />
}

export default AttackFilesTable;