import React, {useEffect, useState} from 'react';

import ReactFlow, {Background, Controls, ReactFlowProvider} from 'react-flow-renderer';

import dagre from 'dagre';
import PlaybookItemTypes from "./PlaybookItemTypes";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

/*

This playbook map is used for read only viewing of playbooks

 */

const PlaybookMap = ({ Playbook }) => {

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  // For automapping elements
  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const isHorizontal = direction === "LR";

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: "TB" });

    nodes.forEach((n) => {
      dagreGraph.setNode(n.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((e) => {
      dagreGraph.setEdge(e.source, e.target);
    });

    dagre.layout(dagreGraph);

    nodes.map((el) => {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    });

  };

  const EdgesFromTasks = (Tasks,Nodes) => {

      const redges = [];

      console.dir(Nodes);

    if(Tasks != null)
    {
      Tasks.forEach((task) => {

        // create connectors

        // find the node for this task
          const Node = Nodes.find(x => x.data.id === task.id);

          const AddPathTo0 = false;

          if (task.depends !== null && typeof task.depends !== "undefined" && task.depends.length > 0) {
          // undefinedAray is not empty

          task.depends.forEach((dep) => {

            // find sourcenode
              const SourceNode = Nodes.find(x => x.data.id === dep);

              const ElPath = {
                  id: dep + "to" + task.id,
                  source: SourceNode.id,
                  target: Node.id
              };

              if(task.dependsDelayMin!=null)
            {
              if(task.dependsDelayMin!==0) ElPath.label = task.dependsDelayMin + " min";
            }

            redges.push(ElPath);

          });

        } else {
          redges.push({
            id: "0to" + task.id,
            source: 'start',
            target: Node.id
          });
        }
      });

    }

  console.dir(redges);

    return redges;
  }

  const NodesFromTasks = (Tasks) => {

      const rnodes = [{id: 'start', type: "input", data: {label: 'Playbook Start'}, position: {x: 250, y: 0}}];

      if(Tasks != null)
    {

      // push the elements themselves
      Tasks.forEach((task) => {

          const PlaybookItemType = PlaybookItemTypes.find(i => i.TaskType === task.$type);

        if(PlaybookItemType === undefined || PlaybookItemType === null)
        {
          console.log("Undefined " + task.$type);
        }

          let DisplayName = PlaybookItemType.Display;

          if(task.Name !== undefined && task.Name !== null)
          {
              DisplayName = task.Name;
          }

        // template new element
          const newElement = {
              id: '' + task.id,
              position: {x: 0, y: 0},
              data: {
                  id: task.id,
                  t: task.$type,
                  label: DisplayName
              }
          };

          /*newElement.data.label = (<>
            {task.description}
          </>);*/

        rnodes.push(newElement);

      });
    }

    console.dir(rnodes);

    return rnodes;

  }

  useEffect(() => {
    // tell parent to re-build playbook
    console.log("Playbook changed");

    if(Playbook != null)
    {

        const n = NodesFromTasks(Playbook.tasks);
        const e = EdgesFromTasks(Playbook.tasks, n);

        setNodes(n);
      setEdges(e);

      // auto move
      getLayoutedElements(n,e);
    }

  },[Playbook]);

  useEffect(() => {
    if (reactFlowInstance && nodes.length) {
      reactFlowInstance.fitView();
    }
  }, [reactFlowInstance, nodes]);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onLoad = (rf) => {
    console.dir(rf);
    setReactFlowInstance(rf);
  };

  return (

    <ReactFlowProvider>
      <ReactFlow
          nodes={nodes}
          edges={edges}
          onLoad={onLoad}
          fitView={true}
      >
        <Controls/>
        <Background
            variant="dots"
            gap={20}
            color="#d3d3d3"
            size={1}
        />
      </ReactFlow>
    </ReactFlowProvider>

  );
};
export default PlaybookMap;