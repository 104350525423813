import React, {useCallback, useEffect} from 'react';
import {Handle, Position} from 'react-flow-renderer';

import {Badge, Button, Space, Tag, theme, Tooltip} from "antd";
import Text from "antd/es/typography/Text";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";

const { useToken } = theme;

function BasicEditorNode({ data }) {
    // Get theme token
    const { token } = useToken();

    const onChange = useCallback((evt) => {
        console.log(evt.target.value);
    }, []);


    useEffect(() => {
        // tell parent to re-build playbook
        console.dir(Position.Bottom);
    },[]);

    const handleEditClick = () => {
        data.onEditClick(data.Task);
    }

    const handleDeleteClick = () => {
        data.onDeleteClick(data.Task);
    }

    const handleAddDependantClick = () => {
        data.onAddDependantClick(data.Task);
    }

    const getName = () => {

        if(data.isStartElement)
        {
            return "Playbook Start";
        }

        if(data.Task !== undefined && data.Task.Name !== undefined && data.Task.Name !== null)
        {
            return data.Task.Name;
        }
    }

    return (
        <div className="basic-editor-node" style={{background: token.colorBgElevated, borderColor: token.colorBorder}}>
            <Handle type="target" position={Position.Top} />

            <Space>
                {data.isStartElement && <Text>{getName()}</Text> }
                {!data.isStartElement && <Space>
                    <Badge count={data.Task.id} style={{
                        background: token.colorSuccessBorder,
                        borderColor: token.colorSuccessActive,
                        color: token.colorSuccessTextActive}}/>
                    <Text>{getName()}</Text>
                </Space>}

                {data.onAddDependantClick &&
                    <Button shape="circle" icon={<PlusOutlined/>} size="small" onClick={handleAddDependantClick}/>
                }

                {data.onEditClick &&
                    <Tooltip title="Edit Task">
                        <Button shape="circle" icon={<EditOutlined/>} size="small" onClick={handleEditClick}/>
                    </Tooltip>
                }

                {data.onDeleteClick &&
                    <Tooltip title="Delete Task">
                        <Button shape="circle" icon={<DeleteOutlined />} size="small" onClick={handleDeleteClick}/>
                    </Tooltip>
                }

            </Space>

            <Handle type="source" position={Position.Bottom} id="a"/>

        </div>
    );
}

export default BasicEditorNode;
