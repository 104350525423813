import {Tag} from 'antd';

import React from "react";
import {
    CheckOutlined,
    ClockCircleOutlined,
    FolderOpenOutlined,
    LoadingOutlined,
    WarningOutlined
} from "@ant-design/icons";

const AttackStatusIcon = (props) =>
{

    const pe = props.Attack;

    let status = "Scheduled";
    let statusco = "orange";
    let statusico = "clock";

    let Ico = <ClockCircleOutlined/>;

    let Phase = "Main";

    /*
        None=0,
        Running=1,              // Is actively running
        Completed=2,            // Has completed all tasks
        Cancelled=4,            // Cancelled
        Scheduled=8,            // Is scheduled for the future
        Archived=128            // Kept for storage but tasks & sites have been removed
    */

    if(pe != null)
    {

        if(pe.Phase === 1)
        {
            Phase = "Preperation";
        }

        if(pe.Phase === 2)
        {
            Phase = "Main";
        }

        if(pe.Phase === 4)
        {
            Phase = "Clean-up";
        }

        if(pe.Status & 1)
        {
            status="Running | " + Phase;
            statusco = "blue";
            Ico = <LoadingOutlined />
        }

        if(pe.Status & 1 && pe.Errors)
        {
            status="Running | " + Phase + " | Errors";
            statusco = "blue";
            Ico = <LoadingOutlined />
        }

        if(pe.Status & 2)
        {
            status="Completed";
            statusco="green";
            Ico=<CheckOutlined />
        }

        if(pe.Status & 4)
        {
            status="Cancelled";
            statusco="default";
            Ico=<WarningOutlined />
        }

        if(pe.Status & 8)
        {
            status="Scheduled";
            statusco="default";
            Ico = <ClockCircleOutlined />;
        }

        if(pe.Status & 128)
        {
            status="Archived";
            statusco="lightgray";
            Ico = <FolderOpenOutlined />;
        }

        if(pe.Errors && pe.Status & 2)
        {
            status="Completed | Errors";
            statusco="red";
            Ico=<WarningOutlined />
        }

    }

    return (
        <Tag icon={Ico} color={statusco}>{status}</Tag>
    )
}

export default AttackStatusIcon;