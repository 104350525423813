// @flow

import React, {Component} from "react";


import {Col, Form, Row, Select} from 'antd';
import {AppleOutlined, WindowsOutlined} from "@ant-design/icons";
import {faDocker} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const { Option } = Select;

class AgentSelect extends Component {


  render() {
    return (

        <Select
            showSearch
            style={{width: '100%'}}
            placeholder="Select a agent"
            onChange={event => this.props.onChange(event)}
            optionLabelProp="label"
            optionFilterProp="children"
            loading={this.props.loading}
            filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
            }
        >
            {
                !this.props.loading && this.props.Agents.map((a, i) => {

                    let Ico = <WindowsOutlined/>;

                    if(a.os === "Mac")
                    {
                        Ico = <AppleOutlined/>
                    }

                    if(a.os === "Linux")
                    {
                        Ico = <FontAwesomeIcon icon={faDocker} />
                    }

                    return (
                        <Select.Option value={a.id} label={a.Name}>
                            <Row gutter={5} align="middle">
                                <Col>
                                    {Ico}
                                </Col>
                                <Col>
                                    {a.Name}
                                </Col>
                            </Row>
                        </Select.Option>
                    )
                })
            }

        </Select>
  
    );
  }
}

export default AgentSelect;
