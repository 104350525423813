import React from "react";
import {Tag} from "antd";
import {
    AimOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    FastForwardOutlined,
    LoadingOutlined,
    PauseOutlined, StopOutlined,
    WarningOutlined
} from "@ant-design/icons";

const TaskStatusIcon = (props) =>
{

    const task = props.task;

    let status = "Scheduled";
    let statusco = "grey";
    let statusico = "clock";
    let Ico = <ClockCircleOutlined/>;

    if(task.owner!=="any" && task.owner!=null)
    {
      status="Assigned";
      statusco = "gray";
        Ico = <AimOutlined />
    }

    if((typeof(task.taskflags) == "string" && task.taskflags.includes("Triggered")) || (typeof(task.taskflags) == "number" && task.taskflags & 16384))
    {
      status="Triggered";
      statusco = "blue";
      Ico = <AimOutlined />
    }

    if(task.waitingOnDependencies)
    {
      status="Waiting on Dependency";
      statusco="grey";
        Ico = <ClockCircleOutlined />;
    }

    if((typeof(task.taskflags) == "string" && task.taskflags.includes("NoSuitableAgent")) || (typeof(task.taskflags) == "number" && task.taskflags & 256))
    {
      status="No Suitable Agent";
      statusco="grey";
      Ico = <WarningOutlined />;
    }

    if((typeof(task.taskflags) == "string" && task.taskflags.includes("InProgress")) || (typeof(task.taskflags) == "number" && task.taskflags & 16))
    {
      status="In Progress";
      statusco="blue";
      Ico = <LoadingOutlined />
    }

    if((typeof(task.taskflags) == "string" && task.taskflags.includes("Complete")) || (typeof(task.taskflags) == "number" &&task.taskflags & 64))
    {
      status="Completed";
      statusco="green";
        Ico=<CheckOutlined />
    }

    if(task.Cancelled)
    {
        status="Cancelled";
        statusco="default";
        Ico=<StopOutlined />
    } else if(task.Errors)
    {

        if(task.softFail===true) {
            status = "Error | Soft Fail";
            statusco = "orange";
        } else if(task.Cancelled)
        {
            status="Error | Cancelled";
            statusco="red";
        } else if(task.complete)
        {
            status="Error | Complete";
            statusco="red";
        } else {
            status="Error";
            statusco="red";
        }

        Ico=<WarningOutlined />
    }

    if((typeof(task.taskflags) == "string" && task.taskflags.includes("DependencyFailed")) || (typeof(task.taskflags) == "number" && task.taskflags & 512))
    {
      status="Dependency Failed";
      statusco="red";
        Ico=<WarningOutlined />
    }

    /*
    None=0,
    Scheduled=1,
    NoSuitableAgent=2,
    Assigned=4,
    InProgress=8,
    Completed=16,
    Cancelled=32,
    FailedDependency=64,
    FailedErrors=128
    */

    if((typeof(task.Status) == "string" && task.Status.includes("Cancelled")) || (typeof(task.Status) == "number" && task.Status & 32))
    {
      status="Cancelled";
      statusco="lightgray";
      statusico="alert-triangle";
    }

    if((typeof(task.Status) == "string" && task.Status.includes("Cancelled")) || (typeof(task.Status) == "number" && task.Status & 512))
    {
        status="Timed Out";
        statusco="lightgray";
        statusico="alert-triangle";
    }

    if(task.Status & 256)
    {
        status="Paused";
        statusco="lightgray";

        Ico=<PauseOutlined />
    }

    if(task.Status & 2048)
    {
        status="Skipped";
        statusco="darkgray";

        Ico=<FastForwardOutlined />
    }




    return (
        <Tag icon={Ico} color={statusco}>{status}</Tag>
    )
}

export default TaskStatusIcon;